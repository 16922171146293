import { useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { utils, writeFileXLSX } from 'xlsx';
import { getPermission, payrollRawDataToDownload } from '../../../common/utils';
import moment from 'moment';
import { Span } from '../../../components/common/Span';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from '../../../components/common/Button';
import { useHistory, useLocation } from 'react-router-dom';
import Search from 'antd/lib/input/Search';

const TitleAndButtons = ({ data, search, setSearch }) => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')
    const pathname = useLocation().pathname;
    const history = useHistory();
    const permissions = getPermission('payroll', 'canViewPayrollListSub')

    const exportFile = useCallback(() => {
        const PayrollRaw = utils.json_to_sheet(payrollRawDataToDownload(data));
        const wb = utils.book_new();
        utils.book_append_sheet(wb, PayrollRaw, "raw");
        writeFileXLSX(wb, `payroll_${moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}.xlsx`);
    }, [data]);

    return (
        <div className={`flex w-full ${mobile ? 'flex-col justify-center items-center px-4 pb-4' : 'justify-between items-center px-4'}`}>
            <div>
                <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                    <Span margin='mt-4' weight='bold' size='2xl'>Payroll</Span>
                </div>
            </div>

            <div>
                <Search
                    placeholder="Search any..."
                    onChange={(e: any) => setSearch(e.target.value)}
                    style={{ width: mobile ? '100%' : 300 }}
                />

                {permissions?.includes('canDownloadPayrollList') && (
                    <Button
                        padding='px-2'
                        border='blue'
                        bg='none'
                        color='blue'
                        radius='none'
                        onClick={exportFile}
                        {...(!mobile ? {
                            width: 'none',
                            margin: 'mx-2'
                        } : {
                            margin: 'mt-2'
                        })}
                    >
                        <div className='flex w-full justify-center items-center'>
                            <Span padding='pr-2' color='blue'>Download</Span>
                            <DownloadOutlined />
                        </div>
                    </Button>
                )}
            </div>
        </div>
    )
}

export default TitleAndButtons