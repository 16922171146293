import BRAND_LOGO from "../../assets/images/logo.png";
import BRAND_LOGO_WHITE from '../../assets/images/logo-white.png'
import NO_DATA from "../../assets/images/Nodata_icon.png";

export const BRAND_NAME = "Holo";

export const BRAND_IMAGES = {
  BRAND_LOGO,
  BRAND_LOGO_WHITE,
  NO_DATA
};

export const DEFAULT = [
  'organization admin',
  'super admin',
  'department head',
  'group head',
  'organization head'
]

const PERMISSION_1 = [
  {
    name: 'Can View',
    action: 'canView'
  },
  {
    name: 'Can Edit',
    action: 'canEdit'
  },
  {
    name: 'Can Create',
    action: 'canCreate'
  },
  {
    name: 'Can Add Manual Log',
    action: 'canAddManualLog'
  },
  {
    name: 'Can Set Schedule',
    action: 'canSetSchedule'
  },
  {
    name: 'Can Set Admin',
    action: 'canSetAdmin'
  },
  {
    name: 'Can Change Password',
    action: 'canChangePassword'
  },
  {
    name: 'Can Delete',
    action: 'canDelete'
  }
]

const PERMISSION_2 = [
  {
    name: 'Can View',
    action: 'canView'
  },
  {
    name: 'Can Edit',
    action: 'canEdit'
  },
  {
    name: 'Can Add Logs',
    action: 'canAddLogs'
  },
  {
    name: 'Can Export',
    action: 'canExport'
  },
  {
    name: 'Can Read Coordinates',
    action: 'canReadCoordinates'
  }
]

const PERMISSION_3 = [
  {
    name: 'Can View',
    action: 'canView'
  },
  {
    name: 'Can Edit',
    action: 'canEdit'
  },
  {
    name: 'Can Create',
    action: 'canCreate'
  },
  {
    name: 'Can Delete',
    action: 'canDelete'
  }
]

const PERMISSION_4 = [
  {
    name: 'Can Handle Employee',
    action: 'canHandleEmployee'
  },
  {
    name: 'Can Handle Dept. Head',
    action: 'canHandleDeptHead'
  },
  {
    name: 'Can Handle Group Head',
    action: 'canHandleGroupHead'
  },
  {
    name: 'Can Handle Org. Head',
    action: 'canHandleOrgHead'
  }
]

export const PERMISSIONS = [
  {
    name: 'Manage Employees',
    role: 'employees',
    permission: PERMISSION_1
  },
  {
    name: 'Manage Timesheet',
    role: 'timesheet',
    permission: PERMISSION_2
  },
  {
    name: 'Manage Organization',
    role: 'organization',
    permission: PERMISSION_3
  },
  {
    name: 'Manage Role',
    role: 'role',
    permission: PERMISSION_3
  },
  {
    name: 'Manage Leave',
    role: 'leave',
    permission: [...PERMISSION_3, ...PERMISSION_4]
  },
  {
    name: 'Manage Overtime',
    role: 'overtime',
    permission: [...PERMISSION_3, ...PERMISSION_4]
  },
  {
    name: 'Manage Manual Log',
    role: 'log',
    permission: [...PERMISSION_3, ...PERMISSION_4]
  },
  {
    name: 'Manage Workflow',
    role: 'workflow',
    permission: PERMISSION_3
  },
  {
    name: 'Manage Payroll',
    role: 'payroll',
    permission: [
      {
        name: 'Can View Contract',
        action: 'canViewContract',
        sub: PERMISSION_3?.slice(1)
      },

      {
        name: 'Can View Payroll List',
        action: 'canViewPayrollList',
        sub: [
          {
            name: 'Can Download Payroll List',
            action: 'canDownloadPayrollList',
          },
        ]
      },
      {
        name: 'Can View E&D',
        action: 'canViewE&D',
        sub: PERMISSION_3?.slice(1)
      },
      {
        name: 'Can View Payslip',
        action: 'canViewPayslip',
        sub: [
          {
            name: 'Can Print Payslip',
            action: 'canPrintPayslip',
          },
          {
            name: 'Can Send Payslip',
            action: 'canSendPayslip',
          },
          {
            name: 'Can Save Payslip',
            action: 'canSavePayslip',
          },
        ]
      },
      {
        name: 'Can View Logs',
        action: 'canViewLogs',
        sub: [
          {
            name: 'Can Edit',
            action: 'canEdit',
          },
        ]
      },
      {
        name: 'Can View Report',
        action: 'canViewReport',
        sub: [
          {
            name: 'Can Print Report',
            action: 'canPrintReport',
          },
          {
            name: 'Can Confirm',
            action: 'canConfirm',
          }
        ]
      },
    ]
  },
  {
    name: 'Manage Benefits',
    role: 'benefits',
    permission: [
      {
        name: 'Can View SSS',
        action: 'canViewSSS',
        sub: [
          {
            name: 'Can Edit',
            action: 'canEdit'
          }
        ]
      },
      {
        name: 'Can View PHIC',
        action: 'canViewPHIC',
        sub: [
          {
            name: 'Can Edit',
            action: 'canEdit'
          }
        ]
      },
      {
        name: 'Can View HDMF',
        action: 'canViewHDMF',
        sub: [
          {
            name: 'Can Edit',
            action: 'canEdit'
          }
        ]
      },
      {
        name: 'Can View TAX',
        action: 'canViewTAX',
        sub: [
          {
            name: 'Can Edit',
            action: 'canEdit'
          }
        ]
      }
    ]
  },
  {
    name: 'Manage Calendar',
    role: 'calendar',
    permission: PERMISSION_3
  },
  {
    name: 'Manage Credentials',
    role: 'credentials',
    permission: PERMISSION_3
  }
]

export const IMAGE_TYPE = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg",
  "image/webp",
]

export const AVATAR = {
  leave: 'https://firebasestorage.googleapis.com/v0/b/holo-pro.appspot.com/o/internal%2Fleave.jpg?alt=media&token=92469f0a-129e-41e8-9be9-51c13fccbafa',
  manual_attendance: 'https://firebasestorage.googleapis.com/v0/b/holo-pro.appspot.com/o/internal%2Fplus.png?alt=media&token=fe5f5ed8-cfd1-4e22-9fc6-ddc4a828f9cc',
  overtime: 'https://firebasestorage.googleapis.com/v0/b/holo-pro.appspot.com/o/internal%2Fovertime.jpg?alt=media&token=478a724e-46f1-4cd6-af08-51261399ced1'
}

export const ED_NAME = {
  add_earnings: 'earnings',
  add_deductions: 'deductions'
}

export const ED_RESERVE_NAMES = [
  "tardiness",
  "undertime",
  "sss ee contribution",
  "sss er contribution",
  "phic ee contribution",
  "phic er contribution",
  "hdmf ee contribution",
  "hdmf er contribution",
  "hmo ee contribution",
  "hmo er contribution",
  "withholding tax",
  "basic salary",
  "night differential",
  "overtime",
  "regular holiday",
  "special holiday",
  "sick leave",
  "vacation leave",
]

export const DEFAULT_DEDUCTIONS = [
  'hdmfEmployeeShare',
  'phicEmployeeShare',
  'sssEmployeeShare',
  'taxEmployee',
  'undertimeDeduction',
  'latetimeDeduction'
]

export const DEFAULT_EARNINGS = [
  'overtimePay',
  'regularHolidayPay',
  'sickLeavePay',
  'specialHolidayPay',
  'vacationLeavePay',
  'worktimePay'
]

export const FILTER_ORGANIZATION_1 = [
  'contracts',
  'calendar',
  'todayAllTimesheet',
  'todayCompletedTask',
  'yesterdayAllTimesheet',
  'yesterdayCompletedTask',
  'todayActiveEmployees'
];

export const FILTER_ORGANIZATION_2 = [
  'employees',
  'alltimesheet',
  'leave',
  'log',
  'overtime',
  'workflow'
];