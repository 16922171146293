import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "antd/dist/antd.less";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from "./store";
import { getPersistor } from '@rematch/persist';
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/lib/integration/react";

if (["development", "staging"].includes(process.env.REACT_APP_ENVIRONMENT)) {
  window.__redux_store = store;
}

const options = {
  position: positions.MIDDLE_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1000000
  }
};

const persistor = getPersistor();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Suspense fallback={<></>}>
            <App />
        </Suspense>
      </AlertProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
