import moment from "moment";
import { useLocation } from "react-router-dom";
import { store } from "../../store";
import ExcelJS from 'exceljs';

export const isPendingAndForPickupExceedsTimeLimit = (status, createdAt, startDelivery) => {
	const currentTime = new Date().getTime();
	const created_At = new Date(createdAt * 1000);
	const start_Delivery = new Date(startDelivery * 1000);

	const createdTime = created_At.setMinutes(created_At.getMinutes() + 10);
	const startdeliveryTime = start_Delivery.setMinutes(start_Delivery.getMinutes() + 30);

	const isPendingMorethan10Minutes = status === 'pending' && currentTime > createdTime ? true : false;
	const isForpickupMorethan30Minutes = status === 'for_pickup' && currentTime > startdeliveryTime ? true : false;
	const result = isPendingMorethan10Minutes || isForpickupMorethan30Minutes ? true : false;

	return result;
}

export const isTimeExceed30Minutes = (seconds) => {
	const currentTime = new Date().getTime() / 1000;
	const timeDue = seconds + (30 * 60);
	const result = currentTime > timeDue ? true : false;

	return result;
}

export const processFirebaseError = (err: any) => {
	let result: string | null = null;
	let [title, message] = err?.code?.split('/');

	if (!title || !message) return result;

	title = removeSCAndCapitalize(title);
	message = removeSCAndCapitalize(message, '-');

	result = `${title}: ${message}`;

	return result;
}

export const formatHolidate = (d) => {
	const today = new Date()
	const holiday = d.date + "/" + today.getFullYear()
	return holiday;
}

export const formatDateStartEnd = (seconds: any) => {

	const formattedSD = moment(new Date(seconds * 1000)).format('MMM. DD, YYYY');

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 9)).format('hh:mm a');

	const finalDateFormat = `${formattedSD} (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateStartEndHalfday = (seconds: any) => {

	const formattedSD = moment(new Date(seconds * 1000)).format('MMM. DD, YYYY');

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 5)).format('hh:mm a');

	const finalDateFormat = `${formattedSD} (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}


export const formatDateStartEndCompleted = (seconds: any) => {

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 9)).format('hh:mm a');

	const finalDateFormat = ` (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateStartEndHalfdayCompleted = (seconds: any) => {

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 5)).format('hh:mm a');

	const finalDateFormat = ` (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateV2 = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMMM Do YYYY | h:mm:ss a')
	return formattedDate;
}

export const formatDate = (date, seconds = false) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format(`MM-DD-YYYY, h:mm${seconds ? ':ss' : ''} a`)
	return formattedDate;
}

export const formatDateOnly = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMM DD, YYYY')
	return formattedDate;
}

export const onlyNumberKey = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		return evt.preventDefault();
	}
	return true;
}

export const getRoleModulesAndObjectOfRoleAccesses = (userCredentials) => {
	const modules = userCredentials?.role?.roleAccesses?.map(role => role?.module?.key);
	const objectOfRole = objectOfRoleAccesses(modules, userCredentials);

	const paylaod = {
		modules,
		objectOfRole
	}

	return paylaod;
}

export const objectOfRoleAccesses = (modules, userCredentials) => {
	let object = {};
	modules?.forEach(mod => {
		object[mod] = userCredentials?.role?.roleAccesses?.filter(role => role?.module?.key === mod)[0];
	});
	return object;
}

export const useQuery = () => {
	return new URLSearchParams(useLocation()?.search);
}

export const removeSCAndCapitalize = (text, seperator = '-') => {
	const result = text?.split(seperator)?.map(str => str[0]?.toUpperCase() + str?.substring(1))?.join(' ');
	return result;
}

export const replaceSCAndJoinBy = (text, joinBy) => {
	const regex = /\s+/g;
	const result = text?.toLowerCase().replace(regex, joinBy);
	return result;
}

export const firebaseDateToText = (time, format = 'MMM DD YYYY HH:MM:SS') => {

	if (!time?.seconds && !time?._seconds) {
		return "";
	}

	const seconds = time?.seconds ?? time?._seconds;

	const dateText = moment(new Date(seconds * 1000)).format(format)
	return dateText;
}

export const logsheetDataToDownload = (data) => {
	const arr = data?.sort((a, b) => a?.clockIn?.time?.seconds - b?.clockIn?.time?.seconds)?.map(item => {
		const d = {
			date: moment(new Date(item?.clockIn?.time?.seconds * 1000)).format('YYYY-MM-DD'),
			clockin_time: moment(new Date(item?.clockIn?.time?.seconds * 1000)).format('LTS'),
			clockout_time: moment(new Date(item?.clockOut?.time?.seconds * 1000)).format('LTS'),
			employee_number: item?.userInfo?.employeeNumber,
			lastname: item.userInfo?.lastName ?? item.userInfo?.name?.split(' ')[2],
			firstname: item.userInfo?.firstName ?? item.userInfo?.name?.split(' ')[0],
			middlename: item.userInfo?.middleName ?? item.userInfo?.name?.split(' ')[1],
			user_email: item.userInfo?.email,
			user_position: item.userInfo?.position,
			user_group: item.userInfo?.group,
			type: item?.type ?? 'Regular'
		}
		return d;
	})
	return arr;
}

export const employeeDataToDownload = (data) => {
	const arr = data?.sort((a, b) => a?.clockIn?.time?.seconds - b?.clockIn?.time?.seconds)?.map(item => {
		const d = {
			Employee_ID: item?.userInfo?.employeeNumber,
			Last_Name: item.userInfo?.lastName ?? item.userInfo?.name?.split(' ')[2],
			Middle_Name: item.userInfo?.middleName ?? item.userInfo?.name?.split(' ')[1],
			First_Name: item.userInfo?.firstName ?? item.userInfo?.name?.split(' ')[0]
		}
		return d;
	})
	return arr;
}

export const attendancePerEmployeeDataToDownload = (data) => {
	const arr = data
		?.sort((a, b) => { return a?.clockIn?.time?.seconds - b?.clockIn?.time?.seconds })
		?.map(item => firebaseDateToText(item?.clockIn?.time, "YYYY-MM-DD"))
	const employees = data?.map(e => e?.userInfo?.id);
	const uniqueDates = [...new Set(arr)];
	const uniqueEmployees = [...new Set(employees)];

	const result = uniqueDates?.map(key => {
		const employeeEqualToDate = data?.filter(e => firebaseDateToText(e?.clockIn?.time, "YYYY-MM-DD") === key);
		const res = employeeEqualToDate?.map(d => {
			const object = {
				date: key,
				timeIn: !d?.clockIn?.time ? 'None' : firebaseDateToText(d?.clockIn?.time, "LTS"),
				timeOut: !d?.clockOut?.time ? 'None' : firebaseDateToText(d?.clockOut?.time, "LTS"),
				name: d?.userInfo?.name,
				type: d?.type ?? 'Regular',
				userId: d?.userInfo?.id
			}
			return object;
		})

		return res;
	})

	const finalResult = uniqueEmployees?.map(ue => {
		const empData: any = [].concat(...result)?.filter((e: any) => e?.userId === ue);
		let object = {};
		['Employee Name', ...uniqueDates]?.forEach((date: any) => {
			const value: any = empData?.filter((d: any) => d?.date === date);
			object[date] = date === 'Employee Name' ? empData[0]?.name : `${value?.map((v, i) => `${value?.length > 1 ? `(${v?.type})` : ''}Time In: ${v?.timeIn} Time Out: ${v?.timeOut}`)}`
		})
		return object;
	})


	return finalResult;
}

export const attendancePerEmployeeDataToDownloadV2 = (data) => {
	const arr = data
		?.sort((a, b) => {
			if (a?.userInfo?.name < b?.userInfo?.name) {
				return -1;
			} else if (a?.userInfo?.name > b?.userInfo?.name) {
				return 1;
			} else {
				return 0;
			}
		})
		.map((d: any) => {
			const res = [
				{
					Name: d?.userInfo?.name,
					"No.": d?.userInfo?.employeeNumber,
					"Date/Time": firebaseDateToText(d?.clockIn?.time, 'L LT'),
					Status: 'C/In'
				},
				{
					Name: d?.userInfo?.name,
					"No.": d?.userInfo?.employeeNumber,
					"Date/Time": firebaseDateToText(d?.clockOut?.time, 'L LT'),
					Status: 'C/Out'
				}
			]
			return res;
		});

	const result = [].concat(...arr)

	return result;
}

export const getPermission = (module: string, sub: any = 'none') => {
	const permissions = store.getState().User?.objectOfRoleModules;

	const permission = permissions[module];

	let result;
	let subResult;

	if (!permission) {
		result = [];
	} else {
		result = Object?.entries(permission)?.map(([key, val]: any) => {
			if (sub === key) {
				const res = Object?.entries(val)?.map(([key, value]) => {
					if (value) return key;
					return false;
				})

				subResult = res;
			};

			if (key?.includes('Sub')) return false;

			if (val) return key;
			return false
		})
	}

	result = result.concat(subResult);

	return result;
}

export const getArrayOfSelectedDays: any = (leaveStart: any, leaveEnd: any) => {
	const startDay = new Date(leaveStart);
	const endDay = new Date(leaveEnd);

	const getDates = () => {
		const date = new Date(startDay.getTime());

		const dates: Array<any> = [];

		while (date <= endDay) {
			dates.push(moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss'));
			date.setDate(date.getDate() + 1);
		}

		return dates;
	}

	const arrayOfDates = getDates();

	return arrayOfDates;
}

export const numOfLeaveHours = (data) => {
	const res = moment(new Date(data?.leaveStart)).diff(moment(new Date(data?.leaveEnd)), 'hours') ?? 0;
	return res;
};

export const generateFilterForRequests = (permissions, userInfo) => {
	let filters;

	if (permissions.includes('canHandleOrgHead')) {
		filters = {
			approver: {
				field: 'approver_level',
				operation: 'in',
				value: [0, 1, 2, 3, 4]
			}
		}
	} else if (permissions.includes('canHandleGroupHead')) {
		filters = {
			approver: {
				field: 'approver_level',
				operation: 'in',
				value: [0, 1, 2]
			}
		}
	} else if (permissions.includes('canHandleDeptHead')) {
		filters = {
			equalGroup: {
				field: 'userInfo.group',
				operation: '==',
				value: userInfo?.group
			},
			approver: {
				field: 'approver_level',
				operation: 'in',
				value: [0, 1]
			}
		}
	} else if (permissions.includes('canHandleEmployee')) {
		filters = {
			equalDept: {
				field: 'userInfo.department',
				operation: '==',
				value: userInfo?.department
			},
			approver: {
				field: 'approver_level',
				operation: 'in',
				value: [0]
			}
		}
	}

	return filters;
}

export const getBase64 = async (file) => {
	const res = await new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (err) => reject(err);
	})

	return res;
}

export const employeeRateTemplate = (data) => {

	const arr = data?.sort((a, b) => a?.lastName - b?.lastName)?.map(item => {
		const d = {
			id: item?.id,
			Last_Name: item?.lastName ?? item?.name?.split(' ')[2],
			Middle_Name: item?.middleName ?? item?.name?.split(' ')[1],
			First_Name: item?.firstName ?? item?.name?.split(' ')[0],
			Employee_Number: item?.employeeNumber,
			Rate_Type: item?.rateType,
			Rate_Amount: item?.rateAmount ?? 'Please enter employee rate per hour here!',
		}
		return d;
	})
	return arr;
}

export const createDefaultTemplate = (data) => {
	const arr = data?.sort((a, b) => a?.lastName - b?.lastName)?.map(item => {
		const d = {
			id: item?.id,
			Last_Name: item?.lastName ?? item?.name?.split(' ')[2],
			Middle_Name: item?.middleName ?? item?.name?.split(' ')[1],
			First_Name: item?.firstName ?? item?.name?.split(' ')[0],
			Employee_Number: item?.employeeNumber,
			Rate_Type: 'Hourly Rate',
			Rate_Amount: 71.25,
		}
		return d;
	})
	return arr;
}

export const generateDefaultExcelFile = async (employeesWithoutContract) => {
	const json_file = createDefaultTemplate(employeesWithoutContract);

	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Template');

	//set headers
	worksheet.columns = Object.keys(json_file[0])?.map((key) => {
		const res = {
			header: key,
			key: key
		}

		return res;
	})

	//add rows and make headers bold
	worksheet.addRows(json_file);
	worksheet.getRow(1).font = { bold: true }

	worksheet.columns.forEach((col: any) => {
		col.width = 25;
	})

	for (let i = 0; i <= json_file?.length; i++) {
		const item = json_file[i];
		const cell = worksheet.getCell(`F${i + 2}`);

		cell.dataValidation = {
			type: 'list',
			formulae: ['"Please select rate type, Hourly Rate, Daily Rate, Monthly Rate"'],
			showErrorMessage: true,
			errorTitle: 'Invalid Value',
			error: 'Please select a value from the list.',
			promptTitle: 'Select Value',
			prompt: 'Choose a value from the list.',
		};
		cell.value = 'Hourly Rate';
	}

	const buffer = await workbook.xlsx.writeBuffer();
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

	return blob;
}

export const addSpaceAndCapitalize = (data) => {
	let result = {};

	for (const key in data) {
		const value = data[key];

		const newKey = key?.replace(/([A-Z])/g, " $1")?.toUpperCase();
		result[newKey] = value;
	}

	return result;
}

export const payrollRawDataToDownload = (data) => {

	const result = data?.map((d) => {

		const benefits = addSpaceAndCapitalize(d?.benefits);
		const deductions = addSpaceAndCapitalize(d?.deductions);
		const earnings = addSpaceAndCapitalize(d?.earnings);
		const all = addSpaceAndCapitalize(d);

		const res = {
			'ID': d?.userInfo?.id,
			'NAME': d?.userInfo?.name,
			'EMPLOYEE NUMBER': d?.userInfo?.employeeNumber,
			'SALARY CYCLE RANGE': `From: ${d?.salaryRange?.from} - To: ${d?.salaryRange?.to}`,
			...benefits,
			...deductions,
			...earnings,
			...all
		}

		delete res['SALARY RANGE'];
		delete res['USER INFO'];
		delete res['BENEFITS'];
		delete res['EARNINGS'];
		delete res['DEDUCTIONS'];

		return res;
	})

	return result;
}

export const onlyNumberKeyDotAndNegative = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		if (ASCIICode === 46 || ASCIICode === 45) {
			return true
		}
		return evt.preventDefault();
	}
	return true;
}

export const isNumber = (value: any) => {
	return !isNaN(Number(value));
}


export const currencyCommaFormat = (value: any, decimal = 0, hasPesoSign: boolean = false) => {
	if (isNumber(value)) {
		return `${hasPesoSign ? '₱ ' : ''}${Number(value).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	} else {
		return `${hasPesoSign ? '₱ ' : ''}${String(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	}
};

export const convertArrayOfObjectIntoNumbers = (data) => {
	const result = data?.map((obj) => {
		for (const key in obj) {
			const val = obj[key];
			obj[key] = Number(val);
		}

		return obj;
	})

	return result;
}

export const getSalaryCycleRange = (salaryCycle, date: any = null) => {
	const defaultFromDate = '2023-01-01';
	const defaultDate = date && date !== defaultFromDate ? moment(date) : moment();

	let from, to;

	switch (salaryCycle) {
		case 'daily':
			from = defaultDate;
			to = defaultDate;
			break;
		case 'weekly':
			const weeklyFrom = defaultDate.clone().startOf('week');
			const weeklyTo = defaultDate.clone().endOf('week');
			from = weeklyFrom
			to = weeklyTo
			break;
		case 'semi-monthly':
			const currentDate = defaultDate.clone().date();
			const startOfSemiMonthly = defaultDate.clone().date(1);
			const endOfSemiMonthly = currentDate > 15 ? defaultDate.clone().endOf('month') : startOfSemiMonthly.clone().add(14, 'days');
			const semiMonthlyFrom = currentDate < 16 ? startOfSemiMonthly : startOfSemiMonthly.clone().add(15, 'days');
			const semiMonthlyTo = endOfSemiMonthly;
			from = semiMonthlyFrom
			to = semiMonthlyTo
			break;
		case 'monthly':
			const monthlyFrom = defaultDate.clone().startOf('month');
			const monthlyTo = defaultDate.clone().endOf('month');
			from = monthlyFrom
			to = monthlyTo
			break;
		default:
			from = null
			to = null
			break;
	}

	return { from: from?.format('YYYY-MM-DD'), to: to?.format('YYYY-MM-DD') }
}

export const contructContractsForReports = (contracts, from, to) => {

	const startDate = moment(from);
	const endDate = moment(to);
	const format = 'YYYY-MM-DD';

	const result = [] as any;

	for (const contract of contracts) {

		switch (contract?.salary_cycle) {
			case 'daily':
				for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
					const dailyResult = {
						...contract,
						salaryRange: {
							from: date.clone().format(format),
							to: date.clone().format(format)
						}
					}

					result.push(dailyResult);
				}
				break;
			case 'weekly':
				for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
					if (date.day() === 0) {
						const weeklyResult = {
							...contract,
							salaryRange: {
								from: date.clone().startOf('week').format(format),
								to: date.clone().endOf('week').format(format)
							}
						}

						result.push(weeklyResult);
					}
				}
				break;
			case 'semi-monthly':
				for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
					if (date.date() === 1) {
						const startOfBimonthly = {
							...contract,
							salaryRange: {
								from: date.clone().format(format),
								to: date.clone().add(14, 'days').format(format)
							}
						}

						result.push(startOfBimonthly);
					}

					if (date.date() === 16) {
						const endOfBimonthly = {
							...contract,
							salaryRange: {
								from: date.clone().format(format),
								to: date.clone().endOf('month').format(format)
							}
						}

						result.push(endOfBimonthly);
					}
				}
				break;
			case 'monthly':
				for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
					if (date.date() === 1 || date.date() === date.clone().endOf('month').date()) {
						const monthlyResult = {
							...contract,
							salaryRange: {
								from: date.clone().startOf('month').format(format),
								to: date.clone().endOf('month').format(format)
							}
						}

						result.push(monthlyResult);
						break;
					}
				}
				break;
			default:
				from = null
				to = null
				break;
		}

	}

	return result;
}

export const getDatesBetweeen = (start: any, end: any, format = 'YYYY-MM-DD') => {
	const startDate = moment(start);
	const endDate = moment(end);
	const daysDiff = endDate.diff(startDate, 'days');

	const datesBetween: any = [];

	for (let i = 0; i <= daysDiff; i++) {
		const date = startDate.clone().add(i, 'days').format(format);
		datesBetween.push(date);
	}

	return datesBetween;
}


export const getOrganizationLogo = (image_name) => {
	const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/internal%2F${image_name}?alt=media&token=d6906702-5904-40b0-b872-adac2fc94a5e`;

	return source;
}

export const getRate = (rate: any, percent: any) => {
	const res = (rate / 100) * percent;
	return res;
}

export const downloadPayslipPDF = (blobData, filename) => {
	const blob = new Blob([blobData], { type: 'application/pdf' });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	link.click();
}