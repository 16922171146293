import { Paper } from '@mui/material';
import React, { useState } from 'react'
import { BRAND_IMAGES } from '../../../../components/constants';
import { Span } from '../../../../components/common/Span';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { useHistory } from 'react-router-dom';
import { verifyEmail } from '../../../../common/api/endpoints/client';
import { UserOutlined } from '@ant-design/icons';
import { InputContainer } from '../../../../components/common/Containers';
import { Input } from '../../../../components/common/Input';
import { Modal, Spin } from 'antd';
import { Button } from '../../../../components/common/Button';

const Email = () => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();

    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const res = await verifyEmail(email);
            console.log('------------------------>', res);
            if (res?.isSuccess) {
                setLoading(false);
                history.push('/sesuccess');
            }
        } catch (err) {

        }
    }

    return (
        <Paper
            elevation={3}
            sx={{
                width: { xs: '90vw', sm: '460px' },
                minHeight: '500px',
                padding: { xs: '10px 20px', sm: '30px 50px' },
                justifyContent: 'center',
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '1'
            }}
        >
            <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

            <div className='flex flex-col justify-start items-start'>
                <Span letters='wider' size='2xl' weight='bold'>Verification</Span>
                <span className='mt-1 text-[13px] font-[500]'>Please verify your account 💁</span>
            </div>

            {error && (
                <div className='text-center mt-4 w-full p-2 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                    <span className='text-xs text-red-500'>
                        {errMsg}
                    </span>
                </div>
            )}

            <form onSubmit={handleSubmit} className='w-full'>
                <InputContainer margin='mt-4' border='grey' flex='row'>
                    <UserOutlined className='ml-2' />
                    <Input
                        disabled={loading}
                        value={email}
                        onChange={(e: any) => {
                            setEmail(e?.target?.value)
                        }}
                        placeholder='sample@gmail.com'
                        border='none'
                        required
                        type='email'
                    />
                </InputContainer>

                <Button
                    shadow='grey'
                    padding='p-10'
                    margin='mt-6'
                    width='full'
                    disabled={loading}
                    type='submit'
                >
                    {loading ? (
                        <Spin className='login_spin' size='small' />
                    ) : 'Submit'}
                </Button>
            </form>
        </Paper>
    )
}

export default Email