import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../../store';
import { currencyCommaFormat, getOrganizationLogo, removeSCAndCapitalize, useQuery } from '../../../../common/utils';
import { Span } from '../../../../components/common/Span';
import { GridContainer } from '../../../../components/common/Containers';
import moment from 'moment';
import Earnings from './Earnings/Earnings';
import Deductions from './Deductions/Deductions';
import { DEFAULT_DEDUCTIONS, DEFAULT_EARNINGS } from '../../../../components/constants';
import DeductionItem from './Deductions/DeductionItem';
import EarninItem from './Earnings/EarninItem';
import { Tabs } from 'antd';
import { Button } from '../../../../components/common/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { AiOutlineUpload } from 'react-icons/ai';

const PayslipView = ({ select, setSelect, users, setUsers }) => {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();

    const [activeKey, setActivKey] = useState('0');

    const { date } = useSelector((state: RootState) => state.Payroll);
    const contracts = useSelector((state: RootState) => state.Table.tables.contracts.data);
    const currentContract = contracts?.find(c => c?.id === select?.contract?.id);

    const contract = select?.contract;
    const organization = contract?.organization;
    const user = select?.userInfo;
    const key = query.get('activeKey');
    const id = query.get('id');
    const image_source = getOrganizationLogo(organization?.logo);

    const handleChangeTab = (key) => {
        const currentpayslip = select?.overlappedPayslips[key];

        const payload = {
            ...currentpayslip,
            overlappedPayslips: select?.overlappedPayslips
        }

        setActivKey(key);
        setSelect(payload)

        dispatch.Payroll.updateData(payload);
    }

    const handleUpdate = () => {
        history.push(`${pathname}?activeKey=${key}&id=${id}&type=update`)
        dispatch.UI.updateFormId({ target: 'currentUpdateId', value: organization?.id });
    }

    useEffect(() => {
        setActivKey('0');
    }, [date]);

    useEffect(() => {
        const selectOrg = JSON.stringify(select?.contract?.organization);
        const CCOrg = JSON.stringify(currentContract?.organization);
        if (selectOrg !== CCOrg) {
            dispatch.Payroll.updateState({ contract: currentContract })
            dispatch.Payroll.calculateCurrentPayroll(null);
        }
    }, [currentContract?.organization]);

    return (
        <div className='w-[800px] px-4 py-2 shadow-md'>
            {select?.overlappedPayslips?.length > 1 && (
                <Tabs
                    activeKey={activeKey}
                    onChange={handleChangeTab}
                    type='card'
                    size='small'
                >
                    {select?.overlappedPayslips?.map((payslip, index) => (
                        <Tabs.TabPane
                            tab={
                                <span className='w-full text-center font-semibold uppercase'>
                                    {`${moment(payslip?.salaryRange?.from).format('MMM DD')} - ${moment(payslip?.salaryRange?.to).format('MMM DD, YYYY')}`}
                                </span>
                            }
                            key={index}
                        />
                    ))}
                </Tabs>
            )}

            {select?.isSaved && (
                <div className='w-full flex justify-start items-center px-4'>
                    <span className='text-center text-xl font-bold text-red-500 tracking-wide'>SAVED!</span>
                </div>
            )}

            <GridContainer grid='two' border='bot' margin='none'>
                <div className='flex flex-col w-full items-start justify-start '>
                    <div className='flex flex-col justify-start items-start'>
                        {organization?.name ? (
                            <Span transform='capitalize' weight='bold' size='2xl' color='brown'>{organization?.name}</Span>
                        ) : (
                            <a onClick={handleUpdate} className='text-xs underline'>Update Name</a>
                        )}

                        {organization?.address ? (
                            <Span transform='capitalize' weight='semi'>{organization?.address}</Span>
                        ) : (
                            <a onClick={handleUpdate} className='text-xs underline'>Update Address</a>
                        )}

                        {organization?.address ? (
                            <Span transform='capitalize' weight='semi'>{organization?.phoneNumber}</Span>
                        ) : (
                            <a onClick={handleUpdate} className='text-xs underline'>Update Contact</a>
                        )}
                    </div>
                </div>

                <div className='flex items-center justify-end'>
                    <div className='w-32 mr-2'>
                        {organization?.logo ? (
                            <img src={image_source} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
                        ) : (
                            <Button
                                padding='px-2'
                                border='blue'
                                bg='none'
                                color='blue'
                                radius='none'
                                onClick={handleUpdate}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='blue'>Add Logo</Span>
                                    <AiOutlineUpload />
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </GridContainer>

            <GridContainer gap='two'>
                <div className='w-full'>
                    <div className=' bg-brown-1 w-full pl-2'>
                        <Span weight='bold'>EMPLOYEE INFORMATION</Span>
                    </div>

                    <div className='pl-2'>
                        <Span transform='capitalize' weight='semi'>{user?.name}</Span>
                        <Span transform='capitalize' weight='semi' size='xs'>{user?.position ?? 'None'}</Span>
                        <Span transform='capitalize' weight='semi' size='xs'>{user?.phoneNumber}</Span>
                        <Span transform='capitalize' weight='semi' size='xs'>{user?.email}</Span>
                    </div>
                </div>

                <div className='col-span-2 grid grid-cols-3 gap-2'>
                    <div className='flex flex-col justify-start items-center'>
                        <span className='w-full bg-brown-1 text-center font-bold'>PAY PERIOD</span>
                        <span className='w-full bg-gray-100 text-center font-semibold uppercase'>
                            {`${moment(select?.salaryRange?.from).format('MMM DD')} - ${moment(select?.salaryRange?.to).format('MMM DD, YYYY')}`}
                        </span>
                    </div>

                    <div className='flex flex-col justify-start items-center'>
                        <span className='w-full bg-brown-1 text-center font-bold'>PAY TYPE</span>
                        <span className='w-full bg-gray-100 text-center font-semibold capitalize'>
                            {removeSCAndCapitalize(contract?.salary_cycle, '-')}
                        </span>
                    </div>

                    <div className='flex flex-col justify-start items-center'>
                        <span className='w-full bg-brown-1 text-center font-bold'>EMP. NUMBER</span>
                        <span className='w-full bg-gray-100 text-center font-semibold uppercase'>
                            {user?.employeeNumber}
                        </span>
                    </div>

                    <div className='flex flex-col justify-start items-center'>
                        <span className='w-full bg-brown-1 text-center font-bold'>SICK LEAVE</span>
                        <span className='w-full bg-gray-100 text-center font-semibold capitalize'>
                            Unused: {user?.sickLeave ?? 0}
                        </span>
                    </div>

                    <div className='flex flex-col justify-start items-center'>
                        <span className='w-full bg-brown-1 text-center font-bold'>VACATION LEAVE</span>
                        <span className='w-full bg-gray-100 text-center font-semibold capitalize'>
                            Unused: {user?.vacationLeave ?? 0}
                        </span>
                    </div>
                </div>
            </GridContainer>

            <GridContainer grid='four' gap='none'>
                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>EARNINGS</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>HOURS</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>RATE</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>TOTAL</span>
                </div>

                <Earnings select={select} />

                {Object?.entries(select?.earnings)?.filter(([key, value]) => !DEFAULT_EARNINGS.includes(key))?.map(([key, value]) => (
                    <EarninItem key={key} label={key} value={value} />
                ))}

                <div className='flex w-full px-2 border-b border-gray-300 col-span-4'>
                    <span className='w-full font-bold text-right'>-</span>
                </div>
                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300 col-span-3'>
                    <span className='w-full font-bold text-right'>GROSS PAY</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>{currencyCommaFormat(select?.grossSalary, 2, true)}</span>
                </div>
            </GridContainer>

            <GridContainer grid='four' gap='none'>
                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>DEDUCTIONS</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>HOURS</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>RATE</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>TOTAL</span>
                </div>

                <Deductions select={select} />

                {Object?.entries(select?.deductions)?.filter(([key, value]) => !DEFAULT_DEDUCTIONS.includes(key))?.map(([key, value]) => (
                    <DeductionItem key={key} label={key} value={value} />
                ))}

                <div className='flex w-full px-2 border-b border-gray-300 col-span-4'>
                    <span className='w-full font-bold text-right'>-</span>
                </div>
                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300 col-span-3'>
                    <span className='w-full font-bold text-right'>TOTAL DEDUCTIONS</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                    <span className='w-full font-bold'>{currencyCommaFormat(select?.totalDeductions, 2, true)}</span>
                </div>
            </GridContainer>

            <div className='grid grid-cols-4 px-4'>
                <div className='flex w-full px-2 bg-gray-200 border-y border-gray-300 col-span-3'>
                    <span className='w-full font-bold text-right'>NET PAY</span>
                </div>

                <div className='flex w-full px-2 bg-gray-200 border-y border-gray-300'>
                    <span className='w-full font-bold'>{currencyCommaFormat(select?.netSalary, 2, true)}</span>
                </div>
            </div>

            <div className='p-4 flex justify-start items-center'>
                <Span size='xs' weight='semi'>To pay on of Ryan Jay Rada Medina: {currencyCommaFormat(select?.netSalary, 2, true)}</Span>
            </div>

            <div className='p-4 flex justify-center items-center'>
                <Span size='xs' weight='semi'>Made by Holo Pro with ❤</Span>
            </div>

        </div>
    )
}

export default PayslipView