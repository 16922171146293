import { Span } from '../../common/Span';
import { Input, InputMask } from '../../common/Input';
import './Forms.css';
import { useQuery } from '../../../common/utils';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    errMesssage: string,
    setFieldValue: Function
}


const LogForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage }: Props) => {
    const query = useQuery();
    const type = query.get('type')

    return (
        <div className='p-2 text-left'>

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Date</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                {...(type === 'update' && { style: { cursor: 'not-allowed' } })}
                disabled={type === 'update' ? true : false}
                value={values.date}
                onChange={handleChange('date')}
                placeholder='Select Date'
                onBlur={handleBlur('date')}
                type='date'
            />
            {errors.date && touched.date && <Span color='red' size='xs'>{errors.date}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Clock In</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.timeIn}
                onChange={handleChange('timeIn')}
                placeholder='Select Date'
                onBlur={handleBlur('timeIn')}
                type='time'
            />
            {errors.timeIn && touched.timeIn && <Span color='red' size='xs'>{errors.timeIn}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Clock Out</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.timeOut}
                onChange={handleChange('timeOut')}
                onBlur={handleBlur('timeOut')}
                type='time'
            />
            {errors.timeOut && touched.timeOut && <Span color='red' size='xs'>{errors.timeOut}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Total Break Time</Span>
                <Span margin='mt-4' color='lightgrey'>(Optional)</Span>
            </div>
            <InputMask
                value={values.workBreakTotal}
                onChange={handleChange('workBreakTotal')}
                onBlur={handleBlur('workBreakTotal')}
                mask='00:00:00'
                placeholder='hh:mm:ss'
            />
            {errors.workBreakTotal && touched.workBreakTotal && <Span color='red' size='xs'>{errors.workBreakTotal}</Span>}

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}

        </div>
    )
}

export default LogForm