import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import Loader from '../../components/Loader';
import { Spin, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { getPermission } from '../../common/utils';
import { Divider, Grid, Paper, useMediaQuery } from '@mui/material';
import './Referral.css';
import { Span } from "../../components/common/Span";
import { Button } from "../../components/common/Button";
import { PiCopySimpleBold } from 'react-icons/pi'
import { useEffect, useMemo } from "react";
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import { COLUMNS } from './Column';
import { generateReferralCode } from "../../common/api";

export default () => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;

    const data: any = useSelector((state: RootState) => state.Table.tables.referrals.data);
    const { isLoading } = useSelector((state: RootState) => state.Table);
    const { userInfo } = useSelector((state: RootState) => state.User);
    const refCode = userInfo?.referralCode ?? null;
    const url = `${process.env.REACT_APP_URL}/signup?r=${refCode}`;
    const free_plan_count = data?.filter(d => d?.organization?.plan?.planType === 'free');
    const premium_plan_count = data?.filter(d => {
        const type = d?.organization?.plan?.planType;
        if (type === 'enterprise' || type === 'advance') return true;
        return false;
    });

    const columns = useMemo(() => COLUMNS, []);
    const items = useMemo(() => {
        return [
            { label: 'Total Referral', value: data?.length },
            { label: 'Free', value: free_plan_count?.length },
            { label: 'Premium', value: premium_plan_count?.length }
        ]
    }, [data]);

    const generateCode = async () => {
        try {
            const res = await generateReferralCode();

            if (res?.isSuccess) {
                dispatch.User.updateState({ userInfo: { ...userInfo, referralCode: res?.message } });
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (!userInfo?.referralCode) {
            generateCode();
        }
    }, [userInfo?.referralCode]);

    useEffect(() => {
        if (userInfo?.referralCode) {
            const filter = {
                ref: {
                    field: 'referredBy',
                    operation: '==',
                    value: userInfo?.referralCode
                }
            }

            dispatch.Table.setMultipleFilterValue({ table: 'referrals', filters: filter });
            dispatch.Table.getFirebaseData({ name: 'employees', table: 'referrals' });
        }
    }, []);

    return (
        <div className='flex justify-center items-start w-full'>
            <Loader isLoading={isLoading} />
            <div className='referral_tab flex justify-center items-start flex-col w-[80%] p-2'>
                <Tabs
                    activeKey='1'
                    style={{ width: '100%' }}
                    tabBarStyle={{ borderColor: 'red' }}
                    size='large'
                >
                    <Tabs.TabPane style={{ fontSize: '16px' }} tab='Referral' key='1'>
                        <div className="flex flex-col justify-center items-start space-y-6 w-full">
                            <Paper
                                elevation={2}
                                sx={{
                                    padding: 2,
                                    width: '100%'
                                }}
                            >
                                <Span weight='semi'>
                                    You can unlock substantial rewards on Holo by inviting your friends to join our community. For each friend who registers using your referral, you stand to earn up to 70% commission. Plus, your friend will benefit too, receiving an enticing 50% discount right away. Please help us extend the reach of Holo and bring our top-tier and cost-effective services to more individuals. We deeply appreciate your support and dedication to making our flatform accessible to everyone.
                                </Span>
                            </Paper>

                            <Paper
                                elevation={2}
                                sx={{
                                    padding: 2,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                {refCode ? (
                                    <div className="flex flex-col justify-center items-start">
                                        <Span weight='semi'>Your invite link:</Span>
                                        <Span weight='semi' size='lg' color='brown'>{url}</Span>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center w-[200px] h-full">
                                        <Spin size='small' />
                                    </div>
                                )}

                                <Button
                                    padding='p-2'
                                    width='36'
                                    onClick={() => {
                                        navigator.clipboard.writeText(url);
                                        dispatch.UI.setAlert({ message: 'Referral code copied in clipboard', type: 'Success' });
                                    }}
                                >
                                    <div className='flex w-full justify-center items-center'>
                                        <Span padding='pr-2' color='white'>Copy</Span>
                                        <PiCopySimpleBold style={{ color: 'white' }} />
                                    </div>
                                </Button>
                            </Paper>

                            <Paper
                                elevation={2}
                                sx={{ width: '100%' }}
                            >
                                <div className="grid grid-cols-3">
                                    {items?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col justify-center items-center"
                                            style={{
                                                padding: '1rem',
                                                ...(index === 1 && {
                                                    borderLeft: 'solid lightgray 1px',
                                                    borderRight: 'solid lightgray 1px'
                                                })
                                            }}
                                        >
                                            <Span weight='medium' color='blue' size='lg'>{item?.label}</Span>
                                            <Span size="lg" weight='medium'>{item?.value}</Span>
                                        </div>
                                    ))}
                                </div>
                            </Paper>

                            <Paper
                                elevation={2}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <div className="p-3">
                                    <Span weight='semi' size='lg'>Friend List</Span>
                                </div>
                                <div className="w-full">
                                    {mobile ? (
                                        <Card data={data} dataType='referral' sortName='createdAt' />
                                    ) : (
                                        <Table columns={columns} data={data} dataType='referral' sortName='createdAt' height='auto' isShowCount={true} />
                                    )}
                                </div>
                            </Paper>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div >
    )
}
