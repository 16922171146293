import { Paper } from '@mui/material'
import RenderEmployess from './Render/RenderEmployess';
import RenderLeave from './Render/RenderLeave';
import RenderOrganization from './Render/RenderOrganization';
import RenderRole from './Render/RenderRole';
import RenderTimesheet from './Render/RenderTimesheet';
import RenderLog from './Render/RenderLog';
import RenderOvertime from './Render/RenderOvertime';
import RenderWorkflow from './Render/RenderWorkflow';
import RenderPayrollList from './Render/RenderPayrollList';
import RenderEarnDed from './Render/RenderEarnDed/RenderEarnDed';
import RenderWithoutRate from './Render/RenderWithoutRate';
import RenderWithoutClockout from './Render/RenderWithoutClockout';
import RenderSSS from './Render/RenderSSS';
import RenderTAX from './Render/RenderTAX';
import RenderCalendar from './Render/RenderCalendar';
import RenderContract from './Render/RenderContract';
import RenderCredential from './Render/RenderCredential';
import RenderDashboard from './Render/RenderDashboard';

const CardItem = ({ item, dataType }) => {

    const renderCard = () => {

        switch (dataType) {
            case 'employees':
                return <RenderEmployess item={item} dataType='employees' />
            case 'timesheet':
                return <RenderTimesheet item={item} />
            case 'organization':
                return <RenderOrganization item={item} dataType='organization' />
            case 'role':
                return <RenderRole item={item} dataType='role' />
            case 'leave':
                return <RenderLeave item={item} dataType='leave' />
            case 'log':
                return <RenderLog item={item} dataType='log' />
            case 'overtime':
                return <RenderOvertime item={item} dataType='overtime' />
            case 'workflow':
                return <RenderWorkflow item={item} dataType='workflow' />
            case 'payroll_list':
                return <RenderPayrollList item={item} dataType='payroll_list' />
            case 'earnings_deductions':
                return <RenderEarnDed item={item} dataType='earnings_deductions' />
            case 'without_rate':
                return <RenderWithoutRate item={item} dataType='without_rate' />
            case 'without_clockout':
                return <RenderWithoutClockout item={item} dataType='without_clockout' />
            case 'sss':
                return <RenderSSS item={item} dataType='without_clockout' />
            case 'tax':
                return <RenderTAX item={item} dataType='without_clockout' />
            case 'calendar':
                return <RenderCalendar item={item} dataType='calendar' />
            case 'contracts':
                return <RenderContract item={item} dataType='contracts' />
            case 'credentials':
                return <RenderCredential item={item} dataType='credentials' />
            case 'dashboard':
                return <RenderDashboard item={item} dataType='dashboard' />
            default:
                return;
        }
    }

    return (
        <Paper elevation={3} style={{ width: '100%', marginTop: '10px' }}>
            {renderCard()}
        </Paper>
    )
}

export default CardItem