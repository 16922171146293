import { Checkbox } from 'antd'
import React from 'react'
import { Span } from '../../../common/Span'
import './RoleForm.css'

const SubPermission = ({ sub, permissions, setFieldValue, permission, module, index }) => {

    const isSubExsist = !!permissions[module?.role][`${permission?.action}Sub`];
    const isChecked = isSubExsist ? permissions[module?.role][`${permission?.action}Sub`][sub?.action] : false;

    const onChange = (e: any) => {
        const value = e.target.checked;

        switch (value) {
            case true:
                let trueData = {
                    ...permissions,
                    [module?.role]: {
                        ...permissions[module?.role],
                        [`${permission?.action}Sub`]: {
                            ...permissions[module?.role][`${permission?.action}Sub`],
                            [sub?.action]: true
                        },
                    }
                }

                setFieldValue('permissions', trueData)
                break;
            case false:
                const falseData = {
                    ...permissions,
                    [module?.role]: {
                        ...permissions[module?.role],
                        [`${permission?.action}Sub`]: {
                            ...permissions[module?.role][`${permission?.action}Sub`],
                            [sub?.action]: false
                        },
                    }
                }

                setFieldValue('permissions', falseData);
                break;
        }
    }

    return (
        <div className='flex justify-between items-center sub-checkbox'>
            <Span color='grey' transform='capitalize' wrap='singleLine'>{sub?.name}</Span>
            <Checkbox value={isChecked} defaultChecked={isChecked} onChange={onChange} />
        </div>
    )
}

export default SubPermission