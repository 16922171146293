import { useDeferredValue, useEffect, useMemo, useState, useTransition } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { Container } from '../../../components/common/Containers'

import Table from '../../../components/Table/Table';
import { COLUMNS } from './Column'
import { createDefaultTemplate, generateDefaultExcelFile, getPermission, getSalaryCycleRange, useQuery } from '../../../common/utils';
import { Span } from '../../../components/common/Span';
import { Button } from '../../../components/common/Button';

import { useMediaQuery } from '@mui/material';
import Card from '../../../components/Card/Card';
import { useHistory, useLocation } from 'react-router-dom';
import Search from 'antd/lib/input/Search';
import { AiOutlineEdit } from 'react-icons/ai';
import AddUpdateContract from '../../../components/ModuleComponents/Modal/AddUpdateContract';

export default ({ activeKey }) => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');
    const pathname = useLocation().pathname;
    const history = useHistory();
    const permissions = getPermission('payroll', 'canViewContractSub');

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [noContract, setNoContract] = useState([]);
    const [isPending, startTransition] = useTransition();

    const deferredSearch = useDeferredValue(search);

    const contract = useSelector((state: RootState) => state.Payroll.contract) as any;
    const { userInfo } = useSelector((state: RootState) => state.User);
    const items: any = useSelector((state: RootState) => state.Table.tables.contracts?.data);
    const employees = useSelector((state: RootState) => state.Table.tables.employees.data);

    const defaultContract = items?.find(c => c?.isDefault);

    const data = useMemo(() => {
        const result = items?.filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

        return result;
    }, [items, deferredSearch])

    const columns = useMemo(() => COLUMNS, []);

    const handleRowClick = (row) => {
        history.push(`${pathname}?activeKey=2&id=${row?.original?.id}`)
        dispatch.Payroll.updateState({ contract: row?.original })
    }

    useEffect(() => {
        dispatch.Table.getFirebaseData({ table: 'contracts', name: 'contracts' });
        dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' });
    }, [])

    useEffect(() => {
        const employeesWithoutContract = employees
            ?.filter(e => !e?.contractId && e?.organization?.id === userInfo?.organization?.id);

        const ids = employeesWithoutContract?.map(e => e?.id);
        startTransition(() => {
            setNoContract(ids);
        })
    }, [employees]);

    useEffect(() => {
        if (!defaultContract && noContract?.length) {
            dispatch.Payroll.addOrUpdateContract({ type: 'add', contract: defaultContract, empWithoutContractId: noContract });
            return;
        }

        if (defaultContract && noContract?.length) {
            dispatch.Payroll.addOrUpdateContract({ type: 'update', contract: defaultContract, empWithoutContractId: noContract });
            return;
        }
    }, [noContract]);

    return (
        <div className='relative'>
            <Container padding='none'>
                <div className={`flex w-full ${mobile ? 'flex-col justify-center items-center px-4 pb-4' : 'justify-between items-center px-4'}`}>
                    <div>
                        <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                            <Span margin='mt-4' weight='bold' size='2xl'>Contract</Span>
                        </div>
                    </div>

                    <div className={`flex justify-center items-center ${mobile ? 'flex-col-reverse w-full' : 'flex-row space-x-2'}`}>
                        <Search
                            placeholder="Search any..."
                            onChange={(e: any) => setSearch(e.target.value)}
                            style={{ width: mobile ? '100%' : 300 }}
                        />

                        {permissions?.includes('canCreate') && (
                            <Button
                                padding='px-2'
                                border='blue'
                                bg='none'
                                color='blue'
                                radius='none'
                                onClick={() => {
                                    setOpen(true);
                                    history.push(`${pathname}?type=add`)
                                }}
                                {...(!mobile ? {
                                    width: '36'
                                } : {
                                    width: 'full',
                                    style: { marginBottom: '8px' }
                                })}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='blue'>Add Contract</Span>
                                    <AiOutlineEdit />
                                </div>
                            </Button>
                        )}
                    </div>
                </div>

                <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                    {mobile ? (
                        <Card data={data} dataType='contracts' sortName='createdAt' />
                    ) : (
                        <Table columns={columns} data={data} sortName='createdAt' isSelected={contract?.id} isRowClick={true} handleRowClick={handleRowClick} isLastColSticky={true} />
                    )}
                </div>

                {activeKey === '1' && <AddUpdateContract open={open} setOpen={setOpen} />}
            </Container >
        </div >
    )
}
