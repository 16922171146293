import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader';
import { Switch } from "antd";
import Table from '../../components/Table/Table';
import { COLUMNS } from './Column'
import { Input } from 'antd';
import { Span } from '../../components/common/Span';
import { Button } from '../../components/common/Button';
import { BiPlusCircle } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { getPermission, removeSCAndCapitalize } from '../../common/utils';
import { Stack, useMediaQuery } from '@mui/material';
import Card from '../../components/Card/Card';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./calendar.css";
import AddHoliday from '../../components/ModuleComponents/AddHoliday';
import UpdateHoliday from '../../components/ModuleComponents/UpdateHoliday';
import EventOption from '../../components/ModuleComponents/Modal/EventOption';
import DeleteModal from '../../components/ModuleComponents/Modal/DeleteModal';

export default () => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const permissions = getPermission('calendar');

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [view, setView] = useState(mobile ? false : true);
    const [open, setOpen] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const { isLoading } = useSelector((state: RootState) => state.Table);
    const data: any = useSelector((state: RootState) => state.Table.tables.calendar.data)
        .filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

    const columns = useMemo(() => COLUMNS, []);
    const { Search } = Input;
    const isShowEventOption = permissions?.includes('canEdit') || permissions?.includes('canDelete');

    const events = data?.map((event) => {
        const result = {
            title: `${removeSCAndCapitalize(event?.name, ' ')} (${removeSCAndCapitalize(event?.holidayType, "_")})`,
            date: new Date(event.date?.seconds * 1000),
            id: event?.id,
            allDay: false,
        };

        return result;
    });

    useEffect(() => {
        dispatch.Table.getFirebaseData({ table: 'calendar', name: 'calendar' })
    }, [])

    return (<div className='relative'>
        <Loader isLoading={isLoading} />
        <Container border='grey' padding='none'>
            <div className='p-4'>
                <div className={`flex ${mobile ? 'flex-col items-center justify-center ' : 'justify-between'}`}>
                    <Span margin='mt-4' weight='bold' size='2xl'>Calendar</Span>
                    <div className={`flex ${mobile ? 'justify-center w-full mt-4' : 'justify-end px-4 my-4'}`}>
                        {permissions.includes('canCreate') && (
                            <Button
                                padding='py-1'
                                border='blue'
                                bg='none'
                                color='blue'
                                {...(!mobile && {
                                    width: 'none'
                                })}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                    onClick={() => {
                                        setAdd(true)
                                        history.push(`${pathname}?type=add`)
                                    }}
                                >
                                    <BiPlusCircle className='my-auto mx-2 text-2xl' />
                                    Add Holiday
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <div className={`flex p-4 ${mobile ? 'px-4 pb-4 flex-col items-center justify-center ' : 'justify-between pr-8'}`}>
                {!view ? (
                    <Search placeholder="Search any..." onChange={(e: any) => setSearch(e.target.value)} style={{ width: mobile ? '100%' : 300 }} />
                ) : (
                    <div></div>
                )}

                {!mobile && (
                    <Switch
                        checkedChildren="List View"
                        unCheckedChildren="Calendar View"
                        defaultChecked={view}
                        onChange={(v) => setView(v)}
                        style={{ width: '100px' }}
                    />
                )}
            </div>

            <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                {mobile ? (
                    <Card data={data} dataType='calendar' sortName='createdAt' />
                ) : (
                    view ? (
                        <div className='p-4'>
                            <FullCalendar
                                initialView="dayGridMonth"
                                plugins={[dayGridPlugin]}
                                events={events}
                                height="65vh"
                                editable={true}
                                selectable={true}
                                eventClick={(e: any) => {
                                    setEventId(e?.event?.id);
                                    setOpen(true);
                                }}
                            />
                        </div>
                    ) : (
                        <Table columns={columns} data={data} dataType='calendar' sortName='date' isLastColSticky={true} />
                    )
                )}
            </div>

            <AddHoliday table='calendar' setAdd={setAdd} add={add} />
            <UpdateHoliday table='calendar' />
            {open && isShowEventOption && <EventOption open={open} setOpen={setOpen} setDeleteModal={setDeleteModal} id={eventId} permissions={permissions} />}
            {deleteModal && <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} table='calendar' id={eventId} />}
        </Container >
    </div >
    )
}
