import { Span } from '../../common/Span';
import { Input } from '../../common/Input';
import { AiOutlineCloseCircle, AiOutlineUpload } from 'react-icons/ai';
import { getBase64, getOrganizationLogo } from '../../../common/utils';
import { useState } from 'react';
import { IMAGE_TYPE } from '../../constants';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    errMesssage: string,
    setFieldTouched: Function,
    setErrors: Function,
    setFieldValue: Function
}

const OrgForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldTouched, setErrors, setFieldValue }: Props) => {

    const [uri, setUri] = useState(null) as any;

    const source = getOrganizationLogo(values?.logo)

    const handleImage = async (e) => {
        e.preventDefault();

        const file = e?.target?.files[0];

        if (!IMAGE_TYPE?.includes(file?.type)) {
            setFieldTouched('logo', true);
            setErrors({ new_logo: 'Invalid file type' });
            return;
        }

        const imgUri = await getBase64(file);

        setUri(imgUri);
        setFieldValue('logo', file);
    }

    const handleRemoveImage = () => {
        setUri(null);
        setFieldValue('deleted_logo', values.logo);
        setFieldValue('logo', '');
    }

    return (
        <div className='px-2 text-left w-full'>

            <div className='flex flex-col items-center justify-center'>
                {uri ?? values?.logo ? (
                    <div className='flex w-2/4 justify-center items-center relative border border-gray-300 border-solid rounded-sm p-2 h-24'>
                        <AiOutlineCloseCircle className=' text-sm text-red-400 absolute top-2 right-2 cursor-pointer' onClick={handleRemoveImage} />
                        <img src={uri ?? source} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
                    </div>
                ) : (
                    <div className='flex w-2/4 flex-col rounded-md justify-center items-center border border-gray-300 border-solid p-2 h-20'>
                        <input type="file" name="file" id="file" onChange={handleImage} accept="image/*" />
                        <label htmlFor="file" className="cursor-pointer">
                            <AiOutlineUpload style={{ fontSize: '40px', color: 'lightgray' }} />
                        </label>
                        <span className='text-xs font-semibold'>Company Logo</span>
                    </div>
                )}
            </div>

            {errors.logo && touched.logo && <Span color='red' size='xs'>{errors.logo}</Span>}

            <div className='flex mt-4 flex-row justify-start items-center'>
                <Span margin='mt-4'>Company Name</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.name}
                onChange={handleChange('name')}
                placeholder='Enter name'
                onBlur={handleBlur('name')}
            />
            {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Company Address</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.address}
                onChange={handleChange('address')}
                placeholder='Enter address'
                onBlur={handleBlur('address')}
            />
            {errors.address && touched.address && <Span color='red' size='xs'>{errors.address}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Company Number</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.phoneNumber}
                onChange={handleChange('phoneNumber')}
                placeholder='Enter phone number'
                onBlur={handleBlur('phoneNumber')}
            />
            {errors.phoneNumber && touched.phoneNumber && <Span color='red' size='xs'>{errors.phoneNumber}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Website</Span>
                <Span margin='mt-4' color='lightgrey'>(Optional)</Span>
            </div>
            <Input
                value={values.website}
                onChange={handleChange('website')}
                placeholder='ex:(https://pro.holoerp.com)'
                onBlur={handleBlur('website')}
            />
            {errors.website && touched.website && <Span color='red' size='xs'>{errors.website}</Span>}

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default OrgForm