import { Avatar } from 'antd'
import React from 'react'
import { Span } from '../../components/common/Span'
import { getOrganizationLogo } from '../../common/utils';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

const Org = ({ data }) => {

    const organizations = useSelector((state: RootState) => state.Table.tables.organization.data);
    const organization = organizations?.find(o => o?.id === data?.organization.id);

    const source = getOrganizationLogo(organization?.logo);

    return (
        <div className="flex flex-row justify-start items-center">
            <div className="w-8 h-8 mr-2">
                <Avatar src={source} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} >{organization.name?.charAt(0).toUpperCase()}</Avatar>
            </div>
            <Span transform='capitalize'>{organization.name}</Span>
        </div>
    )
}

export default Org