import { Avatar } from '@mui/material'
import { Card } from 'antd'
import moment from 'moment'
import React from 'react'
import { AiOutlineInbox } from 'react-icons/ai'
import { firebaseDateToText } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'
import cronstrue from 'cronstrue'
import Attachments from '../../../containers/Workflow/Attachments'

const RenderWorkflow = ({ item, dataType }) => {

    const applicationType = item?.old_from ? 'With Existing Timesheet' : 'New';
    const role = item?.userInfo?.role?.name;

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.taskName?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.taskName}</Span>
                        <Span transform='capitalize' color={item?.scheduled_status === 'active' ? 'green-1' : 'required'}>{item?.scheduled_status}</Span>
                    </div>
                </div>
                <Options data={item} table={dataType} />
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Desc: </span>
                    <>
                        {item?.remarks ? (
                            <span className="capitalize">{item?.remarks}</span>
                        ) : (
                            <div className="emptyContainer flex flex-row justify-start items-center">
                                <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                                <Span color='lightgrey'>No Data</Span>
                            </div>
                        )}
                    </>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Schedule: </span>
                    <Span transform='capitalize'>{cronstrue.toString(item?.cron)}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Created At: </span>
                    <span className="capitalize">{firebaseDateToText(item?.createdAt, 'LLL')}</span>
                </div>

                <div className='flex justify-start items-center py-4'>
                    <span className=' font-semibold mr-1 w-28'>Attachment/s</span>
                    {item?.imagesName?.length ? (
                        <Attachments data={item} />
                    ) : (
                        <div className="emptyContainer flex flex-row justify-start items-center">
                            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                            <Span color='lightgrey'>No Data</Span>
                        </div>
                    )}
                </div>

            </div>

            {item?.createdBy && (
                <div className='py-2 flex justify-center items-start flex-col'>
                    <span className=' font-bold w-full text-center pb-2'>Created By</span>

                    <div className=" flex justify-start items-center flex-row w-full">
                        <strong className="w-20">Name: </strong>
                        <span className="capitalize truncate w-full"> {item?.createdBy?.name}</span>
                    </div>

                    <div className=" flex justify-start items-center flex-row w-full">
                        <strong className="w-20">Position:</strong>
                        <span className="capitalize truncate w-full"> {item?.createdBy?.role?.name}</span>
                    </div>
                </div>
            )}
        </Card>
    )
}

export default RenderWorkflow