import React from 'react'
import { currencyCommaFormat, getRate } from '../../../../../common/utils';

const Deductions = ({ select }) => {

    const { deductions, ratePerHour, contract } = select;

    const lateRatePerHour = getRate(ratePerHour, contract?.tardiness);

    return (
        <>
            {/* Undertime */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Undertime</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{select?.undertimeHours}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(ratePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(deductions?.undertimeDeduction, 2, true)}</span>
            </div>

            {/* Latetime */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Latetime</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{select?.latetimeHours}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(lateRatePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(deductions?.latetimeDeduction, 2, true)}</span>
            </div>

            {/* SSS */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>SSS</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(deductions?.sssEmployeeShare, 2, true)}</span>
            </div>

            {/* Phil-Health */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Phil-Health</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(deductions?.phicEmployeeShare, 2, true)}</span>
            </div>

            {/* Pag-Ibig */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Pag-Ibig</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(deductions?.hdmfEmployeeShare, 2, true)}</span>
            </div>

            {/* Tax */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Tax</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(deductions?.taxEmployee, 2, true)}</span>
            </div>
        </>
    )
}

export default Deductions