import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FullContainer, MainLayout, ContainerLayout } from '../components/common/Containers'
import { BRAND_IMAGES } from '../components/constants'
import Header from '../components/Header/Header'
import SideBar from '../components/Sidebar/Sidebar'
import { Dispatch, RootState } from '../store'
import Attachments from '../containers/Home/Home';

const Home = () => {
  const dispatch = useDispatch<Dispatch>();
  const userInfo = useSelector((state: RootState) => state.User.userInfo);
  const isNewLogin = useSelector((state: RootState) => state.UI.isNewLogin);

  useEffect(() => {
    if (isNewLogin) {
      dispatch.UI.setAlert({ message: userInfo?.email, type: 'Success' });
      dispatch.UI.updateState({ isNewLogin: false });
    }
  }, [isNewLogin]);

  return (
    <>
      <MainLayout>
        <SideBar />
        <Header />

        <ContainerLayout>
          <Attachments />
        </ContainerLayout>
      </MainLayout>
    </>
  )
}

export default Home