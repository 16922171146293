import { Paper } from '@mui/material';
import { useEffect, useState } from 'react'
import { BRAND_IMAGES } from '../../components/constants';
import { Span } from '../../components/common/Span';
import PinInput from 'react-pin-input';
import { Button } from '../../components/common/Button';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import designbg from '../../assets/images/login_design3.png';
import designbg1 from '../../assets/images/login_design.png';
import { Button as MuiButon } from '@mui/material';
import { BiArrowBack } from 'react-icons/bi';
import moment from 'moment';
import { checkOtpCodeForLogin, sendOtpEmailVerification } from '../../common/api';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../common/utils';

const OtpEmail = () => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const query = useQuery();

    const { userInfo } = useSelector((state: RootState) => state.User);

    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [code, setCode] = useState('');
    const [btn, setBtn] = useState(false);
    const emailWarning = useSelector((state: RootState) => state.LocalStorage.emailWarning);
    const { isSent, countdownTime } = emailWarning;
    const defaultCount = countdownTime ? moment(countdownTime).diff(moment(), 'seconds') : (10 * 60);

    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [count, setCount] = useState(defaultCount) as any;

    const handleVerify = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const payload = {
                code,
                reference: query.get('ref'),
                email: userInfo?.email
            }

            const res = await checkOtpCodeForLogin(payload);

            if (res?.isSuccess) {
                history.push('/employees');
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            setError(true);
            const errMessage = err?.response?.data?.error ?? 'Incorrect Code';
            setErrMsg(errMessage);
        }
    }

    const handleResendOTP = async () => {
        try {
            setResendLoading(true);

            const res = await sendOtpEmailVerification({ email: userInfo?.email });

            if (res?.isSuccess) {
                setResendLoading(false);
                history.push(`/otp-email?ref=${res.message?.reference}`);
                const time = moment().add((10 * 60), 'seconds');
                dispatch.LocalStorage.updateState({ emailWarning: { countdownTime: time, isSent: true } });
            }
        } catch (err: any) {
            console.log(err);
            setResendLoading(false);
            const errMsg = err?.response?.data.error ?? 'Unable to send email!';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    useEffect(() => {
        let interval;
        if (isSent) {
            interval = setInterval(() => {
                const baseTime = moment(countdownTime);
                const now = moment();
                const remainingTime = baseTime.diff(now, 'seconds');
                setCount(remainingTime);

                if (remainingTime <= 0) {
                    clearInterval(interval)
                    dispatch.LocalStorage.updateState({ emailWarning: { isSent: false, countdownTime: null } });
                    return;
                }
            }, 1000)
        }

        return () => {
            clearInterval(interval);
        };
    }, [isSent, count]);

    return (
        <div className='min-h-screen overflow-hidden flex items-center justify-center'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

            <Paper
                elevation={3}
                sx={{
                    width: { xs: '90vw', sm: '460px' },
                    minHeight: '500px',
                    padding: { xs: '10px 20px', sm: '30px 50px' },
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: '1'
                }}
            >
                <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

                <div className='flex flex-col justify-start items-start'>
                    <Span letters='wider' size='2xl' weight='bold'>One-Time Verification</Span>
                    <span className='mt-1 text-[13px] font-[500]'>Enter the OTP code sent at {userInfo?.email} 📱</span>
                </div>

                {error && (
                    <div className='text-center mt-4 w-full p-2 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                        <span className='text-xs text-red-500'>
                            {errMsg}
                        </span>
                    </div>
                )}

                <form onSubmit={handleVerify} className='w-full flex flex-col justify-center items-center'>
                    <PinInput
                        disabled={loading}
                        focus={true}
                        length={6}
                        secret={false}
                        style={{ padding: '10px 0' }}
                        inputStyle={{ margin: "5px", border: "none", fontSize: "20px" }}
                        onComplete={(value) => {
                            setCode(value);
                            setBtn(true);
                        }}
                        onChange={() => setBtn(false)}
                    />

                    <MuiButon
                        sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                        onClick={handleResendOTP}
                        disabled={isSent || resendLoading}
                    >
                        Resend OTP {isSent && count}
                    </MuiButon >

                    <Button
                        shadow='grey'
                        padding='p-10'
                        margin='mt-2'
                        width='full'
                        disabled={!btn || loading}
                        type='submit'
                    >
                        {loading ? (
                            <Spin className='login_spin' size='small' />
                        ) : 'VERIFY OTP'}
                    </Button>

                    <MuiButon
                        sx={{ textTransform: 'capitalize', fontWeight: 'bold', marginRight: 'auto', marginTop: 3 }}
                        startIcon={<BiArrowBack />}
                        onClick={() => history.goBack()}
                    >
                        Go Back, Verify Later
                    </MuiButon >
                </form>
            </Paper>
        </div>
    )
}

export default OtpEmail