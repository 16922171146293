import { createModel } from "@rematch/core";
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import { RootModel } from ".";
import { getFirestore, onSnapshot, getDoc, doc as Doc, query, where, doc } from "firebase/firestore";
import { getRoleModulesAndObjectOfRoleAccesses } from "../common/utils";
import { store } from "../store";
import { useHistory } from "react-router-dom";
import { loginUsingPhoneNumber } from "../common/api";

export interface ILogin {
  userEmailPhone?: any,
  password?: any,
  token?: any,
  verifResult?: any,
  type: string
}
export interface State {
  signup_details: any;
  customToken: any;
  reference: any;
  isUserLoggedIn: boolean;
  userInfo: any | null;
  userToken: string;
  shouldRedirectToLogin: boolean;
  role: any;
  roleModules: any;
  objectOfRoleModules: any;
  claims: any
}

const initialState = {
  signup_details: null,
  customToken: null,
  reference: null,
  isUserLoggedIn: false,
  shouldRedirectToLogin: false,
  userInfo: null,
  userToken: "",
  role: {},
  roleModules: [],
  objectOfRoleModules: null,
  claims: null
} as State

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    setUserLoggedIn(state, payload: boolean) {
      state.isUserLoggedIn = payload;
      return { ...state };
    },
    setUserInfo(state, payload: any | null) {
      state.userInfo = payload;
      return { ...state };
    },
    setUserToken(state, payload: string) {
      state.userToken = payload;
      return { ...state };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState }
    },
  },
  effects: (dispatch) => ({
    /**
     * @name login
     * @desc login user using email and password
     */
    async login({ userEmailPhone, password, type, token, verifResult }: ILogin) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      try {
        const auth = getAuth();
        let res;

        switch (type) {
          case 'email-password':
            res = await signInWithEmailAndPassword(auth, userEmailPhone, password);
            break;
          case 'customToken':
            res = await signInWithCustomToken(auth, token);
            break;
          case 'phone-number':
            const verifyResult = await loginUsingPhoneNumber({ phoneNumber: userEmailPhone });

            if (verifyResult?.isSuccess) {
              const reference = verifyResult?.message?.reference;

              dispatch.User.updateState({ reference });
            }

            return { result: 'phoneVerification', type: 'phoneNotVerified' };
          case 'done-verification':
            res = verifResult;
            break
          default:
            throw "Invalid User Type";
        }

        //const idToken = await auth.currentUser?.getIdToken(true);
        if (res) {
          const db = getFirestore();
          const docRef = Doc(db, "employees", res.user.uid);
          const empDoc = await getDoc(docRef);
          const empData: any = empDoc.data();

          // const userCred = await auth.currentUser?.getIdTokenResult();
          const userProfile = await auth.currentUser?.getIdTokenResult();
          const claims = userProfile?.claims;

          if (!empData?.admin && !claims?.inProgress) throw "Invalid User Type";

          if (!empData?.admin && claims?.inProgress && (!claims?.email_verified && !claims?.emailVerified) && type === 'email-password') {
            dispatch.UI.setAlert({ message: 'Please verify user account to continue registration proccess!', type: 'Error' })
            auth.signOut();

            return { result: 'notVerified', type: 'emailNotVerified' }
          }

          const role = empData?.role ? Object?.keys(empData.role?.permissions) : [];
          const permissions = empData?.role?.permissions ?? {};

          dispatch.User.updateState({
            userInfo: empData,
            roleModules: role,
            objectOfRoleModules: permissions,
            isUserLoggedIn: true,
            signup_details: null,
            customToken: null,
            reference: null,
            claims
          });

          dispatch.UI.setErrorMsg({
            target: "login",
            value: "",
          });

          dispatch.UI.resetForm("login");
          return { result: 'success' }
        }
      } catch (err: any) {
        console.log(err);
        const errMsg = err?.data?.error ?? 'Invalid credentials';
        dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        dispatch.UI.setErrorMsg({ target: "login", value: errMsg, });
        return Promise.reject(errMsg);
      }
    },
    async updateUserInfo(payload, rootState) {
      try {
        const db = getFirestore();

        const id = rootState.User.userInfo.id;

        const docRef = doc(db, 'employees', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const role = Object?.keys(data?.role?.permissions);
          const permissions = data?.role?.permissions;

          dispatch.User.updateState({
            userInfo: data,
            roleModules: role,
            objectOfRoleModules: permissions
          })
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async logout(payload, rootState) {
      const auth = getAuth();
      await auth.signOut();
      dispatch.User.resetState();
      dispatch.UI.resetState();
      dispatch.Table.resetState();
      dispatch.Document.resetState();
      dispatch.Payroll.resetState();
      dispatch.User.updateState({ shouldRedirectToLogin: true });
    },
  }),
});
