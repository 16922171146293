import React, { useEffect } from 'react'
import designbg from '../../../assets/images/login_design3.png'
import designbg1 from '../../../assets/images/login_design.png'
import { GridContainer } from '../../../components/common/Containers';
import { BRAND_IMAGES } from '../../../components/constants';
import { Span } from '../../../components/common/Span';
import OrganizationStep from './OrganizationStep';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import ProgressLevel from './ProgressLevel';
import AdditionalinfoStep from './AdditionalinfoStep';
import DesignationStep from './DesignationStep';
import { useQuery } from '../../../common/utils';

const Setup = () => {
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const progress: any = query.get('progress');

    const { claims, userInfo } = useSelector((state: RootState) => state.User);
    const client = useSelector((state: RootState) => state.Document.documents.client.data);

    const progress_level = client?.progressLevel ?? 0;

    const renderSteps = () => {
        switch (progress_level) {
            case 0:
                return <OrganizationStep client={client} />;
            case 1:
                return <AdditionalinfoStep client={client} />;
            case 2:
                return <DesignationStep client={client} />;
            default:
                return;
        }
    }

    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "Are you sure you want to leave this page?";
        };

        window.addEventListener("beforeunload", handler);

        return () => {
            window.removeEventListener("beforeunload", handler);
        };
    }, []);

    useEffect(() => {
        dispatch.Document.getFirebaseDoc({ name: 'client', document: 'client', id: claims?.user_id });
    }, []);

    useEffect(() => {
        if (progress !== null) {
            const data = {
                ...client,
                progressLevel: Number(progress)
            }

            dispatch.Document.setDocumentData({ document: 'client', data });
        }
    }, [progress_level]);

    return (
        <div className='min-h-screen overflow-hidden flex items-center justify-center'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

            <div className='h-screen w-screen grid grid-cols-1 z-50'>
                <div className='pb-10 flex relative flex-col items-center justify-center'>
                    <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

                    {renderSteps()}

                    <ProgressLevel client={client} />
                </div>
            </div>
        </div>
    )
}

export default Setup