import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";
import './Firebase'
import Login from './pages';
import Home from './pages/home';
import LoginRoute from './components/SecuredRoute/LoginRoute';
import AuthRoute from './components/SecuredRoute/AuthRoute';
import Logout from './pages/logout';
import AllTimesheet from './pages/alltimesheet';
import Timesheet from './pages/timesheet';
import Employees from './pages/employees';
import Admin from './pages/users';
import PageNotFound from './pages/404';
import Role from './pages/role';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Organization from './pages/organization';
import LeaveRequest from './containers/Requests/LeaveRequest';
import LogRequest from './containers/Requests/LogRequest';
import OverTimeRequest from './containers/Requests/OverTimeRequest';
import Leave from './pages/leave';
import Log from './pages/log';
import Overtime from './pages/overtime';
import Workflow from './pages/workflow';
import Payroll from './pages/payroll';
import Benefits from './pages/benefits';
import Calendar from './pages/calendar';
import Credentials from './pages/credentials';
import Signup from './pages/signup';
import SESuccess from './pages/sesuccess';
import Setup from './pages/setup';
import Station from './pages/station';
import ForgotPassword from './pages/forgotpassword';
import Referral from './pages/referral';
import OtpEmail from './pages/otpemail';
declare global {
  interface Window {
    Bugpilot: any;
  }
}

function App() {
  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();

  function authStateChanged(user) {
    setUser(user);
    if (initializing) setInitializing(false);

    window.Bugpilot.identify({
      id: user.id, // Required
      email: user.email, // Required for help desk integrations
      // Optional properties
      firstName: user.firstName,
      lastName: user.lastName,
      // ...any other attributes you want to see in the reports
    });

  }

  useEffect(() => {
    (async () => {
      const auth = getAuth();
      const subscriber = onAuthStateChanged(auth, authStateChanged);
      return subscriber;
    })()
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <LoginRoute path="/" exact component={Login} />
        <LoginRoute path="/station" exact component={Station} />
        <LoginRoute path="/signup" exact component={Signup} />
        <LoginRoute path="/forgot-password" exact component={ForgotPassword} />
        <LoginRoute path="/sesuccess" exact component={SESuccess} />

        <AuthRoute path="/home" exact component={Home} />
        <AuthRoute path="/timesheet" exact component={AllTimesheet} />
        <AuthRoute path="/employees" exact component={Employees} />
        <AuthRoute path="/otp-email" exact component={OtpEmail} />
        <AuthRoute path="/organization" exact component={Organization} />
        <AuthRoute path="/credentials" exact component={Credentials} />
        <AuthRoute path="/role" exact component={Role} />
        <AuthRoute path="/referral" exact component={Referral} />
        <AuthRoute path="/leave" exact component={Leave} />
        <AuthRoute path="/log" exact component={Log} />
        <AuthRoute path="/overtime" exact component={Overtime} />
        <AuthRoute path="/workflow" exact component={Workflow} />
        <AuthRoute path="/payroll" exact component={Payroll} />
        <AuthRoute path="/benefits" exact component={Benefits} />
        <AuthRoute path="/calendar" exact component={Calendar} />
        {/* <AuthRoute path="/admin" exact component={Admin} />
        <AuthRoute path="/admin/logsheet/:id" exact component={Timesheet} /> */}
        <AuthRoute path="/employees/logsheet/:id" exact component={Timesheet} />
        <AuthRoute path="/setup" exact component={Setup} />

        <Route path="/logout" exact component={Logout} />
        <Route path="/leave-request" exact component={LeaveRequest} />
        <Route path="/log-request" exact component={LogRequest} />
        <Route path="/over-time-request" exact component={OverTimeRequest} />
        <Route exact component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
