import { post, put, remove } from "../core";

export const createAdminUser = async (body: any) => {
    try {
        const res = await post('/admin/create/user-account', body);
        console.log(res.data);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateAdminAccount = async (id: string, payload: any) => {
    try {
        const res = await put(`/admin/update/user-account/${id}`, payload)
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteAdminAccount = async (id: string) => {
    try {
        const res = await remove(`/admin/delete/user-account/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const setEmployeeAsAdmin = async (id: string, body: any) => {
    try {
        const res = await put(`/admin/set-employee/as-admin/${id}`, body)
        return res.data;
    } catch (err) {
        throw err;
    }
}