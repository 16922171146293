import { postMultipart, put, remove } from "../core";

export const createScheduledtask = async (payload) => {
    try {
        const body = new FormData();

        payload?.images?.forEach(file => {
            body.append('file', file)
        })

        delete payload.images;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'New Scheduled Task')

        const res = await postMultipart('/admin/create/scheduled-task', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateScheduledTask = async (payload) => {
    try {
        const body = new FormData();

        payload?.images?.forEach(file => {
            body.append('file', file)
        })

        delete payload.images;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Update Scheduled Task')

        const res = await postMultipart(`/admin/update/schedule-task/${payload?.id}`, body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteWorkflow = async (id: any) => {
    try {
        const res = await remove(`/admin/delete/schedule-task/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}