import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { addClientDesignation, addClientInformation } from '../../../common/api/endpoints/client';
import { Span } from '../../../components/common/Span';
import { Button } from '../../../components/common/Button';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Spin } from 'antd';
import { InputContainer } from '../../../components/common/Containers';
import { Input } from '../../../components/common/Input';
import { getAuth } from '@firebase/auth';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

type State = {
    position: string,
    department: string,
    group: string,
}

const DesignationStep = ({ client }) => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;

    const initialState = {
        position: client?.position ?? '',
        department: client?.department ?? '',
        group: client?.group ?? '',
    } as State;

    const validateSchema = yup.object({
        position: yup.string().required('Position is required field'),
        department: yup.string().required('Department is required field'),
        group: yup.string()
    })

    const [errMesssage, setErrMessage] = useState('');

    const handleRegister = async (values, actions) => {
        try {
            const res = await addClientDesignation(client?.id, values);

            if (res.isSuccess) {
                const role = res?.empData?.role ? Object?.keys(res?.empData.role?.permissions) : [];
                const permissions = res?.empData?.role?.permissions ?? {};

                const auth = getAuth();
                await auth.currentUser?.getIdToken(true);
                const userProfile = await auth.currentUser?.getIdTokenResult();
                const claims = userProfile?.claims;

                dispatch.User.updateState({
                    userInfo: res?.empData,
                    roleModules: role,
                    objectOfRoleModules: permissions,
                    isUserLoggedIn: true,
                    claims
                });

                actions.setSubmitting(false)
                actions.resetForm();
                history.push('/');
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to add designation'
            setErrMessage(errMsg);
        }
    }

    const handleBack = () => {
        const data = {
            ...client,
            progressLevel: 1
        }

        history.push(`${pathname}?type=update&progress=1`)
        dispatch.Document.setDocumentData({ document: 'client', data });
    }

    return (
        <Formik
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, errors, touched, isSubmitting, handleSubmit }: any) => {
                return (
                    <div className='w-[400px] flex flex-col items-center justify-center'>
                        <div className='flex w-full flex-col justify-center items-center mb-4'>
                            <Span letters='wider' size='2xl' weight='bold'>What's your current position?</Span>
                            <span className='mt-1 text-[13px] font-[500]'>Designation</span>
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    style={{ textTransform: 'capitalize' }}
                                    disabled={isSubmitting}
                                    value={values.position}
                                    onChange={handleChange('position')}
                                    placeholder='Enter position ex:(Admin, Cashier, HR-Manager)*'
                                    onBlur={handleBlur('position')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.position && touched.position && <Span color='red' size='xs'>{errors.position}</Span>}
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    style={{ textTransform: 'capitalize' }}
                                    disabled={isSubmitting}
                                    value={values.department}
                                    onChange={handleChange('department')}
                                    placeholder='Enter department ex:(IT, Accounting, Admin)*'
                                    onBlur={handleBlur('department')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.department && touched.department && <Span color='red' size='xs'>{errors.department}</Span>}
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    style={{ textTransform: 'capitalize' }}
                                    disabled={isSubmitting}
                                    value={values.group}
                                    onChange={handleChange('group')}
                                    placeholder='Enter group ex:(IT, Accounting, Admin)'
                                    onBlur={handleBlur('group')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.group && touched.group && <Span color='red' size='xs'>{errors.group}</Span>}
                        </div>

                        {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}

                        <div className='flex mt-6 flex-row justify-between items-center space-x-2 w-full'>
                            <Button
                                shadow='grey'
                                padding='p-10'
                                width='full'
                                disabled={isSubmitting}
                                onClick={handleBack}
                                bg='none'
                            >
                                <div className='flex flex-row justify-center items-center space-x-2'>
                                    <BsArrowLeftShort color='black' size={20} />
                                    <Span color='default' weight='semi'>Back</Span>
                                    {isSubmitting && <Spin className='login_spin' size='small' />}
                                </div>
                            </Button>

                            <Button
                                shadow='grey'
                                padding='p-10'
                                width='full'
                                disabled={isSubmitting}
                                onClick={() => handleSubmit()}
                            >
                                <div className='flex flex-row justify-center items-center space-x-2'>
                                    {isSubmitting && <Spin className='login_spin' size='small' />}
                                    <Span color='white' weight='semi'>Finish Setup</Span>
                                    <BsArrowRightShort size={20} />
                                </div>
                            </Button>
                        </div>
                    </div>
                )
            }}
        </Formik >
    )
}

export default DesignationStep