import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal } from 'antd';
import { updateOTRequestStatus } from '../../../common/api';
import { InputContainer } from '../../common/Containers';
import { Input } from '../../common/Input';
import { Span } from '../../common/Span';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { useLocation } from 'react-router-dom';

type Props = {
    setUpdate: Function,
    setSuccess?: Function,
    update: boolean,
    data: any,
    type: string
}

type State = {
    remarks: string,
    to: string,
    emp: Array<any>
}


const UpdateOTRequest = ({ data, update, setUpdate, type, setSuccess }: Props) => {
    const dispatch = useDispatch<Dispatch>();
    const pathname = useLocation().pathname;
    const currentModule = pathname?.split('/')[1];

    const { userInfo } = useSelector((state: RootState) => state.User);

    const initialState = {
        remarks: ''
    } as State;

    const validateSchema = yup.object().shape({
        remarks: yup.lazy(() => yup
            .string()
            .when('remarks', {
                is: remarks => {
                    if (type === 'reject') return true;
                    return false
                },
                then: yup.string().required(),
                otherwise: yup.string().optional()
            })
        )
    })

    const handleUpdate = async (values, actions) => {
        try {
            const body = {
                ...data,
                type,
                remarks: values?.remarks,
                ...(currentModule === 'overtime' && {
                    managerInfo: userInfo,
                })
            }

            const res = await updateOTRequestStatus(body);

            if (res?.isSuccess) {
                setUpdate(false);
                if (setSuccess) setSuccess(true);
                if (currentModule === 'overtime') {
                    dispatch.Table.getFirebaseData({ table: 'overtime', name: 'overtime_requests' })
                }
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? `Unable to ${type === 'reject' ? 'Reject' : 'Approve'} Request!`
            actions.setErrors({ errMessage: errMsg });
        }
    }

    return (
        <Formik
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleUpdate(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }: any) => {
                return (
                    <Modal
                        title={`Are you sure you want to "${type === 'reject' ? 'Reject' : 'Approve'}" this overtime request?`}
                        okButtonProps={{ loading: isSubmitting }}
                        onOk={() => handleSubmit()}
                        onCancel={() => setUpdate(false)}
                        okText={type === 'reject' ? 'Reject' : 'Approve'}
                        visible={update}
                        closable={false}
                        okType={type === 'reject' ? 'danger' : 'primary'}
                        maskClosable={false}
                        cancelButtonProps={{ style: { display: currentModule === 'overtime' ? '' : 'none' } }}
                    >

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>Name: </span>
                            <Span transform='capitalize'>{data?.userInfo?.name}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>Position: </span>
                            <Span transform='capitalize'>{data?.userInfo?.position}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>Date: </span>
                            <Span transform='capitalize'>{moment(data?.date).format('LL')}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>From: </span>
                            <Span transform='capitalize'>{moment(data?.timeIn).format('LT')}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>To: </span>
                            <Span transform='capitalize'>{moment(data?.timeOut).format('LT')}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>Work Hours: </span>
                            <Span transform='capitalize'>{data?.workHours}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <span className=' font-semibold w-28'>Reason: </span>
                            <Span transform='capitalize'>{data?.reason}</Span>
                        </div>

                        <div className='flex flex-row justify-start items-center'>
                            <Span margin='mt-4'>Remarks</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer margin='none'>
                            <Input
                                value={values?.remarks}
                                required
                                onChange={handleChange('remarks')}
                                type="text"
                                placeholder='Remarks'
                                onBlur={handleBlur('remarks')}
                            />
                        </InputContainer>
                        {errors.remarks && touched.remarks && <Span color='red' size='xs'>{errors.remarks}</Span>}

                        {errors.errMessage && <Span color='red' size='xs' margin='mt-2'>{errors.errMessage}</Span>}
                    </Modal>
                )
            }}
        </Formik>
    )
}

export default UpdateOTRequest;