import { Avatar } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText, getOrganizationLogo } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "name",
    field: "name",
    Header: "Company Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const source = getOrganizationLogo(d?.logo);
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={source} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} >{d.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d.name}</Span>
        </div>
      )
    }
  },
  {
    id: "phoneNumber",
    field: "phoneNumber",
    Header: "Company Number",
    isVisible: true,
    accessor: 'phoneNumber',
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.phoneNumber ? (
            <span className="capitalize max-w-md block">{d?.phoneNumber}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "address",
    field: "address",
    Header: "Company Address",
    isVisible: true,
    accessor: 'address',
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.address ? (
            <span className="capitalize max-w-md block">{d?.address}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "website",
    field: "website",
    Header: "Website",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.website ? (
            <a href={d?.website} target="_blank" className="max-w-md block underline">{d?.website}</a>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='organization' />
      )
    }
  }

];
