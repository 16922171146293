import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'
import { AiOutlineInbox } from 'react-icons/ai'

const RenderSSS = ({ item, dataType }) => {

    const showVal = item?.compensation_range_to >= 1000000 ? 'Over' : currencyCommaFormat(item?.compensation_range_to, 2, true);

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300' />

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Range From: </span>
                    <Span transform='capitalize'>{currencyCommaFormat(item?.compensation_range_from, 2, true)}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Range To: </span>
                    <Span>{showVal}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Employer Share: </span>
                    <Span>{currencyCommaFormat(item?.employer_share, 2, true)}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Employer Share: </span>
                    <Span>{currencyCommaFormat(item?.employee_share, 2, true)}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Total Share: </span>
                    <Span>{currencyCommaFormat(item?.total_contribution, 2, true)}</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderSSS