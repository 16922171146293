import { Spin } from 'antd';
import { SpinnerCircularFixed } from 'spinners-react';

export default ({ isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className='absolute z-50 items-center flex justify-center w-full h-full overscroll-none bg-[rgba(255,255,255,0.5)]'>
                    <Spin />
                </div>
            )}
        </>
    )
}