import { Backdrop, Slide, useMediaQuery } from '@mui/material'
import React, { useRef, useState } from 'react'
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ImageViewer = ({ open, setOpen, data }) => {
    const mobile = useMediaQuery('(max-width:600px)')

    const [index, setIndex] = useState(0);

    const arrOfImageSource = data?.imagesName?.map((image: any, index: any) => {
        const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/task%2F${image}?alt=media&token=77af9bf2-8b1b-4f80-910f-c0b19ad26d3a`;
        return source;
    })

    // const [listening, setListening] = useState(false);
    const backdropRef: any = useRef(null);

    // useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setOpen));

    const handlePrev = () => {
        if (index < 1) return;
        setIndex(index - 1);
    };

    const handleNext = () => {
        if (index >= (arrOfImageSource?.length - 1)) return;
        setIndex(index + 1);
    }

    return (
        <Backdrop
            open={open}
            onClick={(e) => {
                if (e.target === backdropRef.current) {
                    setOpen(false);
                }
            }}
            sx={{
                zIndex: 99,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            ref={backdropRef}
        >
            <div className={`relative flex justify-center items-center ${mobile ? 'w-4/5 h-4/5' : 'w-3/5 h-3/5'}`}>
                <div key={index} className='flex w-full h-full justify-center items-center transition-all ease-in-out'>
                    <AiOutlineCloseCircle onClick={() => setOpen(false)} className='z-50 text-4xl text-red-500 cursor-pointer absolute bottom-[-50px]' />
                    <img src={arrOfImageSource[index]} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                <div className={`absolute flex justify-between items-center ${mobile ? 'px-2 w-screen' : 'px-10 w-full'}`}>
                    <BsFillArrowLeftCircleFill onClick={handlePrev} className=' text-4xl text-gray-300 cursor-pointer' />
                    <BsFillArrowRightCircleFill onClick={handleNext} className=' text-4xl text-gray-300 cursor-pointer' />
                </div>
            </div>
        </Backdrop>
    )
}

export default ImageViewer