import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { Drawer } from 'antd';
import OrgForm from './Forms/OrgForm';
import { createScheduledtask } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import ScheduledTaskForm from './Forms/ScheduleTaskForm/ScheduledTaskForm';
import moment from 'moment';

type Props = {
    table: string,
    setAdd: Function,
    add: boolean
}

type State = {
    cron: string,
    remarks: string,
    images: Array<any>,
    employees: Array<any>,
    taskName: string,
    startTime: any,
    endTime: any,
    status: string,
}

const AddScheduledTask = ({ table, setAdd, add }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const { userInfo } = useSelector((state: RootState) => state.User);

    const initialState = {
        cron: '0 0 * * *',
        remarks: '',
        images: [],
        employees: [],
        taskName: '',
        startTime: null,
        endTime: null,
        status: 'pending',
        scheduled_status: 'active',
    } as State;

    const validateSchema = yup.object({
        cron: yup.string().required('Cron is required field'),
        taskName: yup.string().required('Name is required field'),
        remarks: yup.string(),
        employees: yup.array().required('Employees is required field').min(1, 'Employees field must be atleast 1 employee'),
        dueDate: yup.string().test('dueDate', 'Due Date should be after creation date', (val) => {
            const dateNow = moment();
            const selectedDate = moment(val);
            if (!val) return true;
            if (dateNow.isBefore(selectedDate)) {
                return true;
            }
            return false
        }).nullable()
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleCreateScheduledtask = async (values, actions) => {
        try {
            const payload = {
                ...values,
                employees: values?.employees?.map(e => e?.id)
            }
            let res;

            switch (table) {
                case 'workflow':
                    res = await createScheduledtask(payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully created' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to create user'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleCreateScheduledtask(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Add Scheduled Task"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Submit'
                            />
                        }
                    >
                        {add && <ScheduledTaskForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddScheduledTask;