import { Typography } from '@mui/material';
import React from 'react'
import { CenterContainer } from '../components/common/Containers'

const PageNotFound = () => {
  return (
    <CenterContainer>
        <Typography variant='h4'>Page Not Found</Typography>
    </CenterContainer>
  )
}

export default PageNotFound;