import { get, getBlob, post, postMultipart, put, remove } from "../core";


export const updateSssSettings = async (payload) => {
    try {
        const body = new FormData();

        body.append('file', payload.file);

        delete payload.file;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Update SSS settings')

        const res = await postMultipart('/admin/update/sss-settings', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updatePhicSettings = async (body: any) => {
    try {
        const res = await post('/admin/update/phic-settings', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateHdmfSettings = async (body: any) => {
    try {
        const res = await post('/admin/update/hdmf-settings', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateTaxSettings = async (payload) => {
    try {
        const body = new FormData();

        body.append('file', payload);
        body.append('name', 'Update TAX settings')

        const res = await postMultipart('/admin/update/tax-settings', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addHoliday = async (payload) => {
    try {
        const res = await post('/admin/add/holiday', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateHoliday = async (eventId, payload) => {
    try {
        const res = await put(`/admin/update/holiday/${eventId}`, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteCalendarEvent = async (eventId) => {
    try {
        const res = await remove(`/admin/delete/calendar-event/${eventId}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addContract = async (payload) => {
    try {
        const body = new FormData();

        body.append('file', payload.file);

        delete payload.file;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Rates Per Employee');

        const res = await postMultipart('/admin/add/contract', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateContract = async (payload) => {
    try {
        const body = new FormData();

        body.append('file', payload?.file);

        delete payload.file;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Rates Per Employee');

        const res = await postMultipart(`/admin/update/contract/${payload?.id}`, body)
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const calculatePayroll = async (payload) => {
    try {
        const res = await post('/admin/get/calculated-payroll', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const generateReportPdf = async (payload) => {
    try {
        const res = await getBlob('/admin/generate/reports/pdf', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const generatepayslipPDF = async (payload) => {
    try {
        const res = await getBlob('/admin/generate/payslip/pdf', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const sendPayslipViaEmail = async (payload) => {
    try {
        const res = await post('/admin/send/payslip', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteContract = async (id) => {
    try {
        const res = await remove(`/admin/delete/contract/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const savedPayslip = async (payload) => {
    try {
        const res = await post('/admin/save/payslip', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getListOfReports = async (payload) => {
    try {
        const res = await post('/admin/get/reports', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addCredential = async (payload) => {
    try {
        const res = await post('/admin/add/credential', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateCredential = async (payload, id) => {
    try {
        const res = await put(`/admin/update/credential/${id}`, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteCredential = async (id) => {
    try {
        const res = await remove(`/admin/delete/credential/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addReport = async (payload) => {
    try {
        const res = await post('/admin/add/report', payload);
        return res.data;
    } catch (err) {
        throw err;
    }
}