import { ClockCircleOutlined, CoffeeOutlined } from "@ant-design/icons";
import { Avatar, Tag } from "antd";
import moment from "moment";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText, getArrayOfSelectedDays, numOfLeaveHours } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "name",
    field: "name",
    Header: "Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{d?.userInfo?.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d?.userInfo?.name}</Span>
        </div>
      )
    }
  },
  {
    id: "position",
    accessor: 'position',
    field: "position",
    Header: "Position",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      const role = d?.userInfo?.role?.name;
      return (
        <>
          <Span transform='capitalize'>{d?.userInfo?.position}</Span>
          {role && (
            <Span transform='capitalize' color='lightgrey'>({role})</Span>
          )}
        </>
      )
    }
  },
  {
    id: "processedBy",
    field: "processedBy",
    Header: "Processed By",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const name = d?.updatedBy?.name;
      const role = d?.updatedBy?.role?.name;
      const remarks = d?.remarks;
      return (
        <>
          {d?.updatedBy ? (
            <div className=" flex justify-start items-start flex-col">
              <div className=" flex justify-start items-center flex-row w-full">
                <strong className="w-24">Name: </strong>
                <span className="capitalize truncate w-full"> {name}</span>
              </div>

              <div className=" flex justify-start items-center flex-row w-full">
                <strong className="w-24">Position:</strong>
                <span className="capitalize truncate w-full"> {role}</span>
              </div>

              <div className=" flex justify-start items-start flex-row w-full">
                <strong className="w-24">Remarks:</strong>
                <span className="capitalize w-full"> {remarks?.length ? remarks : 'None'}</span>
              </div>
            </div>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "date",
    field: "date",
    Header: "Date",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return moment(d?.date).format('LL');
    }
  },
  {
    id: "schedule",
    field: "schedule",
    Header: "Schedule",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className=" flex justify-start items-start flex-col">
          <div className=" flex flex-row justify-start items-start">
            <span className=" font-semibold w-10">From:</span>
            <Span>{moment(d?.timeIn).format('LT')}</Span>
          </div>
          <div className=" flex flex-row justify-start items-start">
            <span className=" font-semibold w-10">To:</span>
            <Span>{moment(d?.timeOut).format('LT')}</Span>
          </div>
        </div>
      )
    }
  },
  {
    id: "workHours",
    field: "workHours",
    Header: "Work Hours",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex justify-around flex-row">
          <Span><ClockCircleOutlined /> {d?.workHours} </Span>
        </div>
      )
    }
  },
  {
    id: "status",
    field: "status",
    Header: "Status",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <Tag
          className="capitalize w-20 text-center"
          color={d?.status === 'pending' ? 'processing' : d?.status === 'approved' ? 'success' : 'error'}
        >
          {d?.status}
        </Tag>
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='overtime' />
      )
    }
  }

];
