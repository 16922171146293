import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Drawer } from 'antd';
import { updatePhicSettings } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import PhicSetingsForm from './Forms/PhicSetingsForm';

type Props = {
    setAdd: Function,
    add: boolean,
    data: any
}

type State = {
    employee_share: string,
    employer_share: string
}

const UpdatePhicSettings = ({ setAdd, add, data }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const initialState = {
        employee_share: data?.employee_share ?? '',
        employer_share: data?.employer_share ?? '',
    } as State;

    const validateSchema = yup.object({
        employee_share: yup.number().required('Emplyee Share is required field'),
        employer_share: yup.number().required('Employer share is required field'),
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleUpdate = async (values, actions) => {
        try {
            const payload = {
                ...values,
                employee_share: parseFloat(values?.employee_share),
                employer_share: parseFloat(values?.employer_share),
                total_contribution: Number(values?.employee_share) + Number(values?.employer_share)
            }

            const res = await updatePhicSettings(payload);

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' });
                dispatch.Document.getFirebaseDoc({ name: 'config', document: 'phic_config', id: 'PHIC' });
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to updated'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleUpdate(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Settings"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Update Settings'
                            />
                        }
                    >
                        {add && <PhicSetingsForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdatePhicSettings;