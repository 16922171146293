import nofile from '../../../assets/images/Nodata_icon.png'; 
import { Span } from "../Span";

const NoData = () => {   

    return (<>      
        <div
            className='flex text-center py-10 h-96 justify-center'
                style={{ 
                flexDirection: "column",
                alignItems: "center", 
                }}
        > 
            <img src={nofile} className="h-24"/> 
            <Span color='grey' margin='mt-2'>No data found</Span>   
        </div>
    </>)
}

export default NoData