import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from '../store'
import { useHistory } from 'react-router-dom'
 
const Logout = () => {

    const history = useHistory();
    const dispatch = useDispatch<Dispatch>()

    useEffect(() => {
        dispatch.Table.resetState();
        dispatch.UI.resetState();
        dispatch.User.resetState();
        dispatch.Document.resetState();
        dispatch.Payroll.resetState();
        dispatch.User.updateState({ shouldRedirectToLogin: true }); 
        history.push(`/`);
    }, [])
    return ( 
        <> 
        </>
    )
}

export default Logout
