import React, { useRef, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { addClientInformation } from '../../../common/api/endpoints/client';
import { Span } from '../../../components/common/Span';
import { Button } from '../../../components/common/Button';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Spin } from 'antd';
import { InputContainer } from '../../../components/common/Containers';
import { Input } from '../../../components/common/Input';
import { useHistory, useLocation } from 'react-router-dom';

type State = {
    email?: string,
    name: string,
    bday: string,
    phoneNumber?: string,
}

const AdditionalinfoStep = ({ client }) => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;

    const initialState = {
        name: client?.name ?? '',
        bday: client?.bday ?? '',
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Name is required field'),
        bday: yup.string().required('Birth day is required field')
    })

    const [errMesssage, setErrMessage] = useState('');

    const formikRef = useRef() as any;

    const handleRegister = async (values, actions) => {
        try {
            let res;
            const dirty = formikRef.current.dirty;

            if (!dirty) {
                res = { isSuccess: true };
            } else {
                res = await addClientInformation(client?.id, values);
            }

            if (res.isSuccess) {
                dispatch.Document.getFirebaseDoc({ name: 'client', document: 'client', id: client?.id });
                actions.setSubmitting(false)
                actions.resetForm();
                history.push('/');
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to add information'
            setErrMessage(errMsg);
        }
    }

    const handleBack = () => {
        const data = {
            ...client,
            progressLevel: 0
        }

        history.push(`${pathname}?type=update&progress=0`);
        dispatch.Document.setDocumentData({ document: 'client', data });
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, errors, touched, isSubmitting, handleSubmit }: any) => {
                return (
                    <div className='w-[400px] flex flex-col items-center justify-center'>
                        <div className='flex w-full flex-col justify-center items-center mb-4'>
                            <Span letters='wider' size='2xl' weight='bold'>Personal Information?</Span>
                            <span className='mt-1 text-[13px] font-[500]'>Additional Information</span>
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    style={{ textTransform: 'capitalize' }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    placeholder='Enter full name*'
                                    onBlur={handleBlur('name')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <Span size='xs' weight='semi' margin='mt-2'>Date of birth</Span>
                            <InputContainer border='grey' flex='row' margin='none'>
                                <Input
                                    style={{ textTransform: 'uppercase' }}
                                    disabled={isSubmitting}
                                    value={values.bday}
                                    onChange={handleChange('bday')}
                                    placeholder='Enter birth day*'
                                    onBlur={handleBlur('bday')}
                                    type='date'
                                    border='none'
                                    max="9999-12-31"
                                    min="0000-01-01"
                                />
                            </InputContainer>
                            {errors.bday && touched.bday && <Span color='red' size='xs'>{errors.bday}</Span>}
                        </div>

                        {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}

                        <div className='flex mt-6 flex-row justify-between items-center space-x-2 w-full'>
                            <Button
                                shadow='grey'
                                padding='p-10'
                                width='full'
                                disabled={isSubmitting}
                                onClick={handleBack}
                                bg='none'
                            >
                                <div className='flex flex-row justify-center items-center space-x-2'>
                                    <BsArrowLeftShort color='black' size={20} />
                                    <Span color='default' weight='semi'>Back</Span>
                                    {isSubmitting && <Spin className='login_spin' size='small' />}
                                </div>
                            </Button>

                            <Button
                                shadow='grey'
                                padding='p-10'
                                width='full'
                                disabled={isSubmitting}
                                onClick={() => handleSubmit()}
                            >
                                <div className='flex flex-row justify-center items-center space-x-2'>
                                    {isSubmitting && <Spin className='login_spin' size='small' />}
                                    <Span color='white' weight='semi'>Continue</Span>
                                    <BsArrowRightShort size={20} />
                                </div>
                            </Button>
                        </div>
                    </div>
                )
            }}
        </Formik >
    )
}

export default AdditionalinfoStep