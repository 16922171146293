import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader';

import Table from '../../components/Table/Table';
import { COLUMNS } from './Column'
import { Input } from 'antd';
import { Span } from '../../components/common/Span';
import { Button } from '../../components/common/Button';
import { BiPlusCircle } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import AddRole from '../../components/ModuleComponents/AddRole';
import UpdateRole from '../../components/ModuleComponents/UpdateRole';
import { generateFilterForRequests, getPermission } from '../../common/utils';
import { useMediaQuery } from '@mui/material';
import Card from '../../components/Card/Card';
import RequestLeave from '../../components/ModuleComponents/RequestLeave';

export default () => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const permissions = getPermission('log');

    const [search, setSearch] = useState('');
    const [request, setRequest] = useState(false);

    const data: any = useSelector((state: RootState) => state.Table.tables.overtime.data)
        .filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

    const { isLoading } = useSelector((state: RootState) => state.Table);
    const { userInfo } = useSelector((state: RootState) => state.User);

    const columns = useMemo(() => COLUMNS, []);
    const { Search } = Input;

    useEffect(() => {
        const filters = generateFilterForRequests(permissions, userInfo);

        dispatch.Table.setMultipleFilterValue({ table: 'overtime', filters: filters })

        dispatch.Table.getFirebaseData({ table: 'overtime', name: 'overtime_requests' });

    }, [])

    return (<div className='relative'>
        <Loader isLoading={isLoading} />
        <Container border='grey' padding='none'>
            <div className='p-4'>
                <div className={`flex ${mobile ? 'flex-col items-center justify-center ' : 'justify-between'}`}>
                    <Span margin='mt-4' weight='bold' size='2xl'>Overtime Management</Span>
                    {/* <div className={`flex ${mobile ? 'justify-center w-full mt-4' : 'justify-end px-4 my-4'}`}>
                        {permissions.includes('canCreate') && (
                            <Button
                                padding='py-1'
                                border='blue'
                                bg='none'
                                color='blue'
                                {...(!mobile && {
                                    width: 'none'
                                })}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                    onClick={() => {
                                        setRequest(true)
                                        history.push(`${pathname}?type=add`)
                                    }}
                                >
                                    <BiPlusCircle className='my-auto mx-2 text-2xl' />
                                    Request Leave
                                </div>
                            </Button>
                        )}
                    </div> */}
                </div>
            </div>

            <Search className={`${mobile ? 'px-4 pb-4' : 'p-4'}`} placeholder="Search any..." onChange={(e: any) => setSearch(e.target.value)} style={{ width: mobile ? '100%' : 300 }} />

            <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                {mobile ? (
                    <Card data={data} dataType='overtime' sortName='createdAt' />
                ) : (
                    <Table columns={columns} data={data} dataType='overtime' sortName='createdAt' isLastColSticky={true} />
                )}
            </div>

            {/* <RequestLeave table='leave' setRequest={setRequest} request={request} />
            <UpdateRole table='role' /> */}
        </Container>
    </div>
    )
}
