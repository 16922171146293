import { Avatar } from '@mui/material'
import { Card } from 'antd'
import moment from 'moment'
import React from 'react'
import { AiOutlineInbox } from 'react-icons/ai'
import { firebaseDateToText } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'

const RenderLog = ({ item, dataType }) => {

    const applicationType = item?.old_from ? 'With Existing Timesheet' : 'New';
    const role = item?.userInfo?.role?.name;

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.userInfo?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.userInfo?.name}</Span>
                        <Span transform='capitalize' color={item?.status === 'pending' ? 'orange' : item?.status === 'approved' ? 'green-1' : 'required'}>{item?.status}</Span>
                    </div>
                </div>
                <Options data={item} table={dataType} />
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Position: </span>
                    <Span transform='capitalize'>{item?.userInfo?.position}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Role: </span>
                    {role ? (
                        <Span transform='capitalize' color='lightgrey'>({role})</Span>
                    ) : (
                        <div className="emptyContainer flex flex-row justify-start items-center">
                            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                            <Span color='lightgrey'>No Data</Span>
                        </div>
                    )}
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>App. Type: </span>
                    <Span color={item?.old_from ? 'required' : 'green-1'} >{applicationType}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Reason: </span>
                    {item?.reason?.length ? (
                        <span className="capitalize">{item?.reason}</span>
                    ) : (
                        <div className="emptyContainer flex flex-row justify-start items-center">
                            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                            <Span color='lightgrey'>No Data</Span>
                        </div>
                    )}
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>From: </span>
                    <span className="capitalize">{moment(item?.timeIn).format('LT')}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>To: </span>
                    <span className="capitalize">{moment(item?.timeOut).format('LT')}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Work Hours: </span>
                    <span className="capitalize">{item?.workHours}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Work Break: </span>
                    <span className="capitalize">{item?.workBreakTotal}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Created At: </span>
                    <span className="capitalize">{firebaseDateToText(item?.createdAt, 'LLL')}</span>
                </div>

            </div>

            {item?.updatedBy && (
                <div className='py-2 flex justify-center items-start flex-col'>
                    <span className=' font-bold w-full text-center pb-2'>Processed By</span>

                    <div className=" flex justify-start items-center flex-row w-full">
                        <strong className="w-20">Name: </strong>
                        <span className="capitalize truncate w-full"> {item?.updatedBy?.name}</span>
                    </div>

                    <div className=" flex justify-start items-center flex-row w-full">
                        <strong className="w-20">Position:</strong>
                        <span className="capitalize truncate w-full"> {item?.updatedBy?.role?.name}</span>
                    </div>

                    <div className=" flex justify-start items-center flex-row w-full">
                        <strong className="w-20">Remarks:</strong>
                        <span className="capitalize w-full"> {item?.remarks?.length ? item?.remarks : 'None'}</span>
                    </div>
                </div>
            )}
        </Card>
    )
}

export default RenderLog