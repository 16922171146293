import { Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Span } from '../../../common/Span'
import Permisssion from './Permisssion';

const Module = ({ module, permissions, setFieldValue }) => {
    const defaultChecked = permissions && permissions[module?.role] ? true : false;

    const [isChecked, setIsChecked] = useState(defaultChecked);
    const switchRef = useRef(null) as any;

    const onChange = (checked: boolean) => {
        switch (checked) {
            case true:
                let permission: any = {};
                module?.permission?.forEach(p => {
                    permission[p?.action] = true

                    if (p?.sub) {
                        let subPermission = {};
                        p?.sub?.forEach(item => subPermission[item?.action] = true);

                        permission[`${p?.action}Sub`] = subPermission;
                    }
                })

                const trueData = {
                    ...permissions,
                    [module?.role]: permission
                }

                setFieldValue('permissions', trueData);
                setIsChecked(true);
                break;
            case false:
                let falseData = permissions;
                delete falseData[module?.role];
                setFieldValue('permissions', falseData);
                setIsChecked(false);
                break;
        }
    }

    const clickSwitch = () => {
        switchRef?.current?.click();
    }

    return (
        <>
            <div className='flex justify-between items-center p-2'>
                <Span transform='capitalize' weight='semi'>{module?.name}</Span>
                <Switch ref={switchRef} defaultChecked={defaultChecked} onChange={onChange} />
            </div>

            {isChecked && module.permission?.map((p, index) => (
                <Permisssion clickSwitch={clickSwitch} permissions={permissions} setFieldValue={setFieldValue} permission={p} module={module} key={index} />
            ))}
        </>
    )
}

export default Module
