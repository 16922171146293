import { post, postMultipart, put } from "../core";

export const preSignUpWithUserEmailPhone = async (data: any) => {
    try {
        const res = await post('/client/pre/signup', data);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const signUpWithUserEmailPhone = async (data: any) => {
    try {
        const res = await post('/client/user-email-phone/signup', data);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const verifyEmail = async (email: string) => {
    try {
        const res = await post('/client/verify/email', { email });
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const createClientOrganization = async (payload: any) => {
    try {
        const body = new FormData();

        body.append('file', payload?.logo);

        delete payload?.logo;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Company Logo')

        const res = await postMultipart('/client/create/organization', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateClientOrganization = async (payload: any) => {
    try {
        const body = new FormData();

        body.append('file', payload?.logo);

        delete payload.logo;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Company Logo');

        const res = await postMultipart('/client/update/organization', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addClientInformation = async (clientId: string, body: any) => {
    try {
        const res = await put(`/client/additional-info/${clientId}`, body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addClientDesignation = async (clientId: string, body: any) => {
    try {
        const res = await put(`/client/add-designation/${clientId}`, body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const sendChangePlanEmail = async (data: any) => {
    try {
        const res = await post('/client/send/change-plan/email', data);
        return res.data;
    } catch (err) {
        throw err;
    }
}