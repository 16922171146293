import { Span } from '../../../common/Span';
import { Button } from '../../../common/Button';
import { PlusOutlined } from '@ant-design/icons';
import Item from './Item';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import RenderOption from './RenderOption';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    setFieldTouched: Function,
    defaultValue: any,
    errors: any,
    touched: any,
    errMesssage: string,
}

const EDForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldValue, setFieldTouched, defaultValue }: Props) => {

    const { data } = useSelector((state: RootState) => state.Payroll);

    const employees = data?.map((d: any) => d?.userInfo);

    const handleAddItem = () => {
        setFieldValue('items', [...values?.items, defaultValue]);
    }

    return (
        <div className='px-2 text-left'>
            <div className='flex flex-col justify-start items-start'>
                <div className='flex flex-row justify-start items-center'>
                    <Span margin='mt-4'>Assign To</Span>
                    <Span color='red'>*</Span>
                </div>

                <Autocomplete
                    style={{ width: '100%' }}
                    disablePortal
                    disableClearable
                    disableCloseOnSelect
                    multiple
                    onFocus={() => setFieldTouched('employees')}
                    size="small"
                    id="combo-box-demo"
                    options={[{ name: 'Select all' }, ...employees]}
                    value={values?.employees}
                    onChange={(event, newValue: any) => {
                        if (newValue.some((option: any) => option?.name === 'Select all')) {
                            setFieldValue('employees', employees);
                        } else {
                            setFieldValue('employees', newValue)
                        }
                    }}
                    onBlur={handleBlur('employees')}
                    getOptionLabel={(option: any) => option.name}
                    ListboxProps={{
                        style: {
                            maxHeight: '220px',
                        }
                    }}
                    renderOption={(props, option, { selected }) =>
                        <RenderOption
                            props={props}
                            option={option}
                            values={values}
                            selected={selected}
                            employees={employees}
                            setFieldValue={setFieldValue}
                        />
                    }
                    renderInput={(params) => (
                        <TextField
                            placeholder='Select Employee...'
                            sx={{
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                },
                            }}
                            {...params}
                        />
                    )}
                />

                {errors.employees && touched.employees && <Span color='red' size='xs'>{errors.employees}</Span>}
            </div>

            <div className='flex flex-col justify-start items-start'>
                <div className='flex flex-row justify-start items-center'>
                    <Span margin='mt-4'>Items</Span>
                    <Span color='red'>*</Span>
                </div>

                {values?.items?.map((data, index) => (
                    <Item
                        key={index}
                        errors={errors?.items ? errors?.items[index] : {}}
                        touched={touched?.items ? touched?.items[index] : {}}
                        index={index}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                ))}
            </div>

            <div className='flex w-full justify-end items-center py-4'>
                <Button
                    padding='px-8'
                    width='none'
                    onClick={handleAddItem}
                >
                    <div className='flex w-full justify-center items-center'>
                        <span className='pr-2 cursor-pointer'>
                            Add Item
                        </span>
                        <PlusOutlined style={{ color: 'white' }} />
                    </div>
                </Button>
            </div>

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default EDForm