import React from 'react'
import { ContainerLayout, MainLayout } from '../components/common/Containers'
import Header from '../components/Header/Header'
import SideBar from '../components/Sidebar/Sidebar'
import Attachment from '../containers/Employees/Employees';

const Employees = () => {
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header />
                <ContainerLayout>
                    <Attachment /> 
                </ContainerLayout>
            </MainLayout>
        </>
    )
}

export default Employees
