import React from 'react'
import { currencyCommaFormat, getRate } from '../../../../../common/utils'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const Earnings = ({ select }) => {

    const { userInfo, earnings, ratePerHour, contract } = select;

    const overtimeRatePerHour = ratePerHour + getRate(ratePerHour, contract?.overtime);
    const regHolidayRatePerHour = getRate(ratePerHour, contract?.regular_holiday);
    const specHolidayRatePerHour = getRate(ratePerHour, contract?.special_holiday);

    return (
        <>
            {/* Standard pay */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Standard Pay</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{`${select?.totalDaysWork * 8 < 10 ? '0' : ''}${select?.totalDaysWork * 8}:00`}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(ratePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(earnings?.worktimePay, 2, true)}</span>
            </div>

            {/* Overtime pay */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Overtime Pay</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{select?.overtimeHours}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(overtimeRatePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(earnings?.overtimePay, 2, true)}</span>
            </div>


            {/* Regular Holiday Pay */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Regular Holiday Pay</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{`${select?.numberOfRegularHoliday * 8 < 10 ? '0' : ''}${select?.numberOfRegularHoliday * 8}:00`}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(regHolidayRatePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(earnings?.regularHolidayPay, 2, true)}</span>
            </div>

            {/* Special Holiday Pay */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Special Holiday Pay</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{`${select?.numberOfSpecialNonWorkingHoliday * 8 < 10 ? '0' : ''}${select?.numberOfSpecialNonWorkingHoliday * 8}:00`}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(specHolidayRatePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(earnings?.specialHolidayPay, 2, true)}</span>
            </div>

            {/* Sick Leave Pay */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Sick Leave Pay</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{`${select?.numberOfSickLeaveDays * 8 < 10 ? '0' : ''}${select?.numberOfSickLeaveDays * 8}:00`}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(ratePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(earnings?.sickLeavePay, 2, true)}</span>
            </div>

            {/* Vacation Leave Pay */}
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>Vacation Leave Pay</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{`${select?.numberOfVacationLeaveDays * 8 < 10 ? '0' : ''}${select?.numberOfVacationLeaveDays * 8}:00`}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(ratePerHour, 2, true)}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(earnings?.vacationLeavePay, 2, true)}</span>
            </div>
        </>
    )
}

export default Earnings