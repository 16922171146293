import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat } from '../../../common/utils'
import { Span } from '../../common/Span'

const RenderPayrollList = ({ item, dataType }) => {

    const role = item?.userInfo?.role?.name;

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-start">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.userInfo?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.userInfo?.name}</Span>
                        <Span transform='capitalize'>{item?.userInfo?.position} - {item?.userInfo?.organization.name}</Span>
                        {role && (
                            <Span transform='capitalize' color='lightgrey'>({role})</Span>
                        )}
                    </div>
                </div>
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Sick Leave: </span>
                    <Span transform='capitalize'>Unused: {item?.userInfo?.sickLeave}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Vacation Leave: </span>
                    <Span transform='capitalize'>Unused: {item?.userInfo?.vacationLeave}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Daily Rate: </span>
                    <span>{currencyCommaFormat((item?.userInfo?.ratePerHour * 8), 2, true)}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Gross Salary: </span>
                    <span>{currencyCommaFormat(item?.grossSalary, 2, true)}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Deductions: </span>
                    <span>{currencyCommaFormat(item?.totalDeductions, 2, true)}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Net Salary: </span>
                    <span>{currencyCommaFormat(item?.netSalary, 2, true)}</span>
                </div>
            </div>
        </Card>
    )
}

export default RenderPayrollList