import { Models } from "@rematch/core";
import { Table } from "./TableModel";
import { UI } from "./UIModel";
import { User } from "./UserModel";
import { Document } from "./DocModel";
import { Payroll } from "./PayrollModel";
import { LocalStorage } from "./LocalStorageModel";

export interface RootModel extends Models<RootModel> {
  Table: typeof Table;
  UI: typeof UI;
  User: typeof User;
  Document: typeof Document;
  Payroll: typeof Payroll;
  LocalStorage: typeof LocalStorage;
}

export const models: RootModel = {
  Table,
  UI,
  User,
  Document,
  Payroll,
  LocalStorage
};
