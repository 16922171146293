import { Span } from '../../common/Span';
import { Input } from '../../common/Input';
import { AiOutlineCloseCircle, AiOutlineUpload } from 'react-icons/ai';
import { getBase64, getOrganizationLogo, removeSCAndCapitalize } from '../../../common/utils';
import { useState } from 'react';
import { IMAGE_TYPE } from '../../constants';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    errMesssage: string,
    setFieldTouched: Function,
    setErrors: Function,
    setFieldValue: Function
}

const CredentialForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldTouched, setErrors, setFieldValue }: Props) => {

    const credentials = useSelector((state: RootState) => state.Table.tables.credentials.data);
    const all_organizations = useSelector((state: RootState) => state.Table.tables.organization.data);

    const credOrgIds = credentials?.map(c => c?.organization?.id);
    const organizations = all_organizations?.filter(o => !credOrgIds?.includes(o?.id));

    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        setVisible(!visible);
    }

    return (
        <div className='px-2 text-left'>

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Organization</Span>
                <Span color='red'>*</Span>
            </div>
            <Autocomplete
                disablePortal
                disableClearable
                filterSelectedOptions
                size="small"
                id="combo-box-demo"
                options={organizations}
                value={values?.organization}
                onChange={(e, newValue) => {
                    setFieldValue('organization', newValue);
                }}
                onBlur={handleBlur('organization')}
                getOptionLabel={(option: any) => removeSCAndCapitalize(option.name, ' ')}
                ListboxProps={{
                    style: {
                        maxHeight: '220px',
                    }
                }}
                renderOption={(props, option: any) => {
                    return (<>
                        <li {...props}>
                            <div className="flex flex-row items-center justify-center">
                                {/* <img src={location_icon} alt="" className="w-6 h-6 object-contain" /> */}
                                <Span transform='capitalize'>{option?.name}</Span>
                            </div>
                        </li>
                    </>)
                }}
                renderInput={(params) => (
                    <TextField
                        placeholder='Select Organization...'
                        sx={{
                            '& legend': {
                                display: 'none'
                            },
                            '& fieldset': {
                                top: 0
                            },
                        }}
                        {...params}
                    />
                )}
            />
            {errors.organization && touched.organization && <Span color='red' size='xs'>{errors.organization}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Base URL</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.baseUrl}
                onChange={handleChange('baseUrl')}
                placeholder='Enter Base URL'
                onBlur={handleBlur('baseUrl')}
            />
            {errors.baseUrl && touched.baseUrl && <Span color='red' size='xs'>{errors.baseUrl}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Database</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.db}
                onChange={handleChange('db')}
                placeholder='Enter database'
                onBlur={handleBlur('db')}
            />
            {errors.db && touched.db && <Span color='red' size='xs'>{errors.db}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Username</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.login}
                onChange={handleChange('login')}
                placeholder='Enter username'
                onBlur={handleBlur('login')}
            />
            {errors.login && touched.login && <Span color='red' size='xs'>{errors.login}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Password</Span>
                <Span color='red'>*</Span>
            </div>
            <div className='border border-grey-1 flex flex-row pr-4 items-center'>
                <Input
                    border='none'
                    value={values.password}
                    onChange={handleChange('password')}
                    placeholder='Enter password'
                    onBlur={handleBlur('password')}
                    type={visible ? 'text' : 'password'}
                />
                <div className='cursor-pointer' onClick={toggleVisibility}>
                    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
            </div>
            {errors.password && touched.password && <Span color='red' size='xs'>{errors.password}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Confirm Password</Span>
                <Span color='red'>*</Span>
            </div>
            <div className='border border-grey-1 flex flex-row pr-4 items-center'>
                <Input
                    border='none'
                    value={values.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    placeholder='Enter password'
                    onBlur={handleBlur('confirmPassword')}
                    type={visible ? 'text' : 'password'}
                />
                <div className='cursor-pointer' onClick={toggleVisibility}>
                    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
            </div>
            {errors.confirmPassword && touched.confirmPassword && <Span color='red' size='xs'>{errors.confirmPassword}</Span>}

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default CredentialForm