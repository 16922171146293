import { Span } from '../../common/Span';
import { Input } from '../../common/Input';
import { InputContainer } from '../../common/Containers';
import { AiOutlinePercentage } from 'react-icons/ai';
import { onlyNumberKeyDotAndNegative } from '../../../common/utils';
import { Button } from '../../common/Button';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    errors: any,
    touched: any,
    errMesssage: string,
}

const PhicSetingsForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldValue }: Props) => {

    return (
        <div className='px-2 text-left'>
            <div className='px-2 text-left'>
                <div className='flex flex-row justify-start items-center mb-1'>
                    <Span>Employee's Share</Span>
                    <Span color='red'>*</Span>
                </div>
                <InputContainer border='grey' padding='none' flex='row' margin='none'>
                    <AiOutlinePercentage className='ml-2' />
                    <Input
                        border='none'
                        style={{ textTransform: 'capitalize' }}
                        value={values.employee_share}
                        onChange={handleChange('employee_share')}
                        placeholder='Enter Percentage'
                        onBlur={handleBlur('employee_share')}
                        onKeyPress={onlyNumberKeyDotAndNegative}
                    />
                </InputContainer>
                {errors.employee_share && touched.employee_share && <Span color='red' size='xs'>{errors.employee_share}</Span>}
            </div>

            <div className='px-2 text-left mt-4'>
                <div className='flex flex-row justify-start items-center mb-1'>
                    <Span>Employer's Share</Span>
                    <Span color='red'>*</Span>
                </div>
                <InputContainer border='grey' padding='none' flex='row' margin='none'>
                    <AiOutlinePercentage className='ml-2' />
                    <Input
                        border='none'
                        style={{ textTransform: 'capitalize' }}
                        value={values.employer_share}
                        onChange={handleChange('employer_share')}
                        placeholder='Enter Percentage'
                        onBlur={handleBlur('employer_share')}
                        onKeyPress={onlyNumberKeyDotAndNegative}
                    />
                </InputContainer>
                {errors.employer_share && touched.employer_share && <Span color='red' size='xs'>{errors.employer_share}</Span>}
            </div>

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default PhicSetingsForm