import React from 'react'
import NoData from '../common/NoData/NoData';
import CardItem from './CardItem';

const Card = ({ data, dataType, sortName }) => {

    return (
        <div className='flex flex-col justify-center items-center mt-2 px-2'>
            {data?.length < 1 && <NoData />}
            {data?.sort((a, b) => {return b.createdAt?.seconds - a.createdAt?.seconds})?.map((item: any, index: any) => <CardItem item={item} key={index} dataType={dataType} />)}
        </div>
    )
}

export default Card