import { Checkbox } from 'antd'
import React, { useEffect } from 'react'
import { useQuery } from '../../../../common/utils';
import { Span } from '../../../common/Span'
import { BsDot } from 'react-icons/bs';
import SubPermission from './SubPermission';

const Permisssion = ({ permissions, setFieldValue, permission, module, clickSwitch }) => {

    const isChecked = permissions ? permissions[module?.role][permission?.action] : false;

    const onChange = (e: any) => {
        const value = e.target.checked;

        switch (value) {
            case true:
                let trueData = {
                    ...permissions,
                    [module?.role]: {
                        ...permissions[module?.role],
                        [permission?.action]: true,
                    }
                }

                if (permission?.sub) {
                    let subPermission = {};
                    permission?.sub?.forEach(item => subPermission[item?.action] = true);

                    trueData[module?.role][`${permission?.action}Sub`] = subPermission;
                }

                setFieldValue('permissions', trueData)
                break;
            case false:
                const trueLength = Object.values(permissions[module?.role]).filter(v => v === true)?.length;
                if (trueLength === 1) {
                    let payload = permissions;
                    delete payload[module?.role];
                    setFieldValue('permissions', payload);
                    clickSwitch();
                    return;
                }

                const falseData = {
                    ...permissions,
                    [module?.role]: {
                        ...permissions[module?.role],
                        [permission?.action]: false
                    }
                }
                delete falseData[module?.role][`${permission?.action}Sub`];
                setFieldValue('permissions', falseData);
                break;
        }
    }

    return (
        <>
            <div className='flex justify-between items-center px-10'>
                <Span color='grey' transform='capitalize' wrap='singleLine'>{permission?.name}</Span>
                <Checkbox disabled={permission?.action === 'canView'} value={isChecked} defaultChecked={isChecked} onChange={onChange} />
            </div>

            {isChecked && permission?.sub && (
                <div className='mx-10 shadow-sm mb-2 pl-5 py-0 pr-6 border-t border-gray-100'>
                    {(permission?.sub ?? [])?.map((sub, index) => (
                        <SubPermission
                            key={index}
                            index={index}
                            sub={sub}
                            permissions={permissions}
                            setFieldValue={setFieldValue}
                            permission={permission}
                            module={module}
                        />
                    ))}
                </div>
            )}
        </>
    )
}

export default Permisssion