import React, { useRef, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Drawer } from 'antd';
import OrgForm from './Forms/OrgForm';
import { addCredential, addOrganization } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CredentialForm from './Forms/CredentialForm';

type Props = {
    table: string,
    setAdd: Function,
    add: boolean
}

type State = {
    organization: Object | null,
    db: string,
    login: string,
    password: any,
    confirmPassword: string,
}

const AddCredential = ({ table, setAdd, add }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const formikRef = useRef() as any;

    const initialState = {
        organization: null,
        baseUrl: '',
        db: '',
        login: '',
        password: '',
        confirmPassword: ''
    } as State;

    const validateSchema = yup.object({
        organization: yup.object().required('Organization is required field').nullable(),
        baseUrl: yup.string().required('Base Url is required field'),
        db: yup.string().required('Database is required field'),
        login: yup.string().required('Username is required field'),
        password: yup.string().required('Password is required field'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required field')
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
            }

            let res;

            switch (table) {
                case 'credentials':
                    res = await addCredential(payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully created' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to create user'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Add Credential"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Add Credential'
                            />
                        }
                    >
                        {add && <CredentialForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddCredential;