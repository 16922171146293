import React, { useEffect, useRef, useState } from 'react'
import { Span, SpanContainer } from '../../../common/Span';
import { Input } from '../../../common/Input';
import { Button } from '../../../common/Button';
import { getAuth } from 'firebase/auth';
import { app } from '../../../../Firebase';
import { Container, InputContainer } from '../../../common/Containers';
import { ConsoleSqlOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { onlyNumberKey, useQuery } from '../../../../common/utils';
import BackDrop from '../../../BackDrop';
import { Spin, Switch } from 'antd';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { PERMISSIONS } from '../../../constants';
import Module from './Module';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    errMesssage: string,
    setFieldValue: Function,
}


const RoleForm = ({ setFieldValue, values, handleChange, handleBlur, errors, touched, errMesssage }: Props) => {

    const query = useQuery();
    const dispatch = useDispatch<Dispatch>();

    const type: any = query.get('type');

    const [allDataLoaded, setAllDataLoaded] = useState(false);

    useEffect(() => {
        setAllDataLoaded(true);
    }, []);

    return (
        <div className='px-2 text-left'>
            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Role Name</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.name}
                onChange={handleChange('name')}
                placeholder='Enter name'
                onBlur={handleBlur('name')}
            />
            {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Role Description</Span>
                <Span margin='mt-4' color='lightgrey'>(Optional)</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.desc}
                onChange={handleChange('desc')}
                placeholder='Desciption'
                onBlur={handleBlur('desc')}
            />
            {errors.desc && touched.desc && <Span color='red' size='xs'>{errors.desc}</Span>}

            <div className='flex flex-row justify-between items-center my-4'>
                <Span>Status</Span>
                <Switch
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    checked={values?.status === 'active' ? true : false}
                    onChange={(value: any) => setFieldValue('status', value ? 'active' : 'inactive')}
                />
            </div>

            {allDataLoaded && (
                <div className='border border-gray-300 p-3 rounded-md'>
                    {PERMISSIONS?.map((module, index) => (
                        <Module module={module} permissions={values?.permissions} setFieldValue={setFieldValue} key={index} />
                    ))}
                </div>
            )}
            {errors.permissions && touched.permissions && <Span color='red' size='xs'>{errors.permissions}</Span>}

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default RoleForm