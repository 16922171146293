import React, { useCallback, useMemo, useState } from 'react'
import { Span } from '../../../common/Span';
import { Input, InputMask } from '../../../common/Input';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Autocomplete, TextField } from '@mui/material';
import RenderOption from './RenderOption';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    handleSubmit: Function,
    errors: any,
    touched: any,
    setFieldTouched: Function,
    isSubmitting: boolean,
    setAssign: Function,
    setFieldValue: Function
}


const ScheduleForm = ({ values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue }: Props) => {

    const employees = useSelector((state: RootState) => state.Table.tables.employees.data);

    const [data, setData] = useState({
        emp: [],
        searchStr: ''
    })

    const renderInput = (params) => {
        return <TextField
            placeholder='Select Employee...'
            sx={{
                '& legend': {
                    display: 'none'
                },
                '& fieldset': {
                    top: 0
                },
            }}
            onChange={(e: any) => {
                setData({ ...data, searchStr: e.target.value })
            }}
            {...params}
        />
    }

    return (
        <div className='p-2 text-left'>
            <Span margin='mt-4'>Select Employee</Span>
            <Autocomplete
                disablePortal
                disableClearable
                disableCloseOnSelect
                multiple
                size="small"
                id="combo-box-demo"
                options={[{ name: 'Select all' }, ...employees]}
                value={values?.emp}
                onChange={(event, newValue: any) => {
                    if (newValue.some((option: any) => option?.name === 'Select all')) {
                        setFieldValue('emp', employees);
                    } else {
                        setFieldValue('emp', newValue)
                    }
                }}
                onBlur={handleBlur('emp')}
                onFocus={() => setFieldTouched('emp')}
                getOptionLabel={(option: any) => option.name}
                ListboxProps={{
                    style: {
                        maxHeight: '220px',
                    }
                }}
                renderOption={(props, option, { selected }) =>
                    <RenderOption
                        props={props}
                        option={option}
                        values={values}
                        selected={selected}
                        employees={employees}
                        setFieldValue={setFieldValue}
                    />
                }
                renderInput={(params) => renderInput(params)}
            />
            {errors.emp && touched.emp && <Span color='red' size='xs'>{errors.emp}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>From</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.from}
                onChange={handleChange('from')}
                placeholder='Select Date'
                onBlur={handleBlur('from')}
                type='time'
            />
            {errors.from && touched.from && <Span color='red' size='xs'>{errors.from}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>To</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.to}
                onChange={handleChange('to')}
                onBlur={handleBlur('to')}
                type='time'
            />
            {errors.to && touched.to && <Span color='red' size='xs'>{errors.to}</Span>}
        </div>
    )
}

export default ScheduleForm