import React, { useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import './Login.css'
import { InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import designbg from '../../assets/images/login_design3.png'
import designbg1 from '../../assets/images/login_design.png'
import { useHistory } from "react-router-dom";
import { Dispatch } from "../../store";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md';
import { BRAND_IMAGES } from '../../components/constants';
import { Spin } from 'antd';
import { Span } from '../../components/common/Span';
import { Paper } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LockOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { onlyNumberKey, processFirebaseError, useQuery } from '../../common/utils';
import { preSignUpWithUserEmailPhone } from '../../common/api/endpoints/client';
import { IoIosPeople } from 'react-icons/io';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { verifyReferralCode } from '../../common/api';
import { debounce } from 'lodash';

export default () => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const cookies = new Cookies();
    const referralCode = query.get('r') ?? cookies.get('referralCode');
    if (referralCode) cookies.set('referralCode', referralCode, { expires: moment().add(3, 'days').toDate() });

    const [errMessage, setErrMessage] = useState('');
    const [error, setError] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationLoading, setValidationLoading] = useState(false);
    const [codeInValid, setCodeInValid] = useState(false);

    const initialState = {
        email: '',
        phoneNumber: '',
        password: '',
        referredBy: referralCode
    };

    const validateSchema = Yup.object().shape({
        email: Yup.string()
            .email('Should be valid email example(youremail@domain.com)')
            .required('Email is required field'),
        phoneNumber: Yup.string()
            .min(10, 'Must be a valid contact number')
            .max(10, 'Must be a valid contact number')
            .required('Phone number is required field'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    const validateReferralCode = debounce(async (val) => {
        try {
            setValidationLoading(true)
            const validation_result = await verifyReferralCode(val);

            if (validation_result?.isSuccess) {
                setValidationLoading(false)
                if (validation_result?.result) {
                    setCodeInValid(false);
                } else {
                    setCodeInValid(true);
                }
            }
        } catch (err) {
            console.log(err);
            setValidationLoading(false)
        }
    }, 1000);

    const handleOnError = (err: any) => {
        console.log(err);
        const errMsg = err?.response?.data?.error ?? 'Unable to Sign up';
        setErrMessage(errMsg);
        setError(true);
        setIsLoading(false);
    }

    const handleSignup = async (values, actions) => {
        try {
            if (codeInValid) return;

            setIsLoading(true);

            const payload = {
                ...values,
                phoneNumber: `+63${values?.phoneNumber}`
            }

            delete payload.confirmPassword;

            const res: any = await preSignUpWithUserEmailPhone(payload);

            if (res?.isSuccess) {
                setIsLoading(false);
                const reference = res?.message?.reference;
                dispatch.User.updateState({ reference, signup_details: payload });
                history.push(`/station?type=preSignUp&show=true&phone=${payload?.phoneNumber}`);
            }
        } catch (err: any) {
            handleOnError(err);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleSignup(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue }: any) => (
                <div className='min-h-screen overflow-hidden flex items-center justify-center'>
                    <img src={designbg} alt="" className='absolute top-0 right-0' />
                    <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

                    <Paper
                        elevation={3}
                        sx={{
                            width: { xs: '90vw', sm: '460px' },
                            minHeight: '500px',
                            padding: { xs: '10px 20px', sm: '30px 50px' },
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: '1'
                        }}
                    >
                        <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

                        <div className='flex flex-col justify-start items-start'>
                            <Span letters='wider' size='2xl' weight='bold'>Sign up</Span>
                            <span className='mt-1 text-[13px] font-[500]'>Create your free account 💁</span>
                        </div>

                        {error && (
                            <div className='text-center mt-4 w-full p-2 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                                <span className='text-xs text-red-500'>
                                    {errMessage}
                                </span>
                            </div>
                        )}

                        <div id='pin-container' className='w-full' />

                        <InputContainer margin='mt-4' border='grey' flex='row'>
                            <UserOutlined className='ml-2' />
                            <Input
                                disabled={isLoading}
                                value={values?.email}
                                onChange={handleChange('email')}
                                onBlur={handleBlur('email')}
                                placeholder='Email ex:(sample.gmail.com)'
                                border='none'
                            />
                        </InputContainer>
                        {errors.email && touched.email && <Span color='red' size='xs'>{errors.email}</Span>}

                        <InputContainer margin='mt-4' border='grey' flex='row'>
                            <div className='absolute h-full flex justify-center items-center left-[26px]'>
                                <Span>(+63)</Span>
                            </div>
                            <PhoneOutlined className='ml-2' />
                            <Input
                                padding='pl-10'
                                maxLength={10}
                                disabled={isLoading}
                                value={values?.phoneNumber}
                                onChange={handleChange('phoneNumber')}
                                onBlur={handleBlur('phoneNumber')}
                                placeholder='Phone number'
                                border='none'
                                onKeyPress={onlyNumberKey}
                            />
                        </InputContainer>
                        {errors.phoneNumber && touched.phoneNumber && <Span color='red' size='xs'>{errors.phoneNumber}</Span>}

                        <InputContainer margin='mt-4' border='grey' flex='row'>
                            <LockOutlined className='ml-2' />
                            <Input
                                disabled={isLoading}
                                value={values?.password}
                                onChange={handleChange('password')}
                                type={isVisible ? 'text' : 'password'}
                                placeholder='Password'
                                onBlur={handleBlur('password')}
                                border='none'
                            />
                            {!isVisible ? (
                                <MdOutlineVisibilityOff
                                    className='w-4 h-4 mr-2 cursor-pointer'
                                    onClick={() => setIsVisible(true)}
                                />
                            ) : (
                                <MdOutlineVisibility
                                    className='w-4 h-4 mr-2 cursor-pointer'
                                    onClick={() => setIsVisible(false)}
                                />
                            )}
                        </InputContainer>
                        {errors.password && touched.password && <Span color='red' size='xs'>{errors.password}</Span>}

                        <InputContainer margin='mt-4' border='grey' flex='row'>
                            <LockOutlined className='ml-2' />
                            <Input
                                disabled={isLoading}
                                value={values?.confirmPassword}
                                onChange={handleChange('confirmPassword')}
                                type={isVisible ? 'text' : 'password'}
                                placeholder='Confirm Password'
                                onBlur={handleBlur('confirmPassword')}
                                border='none'
                            />
                            {!isVisible ? (
                                <MdOutlineVisibilityOff
                                    className='w-4 h-4 mr-2 cursor-pointer'
                                    onClick={() => setIsVisible(true)}
                                />
                            ) : (
                                <MdOutlineVisibility
                                    className='w-4 h-4 mr-2 cursor-pointer'
                                    onClick={() => setIsVisible(false)}
                                />
                            )}
                        </InputContainer>
                        {errors.confirmPassword && touched.confirmPassword && <Span color='red' size='xs'>{errors.confirmPassword}</Span>}

                        {referralCode && (
                            <>
                                <InputContainer margin='mt-4' border='grey' flex='row'>
                                    {validationLoading && (
                                        <div className='absolute flex items-center justify-center w-full h-full z-50'>
                                            <Spin size='small' />
                                        </div>
                                    )}
                                    <Span padding='p-2'>Referral</Span>
                                    <Input
                                        style={{ textTransform: 'uppercase' }}
                                        disabled={validationLoading}
                                        value={values?.referredBy || isLoading}
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            setFieldValue('referredBy', value);
                                            validateReferralCode(value);
                                        }}
                                        onBlur={handleBlur('referredBy')}
                                        border='none'
                                    />
                                </InputContainer>
                                {codeInValid && <Span color='red' size='xs'>Invalid Code</Span>}
                            </>
                        )}

                        <div className='w-full flex justify-center items-center mt-2'>
                            <span
                                className='text-center font-[500] hover:underline hover:text-blue-3 cursor-pointer'
                                onClick={() => history.push('/forgot-password')}
                            >
                                Forgot Password
                            </span>
                        </div>

                        <Button
                            shadow='grey'
                            padding='p-10'
                            margin='mt-2'
                            width='full'
                            disabled={isLoading}
                            onClick={() => handleSubmit()}
                        >
                            {isLoading ? (
                                <Spin className='login_spin' size='small' />
                            ) : 'Sign up'}
                        </Button>

                        <div className='mt-4 w-full flex items-start justify-center flex-row space-x-1'>
                            <span className=' font-[500]'>Already have an account?</span>
                            <span
                                className='font-[500] hover:underline hover:text-blue-3 cursor-pointer'
                                onClick={() => history.push('/')}
                            >
                                Log in
                            </span>
                        </div>
                    </Paper>
                </div>
            )}
        </Formik>
    )
}