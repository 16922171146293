import React, { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { Grid, useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { downloadPayslipPDF, getPermission, useQuery } from '../../../common/utils';
import { Container } from '../../../components/common/Containers';
import { Span } from '../../../components/common/Span';
import { Button } from '../../../components/common/Button';
import { AiOutlineDownload } from 'react-icons/ai';
import { RiSendPlane2Fill } from 'react-icons/ri';
import PayslipNav from './PayslipNav/PayslipNav';
import PayslipView from './PayslipView/PayslipView';
import { generatepayslipPDF, savedPayslip, sendPayslipViaEmail } from '../../../common/api';
import { FileSearchOutlined } from '@ant-design/icons';
import { BiSave } from 'react-icons/bi';
import UpdateOrganization from '../../../components/ModuleComponents/UpdateOrganization';
import moment from 'moment';

const Payslip = ({ activeKey }) => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');
    const pathname = useLocation().pathname;
    const history = useHistory();
    const permissions = getPermission('payroll', 'canViewPayslipSub')
    const query = useQuery();
    const qKey = query.get('activekey');
    const qId = query.get('id');

    const { data: items, earnings, deductions, contract } = useSelector((state: RootState) => state.Payroll);

    const [select, setSelect] = useState(null) as any;
    const [users, setUsers] = useState([]) as any;
    const [search, setSearch] = useState('');

    const deferredSearch = useDeferredValue(search);
    const isSavedEnabled = items?.filter((i: any) => users.includes(i?.userInfo?.id)).some((i: any) => !i?.isSaved);

    const data = useMemo(() => {
        const result = items?.filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

        return result;
    }, [items, deferredSearch])

    const handlePrint = async () => {
        try {
            dispatch.UI.setIsLoading(true)
            const list = items?.filter((i: any) => users?.includes(i?.userInfo?.id))
            const name = list?.length > 1 ? moment().format('LLL') : list[0]?.userInfo?.name;

            const res = await generatepayslipPDF({ list, contract });

            if (res) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Success' });
                downloadPayslipPDF(res, `${name} Payslip.pdf`);
            }

        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to print payslip'
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    const hadnleClickSend = async () => {
        try {
            dispatch.UI.setIsLoading(true);
            const list = items?.filter((i: any) => users?.includes(i?.userInfo?.id))

            const res = await sendPayslipViaEmail({ list, contract });

            if (res?.isSuccess) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Success' });
                handleClickSave();
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to send payslip';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    const handleClickSave = async () => {
        try {
            dispatch.UI.setIsLoading(true);

            const list = items?.filter((i: any) => users.includes(i?.userInfo?.id) && !i?.isSaved);

            const res = await savedPayslip(list);

            if (res?.isSuccess) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Success' });
                dispatch.Payroll.calculateCurrentPayroll(null);
            }

        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to save payslip';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    useEffect(() => {
        if (qId) {
            const currentItem = items?.find((item: any) => item?.userInfo?.id === qId);
            setSelect(currentItem ?? items[0]);
        } else {
            setSelect(items[0]);
        }
    }, [qKey, qId, items]);

    useEffect(() => {
        setUsers([]);
    }, [contract])

    return (
        <div className='relative'>
            <Container padding='none'>
                <div className={`flex w-full ${mobile ? 'flex-col justify-center items-center px-4 pb-4' : 'justify-between items-center px-4'}`}>
                    <div>
                        <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                            <Span margin='mt-4' weight='bold' size='2xl'>Payslip</Span>
                        </div>
                    </div>

                    <div className={`flex ${mobile ? 'flex-col w-full' : 'flex-row space-x-2'} justify-start items-center`}>
                        {permissions?.includes('canPrintPayslip') && (
                            <Button
                                padding='px-2'
                                border='blue'
                                bg='none'
                                color='blue'
                                radius='none'
                                onClick={handlePrint}
                                disabled={!users?.length}
                                style={{ cursor: !users?.length ? 'not-allowed' : 'pointer' }}
                                {...(!mobile ? {
                                    width: 'none',
                                } : {
                                    margin: 'mt-2'
                                })}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='blue'>Print PDF</Span>
                                    <AiOutlineDownload />
                                </div>
                            </Button>
                        )}

                        {permissions?.includes('canSendPayslip') && (
                            <Button
                                padding='px-2'
                                border='blue'
                                bg='none'
                                color='blue'
                                radius='none'
                                onClick={hadnleClickSend}
                                disabled={!isSavedEnabled}
                                style={{ cursor: !users?.length ? 'not-allowed' : 'pointer' }}
                                {...(!mobile ? {
                                    width: 'none'
                                } : {
                                    margin: 'mt-2'
                                })}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='blue'>Send & Save</Span>
                                    <RiSendPlane2Fill />
                                </div>
                            </Button>
                        )}

                        {permissions?.includes('canSavePayslip') && (
                            <Button
                                padding='px-2'
                                border='blue'
                                bg='none'
                                color='blue'
                                radius='none'
                                onClick={handleClickSave}
                                disabled={!isSavedEnabled}
                                style={{ cursor: !users?.length ? 'not-allowed' : 'pointer' }}
                                {...(!mobile ? {
                                    width: 'none'
                                } : {
                                    margin: 'mt-2'
                                })}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='blue'>Save</Span>
                                    <BiSave />
                                </div>
                            </Button>
                        )}
                    </div>
                </div>

                {
                    items?.length < 1 || !select ? (
                        <div className='overflow-auto h-[70vh] flex justify-center items-center flex-col'>
                            <div className='absolute left-0 right-0 p-24 flex justify-center flex-col items-center'>
                                <FileSearchOutlined style={{ fontSize: '6rem', color: 'gray' }} />
                                <Span color='lightgrey' size='2xl'>No Data</Span>
                            </div>
                        </div>
                    ) : (
                        <Grid container sx={{ marginTop: '16px' }}>
                            <Grid container item xs={mobile ? 12 : 3}>
                                <PayslipNav
                                    setSearch={setSearch}
                                    data={data}
                                    users={users}
                                    setUsers={setUsers}
                                    select={select}
                                    setSelect={setSelect}
                                />
                            </Grid>

                            {!mobile && (
                                <Grid container item xs={9} alignItems='start' justifyContent='center' sx={{ height: '70vh', overflow: 'auto' }}>
                                    <PayslipView select={select} setSelect={setSelect} users={users} setUsers={setUsers} />
                                </Grid>
                            )}
                        </Grid>
                    )
                }

                {activeKey === '4' && <UpdateOrganization table='payroll' />}
            </Container >
        </div >
    )
}

export default Payslip