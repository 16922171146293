import { Avatar } from '@mui/material'
import { Card } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { AiOutlineInbox } from 'react-icons/ai'
import { currencyCommaFormat, firebaseDateToText, getArrayOfSelectedDays, numOfLeaveHours } from '../../../../common/utils'
import { Span } from '../../../common/Span'
import Options from '../../../ModuleComponents/Popover/Options'
import RenderEmployees from './RenderEmployees'
import RenderItems from './RenderItems'

const RenderEarnDed = ({ item, dataType }) => {

    const items = item?.items;
    const arrOfAmount = items?.map(i => Number(i?.amount));
    const totalAmount = arrOfAmount?.reduce((a, b) => a + b, 0);

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.type?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.type}</Span>
                    </div>
                </div>
                <Options data={item} table={item?.type} />
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-start p-2 my-2 border border-gray-300 rounded-sm'>
                    <span className=' font-semibold mr-1 w-28'>Employees: </span>
                    <RenderEmployees employees={item?.employeesData} />
                </div>

                <div className='flex justify-start items-start p-2 my-2 border border-gray-300 rounded-sm'>
                    <span className=' font-semibold mr-1 w-28'>Items: </span>
                    <RenderItems items={item?.items} />
                </div>

                <div className='flex justify-start items-center px-2'>
                    <span className=' font-semibold mr-1 w-28'>Total Amount: </span>
                    <Span>{currencyCommaFormat(totalAmount, 2, true)}</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderEarnDed