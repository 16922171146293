import { createModel } from "@rematch/core";
import { RootModel } from ".";

interface EWarning {
    countdownTime: any;
    isSent: boolean;
}

interface State {
    emailWarning: EWarning;
}

const initialState = {
    emailWarning: {
        countdownTime: null,
        isSent: false
    }
} as State;

export const LocalStorage = createModel<RootModel>()({
    state: initialState,
    reducers: {
        resetState() {
            return {
                ...initialState
            }
        },
        updateState(state, newState) {
            return {
                ...state,
                ...newState
            }
        }
    },
    effects: (dispatch) => ({

    })
})