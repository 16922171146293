import React from 'react'
import { currencyCommaFormat, removeSCAndCapitalize } from '../../../../../common/utils'

const EarninItem = ({ label, value }) => {
    return (
        <>
            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{removeSCAndCapitalize(label, "_")}</span>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>

            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(value, 2, true)}</span>
            </div>
        </>
    )
}

export default EarninItem