import { Alert, Button, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../store'
import { Spin } from 'antd'
import { sendOtpEmailVerification } from '../common/api'
import { useHistory } from 'react-router-dom'

const WarnVerifyEmail = ({ setWarn }) => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')

    const { claims, userInfo } = useSelector((state: RootState) => state.User);

    const [loading, setLoading] = useState(false);

    const handleVerify = async () => {
        try {
            setLoading(true);

            const res = await sendOtpEmailVerification({ email: userInfo?.email });

            if (res?.isSuccess) {
                setLoading(false);
                history.push(`/otp-email?ref=${res.message?.reference}`);
            }
        } catch (err: any) {
            console.log(err);
            setLoading(false);
            const errMsg = err?.response?.data.error ?? 'Unable to send email!';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    useEffect(() => {
        if (claims?.email_verified || claims?.emailVerified) {
            setWarn(false);
            dispatch.UI.setAlert({ type: 'Success', message: 'Email successfully verified' });
        }
    }, [claims]);

    return (
        <Alert
            elevation={5}
            style={{
                marginBottom: 10
            }}
            severity="warning"
            action={
                loading ? (
                    <div className='w-full h-full flex items-center justify-center pr-2'>
                        <Spin size='small' />
                    </div>
                ) : (
                    <Button color="inherit" size="small" sx={{ fontWeight: "bold" }} onClick={handleVerify}>
                        Verify
                    </Button >
                )
            }
        >
            Verify your email address
        </Alert >
    )
}

export default WarnVerifyEmail