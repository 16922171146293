import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { updateHoliday, updateOrganization } from '../../common/api';
import { Drawer } from 'antd';
import OrgForm from './Forms/OrgForm';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import HolidayForm from './Forms/HolidayForm';
import moment from 'moment';

type Props = {
    table: string,
}

type State = {
    name: string,
    holidayType: string,
    date: any
}

const UpdateHoliday = ({ table }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const event = useSelector((state: RootState) => state.Table.tables[table]?.data)?.find(d => d.id === currentUpdateId);

    const initialState = {
        name: event?.name ?? '',
        holidayType: event?.holidayType ?? '',
        date: event?.date_string ?? ''
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Name is required field'),
        holidayType: yup.string().required('Holiday type is required field'),
        date: yup.string().required('Date is required field')
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
                date: new Date(values?.date),
                date_string: moment(values.date).format('YYYY-MM-DD')
            }

            let res = await updateHoliday(currentUpdateId, payload);

            if (res.isSuccess) {
                actions.setSubmitting(false)
                dispatch.UI.resetForm('currentUpdateId');
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update event'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Holiday"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            props.resetForm();
                            history.goBack();
                            dispatch.UI.resetForm('currentUpdateId')
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText='Update Holiday'
                            />
                        }
                    >
                        {currentUpdateId && <HolidayForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateHoliday;