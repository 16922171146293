import { post, put, remove } from "../core";

export const addRole = async (body: any) => {
    try {
        const res = await post('/admin/add/role', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateRole = async (id: any, body: any) => {
    try {
        const res = await put(`/admin/update/role/${id}`, body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteRole = async (id: any) => {
    try {
        const res = await remove(`/admin/delete/role/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}