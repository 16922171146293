import React, { useState } from 'react'
import { Avatar, Checkbox, Divider, FormControlLabel, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { Span } from '../../../../components/common/Span';
import { useHistory, useLocation } from 'react-router-dom';

const ItemList = ({ data, select, setSelect, users, setUsers }) => {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const user = data?.userInfo;

    const handleClick = (e) => {
        e.preventDefault();

        if (users?.includes(user?.id)) {
            const payload = users?.filter((u: any) => u !== user?.id);
            setUsers(payload);
        } else {
            setUsers([...users, user?.id]);
        }
    }

    const handleSelect = () => {
        setSelect(data);
        history.push(`${pathname}?activeKey=4&id=${user?.id}`)
    }

    return (
        <>
            <ListItem
                key={user?.id}
                secondaryAction={
                    <Checkbox
                        checked={!!users?.includes(user?.id)}
                        onClick={handleClick}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
                }
                disablePadding
            >
                <ListItemButton selected={select?.userInfo?.id === user?.id} onClick={handleSelect} alignItems='flex-start'>
                    <ListItemAvatar sx={{ minWidth: 0, paddingRight: '8px' }}>
                        <Avatar
                            sx={{ width: 24, height: 24, fontSize: '12px', bgcolor: '#FAAD14' }}
                            alt={data?.name}
                        >
                            {user?.name[0]?.toUpperCase()}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText id={user?.id}>
                        <Stack direction='column' justifyContent='center' alignItems='start'>
                            <Typography
                                noWrap
                                variant='body2'
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', fontWeight: '600' }}
                            >
                                {user?.name}
                            </Typography>
                            <Typography
                                noWrap
                                variant='caption'
                                color='GrayText'
                                fontWeight='700'
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize', width: '100%' }}
                            >
                                {user?.position}
                            </Typography>
                            <Typography
                                noWrap
                                variant='caption'
                                color='GrayText'
                                fontWeight='700'
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize', width: '100%', }}
                            >
                                {user?.role?.name ?? 'None'} - <span className={`font-semibold ${data?.isSaved ? 'text-red-500' : 'text-gray-400'}`}>{data?.isSaved ? 'Saved' : 'Not saved'}</span>
                            </Typography>
                        </Stack>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <Divider variant='fullWidth' component="li" />
        </>
    )
}

export default ItemList