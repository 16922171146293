import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getOrganizationLogo, removeSCAndCapitalize } from '../../../../common/utils';
import { GridContainer } from '../../../../components/common/Containers';
import { Span } from '../../../../components/common/Span';
import { Alert } from '@mui/material';
import EarninItem from './Earnings/EarninItem';
import DeductionItem from './Deductions/DeductionItem';

const View = ({ select, status }) => {

    const { userInfo } = useSelector((state: RootState) => state.User);

    const organization = userInfo?.organization;
    const savedPayslip = select?.payslips?.filter((p) => p?.isSaved);
    const notSavedPayslip = select?.payslips?.filter((p) => !p?.isSaved);
    const image_source = getOrganizationLogo(organization?.logo);

    return (
        <>
            <GridContainer grid='two' border='bot' margin='none'>
                <div className='flex flex-col w-full items-start justify-start '>
                    <div className='flex flex-col justify-start items-start'>
                        {organization?.name && (
                            <Span transform='capitalize' weight='bold' size='2xl' color='brown'>{organization?.name}</Span>
                        )}

                        {organization?.address && (
                            <Span transform='capitalize' weight='semi'>{organization?.address}</Span>
                        )}

                        {organization?.address && (
                            <Span transform='capitalize' weight='semi'>{organization?.phoneNumber}</Span>
                        )}
                    </div>
                </div>

                <div className='flex items-center justify-end'>
                    <div className='w-32 mr-2'>
                        {organization?.logo && (
                            <img src={image_source} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
                        )}
                    </div>
                </div>
            </GridContainer>

            {status === 'incomplete' && (
                <div className='px-4'>
                    <Alert severity="warning" sx={{ mt: 2 }}>
                        Warning: Please ensure that you save all your payslips before proceeding to generate any reports.
                    </Alert>
                </div>
            )}

            <GridContainer gap='two'>
                <div className='w-full'>
                    <div className=' bg-brown-1 w-full pl-2'>
                        <Span weight='bold'>BATCH INFORMATION</Span>
                    </div>

                    <div className='pl-2'>
                        <Span transform='capitalize' weight='semi'>{select?.contract}</Span>
                        <Span transform='capitalize' weight='semi' size='xs'>{select?.referenceNumber}</Span>
                        <Span transform='uppercase' weight='semi' size='xs'>{select?.payPeriod}</Span>
                    </div>
                </div>

                <div className='col-span-2 grid grid-cols-3 gap-2'>
                    {status === 'incomplete' && (
                        <>
                            <div className='flex flex-col justify-start items-center'>
                                <span className='w-full bg-brown-1 text-center font-bold'>SAVED PAYSLIP</span>
                                <span className='w-full bg-gray-100 text-center font-semibold uppercase'>
                                    {savedPayslip?.length}
                                </span>
                            </div>

                            <div className='flex flex-col justify-start items-center'>
                                <span className='w-full bg-red-500 text-yellow-50 text-center font-bold'>UNSAVED PAYSLIP</span>
                                <span className='w-full bg-gray-100 text-center font-semibold capitalize'>
                                    {notSavedPayslip?.length}
                                </span>
                            </div>

                            <div className='flex flex-col justify-start items-center'>
                                <span className='w-full bg-red-500 text-yellow-50 text-center font-bold'>REQUIRED SAVED</span>
                                <span className='w-full bg-gray-100 text-center font-semibold uppercase'>
                                    {select?.payslips?.length}
                                </span>
                            </div>
                        </>
                    )}

                    {status !== 'incomplete' && (
                        <div className='flex flex-col justify-start items-center'>
                            <span className='w-full bg-brown-1 text-center font-bold'>PAYSLIP COUNT</span>
                            <span className='w-full bg-gray-100 text-center font-semibold capitalize'>
                                {select?.payslips?.length}
                            </span>
                        </div>
                    )}

                    <div className='flex flex-col justify-start items-center'>
                        <span className='w-full bg-brown-1 text-center font-bold'>PAY TYPE</span>
                        <span className='w-full bg-gray-100 text-center font-semibold capitalize'>
                            {removeSCAndCapitalize(select?.salary_cycle, '-')}
                        </span>
                    </div>
                </div>
            </GridContainer>

            {status !== 'incomplete' && (
                <>

                    <GridContainer gap='none'>
                        <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                            <span className='w-full font-bold'>EARNINGS</span>
                        </div>

                        <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                            <span className='w-full font-bold'>DESCRIPTION</span>
                        </div>

                        <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                            <span className='w-full font-bold'>AMOUNT</span>
                        </div>

                        {select?.earnings?.filter(a => a?.amount > 0)?.map((item, index) => (
                            <EarninItem key={index} item={item} />
                        ))}
                    </GridContainer>

                    <GridContainer gap='none'>
                        <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                            <span className='w-full font-bold'>DEDUCTIONS</span>
                        </div>

                        <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                            <span className='w-full font-bold'>DESCRIPTION</span>
                        </div>

                        <div className='flex w-full px-2 bg-gray-200 border-b border-gray-300'>
                            <span className='w-full font-bold'>AMOUNT</span>
                        </div>

                        {select?.deductions?.filter(a => a?.amount < 0)?.map((item, index) => (
                            <DeductionItem key={index} item={item} />
                        ))}
                    </GridContainer>
                </>
            )}

            <div className='p-4 flex justify-center items-center border-t border-t-gray-300'>
                <Span size='xs' weight='semi'>Made by Holo Pro with ❤</Span>
            </div>
        </>
    )
}

export default View