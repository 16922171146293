import { Input } from '../common/Input'
import { Select } from '../common/Select';

export function ColumnSelectFilter({
    column: {
      filterValue, 
      setFilter,
    },
   }) {
    return (
      <Select width='150' padding='1.5' onChange={(e: any) => setFilter(e.target.value === 'active' ? 'a' : 'i')}>
        <option value='' selected></option>
        <option value='inactive'>Inactive</option>
        <option value='active'>Active</option>
      </Select>
    );
   }
    