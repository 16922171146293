import { Avatar, Tag } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "name",
    field: "name",
    Header: "Role Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} >{d.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d.name}</Span>
        </div>
      )
    }
  },
  {
    id: "desc",
    field: "desc",
    Header: "Role Description",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.desc ? (
            <span className="capitalize">{d?.desc}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "status",
    field: "status",
    Header: "Status",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.status === 'active' ? (
            <Tag className="capitalize" color='success'>{d?.status}</Tag>
          ) : (
            <Tag className="capitalize" color='error'>{d?.status}</Tag>
          )}
        </>
      )
    }
  },
  {
    id: "permissions",
    accessor: (d) => JSON.stringify(d.permissions),
    field: "permissions",
    Header: "Permissions",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      const permArray = Object.keys(d?.permissions);
      return <Span transform='capitalize'>{permArray?.map((name, i) => `${name}${permArray?.length - 1 === i ? '' : ', '}`)}</Span>
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='role' />
      )
    }
  }

];
