import React from 'react'

const ProgressLevel = ({ client }) => {

    const progress_level = client?.progressLevel ?? 0;

    return (
        <div className='absolute w-full bottom-5 flex flex-row items-center justify-center space-x-2'>
            {[...Array(3)]?.map((u, index) => (
                <div key={index} className={` w-8 h-2 rounded-md ${progress_level >= index ? 'bg-palette-mainColor' : 'bg-palette-grey'}`} />
            ))}
        </div>
    )
}

export default ProgressLevel