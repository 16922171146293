import { currencyCommaFormat, firebaseDateToText, removeSCAndCapitalize } from "../../../../common/utils";
import { Span } from "../../../../components/common/Span";

export const COLUMNS = [
  {
    id: "salary_cycle",
    field: "salary_cycle",
    accessor: (data) => <Span>{removeSCAndCapitalize(data?.salary_cycle, '-')}</Span>,
    Header: "Salary Cycle",
    isVisible: true,
  },
  {
    id: "lower_bracket",
    field: "lower_bracket",
    Header: "Lower Bracket",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const showVal = d?.lower_bracket >= 1000000 ? 'Over' : currencyCommaFormat(d?.lower_bracket, 2, true);
      return <Span>{showVal}</Span>;
    }
  },
  {
    id: "upper_bracket",
    field: "upper_bracket",
    Header: "Upper Bracket",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const showVal = d?.upper_bracket >= 1000000 ? 'Over' : currencyCommaFormat(d?.upper_bracket, 2, true);
      return <Span>{showVal}</Span>;
    }
  },
  {
    id: "tax",
    field: "tax",
    accessor: (data) => <Span>{data?.tax}%</Span>,
    Header: "Tax %",
    isVisible: true,
  },
  {
    id: "prescribed",
    field: "prescribed",
    accessor: (data) => currencyCommaFormat(data?.prescribed, 2, true),
    Header: "Prescribed",
    isVisible: true,
  }
];
