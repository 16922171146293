import { CalendarOutlined, ClockCircleOutlined, CoffeeOutlined } from '@ant-design/icons'
import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { AiOutlineInbox } from 'react-icons/ai'
import { ImLocation2 } from 'react-icons/im'
import { firebaseDateToText } from '../../../common/utils'
import { Span } from '../../common/Span'

const RenderTimesheet = ({ item }) => {

    const d = item;
    const dateText = firebaseDateToText(d.clockIn?.time, 'MMM DD YYYY HH:mm')
    const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/timesheet%2F${d.clockIn?.image ?? ''}?alt=media&token=434f8e39-f79e-444b-881f-035cc434334b`
    const dateTextOut = firebaseDateToText(d.clockOut?.time, 'MMM DD YYYY HH:mm');
    const sourceOut = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/timesheet%2F${d?.clockOut?.image ?? ''}?alt=media&token=434f8e39-f79e-444b-881f-035cc434334b`
    const hasData = d?.clockOut?.image || d.clockOut?.location?.latitude || d.clockOut?.location?.longitude || dateTextOut;
    const workHours = d.workHours
    const workBreakTotal = d.workBreakTotal
    const hasWorkHours = workHours || workBreakTotal;

    const role = item?.userInfo?.role?.name;

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-start">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.userInfo?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.userInfo?.name}</Span>
                        <Span transform='capitalize'>{item?.userInfo?.position} - {item?.userInfo?.organization.name}</Span>
                        {role && (
                            <Span transform='capitalize' color='lightgrey'>({role})</Span>
                        )}
                    </div>
                </div>
            </div>

            <div className='py-2'>
                <div className='flex justify-start items-start flex-row'>
                    <span className=' font-semibold w-24'>Time In : </span>

                    <div className="flex flex-row">
                        <a target="_blank" href={source} className="mr-2"><Avatar src={source} style={{ color: '#f56a00', backgroundColor: '#fde3cf', height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>IN</Avatar></a>
                        <div className="w-[150px]">
                            <Span><CalendarOutlined /> {dateText} </Span>
                            <div className="flex flex-row justify-start items-center">
                                <ImLocation2 />
                                <a target={"_blank"} className='truncate' href={`https://www.google.com/maps/place/${d.clockIn?.location?.latitude ?? ''},${d.clockIn?.location?.longitude ?? ''}`}>{`${d.clockIn?.location?.latitude ?? ''},${d.clockIn?.location?.longitude ?? ''}`}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex justify-start items-start flex-row'>
                    <span className=' font-semibold w-24'>Time Out: </span>

                    <>
                        {hasData ? (
                            <div className="flex flex-row">
                                <a target="_blank" href={sourceOut} className="mr-2"><Avatar src={sourceOut} style={{ color: '#f56a00', backgroundColor: '#fde3cf', height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>OUT</Avatar></a>
                                <div className="w-[150px]">
                                    <Span><CalendarOutlined /> {dateTextOut} </Span>
                                    <div className="flex flex-row justify-start items-center">
                                        <ImLocation2 />
                                        <a target={"_blank"} className='truncate' href={`https://www.google.com/maps/place/${d.clockOut?.location?.latitude ?? ''},${d.clockOut?.location?.longitude ?? ''}`}>{`${d.clockOut?.location?.latitude ?? ''},${d.clockOut?.location?.longitude ?? ''}`}</a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="emptyContainer flex flex-row justify-start items-center px-2">
                                <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                                <Span color='lightgrey'>No Data</Span>
                            </div>
                        )}
                    </>
                </div>

                <div className='flex justify-start items-start flex-row'>
                    <span className=' font-semibold w-24'>Work Hours: </span>

                    <>
                        {hasWorkHours ? (
                            <div>
                                <Span><ClockCircleOutlined /> {workHours} </Span>
                                <Span><CoffeeOutlined /> {workBreakTotal} </Span>
                            </div>
                        ) : (
                            <div className="emptyContainer flex flex-row justify-start items-center px-2">
                                <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                                <Span color='lightgrey'>No Data</Span>
                            </div>
                        )}
                    </>
                </div>

                <div className='flex justify-start items-start flex-row'>
                    <span className=' font-semibold w-24'>Email: </span>
                    <Span>{item?.userInfo?.email ?? ''}</Span>
                </div>

                <div className='flex justify-start items-start flex-row'>
                    <span className=' font-semibold w-24'>Created At: </span>
                    <Span transform='capitalize'>{firebaseDateToText(item?.createdAt, 'MMM DD YYYY HH:mm')}</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderTimesheet