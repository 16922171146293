import { Drawer, Tabs } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch, RootState } from '../../../store'
import AddBulkEmployee from './AddBulkUsers'
import { Formik } from 'formik';
import * as yup from 'yup';
import { createUser } from '../../../common/api'
import UserForm from '../Forms/UserForm'
import DrawerFooter from '../Footer/DrawerFooter'
import { useMediaQuery } from '@mui/material'
import { useQuery } from '../../../common/utils'

type Props = {
    setAdd: Function,
    add: boolean
}

const AddUsersSingleAndBulk = ({ setAdd, add }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');

    const { userInfo } = useSelector((state: RootState) => state.User);
    const organization = useSelector((state: RootState) => state.Table.tables.organization.data)
        .find((o) => o?.id === userInfo?.organization?.id);

    const [errMesssage, setErrorMesage] = useState(null) as any;
    const [isSingleTab, setSingleTab] = useState(true);
    const { TabPane } = Tabs;

    const initialState = {
        email: '',
        name: '',
        bday: '',
        password: '',
        phoneNumber: '',
        sickLeave: '',
        vacationLeave: '',
        employeeNumber: '',
        position: '',
        department: '',
        group: '',
        organization: organization
    }

    const validateSchema = yup.object({
        email: yup.string().email('Should be valid email example(youremail@domain.com)').required('Email is required field'),
        name: yup.string().required('Name is required field'),
        bday: yup.string().required('Birth day is required field'),
        password: yup.string().required('Password is required field').min(8, 'Password must be at least 8 characters'),
        phoneNumber: yup.string().required('Phone number is required field')
            .min(10, 'Phone number must be at least 10 characters')
            .max(10, 'Phone number must be at most 10 characters')
            .test(
                'isNaN',
                'Number only',
                (val: any) => !isNaN(val) ? true : false
            ),
        sickLeave: yup.number().required('Sick leave is required field, enter 0 if not entitled'),
        vacationLeave: yup.number().required('Vacation leave is required field, enter 0 if not entitled'),
        employeeNumber: yup.string().required('Employee number is required field'),
        position: yup.string().required('Position is required field'),
        department: yup.string().required('Department is required field'),
        group: yup.string(),
        organization: yup.object().nullable().required('Organization is required field')
    })

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
                phoneNumber: `+63${values?.phoneNumber}`,
                ratePerHour: Number(values?.ratePerHour),
                sickLeave: Number(values?.sickLeave),
                vacationLeave: Number(values?.vacationLeave)
            }
            const res = await createUser(payload);
            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false)
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Account Successfully created' })
                dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to create employee'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Add Employee"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            history.goBack();
                            setAdd(false)
                        }}
                        visible={add}
                        footer={
                            isSingleTab ? (
                                <DrawerFooter
                                    {...props}
                                    funcToRun={() => setAdd(false)}
                                    setErrorMesage={setErrorMesage}
                                    okText='Add Employee'
                                />
                            ) : (
                                <></>
                            )
                        }
                    >
                        {add && (
                            <Tabs defaultActiveKey='single' onChange={(v) => v === 'single' ? setSingleTab(true) : setSingleTab(false)}>
                                <TabPane tab='Single' key='single'>
                                    <UserForm {...props} errMesssage={errMesssage} />
                                </TabPane>
                                <TabPane tab='Bulk' key='bulk'>
                                    <AddBulkEmployee setAdd={setAdd} />
                                </TabPane>
                            </Tabs>
                        )}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddUsersSingleAndBulk