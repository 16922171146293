import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat, firebaseDateToText } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'
import { AiOutlineInbox } from 'react-icons/ai'
import moment from 'moment'

const RenderWithoutClockout = ({ item, dataType }) => {

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.userInfo?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.userInfo?.name}</Span>
                        <Span transform='capitalize'>{item?.userInfo?.position} - {item?.userInfo?.organization.name}</Span>
                        {item?.role && (
                            <Span transform='capitalize' color='lightgrey'>({item?.userInfo?.role?.name})</Span>
                        )}
                    </div>
                </div>

                <Options data={item} table={dataType} />
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Timesheet Date: </span>
                    <span>{moment(item?.timesheetDate).format('LL')}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Clock In: </span>
                    {!item?.clockIn ? (
                        <div className="emptyContainer flex flex-row justify-start items-center">
                            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                            <Span color='lightgrey'>No Data</Span>
                        </div>
                    ) : (
                        <span>{firebaseDateToText(item?.clockIn?.time, 'LLL')}</span>
                    )}
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Clock Out: </span>
                    {!item?.clockOut ? (
                        <div className="emptyContainer flex flex-row justify-start items-center">
                            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                            <Span color='lightgrey'>No Data</Span>
                        </div>
                    ) : (
                        <span>{firebaseDateToText(item?.clockOut?.time, 'LLLL')}</span>
                    )}
                </div>

            </div>
        </Card>
    )
}

export default RenderWithoutClockout