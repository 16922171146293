import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, where, } from "firebase/firestore";

var DOCUMENT_UNSUBSCRIBE: any;

interface IFirebaseDocPayload {
  document: string;
  name: string;
  id: string;
}

interface ITableUnsubscribePayload {
  table: string;
  data: any;
}

interface IDocDataPayload {
  document: string;
  data: any;
}

interface IFilterMultiplePayload {
  document: string,
  filters: any
}

export interface IFilterValue {
  field: string;
  operation: string;
  value: any;
  type: string;
}

interface IFilterPayload {
  document: string;
  key: string;
  filter: any;
}

let defaultDocValue = {
  filters: {},
  data: null,
}

const initialState = {
  documents: {
    payroll_config: defaultDocValue,
    sss_config: defaultDocValue,
    phic_config: defaultDocValue,
    hdmf_config: defaultDocValue,
    tax_config: defaultDocValue,
    current_log: defaultDocValue,
    client: defaultDocValue,
    organization: defaultDocValue
  }
} as any;

export const Document = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    resetDocument(state, document: string) {
      return {
        ...state,
        documents: {
          ...state.documents,
          [document]: defaultDocValue
        }
      };
    },
    setFilterValue(state, payload: IFilterPayload) {
      const { document, key, filter } = payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          [document]: {
            ...state.documents[document],
            filters: { [key]: filter },
          },
        },
      };
    },
    setMultipleFilterValue(state, payload: IFilterMultiplePayload) {
      const { document, filters } = payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          [document]: {
            ...state.documents[document],
            filters: filters
          }
        }
      }
    },
    setDocumentData(state, payload: IDocDataPayload) {
      const { document, data } = payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          [document]: {
            ...state.documents[document],
            data,
          },
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getFirebaseDoc(payload: IFirebaseDocPayload, rootState) {

      const db = getFirestore();

      let { name, document, id } = payload

      let { filters } = rootState.Document.documents[document];

      let docRef = doc(db, name, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch.Document.setDocumentData({ document, data: data })
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    },

    async subscribeFirebaseDoc(payload: IFirebaseDocPayload, rootState) {
      const db = getFirestore();

      let { name, document, id } = payload;

      if (DOCUMENT_UNSUBSCRIBE) DOCUMENT_UNSUBSCRIBE();

      DOCUMENT_UNSUBSCRIBE = onSnapshot(doc(db, name, id), (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          dispatch.Document.setDocumentData({ document, data: data })
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })
    },

    async unsubscribed() {
      DOCUMENT_UNSUBSCRIBE();
    },

  }),
});
