import React, { useEffect, useMemo, useState } from 'react'
import { Span } from '../../../components/common/Span'
import Table from '../../../components/Table/Table'
import { COLUMNS } from './Column';
import moment from 'moment';
import { Dispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import { firebaseDateToText } from '../../../common/utils';
import { useMediaQuery } from '@mui/material';
import Card from '../../../components/Card/Card';

const ActiveEmployees = () => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')
    const tablet = useMediaQuery('(max-width:1280px)')

    const activeEmployees = useSelector((state: RootState) => state.Table.tables.todayActiveEmployees.data);
    const data = useSelector((state: RootState) => state.Table.tables.todayActiveEmployeesWithTasks.data);

    const columns = useMemo(() => COLUMNS, []);

    const getData = async () => {
        const db = getFirestore();
        let result: any = [];

        for (const timesheet of activeEmployees) {
            const q = query(
                collection(db, 'tasks'),
                where('userInfo.id', '==', timesheet.userId),
                where('status', '==', 'on_going'),
                orderBy('createdAt', 'desc'),
                limit(1)
            )

            const snapshot = await getDocs(q);

            if (snapshot.docs.length < 1) continue;

            const data = snapshot.docs[0].data();

            const obj = {
                name: timesheet?.userInfo?.name,
                time: firebaseDateToText(timesheet?.clockIn?.time, 'h:mm A'),
                task: data?.taskName
            }

            result.push(obj);
        }

        dispatch.Table.setTableData({ table: 'todayActiveEmployeesWithTasks', data: result });
    }

    useEffect(() => {
        const filter = {
            date: {
                field: 'timesheetDate',
                operation: '==',
                value: moment().format('YYYY-MM-DD')
            },
            clockout: {
                field: 'clockOut',
                operation: '==',
                value: null
            }
        }

        dispatch.Table.setMultipleFilterValue({ table: 'todayActiveEmployees', filters: filter });
        dispatch.Table.getFirebaseData({ name: 'timesheet', table: 'todayActiveEmployees' });
    }, []);

    useEffect(() => {
        getData();
    }, [activeEmployees])

    return (
        <div className='flex flex-col justify-start items-start space-y-4'>
            <Span weight='semi' size='3xl'>Active Employees</Span>

            <div className='w-full'>
                {mobile ? (
                    <Card data={data} dataType='dashboard' sortName='createdAt' />
                ) : (
                    <Table columns={columns} data={data} dataType='dashboard' sortName='createdAt' height={tablet ? '40vh' : '55vh'} />
                )}
            </div>
        </div>
    )
}

export default ActiveEmployees