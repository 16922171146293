import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Span } from '../../common/Span'
import { Input } from '../../common/Input'
import { GridContainer, InputContainer } from '../../common/Containers'
import { Select } from '../../common/Select'
import { BsRecycle, BsCalendarWeek } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlinePercentage } from 'react-icons/ai';
import { employeeRateTemplate, onlyNumberKey, useQuery } from '../../../common/utils';
import { FcInfo } from 'react-icons/fc';
import { Tooltip } from 'antd'
import { utils, writeFileXLSX } from 'xlsx'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Button } from '../../common/Button'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import moment from 'moment';
import ExcelJS from 'exceljs';

const ContractForm = ({ setFieldValue, values, handleChange, handleBlur, errors, touched, contract, setErrors }) => {
    const query = useQuery();
    const type = query.get('type');

    const { userInfo } = useSelector((state: RootState) => state.User);
    const employees = useSelector((state: RootState) => state.Table.tables.employees?.data)
        ?.filter(e => e?.organization?.id === userInfo?.organization?.id);

    const daysOfWeek = moment.weekdays();

    const inputRef = useRef(null) as any;
    const elem = useRef(null) as any;

    const exportFile = async () => {
        const filteredEmployees = employees?.filter(e => e?.isDefault);
        const contractEmployeesData = employees?.filter(e => e?.contractId === contract?.id);
        const json_file = employeeRateTemplate(type === 'update' ? contractEmployeesData : filteredEmployees);

        if (!json_file?.length) {
            setErrors({ download: 'No availabe employees' });
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Template');
        let worksheet1;
        let AE_json_file;

        if (type === 'update') {
            AE_json_file = employeeRateTemplate(filteredEmployees) //available employees
            worksheet1 = workbook.addWorksheet('Available Employees');

            if (AE_json_file?.length) {

                worksheet1.columns = Object.keys(AE_json_file[0])?.map((key) => {
                    const res = {
                        header: key,
                        key: key
                    }

                    return res;
                })

                worksheet1.addRows(AE_json_file);
                worksheet1.getRow(1).font = { bold: true };

                worksheet1.columns.forEach((col: any) => {
                    col.width = 25;
                });

                for (let i = 0; i < AE_json_file?.length; i++) {
                    const item = AE_json_file[i];
                    const cell = worksheet1.getCell(`F${i + 2}`);

                    cell.dataValidation = {
                        type: 'list',
                        formulae: ['"Please select rate type, Hourly Rate, Daily Rate, Monthly Rate"'],
                        showErrorMessage: true,
                        errorTitle: 'Invalid Value',
                        error: 'Please select a value from the list.',
                        promptTitle: 'Select Value',
                        prompt: 'Choose a value from the list.',
                    };

                    switch (item?.Rate_Type) {
                        case 'hourlyRate':
                            cell.value = 'Hourly Rate';
                            break;
                        case 'dailyRate':
                            cell.value = 'Daily Rate';
                            break;
                        case 'monthlyRate':
                            cell.value = 'Monthly Rate';
                            break;
                        default:
                            cell.value = 'Please select rate type'
                            break;
                    }
                }
            };
        }

        //set headers
        worksheet.columns = Object.keys(json_file[0])?.map((key) => {
            const res = {
                header: key,
                key: key
            }

            return res;
        })

        //add rows and make headers bold
        worksheet.addRows(json_file);
        worksheet.getRow(1).font = { bold: true }

        worksheet.columns.forEach((col: any) => {
            col.width = 25;
        })

        for (let i = 0; i < json_file?.length; i++) {
            const item = json_file[i];
            const cell = worksheet.getCell(`F${i + 2}`);

            cell.dataValidation = {
                type: 'list',
                formulae: ['"Please select rate type, Hourly Rate, Daily Rate, Monthly Rate"'],
                showErrorMessage: true,
                errorTitle: 'Invalid Value',
                error: 'Please select a value from the list.',
                promptTitle: 'Select Value',
                prompt: 'Choose a value from the list.',
            };

            switch (item?.Rate_Type) {
                case 'hourlyRate':
                    cell.value = 'Hourly Rate';
                    break;
                case 'dailyRate':
                    cell.value = 'Daily Rate';
                    break;
                case 'monthlyRate':
                    cell.value = 'Monthly Rate';
                    break;
                default:
                    cell.value = 'Please select rate type'
                    break;
            }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const dataUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'Employee_Rate_Per_Hour_Template.xlsx';
        link.click();
        window.URL.revokeObjectURL(dataUrl);
    };

    useEffect(() => {
        if (!values?.file && inputRef?.current) {
            inputRef.current.value = null;
        }
    }, [values?.file]);

    useEffect(() => {
        elem?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [type])

    return (
        <div ref={elem}>
            <Span weight='semi'>Contract Info.</Span>
            <GridContainer grid='two' padding='none' gap='two' margin='none'>
                <div className='px-2 text-left col-span-2'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Contract Name</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='pr-2' flex='row' margin='none'>
                        <Input
                            disabled={contract?.isDefault}
                            border='none'
                            style={{ textTransform: 'capitalize', ...(contract?.isDefault && { cursor: 'not-allowed' }) }}
                            value={values.name}
                            onChange={handleChange('name')}
                            placeholder='Enter Name'
                            onBlur={handleBlur('name')}
                        />
                    </InputContainer>
                    {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}
                </div>
            </GridContainer>

            <Span weight='semi' margin='mt-4'>Working Days</Span>
            <GridContainer grid='two' padding='none' gap='two' margin='none'>
                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Week From</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='pr-2' flex='row' margin='none'>
                        <BsCalendarWeek className=' absolute left-2' />
                        <Select
                            style={{ paddingLeft: '25px' }}
                            border='none'
                            value={values?.week_from === '' ? 'none' : values?.week_from}
                            onChange={handleChange('week_from')}
                            onBlur={handleBlur('week_from')}
                        >
                            <option value='none' selected disabled>Choose week day...</option>
                            {daysOfWeek?.map((d) => (
                                <option key={d} value={d}>{d}</option>
                            ))}
                        </Select>
                    </InputContainer>
                    {errors.week_from && touched.week_from && <Span color='red' size='xs'>{errors.week_from}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Week To</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='pr-2' flex='row' margin='none'>
                        <BsCalendarWeek className=' absolute left-2' />
                        <Select
                            style={{ paddingLeft: '25px' }}
                            border='none'
                            value={values?.week_to === '' ? 'none' : values?.week_to}
                            onChange={handleChange('week_to')}
                            onBlur={handleBlur('week_to')}
                        >
                            <option value='none' selected disabled>Choose week day...</option>
                            {daysOfWeek?.filter(d => d !== values?.week_from)?.map((d) => (
                                <option key={d} value={d}>{d}</option>
                            ))}
                        </Select>
                    </InputContainer>
                    {errors.week_to && touched.week_to && <Span color='red' size='xs'>{errors.week_to}</Span>}
                </div>
            </GridContainer>

            <Span weight='semi' margin='mt-4'>Additional Rates</Span>
            <GridContainer grid='two' padding='none' gap='two' margin='none'>
                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Salary Cycle</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='pr-2' flex='row' margin='none'>
                        <BsRecycle className=' absolute left-2' />
                        <Select
                            style={{ paddingLeft: '25px' }}
                            border='none'
                            value={values?.salary_cycle === '' ? 'none' : values?.salary_cycle}
                            onChange={handleChange('salary_cycle')}
                            onBlur={handleBlur('salary_cycle')}
                        >
                            <option value='none' selected disabled>Choose cycle...</option>
                            <option value='daily'>Daily</option>
                            <option value='weekly'>Weekly</option>
                            <option value='semi-monthly'>Semi Monthly</option>
                            <option value='monthly'>Monthly</option>
                        </Select>
                    </InputContainer>
                    {errors.salary_cycle && touched.salary_cycle && <Span color='red' size='xs'>{errors.salary_cycle}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Night Shift Rate</Span>
                        <Span color='red'>*</Span>
                        <Tooltip title='This will be added in addition to 100%.'>
                            <FcInfo className='ml-2' />
                        </Tooltip>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <AiOutlinePercentage className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.night_shift}
                            onChange={handleChange('night_shift')}
                            placeholder='Enter Rate'
                            onBlur={handleBlur('night_shift')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.night_shift && touched.night_shift && <Span color='red' size='xs'>{errors.night_shift}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Overtime Rate</Span>
                        <Span color='red'>*</Span>
                        <Tooltip title='This will be added in addition to 100%.'>
                            <FcInfo className='ml-2' />
                        </Tooltip>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <AiOutlinePercentage className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.overtime}
                            onChange={handleChange('overtime')}
                            placeholder='Enter Rate'
                            onBlur={handleBlur('overtime')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.overtime && touched.overtime && <Span color='red' size='xs'>{errors.overtime}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Regular Holiday Rate</Span>
                        <Span color='red'>*</Span>
                        <Tooltip title='This will be added in addition to 100%.'>
                            <FcInfo className='ml-2' />
                        </Tooltip>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <AiOutlinePercentage className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.regular_holiday}
                            onChange={handleChange('regular_holiday')}
                            placeholder='Enter Rate'
                            onBlur={handleBlur('regular_holiday')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.regular_holiday && touched.regular_holiday && <Span color='red' size='xs'>{errors.regular_holiday}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Special Holiday Rate</Span>
                        <Span color='red'>*</Span>
                        <Tooltip title='This will be added in addition to 100%.'>
                            <FcInfo className='ml-2' />
                        </Tooltip>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <AiOutlinePercentage className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.special_holiday}
                            onChange={handleChange('special_holiday')}
                            placeholder='Enter Rate'
                            onBlur={handleBlur('special_holiday')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.special_holiday && touched.special_holiday && <Span color='red' size='xs'>{errors.special_holiday}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Tardiness</Span>
                        <Span color='red'>*</Span>
                        <Tooltip title='This will be the percentage of hourly rate.'>
                            <FcInfo className='ml-2' />
                        </Tooltip>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <AiOutlinePercentage className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.tardiness}
                            onChange={handleChange('tardiness')}
                            placeholder='Enter Rate'
                            onBlur={handleBlur('tardiness')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.tardiness && touched.tardiness && <Span color='red' size='xs'>{errors.tardiness}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Grace Period</Span>
                        <Span color='lightgrey'>(Minutes)</Span>
                        <Span color='red'>*</Span>
                        <Tooltip title='This will be added in addition to 100%.'>
                            <FcInfo className='ml-2' />
                        </Tooltip>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <AiOutlineClockCircle className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.gracePeriod}
                            onChange={handleChange('gracePeriod')}
                            placeholder='Enter Minutes'
                            onBlur={handleBlur('gracePeriod')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.gracePeriod && touched.gracePeriod && <Span color='red' size='xs'>{errors.gracePeriod}</Span>}
                </div>
            </GridContainer>

            <Span weight='semi' margin='mt-4'>Leaves</Span>
            <GridContainer grid='two' padding='none' gap='two' margin='none'>
                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Sick Leave</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <BsCalendarWeek className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.sickLeave}
                            onChange={handleChange('sickLeave')}
                            placeholder='Enter Number of Leave Entitlement'
                            onBlur={handleBlur('sickLeave')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.sickLeave && touched.sickLeave && <Span color='red' size='xs'>{errors.sickLeave}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center'>
                        <Span>Vacation Leave</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <BsCalendarWeek className='ml-2' />
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values.vacationLeave}
                            onChange={handleChange('vacationLeave')}
                            placeholder='Enter Number of Leave Entitlement'
                            onBlur={handleBlur('vacationLeave')}
                            onKeyPress={onlyNumberKey}
                        />
                    </InputContainer>
                    {errors.vacationLeave && touched.vacationLeave && <Span color='red' size='xs'>{errors.vacationLeave}</Span>}
                </div>
            </GridContainer>

            {!contract?.isDefault && (
                <>
                    <Span weight='semi' margin='mt-4'>Rates Per Employee</Span>
                    <GridContainer grid='two' padding='none' gap='two' margin='none'>
                        <div className='px-2 text-left'>
                            <div className='flex flex-row justify-start items-center'>
                                <Span>Download Template</Span>
                                <Span color='red'>*</Span>
                            </div>
                            <Button
                                padding='px-8'
                                onClick={exportFile}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='white'>Template</Span>
                                    <DownloadOutlined style={{ color: 'white' }} />
                                </div>
                            </Button>
                            {errors.download && <Span color='red' size='xs'>{errors.download}</Span>}
                        </div>

                        <div className='px-2 text-left'>
                            <div className='flex flex-row justify-start items-center'>
                                <Span>Upload Template</Span>
                                <Span color='red'>*</Span>
                            </div>
                            <input
                                ref={inputRef}
                                id="file"
                                type="file"
                                name="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={(e: any) => {
                                    setFieldValue('file', e.target.files[0]);
                                }}
                            />
                            <Button
                                padding='px-8'
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <label htmlFor="file" className='pr-2 cursor-pointer'>
                                        Choose file
                                    </label>
                                    <UploadOutlined style={{ color: 'white' }} />
                                </div>
                            </Button>
                            <span className='truncate block'><strong>Filename: </strong>{values?.file?.name}</span>
                            {errors.file && touched.file && <Span color='red' size='xs'>{errors.file}</Span>}
                        </div>
                    </GridContainer>
                </>
            )}
        </div >
    )
}

export default ContractForm