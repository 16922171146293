import AlertSnackBar from '../AlertSnackBar';
import React, { useState } from "react";
import BackDrop from '../BackDrop';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import { AiOutlineLogout, AiOutlineMenuFold } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { Avatar, Menu, MenuItem, useMediaQuery, Zoom } from '@mui/material';
import { BRAND_IMAGES } from '../constants';
import { Span } from '../common/Span';
import { useHistory } from 'react-router-dom';

export default function Header() {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const collapsed = useSelector((state: RootState) => state.UI.sideBarCollapse);
    const user: any = useSelector((state: RootState) => state.User?.userInfo) as any;

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMyAccount = () => {
        history.push('/employees?type=update')
        dispatch.UI.updateFormId({ target: 'currentUpdateId', value: user?.id });
    }

    return (
        <header>
            {/* <BackDrop /> */}
            <AlertSnackBar />
            {!mobile &&
                collapsed && (
                    <div className='flex-1 ml-[60px] pl-2'>
                        <img src={BRAND_IMAGES['BRAND_LOGO_WHITE']} className='h-[25px]' />
                    </div>
                )
            }

            {mobile ? (
                <div className='flex justify-between items-center w-full h-full pl-2'>
                    <AiOutlineMenuFold className='cursor-pointer text-lg text-gray-400 font-semibold' onClick={() => dispatch.UI.updateState({ openMobileDrawer: true })} />
                    <img src={BRAND_IMAGES['BRAND_LOGO_WHITE']} className='h-[25px]' />
                    <div></div>
                </div>
            ) : (
                <div className='flex' >
                    <span className='mx-3 text-white capitalize'>{user?.name ?? ''}</span>

                    <div>
                        <Avatar sx={{ backgroundColor: '#1890ff', width: 26, height: 26, cursor: 'pointer' }} onClick={handleClick}>
                            <span className='text-base capitalize'>{(user?.name)?.substring(0, 1)}</span>
                        </Avatar>
                    </div>
                </div >
            )}

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleClickMyAccount();
                    }}
                >
                    <CgProfile className='mx-2 text-xl' />
                    <Span weight='semi'>My account</Span>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        dispatch.User.logout(null);
                    }}
                >
                    <AiOutlineLogout className='mx-2 text-xl' />
                    <Span weight='semi'>Logout</Span>
                </MenuItem>
            </Menu>
        </header >
    )
}

