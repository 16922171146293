import React, { useRef, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { updateCredential, updateOrganization } from '../../common/api';
import { Drawer } from 'antd';
import OrgForm from './Forms/OrgForm';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CredentialForm from './Forms/CredentialForm';

type Props = {
    table: string,
}

type State = {
    organization: Object | null,
    db: string,
    login: string,
    password: any,
    confirmPassword: string,
}

const UpdateCredential = ({ table }: Props) => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')
    const formikRef = useRef() as any;

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const cred = useSelector((state: RootState) => state.Table.tables[table]?.data)?.find(d => d.id === currentUpdateId);

    const initialState = {
        organization: cred?.organization ?? null,
        baseUrl: cred?.baseUrl ?? '',
        db: cred?.db ?? '',
        login: cred?.login ?? '',
        password: cred?.password ?? '',
        confirmPassword: cred?.confirmPassword ?? ''
    } as State;

    const validateSchema = yup.object({
        organization: yup.object().required('Organization is required field').nullable(),
        baseUrl: yup.string().required('Base Url is required field'),
        db: yup.string().required('Database is required field'),
        login: yup.string().required('Username is required field'),
        password: yup.string().required('Password is required field'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required field')
    })

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleUpdate = async (values, actions) => {
        try {
            const payload = {
                ...values,
            }

            let res;

            switch (table) {
                case 'credentials':
                    res = await updateCredential(payload, currentUpdateId);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                dispatch.UI.resetForm('currentUpdateId');
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update user'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleUpdate(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Credential"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            props.resetForm();
                            history.goBack();
                            dispatch.UI.resetForm('currentUpdateId')
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText='Update Credential'
                            />
                        }
                    >
                        {currentUpdateId && <CredentialForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateCredential;