import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const OldImage = ({ data, setFieldValue, values }) => {

    const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/task%2F${data}?alt=media&token=77af9bf2-8b1b-4f80-910f-c0b19ad26d3a`;

    const handleRemoveImage = () => {
        const value = values?.oldImages?.filter(i => i !== data);
        setFieldValue('oldImages', value);
        setFieldValue('deletedImages', [...values?.deletedImages, data]);
    }

    return (
        <div key={data} className='w-14 h-14 p-1 border border-gray-300 border-solid relative mr-2 rounded-sm'>
            <AiOutlineCloseCircle className=' text-sm text-red-400 absolute top-[-5px] right-[-5px] cursor-pointer' onClick={handleRemoveImage} />
            <img src={source} alt={data} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
        </div>
    )
}

export default OldImage