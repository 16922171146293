import React from 'react'
import designbg from '../../../assets/images/login_design3.png'
import designbg1 from '../../../assets/images/login_design.png'
import { Paper } from '@mui/material'
import { BRAND_IMAGES } from '../../../components/constants'
import { Span } from '../../../components/common/Span'

const SESuccess = () => {
    return (
        <div className='min-h-screen overflow-hidden flex items-center justify-center'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

            <Paper
                elevation={3}
                sx={{
                    width: { xs: '90vw', sm: '460px' },
                    minHeight: '500px',
                    padding: { xs: '10px 20px', sm: '30px 50px' },
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: '1'
                }}
            >
                <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className=' h-[70px] m-[30px]' />

                <div className='flex flex-col justify-start pt-16 items-center flex-1 h-full'>
                    <Span letters='wider' size='2xl' weight='bold'>Thanks for signing up.</Span>
                    <span className='mt-4 text-[13px] font-[500] text-center'>
                        Please click on the link that has just been sent to your email to verify your email
                        and continue registration process.
                    </span>
                </div>
            </Paper>
        </div>
    )
}

export default SESuccess