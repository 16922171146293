import { useEffect, useRef, useState } from "react";
import './Options.css'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from "../../../store";
import ListenForOutsideClicks from "../../ListenForOutsideClicks";
import { Paper, useMediaQuery, Zoom } from "@mui/material";
import { Container } from "../../common/Containers";
import { DeleteOutlined, EditOutlined, UnorderedListOutlined, MoreOutlined, SettingOutlined, ScheduleOutlined } from "@ant-design/icons";
import { Span } from "../../common/Span";
import { useHistory, useLocation } from "react-router-dom";
import DeleteModal from "../Modal/DeleteModal";
import { getPermission } from "../../../common/utils";
import { Empty, Modal } from "antd";
import SetAdmin from "../Modal/SetAdmin";
import { DEFAULT } from "../../constants";
import { FcApprove, FcDisapprove } from 'react-icons/fc';
import RenderRequestModal from "./RenderRequestModal";
import { RiLockPasswordLine } from "react-icons/ri";
import { changePassword } from "../../../common/api";

const Options = ({ data, table, subPermission = 'none' }: any) => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const currentModule = pathname?.split('/')[1];

    const organizationAdmin = useSelector((state: RootState) => state.Table.tables.role?.data)
        .find(r => r?.name === 'organization admin');

    const permissions = getPermission(currentModule, subPermission);
    const isRoleAndOrgOrSuper = currentModule === 'role' && DEFAULT.includes(data?.name);
    const isEmployeeAndOrgOrSuper = currentModule === 'employees' && DEFAULT.includes(data?.role?.name);
    const isShowLogsheet = ['employees'].includes(table) ? true : false;
    const isShowSetAdmin = ['employees'].includes(table) && !data?.admin && permissions.includes('canSetAdmin') ? true : false;
    const isShowUpdate = permissions.includes('canEdit') ? (isRoleAndOrgOrSuper ? false : true) : false;
    const isShowDeleteLevel1 = permissions.includes('canDelete') && !['without_clockout', 'without_rate'].includes(table) && !isEmployeeAndOrgOrSuper;
    const isShowDelete = isShowDeleteLevel1 ? (isRoleAndOrgOrSuper ? false : true) : false;
    const isShowApproveReject = ['leave', 'log', 'overtime'].includes(currentModule) && data?.status === 'pending';
    const isShowChangePassword = permissions?.includes('canChangePassword');
    const isOptionEmpty = [isShowLogsheet, isShowSetAdmin, isShowUpdate, isShowDelete, isShowApproveReject, isShowChangePassword].filter(v => v === true)?.length < 1;

    const [type, setType] = useState('');
    const [update, setUpdate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [assignAdmin, setAssignAdmin] = useState(false);
    const [open, setOpen] = useState(false);
    const [listening, setListening] = useState(false);
    const menuRef: any = useRef(null);

    useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setOpen));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(!open)
    };

    const showLogsheet = () => {
        history.push(`${pathname}/logsheet/${data.id}?name=${data.name}`)
    }

    const showUpdate = () => {
        history.push(`${pathname}?type=update`)
        dispatch.UI.updateFormId({ target: 'currentUpdateId', value: data?.id });
    }

    const renderSuccess = () => {
        Modal.success({
            title: 'Change Password',
            content: 'Note: We have sent a password instruction to your email'
        })
    }

    const handleChangePassword = async () => {
        try {
            dispatch.UI.setIsLoading(true);
            const res = await changePassword({ email: data?.email });

            if (res.isSuccess) {
                dispatch.UI.setIsLoading(false);
                renderSuccess();
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to change password';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    useEffect(() => {
        dispatch.Table.getFirebaseData({ table: 'role', name: 'role' })
    }, [])

    return (
        <>
            <div className='flex items-center justify-center px-2 cursor-pointer relative' onClick={handleClick}>
                <div ref={menuRef}>
                    <MoreOutlined className='text-xl' />
                </div>

                <Zoom in={open}>
                    <Paper elevation={5} className={`${mobile ? 'pop_over_driver_mobile' : 'pop_over_driver'} z-50`}>
                        <Container padding='p-2'>
                            {isOptionEmpty && (
                                <div className="emptyContainer">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Data' />
                                </div>
                            )}

                            {isShowUpdate && (
                                <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-end" onClick={showUpdate} >
                                    <EditOutlined style={{ color: '#fcae1e', fontSize: '18px' }} data-tip data-for='update' />
                                    <Span margin='mx-2' weight='semi'>Update</Span>
                                </div>
                            )}

                            {isShowDelete && (
                                <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-center" onClick={() => setDeleteModal(true)}>
                                    <DeleteOutlined style={{ color: '#ff0e0e', fontSize: '18px' }} data-tip data-for='delete' />
                                    <Span margin='mx-2' weight='semi'>Delete</Span>
                                </div>
                            )}

                            {isShowSetAdmin && (
                                <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-center" onClick={() => setAssignAdmin(true)}>
                                    <SettingOutlined style={{ color: '#5bc0de', fontSize: '18px' }} data-tip data-for='update' />
                                    <Span margin='mx-2' weight='semi'>Set As Admin</Span>
                                </div>
                            )}

                            {isShowLogsheet && (
                                <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-center" onClick={showLogsheet}>
                                    <UnorderedListOutlined style={{ color: '#5bc0de', fontSize: '18px' }} data-tip data-for='update' />
                                    <Span margin='mx-2' weight='semi'>Check Log Sheet</Span>
                                </div>
                            )}

                            {isShowApproveReject && (
                                <>
                                    <div
                                        className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-center"
                                        onClick={() => {
                                            setType('approve');
                                            setUpdate(true);
                                        }}
                                    >
                                        <FcApprove style={{ color: '#5bc0de', fontSize: '18px' }} data-tip data-for='update' />
                                        <Span margin='mx-2' weight='semi'>Approve</Span>
                                    </div>

                                    <div
                                        className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-center"
                                        onClick={() => {
                                            setType('reject');
                                            setUpdate(true);
                                        }}
                                    >
                                        <FcDisapprove style={{ color: '#5bc0de', fontSize: '18px' }} data-tip data-for='update' />
                                        <Span margin='mx-2' weight='semi'>Reject</Span>
                                    </div>
                                </>
                            )}

                            {isShowChangePassword && (
                                <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer justify-start items-center" onClick={handleChangePassword}>
                                    <RiLockPasswordLine style={{ color: '#5bc0de', fontSize: '18px' }} data-tip data-for='update' />
                                    <Span margin='mx-2' weight='semi'>Change Password</Span>
                                </div>
                            )}
                        </Container>
                    </Paper>
                </Zoom>

                {update && <RenderRequestModal update={update} setUpdate={setUpdate} type={type} data={data} table={table} />}
                {deleteModal && <DeleteModal id={data?.id} deleteModal={deleteModal} setDeleteModal={setDeleteModal} table={table} />}
                {assignAdmin && <SetAdmin data={data} assignAdmin={assignAdmin} setAssignAdmin={setAssignAdmin} table={table} organizationAdmin={organizationAdmin} />}
            </div>
        </>
    )
}

export default Options

