import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { Dispatch } from '../../../store';
import ScheduleForm from '../Forms/ScheduleForm/ScheduleForm';
import { setEmployeeSchedule } from '../../../common/api';

type Props = {
    table: string,
    setAssign: Function,
    assign: boolean,
    id: string
}

type State = {
    from: string,
    to: string,
    emp: Array<any>
}


const SetSchedule = ({ table, setAssign, assign, id }: Props) => {

    const initialState = {
        from: '',
        to: '',
        emp: []
    } as State;

    const validateSchema = yup.object({
        from: yup.string().required('From is required field'),
        to: yup.string().required('To is required field'),
        emp: yup.array().required('Emp is required field').min(1, 'Employee field must have at least 1 item')
    })

    const dispatch = useDispatch<Dispatch>();

    const handleSetSched = async (values, actions) => {
        try {
            const payload = {
                schedule: {
                    from: values.from,
                    to: values.to
                },
                user_ids: values.emp?.map(e => e.id)
            }
            let res;

            switch (table) {
                case 'employees':
                    res = await setEmployeeSchedule(payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAssign(false)
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Schedule Set Successfully' })
                const timeOut = setTimeout(() => {
                    dispatch.Table.getFirebaseData({ table, name: table })
                    clearTimeout(timeOut);
                }, 1000)
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to set schedule'
            dispatch.UI.setAlert({ type: 'Error', message: errMsg })
        }
    }

    return (
        <Formik
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleSetSched(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Modal
                        title="Set Schedule"
                        cancelButtonProps={{ loading: props.isSubmitting }}
                        okButtonProps={{ loading: props.isSubmitting }}
                        onOk={() => props.handleSubmit()}
                        onCancel={() => {
                            setAssign(false)
                            props.resetForm();
                        }}
                        visible={assign}
                        bodyStyle={{
                            maxHeight: '50vh',
                            overflow: 'auto'
                        }}
                    >
                        <ScheduleForm {...props} setAssign={setAssign} />
                    </Modal>
                )
            }}
        </Formik>
    )
}

export default SetSchedule;