import { post, postMultipart, put, remove } from "../core";

export const addOrganization = async (payload: any) => {
    try {
        const body = new FormData();

        body.append('file', payload?.logo);

        delete payload.logo;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Company Logo');

        const res = await postMultipart('/admin/add/organization', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateOrganization = async (payload: any) => {
    try {
        const body = new FormData();

        body.append('file', payload?.logo);

        delete payload.logo;
        body.append('details', JSON.stringify(payload));
        body.append('name', 'Company Logo');

        const res = await postMultipart('/admin/update/organization/', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteOrganization = async (id: any) => {
    try {
        const res = await remove(`/admin/delete/organization/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}