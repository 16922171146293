import { Avatar } from "antd";
import { Span } from "../../../../components/common/Span";
import Options from "../../../../components/ModuleComponents/Popover/Options";
import RenderEmployees from "./RenderEmployees";
import RenderItems from "./RenderItems";
import { currencyCommaFormat } from "../../../../common/utils";

export const COLUMNS = [
  {
    id: "type",
    field: "type",
    Header: "Type",
    accessor: 'type',
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{d?.type?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d?.type}</Span>
        </div>
      )
    }
  },
  {
    id: "employees",
    field: "employees",
    Header: "Employees",
    accessor: 'employees',
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const employees = d?.employeesData;

      return <RenderEmployees employees={employees} />
    }
  },
  {
    id: "items",
    field: "items",
    Header: "Items",
    accessor: 'items',
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const items = d?.items;

      return <RenderItems items={items} />
    }
  },
  {
    id: "total",
    field: "total",
    Header: "Total Amount",
    accessor: 'total',
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const items = d?.items;
      const arrOfAmount = items?.map(i => Number(i?.amount));
      const totalAmount = arrOfAmount?.reduce((a, b) => a + b, 0);

      return <Span>{currencyCommaFormat(totalAmount, 2, true)}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table={d?.type} subPermission='canViewE&DSub' />
      )
    }
  }

];
