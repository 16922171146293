import * as React from "react";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  size?: "4xl" | "3xl" | "2xl" | "xl" | "lg" | "base" | "xs";
  weight?: "medium" | "semi" | "bold";
  letters?: "wider";
  padding?: "p-2" | "px-4" | "pr-2" | "py-4" | "pl-2" | "normal";
  color?:
  | "grey"
  | "lightgrey"
  | "red"
  | "brown"
  | "white"
  | "green"
  | "required"
  | "blue"
  | "orange"
  | "default"
  | "violet"
  | "green-1";
  bg?: "red" | "darkred" | "green" | "darkgreen" | "brown";
  border?: "red" | "green" | "brown" | "right";
  width?: "full" | "w-20" | "w-28";
  transform?: "uppercase" | "capitalize";
  margin?:
  | "mx-2"
  | "mx-4"
  | "mx-4"
  | "mt-4"
  | "my-auto"
  | "mt-2"
  | "my-4"
  | "mx-auto"
  | "mb-6"
  | "mb-2"
  | "ml-2"
  radius?: "rounded" | "md" | "xl";
  position?: "absolute";
  align?: "center" | "right";
  wrap?: 'singleLine' | 'none'
};

let getFontSize = (size: Props["size"]) => {
  switch (size) {
    case "4xl":
      return "text-4xl";
    case "3xl":
      return "text-3xl";
    case "2xl":
      return "text-2xl";
    case "xl":
      return "text-xl";
    case "lg":
      return "text-lg";
    case "base":
      return "text-base";
    case "xs":
      return "text-xs";
    default:
      return "text-sm";
  }
};

let getFontWeight = (weight: Props["weight"]) => {
  switch (weight) {
    case "bold":
      return "font-bold";
    case "semi":
      return "font-semibold";
    case "medium":
      return "font-medium";
    default:
      return "font-normal";
  }
};

let getLetterSpacing = (letters: Props["letters"]) => {
  switch (letters) {
    case "wider":
      return "tracking-wider";
    default:
      return "tracking-normal";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "pl-2":
      return "pl-2"
    case "normal":
      return "px-4 py-2";
    case "px-4":
      return "px-4 py-0.5";
    case "py-4":
      return "py-4";
    case "pr-2":
      return "pr-2";
    case "p-2":
      return "p-2";
    default:
      return "p-0";
  }
};

let getColor = (color: Props["color"]) => {
  switch (color) {
    case "violet":
      return 'text-[#7140b8]'
    case "required":
      return "text-[#F12B2C]";
    case "blue":
      return "text-blue-3";
    case "green":
      return "text-green-2";
    case "green-1":
      return "text-[#52c41a]"
    case "brown":
      return "text-brown-1";
    case "white":
      return "text-white";
    case "red":
      return "text-red-2";
    case "lightgrey":
      return "text-grey-11";
    case "grey":
      return "text-grey-4";
    case "orange":
      return "text-orange-1";
    case 'default':
      return "text-black-1";
    default:
      return "text-black-1";
  }
};

let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "brown":
      return "bg-brown-1 bg-opacity-20";
    case "darkgreen":
      return "bg-green-2";
    case "green":
      return "bg-green-3";
    case "darkred":
      return "bg-red-3";
    case "red":
      return "bg-red-1";
    default:
      return "bg-transparent";
  }
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "brown":
      return "border border-brown-1";
    case "green":
      return "border border-green-2";
    case "red":
      return "border border-red-2";
    case "right":
      return "border-r border-grey-1";
    default:
      return "border-none";
  }
};

let getWidth = (width: Props["width"]) => {
  switch (width) {
    case 'w-28':
      return 'w-28'
    case 'w-20':
      return 'w-20'
    case "full":
      return "w-full";
    default:
      return "";
  }
};

let getTransform = (transform: Props["transform"]) => {
  switch (transform) {
    case "capitalize":
      return "capitalize";
    case "uppercase":
      return "uppercase";
    default:
      return "normal-case";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "ml-2":
      return "ml-2"
    case "mx-auto":
      return "mx-auto";
    case "my-auto":
      return "my-auto";
    case "mb-6":
      return "mb-7 mt-4";
    case "mt-4":
      return "mt-4 mb-1";
    case "mt-2":
      return "mt-2";
    case "my-4":
      return "my-4";
    case "mx-4":
      return "mx-4";
    case "mx-2":
      return "mx-2";
    case "mb-2":
      return "mb-2"
    default:
      return "m-0";
  }
};

let getBorderRadius = (radius: Props["radius"]) => {
  switch (radius) {
    case "xl":
      return "rounded-xl";
    case "md":
      return "rounded-md";
    case "rounded":
      return "rounded";
    default:
      return "rounded-none";
  }
};

let getPosition = (position: Props["position"]) => {
  switch (position) {
    case "absolute":
      return "absolute";
    default:
      return "";
  }
};

let getAlign = (align: Props["align"]) => {
  switch (align) {
    case "center":
      return "text-center";
    case "right":
      return "text-right";
    default:
      return "";
  }
};

let getWrap = (wrap: Props['wrap']) => {
  switch (wrap) {
    case 'none':
      return ''
    case 'singleLine':
      return 'truncate'
    default:
      return ''
  }
}

export const Span = ({
  size,
  weight,
  padding,
  letters,
  color,
  border,
  bg,
  width,
  transform,
  margin,
  radius,
  children,
  align,
  wrap,
}: Props) => {
  return (
    <div className={classnames(getMargin(margin), getAlign(align))}>
      <span
        className={classnames(
          getFontSize(size),
          getFontWeight(weight),
          getLetterSpacing(letters),
          getPadding(padding),
          getColor(color),
          getBorder(border),
          getBg(bg),
          getWidth(width),
          getTransform(transform),
          getBorderRadius(radius),
          getAlign(align),
          getWrap(wrap),
        )}
      >
        {children}
      </span>
    </div>
  );
};

export const SpanContainer = ({
  size,
  weight,
  padding,
  letters,
  color,
  position,
  border,
  bg,
  width,
  transform,
  radius,
  children,
}: Props) => {
  return (
    <span
      className={classnames(
        getFontSize(size),
        getFontWeight(weight),
        getLetterSpacing(letters),
        getPadding(padding),
        getColor(color),
        getBorder(border),
        getBg(bg),
        getWidth(width),
        getTransform(transform),
        getBorderRadius(radius),
        getPosition(position)
      )}
    >
      {children}
    </span>
  );
};
