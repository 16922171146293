import React, { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { Grid, useMediaQuery } from '@mui/material';
import { downloadPayslipPDF, getPermission, removeSCAndCapitalize, useQuery } from '../../../common/utils';
import { Container } from '../../../components/common/Containers';
import { Span } from '../../../components/common/Span';
import { Button } from '../../../components/common/Button';
import { AiOutlineDownload } from 'react-icons/ai';
import ReportsNav from './ReportsNav/ReportsNav';
import ReportsView from './ReportsView/ReportsView';
import { generateReportPdf } from '../../../common/api';
import { FileSearchOutlined } from '@ant-design/icons';
import UpdateOrganization from '../../../components/ModuleComponents/UpdateOrganization';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

const Reports = ({ activeKey }) => {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');
    const permissions = getPermission('payroll', 'canViewReportSub')
    const query = useQuery();
    const qKey = query.get('activekey');
    const qId = query.get('id');

    const { reports: items, data: payslips, date, contract } = useSelector((state: RootState) => state.Payroll);
    const isLoading = useSelector((state: RootState) => state.UI.isLoading);

    const [select, setSelect] = useState(null) as any;
    const [contracts, setContracts] = useState([]) as any;
    const [search, setSearch] = useState('');

    const deferredSearch = useDeferredValue(search);

    const data = useMemo(() => {
        const result = items?.filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

        return result;
    }, [items, deferredSearch])

    const handlePrint = async () => {
        try {
            dispatch.UI.setIsLoading(true)

            const name = contracts?.length > 1 ? moment().format('LLL') : contracts[0]?.contract;

            const res = await generateReportPdf({ list: contracts });

            if (res) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Success' });
                downloadPayslipPDF(res, `${removeSCAndCapitalize(name, '_')} Report-${moment().format('LL')}.pdf`);
            }

        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to print payslip'
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    useEffect(() => {
        if (qId) {
            const currentItem = items?.find((item: any) => item?.id === qId);
            setSelect(currentItem);
        } else {
            setSelect(items[0]);
        }
    }, [qKey, items, qId]);

    useEffect(() => {
        history.push(`${pathname}?activeKey=6`)
    }, [contract])

    useEffect(() => {
        dispatch.Table.getFirebaseData({ table: 'credentials', name: 'credentials' });
    }, []);

    return (
        <div className='relative'>
            <Container padding='none'>
                <div className={`flex w-full ${mobile ? 'flex-col justify-center items-center px-4 pb-4' : 'justify-between items-center px-4'}`}>
                    <div>
                        <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                            <Span margin='mt-4' weight='bold' size='2xl'>Reports</Span>
                        </div>
                    </div>

                    <div className={`flex ${mobile ? 'flex-col w-full' : 'flex-row space-x-2'} justify-start items-center`}>
                        {permissions?.includes('canPrintReport') && (
                            <Button
                                padding='px-2'
                                border='blue'
                                bg='none'
                                color='blue'
                                radius='none'
                                onClick={handlePrint}
                                disabled={!contracts?.length}
                                style={{ cursor: !contracts?.length ? 'not-allowed' : 'pointer' }}
                                {...(!mobile ? {
                                    width: 'none',
                                } : {
                                    margin: 'mt-2'
                                })}
                            >
                                <div className='flex w-full justify-center items-center'>
                                    <Span padding='pr-2' color='blue'>Print Report</Span>
                                    <AiOutlineDownload />
                                </div>
                            </Button>
                        )}
                    </div>
                </div>

                {(items?.length < 1 || !select) && !isLoading ? (
                    <div className='overflow-auto h-[70vh] flex justify-center items-center flex-col'>
                        <div className='absolute left-0 right-0 p-24 flex justify-center flex-col items-center'>
                            <FileSearchOutlined style={{ fontSize: '6rem', color: 'gray' }} />
                            <Span color='lightgrey' size='2xl'>No Data</Span>
                        </div>
                    </div>
                ) : (
                    <Grid container sx={{ marginTop: '16px' }}>
                        <Grid container item xs={mobile ? 12 : 3}>
                            <ReportsNav
                                setSearch={setSearch}
                                data={data}
                                contracts={contracts}
                                setContracts={setContracts}
                                select={select}
                                setSelect={setSelect}
                            />
                        </Grid>

                        {!mobile && (
                            <Grid container item xs={9} alignItems='start' justifyContent='center' sx={{ height: '70vh', overflow: 'auto' }}>
                                <ReportsView permissions={permissions} select={select} setSelect={setSelect} contracts={contracts} setContracts={setContracts} />
                            </Grid>
                        )}
                    </Grid>
                )}

                {activeKey === '6' && <UpdateOrganization table='payroll' />}
            </Container >
        </div >
    )
}

export default Reports