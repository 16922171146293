import { Avatar } from "antd";
import moment from "moment";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  // {
  //   id: "id",
  //   field: "id",
  //   Header: "ID",
  //   isVisible: false,
  //   Cell: data => {
  //     const d = data?.row?.original
  //     return <span>{d.id}</span>
  //   }
  // },
  {
    id: "employee",
    field: "employee",
    Header: "Employee Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} >{d.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d.name}</Span>
        </div>
      )
    }
  },
  {
    id: "employee_email",
    field: "employee_email",
    Header: "Employee Email",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <span>{d?.email}</span>
    }
  },
  {
    id: "position",
    accessor: 'position',
    field: "position",
    Header: "Position",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      const role = d?.role?.name;
      return (
        <>
          <Span transform='capitalize'>{d.position}</Span>
          {role && (
            <Span transform='capitalize' color='lightgrey'>({role})</Span>
          )}
        </>
      )
    }
  },
  {
    id: "department",
    accessor: 'department',
    field: "department",
    Header: "Department",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{d.department}</Span>
    }
  },
  {
    id: "group",
    accessor: 'group',
    field: "group",
    Header: "Group",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{d.group}</Span>
    }
  },
  {
    id: "organization",
    accessor: (d) => JSON.stringify(d.organization),
    field: "organization",
    Header: "Organization",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{d?.organization?.name}</Span>
    }
  },
  {
    id: "schedule",
    field: "schedule",
    Header: "Schedule",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const date = '2023-01-01';
      const from = moment(date + ' ' + d?.schedule?.from).format('hh:mm A');
      const to = moment(date + ' ' + d?.schedule?.to).format('hh:mm A');
      return (
        <>
          {d?.schedule ? (
            <div className="flex justify-start items-center flex-row">
              <Span margin='mx-2'><strong>IN:</strong> {from} </Span>
              <Span><strong>OUT:</strong> {to} </Span>
            </div>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mx-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='employees' />
      )
    }
  }

];
