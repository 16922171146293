import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader';

import Table from '../../components/Table/Table';
import { COLUMNS } from './Column'
import { Input } from 'antd';
import { Span } from '../../components/common/Span';
import { Button } from '../../components/common/Button';
import { BiPlusCircle } from 'react-icons/bi';
import UpdateUser from '../../components/ModuleComponents/UpdateUser';
import AddUsersSingleAndBulk from '../../components/ModuleComponents/AddUsersSingleAndBulk/AddUsersSingleAndBulk';
import { useHistory, useLocation } from 'react-router-dom';
import { ScheduleOutlined } from '@ant-design/icons';
import SetSchedule from '../../components/ModuleComponents/Modal/SetSchedule';
import { getPermission } from '../../common/utils';
import { useMediaQuery } from '@mui/material';
import Card from '../../components/Card/Card';
import EmpLimitExceed from '../../components/ModuleComponents/Modal/EmpLimitExceed';
import ContactUs from '../../components/ModuleComponents/Modal/ContactUs';
import WarnVerifyEmail from '../../components/WarnVerifyEmail';
import { getAuth, onIdTokenChanged } from 'firebase/auth';

export default () => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')
    const pathname = useLocation().pathname;

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [assign, setAssign] = useState(false);
    const [isLimit, setIsLimit] = useState(false);
    const [contactUs, setContactUs] = useState(false);
    const [warn, setWarn] = useState(false);

    const organization = useSelector((state: RootState) => state.Table.tables.organization.data);
    const { userInfo, claims } = useSelector((state: RootState) => state.User);
    const data: any = useSelector((state: RootState) => state.Table.tables.employees.data)
        .filter(d => {//filter base on search input
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });
    const { isLoading } = useSelector((state: RootState) => state.Table);
    const { isLoading: uiLoading } = useSelector((state: RootState) => state.UI);

    const permissions = getPermission('employees')
    const current_org = organization?.find(o => o?.id === userInfo?.organization?.id);

    const columns = useMemo(() => COLUMNS, []);
    const { Search } = Input;

    const handleAdd = () => {
        if (current_org?.numberOfEmployee >= current_org?.plan?.limit) {
            setIsLimit(true);
            return;
        }

        setAdd(true)
        history.push(`${pathname}?type=add`)
    }

    useEffect(() => {
        dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' })
        dispatch.Table.getFirebaseData({ table: 'organization', name: 'organization' });
    }, [userInfo])

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onIdTokenChanged(auth, async (user: any) => {
            await user?.getIdToken(true);
            const idTokenResult = await user.getIdTokenResult();
            const claim_result = idTokenResult.claims;
            dispatch.User.updateState({ claims: claim_result });

            if (claim_result?.email_verified) {
                dispatch.LocalStorage.updateState({ emailWarning: { isSent: false, countdownTime: null } });
                unsubscribe();
            } else {
                setWarn(true);
            }
        });

        return () => {
            unsubscribe();
        }
    }, []);

    useEffect(() => {
        dispatch.User.updateUserInfo(null);
    }, [])

    return (<div className='relative'>
        <Loader isLoading={isLoading} />
        <Loader isLoading={uiLoading} />
        {warn && <WarnVerifyEmail setWarn={setWarn} />}
        <Container border='grey' padding='none'>
            <div className='p-4'>
                <div className={`flex ${mobile ? 'justify-center items-center flex-col' : 'justify-between'}`}>
                    <Span margin='mt-4' weight='bold' size='2xl'>All Employees</Span>
                    <div className={`flex ${mobile ? 'justify-center w-full items-center flex-col mt-2' : 'justify-end px-4 my-4'}`}>
                        {permissions.includes('canSetSchedule') && (
                            <Button
                                padding='py-1'
                                border='blue'
                                margin='mx-2'
                                {...(!mobile && {
                                    width: 'none'
                                })}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                    onClick={() => {
                                        setAssign(true)
                                        history.push(`${pathname}?type=add`)
                                    }}
                                >
                                    <ScheduleOutlined className='my-auto mx-2 text-2xl' data-tip data-for='schedule' />
                                    Set Schedule
                                </div>
                            </Button>
                        )}

                        {permissions.includes('canCreate') && (
                            <Button
                                disabled={!claims?.email_verified && !claims?.emailVerified}
                                padding='py-1'
                                border='blue'
                                bg='none'
                                color='blue'
                                {...(!mobile ? {
                                    width: 'none'
                                } : {
                                    margin: 'mt-2'
                                })}
                                onClick={handleAdd}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                >
                                    <BiPlusCircle className='my-auto mx-2 text-2xl' />
                                    Add Employee
                                </div>
                            </Button>
                        )}

                    </div>
                </div>
            </div>

            <Search className='px-4' placeholder="Search any..." onChange={(e: any) => setSearch(e.target.value)} style={{ width: mobile ? '100%' : 300 }} />

            <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                {mobile ? (
                    <Card data={data} dataType='employees' sortName='createdAt' />
                ) : (
                    <Table columns={columns} data={data} dataType='employees' sortName='createdAt' isLastColSticky={true} />
                )}
            </div>

            <SetSchedule assign={assign} setAssign={setAssign} table='employees' id={data?.id} />
            <AddUsersSingleAndBulk setAdd={setAdd} add={add} />
            <UpdateUser table='employees' />
            {isLimit && <EmpLimitExceed isLimit={isLimit} setIsLimit={setIsLimit} current_org={current_org} contactUs={contactUs} setContactUs={setContactUs} />}
            {contactUs && <ContactUs contactUs={contactUs} setContactUs={setContactUs} />}
        </Container>
    </div>
    )
}
