import { Box, Checkbox, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import Search from 'antd/lib/input/Search'
import React from 'react'
import ItemList from './ItemList';

const ReportsNav = ({ setSearch, data, contracts, setContracts, select, setSelect }) => {
    const mobile = useMediaQuery('(max-width:600px)');

    const handleCheckedAll = () => {
        const filteredData = data?.filter((d) => {
            const status = d?.isConfirmed ? 'Confirmed' : d?.payslips?.some((res) => !res.isSaved) ? 'Incomplete' : 'Can Confirm';

            return status !== 'Incomplete'
        })

        if (contracts?.length < filteredData?.length) {
            setContracts(filteredData);
        } else if (contracts?.length === filteredData?.length) {
            setContracts([]);
        }
    }

    return (
        <List dense sx={{ width: '100%', padding: '0 8px', maxWidth: mobile ? '100%' : 300, height: '70vh' }} component={Paper} >
            <ListItem
                disablePadding
                key='search'
            >
                <Stack justifyContent='center' alignItems='center' sx={{ width: '100%', my: '10px' }}>
                    <Search
                        className={`${mobile ? 'px-4 pb-4' : ''}`}
                        placeholder="Search any..."
                        onChange={(e: any) => setSearch(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Stack>
            </ListItem>

            <ListItem
                disablePadding
                key='all_item'
                secondaryAction={
                    <Checkbox
                        checked={data?.length === contracts?.length}
                        indeterminate={data?.length !== contracts?.length && contracts?.length > 0}
                        onClick={handleCheckedAll}
                        size='small'
                    />
                }
            >
                <ListItemButton>
                    <ListItemText>
                        <Typography variant='body2' fontWeight='bold' align='center'>Select All</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <Divider variant='fullWidth' component="li" />

            <Box
                sx={{
                    overflow: mobile ? 'auto' : 'hidden',
                    height: '55vh',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                        overflow: 'auto'
                    }
                }}
            >
                {data?.map((item) => (
                    <ItemList data={item} select={select} setSelect={setSelect} contracts={contracts} setContracts={setContracts} />
                ))}
            </Box>
        </List>
    )
}

export default ReportsNav