import { Avatar, Paper, useMediaQuery } from '@mui/material'
import { HiUserGroup } from 'react-icons/hi';
import React from 'react'
import { Span } from '../../../components/common/Span';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const EmployeesCard = () => {
    const mobile = useMediaQuery('(max-width:600px)')
    
    const organization = useSelector((state: RootState) => state.Document.documents.organization.data);

    const limit = organization?.plan?.limit ?? 200;
    const employee_count = organization?.numberOfEmployee ?? 0;

    return (
        <Paper
            elevation={2}
            sx={{
                display: 'flex',
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
                width: mobile ? '100%' : '80%',
                minWidth: 300,
                height: 100,
                flexDirection: 'row'
            }}
        >
            <Avatar
                sx={{
                    bgcolor: '#1890ff80',
                    width: 50,
                    height: 50
                }}
            >
                <HiUserGroup className='text-[35px]' />
            </Avatar>

            <div className='flex flex-col justify-start items-center ml-4'>
                <div className='flex flex-row justify-start items-center space-x-2'>
                    <Span weight='semi' size='3xl'>{employee_count}</Span>
                    <Span weight='semi'>/ {limit}</Span>
                </div>

                <Span weight='semi' letters='wider'>EMPLOYEES</Span>
            </div>
        </Paper>
    )
}

export default EmployeesCard