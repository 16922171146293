import React from 'react'
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Tabs } from 'antd'
import SSS from './Content/SSS/SSS'
import { Span } from '../../components/common/Span'
import { RiGovernmentLine } from 'react-icons/ri'
import PHIC from './Content/PHIC/PHIC'
import HDMF from './Content/HDMF/HDMF'
import TAX from './Content/TAX/TAX'
import { getPermission } from '../../common/utils'

const Benefits = () => {
    const permissions = getPermission('benefits');

    const { isLoading } = useSelector((state: RootState) => state.UI);

    return (
        <div className='relative'>
            <Loader isLoading={isLoading} />
            <Container border='grey' padding='p-2'>
                <Tabs defaultActiveKey="1">
                    {permissions?.includes('canViewSSS') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <RiGovernmentLine className='mr-2' />
                                    <span className=' font-semibold'>SSS</span>
                                </div>
                            }
                            key="1"
                        >
                            <SSS />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewPHIC') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <RiGovernmentLine className='mr-2' />
                                    <span className=' font-semibold'>PHIC</span>
                                </div>
                            }
                            key="2"
                        >
                            <PHIC />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewHDMF') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <RiGovernmentLine className='mr-2' />
                                    <span className=' font-semibold'>HDMF</span>
                                </div>
                            }
                            key="3"
                        >
                            <HDMF />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewTAX') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <RiGovernmentLine className='mr-2' />
                                    <span className=' font-semibold'>TAX</span>
                                </div>
                            }
                            key="4"
                        >
                            <TAX />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </Container>
        </div>
    )
}

export default Benefits