import React from 'react'
import { currencyCommaFormat, removeSCAndCapitalize } from '../../../../../common/utils'
import { Tooltip } from '@mui/material'

const DeductionItem = ({ item }) => {
    return (
        <>
            <div className='flex w-full px-2 border-b border-gray-300'>
                <Tooltip title={item?.item} placement='top'>
                    <span className='w-full truncate'>{item?.item}</span>
                </Tooltip>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <Tooltip title={item?.desc} placement='top'>
                    <span className='w-full truncate'>{item?.desc}</span>
                </Tooltip>
            </div>

            <div className='flex w-full px-2 border-b border-gray-300'>
                <span className='w-full'>{currencyCommaFormat(item?.amount, 2, true)}</span>
            </div>
        </>
    )
}

export default DeductionItem