import { Span } from '../../components/common/Span'
import { useMediaQuery } from '@mui/material'
import ActiveEmployees from './ActiveEmployees/ActiveEmployees'
import EmployeesCard from './DashboardNav/EmployeesCard'
import TodayCard from './DashboardNav/TodayCard'
import YesterdayCard from './DashboardNav/YesterdayCard'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store'
import moment from 'moment';

const Home = () => {
    const mobile = useMediaQuery('(max-width:600px)');
    const tablet = useMediaQuery('(max-width:1300px)');
    const dispatch = useDispatch<Dispatch>();

    const { userInfo } = useSelector((state: RootState) => state.User);

    useEffect(() => {
        dispatch.Document.getFirebaseDoc({ name: 'organization', document: 'organization', id: userInfo?.organization?.id });
    }, []);

    return (
        <div className='overflow-y-auto border border-solid border-gray-300 rounded-md bg-white h-[85vh]'>
            <div className='p-4'>
                <div className={`flex ${mobile ? 'justify-center items-center flex-col' : 'justify-between'}`}>
                    <Span margin='mt-4' weight='bold' size='2xl'>Dashboard</Span>
                </div>

                <div className='grid gap-2 mt-4 sm:grid-cols-1 xl:grid-cols-3'>
                    <div className='flex flex-1 xs:flex-col xl:flex-col sm:flex-row items-start justify-start p-4 xs:space-y-2 sm:space-x-2 sm:space-y-0 xl:space-x-0  xl:space-y-4 overflow-x-auto'>
                        <EmployeesCard />
                        <TodayCard />
                        <YesterdayCard />
                    </div>

                    <div className='xl:col-span-2 p-4 flex-1'>
                        <ActiveEmployees />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home