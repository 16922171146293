import { Avatar } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText, getOrganizationLogo } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";
import Org from "./Org";

export const COLUMNS = [
  {
    id: "organization",
    field: "organization",
    Header: "Organization",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <Org data={d} />
    }
  },
  {
    id: "baseUrl",
    field: "baseUrl",
    Header: "Base URL",
    isVisible: true,
    accessor: 'baseUrl',
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.baseUrl ? (
            <span className="capitalize max-w-md block">{d?.baseUrl}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "db",
    field: "db",
    Header: "Database",
    isVisible: true,
    accessor: 'db',
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.db ? (
            <span className="capitalize max-w-md block">{d?.db}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "login",
    field: "login",
    Header: "Username",
    isVisible: true,
    accessor: 'login',
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.login ? (
            <span className="capitalize max-w-md block">{d?.login}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='credentials' />
      )
    }
  }

];
