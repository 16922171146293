import Attachment from '../containers/Authentication/Redirect/Station/Station';

const Station = () => {
    return (
        <>
            <Attachment />
        </>
    )
}

export default Station
