import { Avatar } from '@mui/material'
import { Card } from 'antd'
import moment from 'moment'
import React from 'react'
import { firebaseDateToText } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'

const RenderEmployess = ({ item, dataType }) => {

    const d = item;
    const date = '2023-01-01';
    const from = moment(date + ' ' + d?.schedule?.from).format('hh:mm A');
    const to = moment(date + ' ' + d?.schedule?.to).format('hh:mm A');

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.name}</Span>
                        <Span transform='capitalize' color='lightgrey'>{item?.role?.name}</Span>
                    </div>
                </div>
                <Options data={item} table={dataType} />
            </div>

            <div className='py-2'>
                <div className='flex justify-start items-center'>
                    <span className='font-semibold mx-2 w-24'>Email: </span>
                    <Span>{item?.email ?? ''}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className='font-semibold mx-2 w-24'>Position: </span>
                    <Span transform='capitalize'>{item?.position ?? ''}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className='font-semibold mx-2 w-24'>Department: </span>
                    <Span transform='capitalize'>{item?.department ?? ''}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className='font-semibold mx-2 w-24'>Organization: </span>
                    <Span transform='capitalize'>{item?.organization?.name ?? ''}</Span>
                </div>

                <div className='flex justify-start items-start'>
                    <span className='font-semibold mx-2 w-24'>Sched: </span>
                    <div className="flex justify-start items-start flex-col">
                        <Span size='xs'><strong>IN:</strong> {from ?? ''} </Span>
                        <Span size='xs'><strong>OUT:</strong> {to ?? ''} </Span>
                    </div>
                </div>

                <div className='flex justify-start items-center'>
                    <span className='font-semibold mx-2 w-24'>Created At: </span>
                    <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderEmployess