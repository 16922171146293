import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { getPermission } from '../../../../common/utils';
import { Span } from '../../../../components/common/Span';
import { Button } from '../../../../components/common/Button';
import { BiCloudUpload } from 'react-icons/bi';
import UpdatePhicSettings from '../../../../components/ModuleComponents/UpdatePhicSettings';
import { FileSearchOutlined } from '@ant-design/icons';

const PHIC = () => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const permissions = getPermission('benefits', 'canViewPHICSub');

    const [add, setAdd] = useState(false);

    const data: any = useSelector((state: RootState) => state.Document.documents.phic_config?.data);

    useEffect(() => {
        dispatch.Document.getFirebaseDoc({ name: 'config', document: 'phic_config', id: 'PHIC' })
    }, [])

    return (
        <>
            <div className='px-4'>
                <div className={`flex ${mobile ? 'flex-col items-center justify-center ' : 'justify-between'}`}>
                    <span className={`text-2xl mt-4 font-bold ${mobile ? 'w-full text-center' : 'truncate'}`}>Philippine Health Insurance Configuration</span>
                    <div className={`flex ${mobile ? 'justify-center w-full mt-4' : 'justify-end px-4 my-4'}`}>
                        {permissions?.includes('canEdit') && (
                            <Button
                                padding='py-1'
                                border='blue'
                                bg='none'
                                color='blue'
                                margin='mx-2'
                                {...(!mobile && {
                                    width: 'none'
                                })}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                    onClick={() => {
                                        setAdd(true)
                                        history.push(`${pathname}?type=add`)
                                    }}
                                >
                                    <BiCloudUpload className='my-auto mx-2 text-2xl' />
                                    Update Settings
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <div className='overflow-auto h-[70vh] flex justify-center items-center flex-col'>
                {!data ? (
                    <div className='absolute left-0 right-0 p-24 flex justify-center flex-col items-center'>
                        <FileSearchOutlined style={{ fontSize: '6rem', color: 'gray' }} />
                        <Span color='lightgrey' size='2xl'>No Data</Span>
                    </div>
                ) : (
                    <>
                        <div className={`flex justify-center items-start flex-col p-4 ${mobile ? 'w-[95%]' : 'w-[60%]'}`}>
                            <span className='font-bold'>PhilHealth:</span>
                            <span className='text-justify font-semibold'>
                                {`The Philippine Health Insurance Corporation (PhilHealth) is a government-mandated social health insurance program in the Philippines. It aims to provide financial protection and access to healthcare for all Filipinos, including employees and their employers. Under the current contribution scheme, both employees and employers share the burden of funding PhilHealth, with each party contributing 2% of the employee's monthly salary. `}
                            </span>
                        </div>
                        <div className={`p-2 ${mobile ? 'w-[95%]' : 'w-[60%]'} grid grid-cols-3 gap-1`}>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center'>Employee’s Share</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center'>Employer’s Share</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center'>Total</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span align='center'>{data?.employee_share}%</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span align='center'>{data?.employer_share}%</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span align='center'>{data?.total_contribution}%</Span>
                            </div>
                        </div>
                    </>
                )}
            </div >

            <UpdatePhicSettings setAdd={setAdd} add={add} data={data} />
        </>
    )
}

export default PHIC