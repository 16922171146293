import React, { useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import './Login.css'
import { InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';

import designbg from '../../assets/images/login_design3.png'
import designbg1 from '../../assets/images/login_design.png'
import { useHistory } from "react-router-dom";
import { Dispatch } from "../../store";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md';
import { BRAND_IMAGES } from '../../components/constants';
import { Spin } from 'antd';
import { Span } from '../../components/common/Span';
import { Paper } from '@mui/material';
import { LockOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createVerifier } from '../../common/api';

export default () => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();

    const formikRef = useRef() as any;
    const [error, setError] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        userEmailPhone: '',
        password: '',
        confirmPassword: '',
    };

    const validateSchema = Yup.object().shape({
        userEmailPhone: Yup.lazy(() =>
            Yup.string()
                .when('userEmailPhone', {
                    is: (userEmailPhone) => isNaN(userEmailPhone),
                    then: Yup.string().email('Should be valid email example(youremail@domain.com)').required('Email or Phone number is required field'),
                    otherwise: Yup.string().min(10, 'Must be a valid contact number').max(10, 'Must be a valid contact number')
                })
        ),
        password: Yup.lazy(() =>
            Yup.string()
                .when('password', {
                    is: () => isNaN(formikRef?.current?.values?.userEmailPhone),
                    then: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
                    otherwise: Yup.string().optional()
                })
        )
    });

    const handleLogin = async (values, actions) => {
        try {
            setIsLoading(true);
            dispatch.UI.resetAlert();

            const type = isNaN(values?.userEmailPhone) ? 'email-password' : 'phone-number';
            // let appVerifier;

            // if (type === 'phone-number') {
            //     appVerifier = await createVerifier();
            // }

            const payload = {
                userEmailPhone: type === 'phone-number' ? `+63${values?.userEmailPhone}` : values?.userEmailPhone,
                password: type === 'phone-number' ? '' : values?.password,
                type
            }

            const res: any = await dispatch.User.login(payload);
            console.log(res);
            switch (res?.result) {
                case 'success':
                    setIsLoading(false);
                    break;
                case 'notVerified':
                    setIsLoading(false);
                    history.push(`/station?type=${res?.type}&show=true`);
                    break;
                case 'phoneVerification':
                    history.push(`/station?type=${res?.type}&show=true&phone=${payload?.userEmailPhone}`);
                    break;
                default:
                    break;
            }
        } catch (err: any) {
            setError(true)
            setIsLoading(false);
        }
    }

    const handleEnter = (e: any) => {
        const ASCIICode = (e.which) ? e.which : e.keyCode;
        if (ASCIICode === 13) {
            const values = formikRef?.current?.values;
            const actions = formikRef?.current?.actions;
            handleLogin(values, actions);
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleLogin(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue }: any) => (
                <div className='min-h-screen overflow-hidden flex items-center justify-center'>
                    <img src={designbg} alt="" className='absolute top-0 right-0' />
                    <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

                    <Paper
                        elevation={3}
                        sx={{
                            width: { xs: '90vw', sm: '460px' },
                            height: '500px',
                            padding: { xs: '10px 20px', sm: '30px 50px' },
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: '1'
                        }}
                    >
                        <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

                        <div className='flex flex-col justify-start items-start'>
                            <Span letters='wider' size='2xl' weight='bold'>Log in</Span>
                            <span className='mt-1 text-[13px] font-[500]'>Welcome back to Holo 🙋</span>
                        </div>

                        {error && (
                            <div className='text-center mt-4 w-full p-2 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                                <span className='text-xs text-red-500'>
                                    {isNaN(values?.userEmailPhone) ? 'Email or password is incorrect!' : 'Invalid Phone number!'}
                                </span>
                            </div>
                        )}

                        <div id='pin-container' className='w-full' />

                        <InputContainer margin='mt-4' border='grey' flex='row'>
                            {!isNaN(values.userEmailPhone) && values?.userEmailPhone !== '' && (
                                <div className='absolute h-full flex justify-center items-center left-[26px]'>
                                    <Span>(+63)</Span>
                                </div>
                            )}
                            {isNaN(values.userEmailPhone) || values?.userEmailPhone === '' ? (
                                <UserOutlined className='ml-2' />
                            ) : (
                                <PhoneOutlined className='ml-2' />
                            )}
                            <Input
                                {...(!isNaN(values?.userEmailPhone) && values?.userEmailPhone !== '' && { padding: 'pl-10', maxLength: 10 })}
                                disabled={isLoading}
                                value={values?.userEmailPhone}
                                onChange={handleChange('userEmailPhone')}
                                onBlur={handleBlur('userEmailPhone')}
                                placeholder='Email or Phone number'
                                border='none'
                                onKeyDown={handleEnter}
                            />
                        </InputContainer>
                        {errors.userEmailPhone && touched.userEmailPhone && <Span color='red' size='xs'>{errors.userEmailPhone}</Span>}

                        {!isNaN(values.userEmailPhone) && values?.userEmailPhone !== '' ? (
                            <></>
                        ) : (
                            <>
                                <InputContainer margin='mt-4' border='grey' flex='row'>
                                    <LockOutlined className='ml-2' />
                                    <Input
                                        disabled={isLoading}
                                        value={values?.password}
                                        onChange={handleChange('password')}
                                        type={isVisible ? 'text' : 'password'}
                                        placeholder='Password'
                                        onBlur={handleBlur('password')}
                                        border='none'
                                        onKeyDown={handleEnter}
                                    />
                                    {!isVisible ? (
                                        <MdOutlineVisibilityOff
                                            className='w-4 h-4 mr-2 cursor-pointer'
                                            onClick={() => setIsVisible(true)}
                                        />
                                    ) : (
                                        <MdOutlineVisibility
                                            className='w-4 h-4 mr-2 cursor-pointer'
                                            onClick={() => setIsVisible(false)}
                                        />
                                    )}
                                </InputContainer>
                                {errors.password && touched.password && <Span color='red' size='xs'>{errors.password}</Span>}
                            </>
                        )}

                        <div className='w-full flex justify-center items-center mt-2'>
                            <span
                                className='text-center font-[500] hover:underline hover:text-blue-3 cursor-pointer'
                                onClick={() => history.push('/forgot-password')}
                            >
                                Forgot Password
                            </span>
                        </div>

                        <Button
                            type='submit'
                            shadow='grey'
                            padding='p-10'
                            margin='mt-2'
                            width='full'
                            disabled={isLoading}
                            onClick={() => handleSubmit()}
                        >
                            {isLoading ? (
                                <Spin className='login_spin' size='small' />
                            ) : 'Log in'}
                        </Button>

                        <div className='mt-8 w-full flex items-start justify-center flex-row space-x-1'>
                            <span className=' font-[500]'>Don't have an account?</span>
                            <span
                                className='font-[500] hover:underline hover:text-blue-3 cursor-pointer'
                                onClick={() => history.push('/signup')}
                            >
                                Sign up
                            </span>
                        </div>
                    </Paper>
                </div>
            )}
        </Formik>
    )
}