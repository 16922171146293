import React, { useState } from 'react'
import { Span } from '../../../../components/common/Span';

const RenderEmployees = ({ employees }) => {

    const [show, setShow] = useState(false);

    return (
        <div className="flex flex-col justify-start items-start w-52">
            <div className='max-h-40 overflow-auto'>
                {employees?.filter((d, i) => show ? i < employees?.length : i < 3)?.map((emp: any, index: any) => (
                    <div key={index} className='w-full border-b border-gray-300 border-solid flex flex-row justify-start items-center'>
                        <span className='w-6'>{index + 1}:</span>
                        <Span>{emp?.name}</Span>
                    </div>
                ))}
            </div>

            {employees?.length > 3 && (
                <div className='pt-2 flex items-center justify-center w-full'>
                    <div
                        className='px-1 rounded-md border border-gray-300 border-solid cursor-pointer'
                        onClick={() => setShow(!show)}
                    >
                        <span className='text-[10px] font-bold tracking-wide'>{show ? 'HIDE' : 'SHOW MORE'}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RenderEmployees