import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Drawer } from 'antd';
import { updateHdmfSettings } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import HdmfSettingsForm from './Forms/HdmfSettingsForm/HdmfSettingsForm';
import { convertArrayOfObjectIntoNumbers } from '../../common/utils';

type Props = {
    setAdd: Function,
    add: boolean,
    data: any
}

type State = {
    ranges: any,
    sealing_amount: any,
    sealing_eeshare_amount: any,
    sealing_ershare_amount: any
}

const UpdateHdmfSettings = ({ setAdd, add, data }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const defaultValue = {
        hdmf_range_from: '',
        hdmf_range_to: '',
        employee_share: '',
        employer_share: '',
    }

    const initialState = {
        ranges: data?.ranges ?? [
            defaultValue
        ],
        sealing_amount: data?.sealing_amount ?? '',
        sealing_eeshare_amount: data?.sealing_eeshare_amount ?? '',
        sealing_ershare_amount: data?.sealing_ershare_amount ?? ''
    } as State;

    const validateSchema = yup.object().shape({
        ranges: yup.array().of(
            yup.object().shape({
                hdmf_range_from: yup.number().required('Range From is required field'),
                hdmf_range_to: yup.number().required('Range To is required field'),
                employee_share: yup.number().required('Emplyee Share is required field'),
                employer_share: yup.number().required('Employer share is required field'),
            })
        ),
        sealing_amount: yup.number().required('Sealing Amount is required field'),
        sealing_eeshare_amount: yup.number().required('Employee Share Amount is required field'),
        sealing_ershare_amount: yup.number().required('Employer Share Amount is required field')
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleUpdate = async (values, actions) => {
        try {

            const ranges = convertArrayOfObjectIntoNumbers(values?.ranges);

            const payload = {
                ...values,
                ranges: ranges,
                sealing_amount: Number(values?.sealing_amount),
                sealing_eeshare_amount: Number(values?.sealing_eeshare_amount),
                sealing_ershare_amount: Number(values?.sealing_ershare_amount),
                sealing_total_amount: Number(values?.sealing_eeshare_amount) + Number(values?.sealing_ershare_amount)
            }

            const res = await updateHdmfSettings(payload);

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' });
                dispatch.Document.getFirebaseDoc({ name: 'config', document: 'hdmf_config', id: 'HDMF' });
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to updated'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleUpdate(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Settings"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Update Settings'
                            />
                        }
                    >
                        {add && <HdmfSettingsForm {...props} errMesssage={errMesssage} defaultValue={defaultValue} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateHdmfSettings;