import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { updateAdminAccount, updateEmployeeAccount } from '../../common/api';
import { Drawer } from 'antd';
import moment from 'moment';
import UserForm from './Forms/UserForm';
import { useHistory } from 'react-router-dom';
import DrawerFooter from './Footer/DrawerFooter';
import { useMediaQuery } from '@mui/material';

type Props = {
    table: string,
}

type State = {
    email: string,
    name: string,
    password: string,
    bday: string,
    phoneNumber: string,
    employeeNumber: string,
    position: string,
    department: string,
    group: string,
    organization: object,
    role: object | null
}

const UpdateEmployee = ({ table }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const user = useSelector((state: RootState) => state.Table.tables[table]?.data).find(d => d.id === currentUpdateId);
    const roleModules = useSelector((state: RootState) => state.User.roleModules);

    const isAuthorizedToUpdateRole = roleModules.includes('role');

    const initialState = {
        email: user?.email ?? '',
        name: user?.name ?? '',
        password: '',
        bday: moment(user?.bday).format('YYYY-MM-DD') ?? '',
        phoneNumber: user?.phoneNumber.replace('+63', '') ?? '',
        employeeNumber: user?.employeeNumber ?? '',
        sickLeave: String(user?.sickLeave) ?? '',
        vacationLeave: String(user?.vacationLeave) ?? '',
        position: user?.position ?? '',
        department: user?.department ?? '',
        group: user?.group ?? '',
        organization: user?.organization ?? null,
        role: user?.role ?? null
    } as State;

    const validateSchema = yup.object({
        email: yup.string().email('Should be valid email example(youremail@domain.com)').required('Email is required field'),
        name: yup.string().required('Name is required field'),
        bday: yup.string().required('Birth day is required field'),
        password: yup.string().min(8, 'Password must be at least 8 characters'),
        phoneNumber: yup.string().required('Phone number is required field')
            .min(10, 'Phone number must be at least 10 characters')
            .max(10, 'Phone number must be at most 10 characters')
            .test(
                'isNaN',
                'Number only',
                (val: any) => !isNaN(val) ? true : false
            ),
        sickLeave: yup.number().required('Sick leave is required field, enter 0 if not entitled'),
        vacationLeave: yup.number().required('Vacation leave is required field, enter 0 if not entitled'),
        employeeNumber: yup.string().required('Employee number is required field'),
        position: yup.string().required('Position is required field'),
        department: yup.string().required('Department is required field'),
        group: yup.string(),
        organization: yup.object().required('Organization is required field').nullable(),
        role: yup.object().nullable()
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
                phoneNumber: `+63${values.phoneNumber}`,
                ratePerHour: Number(values?.ratePerHour),
                sickLeave: Number(values?.sickLeave),
                vacationLeave: Number(values?.vacationLeave)
            }

            if (!isAuthorizedToUpdateRole) delete payload?.role;
            let res;

            switch (table) {
                case 'employees':
                    res = await updateEmployeeAccount(user?.id, payload);
                    break;
                case 'users':
                    res = await updateAdminAccount(user?.id, payload);
                    break
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false);
                dispatch.User.updateUserInfo(null);
                dispatch.UI.resetForm('currentUpdateId');
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Account Successfully updated' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update user'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Employee"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            props.resetForm();
                            history.goBack();
                            dispatch.UI.resetForm('currentUpdateId')
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText='Update Employee'
                            />
                        }
                    >
                        {currentUpdateId && <UserForm {...props} user={user} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateEmployee;