import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Drawer } from 'antd';
import { addHoliday } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import HolidayForm from './Forms/HolidayForm';
import moment from 'moment';

type Props = {
    table: string,
    setAdd: Function,
    add: boolean
}

type State = {
    name: string,
    holidayType: string | any,
    date: any
}

const AddHoliday = ({ table, setAdd, add }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const initialState = {
        name: '',
        holidayType: '',
        date: ''
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Name is required field'),
        holidayType: yup.string().required('Holiday type is required field'),
        date: yup.string().required('Date is required field')
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
                date: new Date(values?.date),
                date_string: moment(values.date).format('YYYY-MM-DD')
            }

            let res = await addHoliday(payload);

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully created' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to create holiday'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Add Holiday"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Add Holiday'
                            />
                        }
                    >
                        {add && <HolidayForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddHoliday;