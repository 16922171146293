import { CalendarOutlined, ClockCircleOutlined, CoffeeOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { firebaseDateToText } from "../../common/utils";
import { Span } from "../../components/common/Span";
import { ImLocation2 } from 'react-icons/im'
import { AiOutlineInbox } from "react-icons/ai";
import { AVATAR } from "../../components/constants";
import { store } from "../../store";

export const COLUMNS = [
  {
    id: "id",
    field: "id",
    Header: "ID",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <Span>{d.id}</Span>
    }
  },
  {
    id: "employee",
    field: "employee",
    Header: "Employee Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <Span transform='capitalize'>{d.userInfo?.name}</Span>
    }
  },
  {
    id: "employee_email",
    field: "employee_email",
    Header: "Employee Email",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <Span>{d.userInfo?.email}</Span>
    }
  },
  {
    id: "type",
    field: "type",
    Header: "Type",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <Span transform='capitalize'>{d?.type ? d?.type?.replace('_', ' ') : 'Regular'}</Span>
    }
  },
  {
    id: "clockIn",
    field: "clockIn",
    Header: "Time In",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const dateText = firebaseDateToText(d.clockIn?.time, 'LLL')
      const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/timesheet%2F${d.clockIn.image ?? ''}?alt=media&token=434f8e39-f79e-444b-881f-035cc434334b`
      const isShowCoordinates = !!store.getState().User.objectOfRoleModules?.timesheet?.canReadCoordinates;

      return (
        <div className="flex justify-around items-center flex-row">
          <a target="_blank" href={source} className="mr-5">
            <Avatar src={d?.type && !d.clockIn.image ? AVATAR[d?.type] : source} style={{ color: '#f56a00', backgroundColor: '#fde3cf', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>IN</Avatar>
          </a>
          <div className="w-[200px]">
            <Span><CalendarOutlined /> {dateText} </Span>
            {isShowCoordinates && (
              <div className="flex flex-row justify-start items-center">
                <ImLocation2 />
                <a target={"_blank"} className='truncate' href={`https://www.google.com/maps/place/${d.clockIn.location?.latitude},${d.clockIn.location?.longitude}`}>{`${d.clockIn.location?.latitude ?? ''},${d.clockIn.location?.longitude ?? ''}`}</a>
              </div>
            )}
          </div>
        </div>
      )
    }
  },
  {
    id: "clockOut",
    field: "clockOut",
    Header: "Time Out",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const dateText = firebaseDateToText(d.clockOut?.time, 'LLL')
      const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/timesheet%2F${d.clockOut?.image ?? ''}?alt=media&token=434f8e39-f79e-444b-881f-035cc434334b`
      const hasData = d?.clockOut?.image || d.clockOut?.location?.latitude || d.clockOut?.location?.longitude || dateText;
      const isShowCoordinates = !!store.getState().User.objectOfRoleModules?.timesheet?.canReadCoordinates;

      return (
        <>
          {hasData ? (
            <div className="flex justify-around items-center flex-row">
              <a target="_blank" href={source} className="mr-5">
                <Avatar src={d?.type && !d.clockOut?.image ? AVATAR[d?.type] : source} style={{ color: '#f56a00', backgroundColor: '#fde3cf', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>OUT</Avatar>
              </a>
              <div className="w-[200px]">
                <Span><CalendarOutlined /> {dateText} </Span>
                {isShowCoordinates && (
                  <div className="flex flex-row justify-start items-center">
                    <ImLocation2 />
                    <a target={"_blank"} className='truncate' href={`https://www.google.com/maps/place/${d.clockOut?.location?.latitude ?? ''},${d.clockOut?.location?.longitude ?? ''}`}>{`${d.clockOut?.location?.latitude ?? ''},${d.clockOut?.location?.longitude ?? ''}`}</a>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "workHours",
    field: "workHours",
    Header: "Work Hours",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const workHours = d.workHours
      const workBreakTotal = d.workBreakTotal;
      const hasData = workHours || workBreakTotal;
      return (
        <>
          {hasData ? (
            <div className="flex justify-around flex-row">
              <div className="w-[200px]">
                <Span><ClockCircleOutlined /> {workHours} </Span>
                <div className="flex flex-row justify-start items-center">
                  <Span><CoffeeOutlined /> {workBreakTotal} </Span>
                </div>
              </div>
            </div>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
];
