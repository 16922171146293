import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat, firebaseDateToText, removeSCAndCapitalize } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'
import { AiOutlineInbox } from 'react-icons/ai'

const RenderCalendar = ({ item, dataType }) => {

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.holidayType?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{removeSCAndCapitalize(item?.holidayType, '_')}</Span>
                    </div>
                </div>

                <Options data={item} table={dataType} />
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Holiday: </span>
                    <Span transform='capitalize'>{removeSCAndCapitalize(item?.name, '_')}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Date: </span>
                    <Span transform='capitalize'>{firebaseDateToText(item?.date, 'LL')}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Created At: </span>
                    <Span>{firebaseDateToText(item.createdAt, 'LLL')}</Span>
                </div>

            </div>
        </Card>
    )
}

export default RenderCalendar