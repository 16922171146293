import { SpanContainer, Span } from '../common/Span';
import { useTable, useFilters, useSortBy, usePagination, useRowSelect } from 'react-table'
import { useHistory, useLocation } from 'react-router-dom'

import { BiSortDown, BiSortUp } from 'react-icons/bi'
import { ColumnSelectFilter } from "./selectFilter";
import './Table.css'
import { useCallback, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

const Table = ({ columns, data, dataType, sortName, desc = true, isSelected = null, isSelection = false, isRowClick = false, handleRowClick, height = '65vh', isLastColSticky = false, isShowCount = false }: any) => {
    const dispatch = useDispatch<RootState>();
    const location = useLocation();
    const pathname = location.pathname;
    const currentModule = pathname?.split('/')[1];

    const selectedData = useSelector((state: RootState) => state.UI?.selectedData);

    const [isloading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false)
    }, [data]);

    const tableInstance = useTable({
        columns,
        data,
        autoResetSelectedRows: false,
        autoResetPage: false,
        autoResetFilters: false,
        defaultColumn: { Filter: ColumnSelectFilter },
        initialState: {
            sortBy: [
                {
                    id: sortName,
                    desc: desc
                }
            ],
            // selectedRowIds: {
            //     7: true
            // }
        },

    },
        useFilters, useSortBy, usePagination,
        useRowSelect,
        hooks => {
            if (isSelection) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        )
                    },
                    ...columns
                ]);
            }
        }
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageOptions,
        state,
        prepareRow,
        gotoPage,
        setPageSize,
        previousPage,
        nextPage,
        selectedFlatRows
    } = tableInstance
    const { pageIndex, pageSize } = state
    const page_index = pageIndex;
    const page_count = pageOptions.length;

    const updateSelectedData = useCallback(() => {
        if (isSelection && selectedData?.length !== selectedFlatRows?.length) {
            const selected = selectedFlatRows?.map((row) => row?.original);
            dispatch.UI.updateState({ selectedData: selected })
        }
    }, [selectedFlatRows]);

    useEffect(() => {
        if (page_index > page_count) {
            gotoPage(0)
        }
    }, [page_count]);

    useEffect(() => {
        updateSelectedData();
    }, [updateSelectedData])

    return (
        <>
            <div style={{ overflow: 'auto', height: height, minHeight: '40vh' }}>
                <table {...getTableProps()} border='0' cellSpacing='0' cellPadding='0' className='w-full relative'>

                    <thead>
                        {data?.length === 0 ? (
                            <tr>
                                <th></th>
                            </tr>
                        ) : (
                            <>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {isShowCount && (
                                            <th>
                                                <div className='flex font-semibold px-3.5 py-4 fil whitespace-nowrap'>
                                                    NO.
                                                </div>
                                            </th>
                                        )}
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <div className='flex font-semibold px-3.5 py-4 fil whitespace-nowrap'>
                                                    {column.render('Header')}
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <BiSortDown className='my-auto text-lg mx-2' />
                                                            : <BiSortUp className='my-auto text-lg mx-2' />
                                                        : ''}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </>
                        )}

                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {data?.length === 0 ? (
                            <div className='absolute left-0 right-0 p-24 flex justify-center flex-col items-center'>
                                <FileSearchOutlined style={{ fontSize: '6rem', color: 'gray' }} />
                                <Span color='lightgrey' size='2xl'>No Data</Span>
                            </div>
                        ) : (
                            isloading ? (
                                <>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} className="cursor-pointer">
                                                {row.cells.map((cell) => {
                                                    return <td {...cell.getCellProps()} className='text-start text-sm p-4'>
                                                        <Skeleton paragraph={false} />
                                                    </td>;
                                                })}
                                            </tr>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {page.map((row, index) => {
                                        prepareRow(row);
                                        const lastRow = row?.cells?.length - 1;
                                        const isHideDefault = currentModule === 'payroll' && row?.original?.name === 'default' && row?.original?.empCount < 1;
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                {...(isSelected && row?.original?.id === isSelected && { className: 'bg-gray-100' })}
                                                {...(isHideDefault) && { style: { display: 'none' } }}
                                            >
                                                {isShowCount && (
                                                    <td
                                                        className='text-start align-middle text-sm p-4 min-w-[150px]'
                                                    >
                                                        <Span weight='semi'>{index + 1}</Span>
                                                    </td>
                                                )}
                                                {row.cells.map((cell, index) => {
                                                    return (
                                                        <td
                                                            {...(isRowClick && index !== lastRow && { onClick: () => handleRowClick(row), style: { cursor: 'pointer' } })}
                                                            {...cell.getCellProps()}
                                                            className={`${isLastColSticky && index === lastRow ? ' bg-white w-5 sticky right-0' : 'p-4 min-w-[150px]'} text-start align-middle text-sm ${isRowClick ? 'cursor - pointer' : ''}`}
                                                        >
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </>
                            )
                        )}
                    </tbody>
                </table>
            </div >

            <div className='flex justify-end p-4 items-center'>
                <div className='flex flex-1 justify-start items-center'>
                    {selectedFlatRows?.length > 0 && (
                        <Span weight='semi'>{`${selectedFlatRows?.length} Rows Selected`}</Span>
                    )}
                </div>

                <div className='px-4'>
                    <SpanContainer color='grey'>Rows per page: </SpanContainer>
                    <select
                        className='px-1.5 text-base focus:outline-none text-grey-7'
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>

                <SpanContainer padding='px-4' color='grey'>
                    {pageIndex + 1}-{pageSize} of {pageOptions.length}
                </SpanContainer>

                <div className='flex my-auto'>
                    <SpanContainer padding='pr-2'>
                        <MdArrowBackIosNew onClick={previousPage} className='text-grey-4 cursor-pointer' />
                    </SpanContainer>
                    <SpanContainer>
                        <MdArrowForwardIos onClick={nextPage} className='text-grey-4 cursor-pointer' />
                    </SpanContainer>
                </div>
            </div>
        </>
    );
}


export default Table;

export const handleMultiSortBy = (column, setSortBy, meinSortBy) => {
    const desc =
        column.isSortedDesc === true
            ? undefined
            : column.isSortedDesc === false
                ? true
                : false;
    setSortBy([{ id: column.id, desc }, ...meinSortBy]);
};

