import { Avatar, Tag } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "email",
    field: "email",
    Header: "EMAIL",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <Span color='lightgrey'>{d?.email}</Span>
    }
  },
  {
    id: "active",
    field: "active",
    Header: "ACTIVE",
    isVisible: true,
    Cell: data => {
      return <Tag className="capitalize" color='success'>YES</Tag>
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "DATE OF REGISTRATION",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'YYYY-MM-DD')}</Span>
    }
  }

];
