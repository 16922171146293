import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { Drawer } from 'antd';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import LogForm from './Forms/LogForm';
import { addLog } from '../../common/api';
import { firebaseDateToText } from '../../common/utils';

type Props = {
    table: string,
}

type State = {
    date: string,
    timeIn: string,
    timeOut: string,
    workBreakTotal: string,
}

const UpdateLog = ({ table }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const log = useSelector((state: RootState) => state.Document.documents.current_log?.data);

    const initialState = {
        date: log?.timesheetDate ?? '',
        timeIn: firebaseDateToText(log?.clockIn?.time, 'HH:mm') ?? '',
        timeOut: '',
        workBreakTotal: log?.workBreakTotal ?? '',
    } as State;

    const validateSchema = yup.object({
        date: yup.string().required('Date is required field'),
        timeIn: yup.string().required('Clock in is required field'),
        timeOut: yup.string().required('Clock in is required field'),
        workBreakTotal: yup.string(),
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
                timeIn: `${values?.date} ${values?.timeIn}`,
                timeOut: `${values?.date} ${values?.timeOut}`,
                userId: log?.userInfo?.id
            }

            const res = await addLog(payload);

            if (res.isSuccess) {
                actions.setSubmitting(false)
                dispatch.UI.resetForm('currentUpdateId');
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' });
                dispatch.Payroll.calculateCurrentPayroll(null);
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update user'
            setErrorMesage(errMsg);
        }
    }

    useEffect(() => {
        dispatch.Document.getFirebaseDoc({ name: 'timesheet', document: 'current_log', id: currentUpdateId })
    }, [currentUpdateId])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Log"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            props.resetForm();
                            history.goBack();
                            dispatch.UI.resetForm('currentUpdateId')
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText='Update Log'
                            />
                        }
                    >
                        {currentUpdateId && <LogForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateLog;