import React, { useState } from 'react'
import { Avatar, Checkbox, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { Span } from '../../../../components/common/Span';
import { useHistory, useLocation } from 'react-router-dom';

const ItemList = ({ data, select, setSelect, contracts, setContracts }) => {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const isInContracts = contracts?.some((res) => res?.id === data?.id);

    const status = data?.isConfirmed ? 'Confirmed' : data?.payslips?.some((res) => !res.isSaved) ? 'Incomplete' : 'Can Confirm';
    const color = status === 'Confirmed' ? 'brown' : status === 'Can Confirm' ? 'blue' : 'red';

    const handleClick = (e) => {
        e.preventDefault();

        if (isInContracts) {
            const payload = contracts?.filter((c: any) => c?.id !== data?.id);
            setContracts(payload);
        } else {
            setContracts([...contracts, data]);
        }
    }

    const handleSelect = () => {
        setSelect(data);
        history.push(`${pathname}?activeKey=6&id=${data?.id}`)
    }

    return (
        <>
            <ListItem
                key={data?.id}
                secondaryAction={
                    <Checkbox
                        disabled={status === 'Incomplete'}
                        checked={isInContracts}
                        onClick={handleClick}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
                }
                disablePadding
            >
                <ListItemButton selected={select?.id === data?.id} onClick={handleSelect} alignItems='flex-start'>
                    <ListItemAvatar sx={{ minWidth: 0, paddingRight: '8px' }}>
                        <Avatar
                            sx={{ width: 24, height: 24, fontSize: '12px', bgcolor: '#FAAD14' }}
                            alt={data?.contract}
                        >
                            {data?.contract[0]?.toUpperCase()}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText id={data?.id}>
                        <Stack direction='column' justifyContent='center' alignItems='start'>
                            <Typography
                                noWrap
                                variant='body2'
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', fontWeight: '600', textTransform: 'capitalize' }}
                            >
                                {data?.contract}
                            </Typography>
                            <Typography
                                noWrap
                                variant='caption'
                                color='GrayText'
                                fontWeight='700'
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'uppercase', width: '100%' }}
                            >
                                {data?.referenceNumber}
                            </Typography>
                            <Typography
                                noWrap
                                variant='caption'
                                color='GrayText'
                                fontWeight='700'
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize', width: '100%' }}
                            >
                                {data?.payPeriod}
                            </Typography>

                            <Span transform='uppercase' size='xs' color={color} weight='semi'>{status}</Span>
                        </Stack>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <Divider variant='fullWidth' component="li" />
        </>
    )
}

export default ItemList