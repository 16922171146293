import { Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setEmployeeAsAdmin } from '../../../common/api';
import { Dispatch, RootState } from '../../../store';
import { Span } from '../../common/Span';

const SetAdmin = ({ assignAdmin, setAssignAdmin, table, data, organizationAdmin }) => {
    const dispatch = useDispatch<Dispatch>()

    const isLoading = useSelector((state: RootState) => state.UI.isLoading);

    const handleSetAsAdmin = async () => {
        try {
            dispatch.UI.setIsLoading(true);

            const res = await setEmployeeAsAdmin(data?.id, organizationAdmin);

            if (res?.isSuccess) {
                dispatch.UI.setIsLoading(false);
                setAssignAdmin(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Account Successfully updated' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to set user';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg })
        }
    }


    return (
        <Modal
            title="Set as Admin!"
            cancelButtonProps={{ loading: isLoading }}
            okButtonProps={{ loading: isLoading }}
            onOk={() => handleSetAsAdmin()}
            onCancel={() => {
                setAssignAdmin(false)
            }}
            visible={assignAdmin}
        >
            <Span>{`Are you sure, you want to set "${data?.email}" as admin?`}</Span>
        </Modal>
    )
}

export default SetAdmin