import React, { useRef, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { updateOrganization } from '../../common/api';
import { Drawer } from 'antd';
import OrgForm from './Forms/OrgForm';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import EDForm from './Forms/EDForm/EDForm';
import { removeSCAndCapitalize } from '../../common/utils';
import { ED_RESERVE_NAMES } from '../constants';

type Props = {
    table: string,
}

type State = {
    items: Array<any>;
    employees: Array<any>;
}

const UpdateED = ({ table }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')

    const formikRef = useRef() as any;

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const { deductions, earnings } = useSelector((state: RootState) => state.Payroll);

    const item: any = [...deductions, ...earnings]?.find((i: any) => i?.id === currentUpdateId);

    const defaultValue = {
        name: '',
        amount: '',
    }

    const initialState = {
        items: item?.items ?? [],
        employees: item?.employeesData ?? []
    } as State;

    const validateSchema = yup.object().shape({
        items: yup.array().of(
            yup.object().shape({
                name: yup.string()
                    .required('Name is required field')
                    .test('name', 'Name already exist', (val: any) => {
                        const values = formikRef?.current?.values?.items?.map(i => i?.name?.toLowerCase());
                        const valuesCount = values?.filter(str => str === val?.toLowerCase());
                        const res = ED_RESERVE_NAMES?.includes(val?.toLowerCase()) || valuesCount?.length > 1 ? false : true;
                        return res;
                    }),
                amount: yup.number().required('Amount is required field'),
            })
        ),
        employees: yup.array().min(1, 'Please select atleast 1 employee').required('Employees is required field'),
    })

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const timeout = setTimeout(() => {
                const payload = {
                    type: item?.type,
                    employeesId: values?.employees?.map(d => d?.id),
                    items: values?.items,
                    employeesData: values?.employees,
                    id: item?.id
                }

                dispatch.Payroll.updateED({ name: item?.type, data: payload });
                actions.setSubmitting(false)
                dispatch.UI.resetForm('currentUpdateId');
                history.goBack();
                clearTimeout(timeout);
            }, 1000)
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title={`Update ${removeSCAndCapitalize(item?.type, '_')}`}
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            props.resetForm();
                            history.goBack();
                            dispatch.UI.resetForm('currentUpdateId')
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText={`Update ${removeSCAndCapitalize(item?.type, '_')}`}
                            />
                        }
                    >
                        {currentUpdateId && <EDForm defaultValue={defaultValue} {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateED;