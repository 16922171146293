import React, { memo } from 'react'
import { GridContainer, InputContainer } from '../../../common/Containers'
import { Span } from '../../../common/Span'
import { AiOutlinePercentage } from 'react-icons/ai'
import { Input } from '../../../common/Input'
import { onlyNumberKeyDotAndNegative } from '../../../../common/utils'
import { Badge, Tag } from 'antd'

const Range = ({ values, errors, touched, index, setFieldTouched, setFieldValue }) => {

    const handleRemove = () => {
        console.log('render')
        const value = values?.ranges?.filter((d, i) => i !== index);
        setFieldValue('ranges', value);
    }

    return (
        <Badge.Ribbon text={index + 1} placement='start'>
            <Badge.Ribbon
                text={<div onClick={handleRemove}>Remove</div>}
                placement='end' color='red'
                style={{ cursor: 'pointer', display: values?.ranges?.length < 2 ? 'none' : 'flex' }}
            >
                <GridContainer border='grey' grid='two' gap='two' margin='mt-2'>
                    <div className='px-2 text-left'>
                        <div className='flex flex-row justify-start items-center mb-1'>
                            <Span>Range From</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer border='grey' padding='none' flex='row' margin='none'>
                            <Span margin='ml-2' size='base'>₱</Span>
                            <Input
                                border='none'
                                style={{ textTransform: 'capitalize' }}
                                value={values?.ranges[index]?.hdmf_range_from}
                                onChange={(e: any) => setFieldValue(`ranges.${index}.hdmf_range_from`, e.target.value)}
                                placeholder='Enter Amount'
                                onBlur={() => setFieldTouched(`ranges.${index}.hdmf_range_from`, true)}
                                onKeyPress={onlyNumberKeyDotAndNegative}
                            />
                        </InputContainer>
                        {errors?.hdmf_range_from && touched?.hdmf_range_from && <Span color='red' size='xs'>{errors?.hdmf_range_from}</Span>}
                    </div>

                    <div className='px-2 text-left'>
                        <div className='flex flex-row justify-start items-center mb-1'>
                            <Span>Range To</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer border='grey' padding='none' flex='row' margin='none'>
                            <Span margin='ml-2' size='base'>₱</Span>
                            <Input
                                border='none'
                                style={{ textTransform: 'capitalize' }}
                                value={values?.ranges[index]?.hdmf_range_to}
                                onChange={(e: any) => setFieldValue(`ranges.${index}.hdmf_range_to`, e.target.value)}
                                placeholder='Enter Amount'
                                onBlur={() => setFieldTouched(`ranges.${index}.hdmf_range_to`, true)}
                                onKeyPress={onlyNumberKeyDotAndNegative}
                            />
                        </InputContainer>
                        {errors?.hdmf_range_to && touched?.hdmf_range_to && <Span color='red' size='xs'>{errors?.hdmf_range_to}</Span>}
                    </div>

                    <div className='px-2 text-left'>
                        <div className='flex flex-row justify-start items-center mb-1'>
                            <Span>Employee's Share</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer border='grey' padding='none' flex='row' margin='none'>
                            <AiOutlinePercentage className='ml-2' />
                            <Input
                                border='none'
                                style={{ textTransform: 'capitalize' }}
                                value={values?.ranges[index]?.employee_share}
                                onChange={(e: any) => setFieldValue(`ranges.${index}.employee_share`, e.target.value)}
                                placeholder='Enter Percentage'
                                onBlur={() => setFieldTouched(`ranges.${index}.employee_share`, true)}
                                onKeyPress={onlyNumberKeyDotAndNegative}
                            />
                        </InputContainer>
                        {errors?.employee_share && touched?.employee_share && <Span color='red' size='xs'>{errors?.employee_share}</Span>}
                    </div>

                    <div className='px-2 text-left'>
                        <div className='flex flex-row justify-start items-center mb-1'>
                            <Span>Employer's Share</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer border='grey' padding='none' flex='row' margin='none'>
                            <AiOutlinePercentage className='ml-2' />
                            <Input
                                border='none'
                                style={{ textTransform: 'capitalize' }}
                                value={values?.ranges[index]?.employer_share}
                                onChange={(e: any) => setFieldValue(`ranges.${index}.employer_share`, e.target.value)}
                                placeholder='Enter Percentage'
                                onBlur={() => setFieldTouched(`ranges.${index}.employer_share`, true)}
                                onKeyPress={onlyNumberKeyDotAndNegative}
                            />
                        </InputContainer>
                        {errors?.employer_share && touched?.employer_share && <Span color='red' size='xs'>{errors?.employer_share}</Span>}
                    </div>
                </GridContainer>
            </Badge.Ribbon>
        </Badge.Ribbon>
    )
}

export default Range