import React from 'react'
import UpdateLeaveRequest from '../Modal/UpdateLeaveRequest'
import UpdateLogRequest from '../Modal/UpdateLogRequest'
import UpdateOTRequest from '../Modal/UpdateOTRequest'
import AddUpdateContract from '../Modal/AddUpdateContract'

const RenderRequestModal = ({ update, setUpdate, type, data, table }) => {
    switch (table) {
        case 'leave':
            return <UpdateLeaveRequest update={update} setUpdate={setUpdate} type={type} data={data} />
        case 'log':
            return <UpdateLogRequest update={update} setUpdate={setUpdate} type={type} data={data} />
        case 'overtime':
            return <UpdateOTRequest update={update} setUpdate={setUpdate} type={type} data={data} />
        default:
            return <></>
    }
}

export default RenderRequestModal