import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat, removeSCAndCapitalize } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'
import { AiOutlineInbox } from 'react-icons/ai'

const RenderTAX = ({ item, dataType }) => {

    const lower = item?.lower_bracket >= 1000000 ? 'Over' : currencyCommaFormat(item?.lower_bracket, 2, true);
    const upper = item?.upper_bracket >= 1000000 ? 'Over' : currencyCommaFormat(item?.upper_bracket, 2, true);

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300' />

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Salary Cycle: </span>
                    <Span transform='capitalize'>{removeSCAndCapitalize(item?.salary_cycle, '-')}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Lower Bracket: </span>
                    <Span>{lower}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Upper Bracket: </span>
                    <Span>{upper}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Prescribed: </span>
                    <Span>{currencyCommaFormat(item?.prescribed, 2, true)}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Tax %: </span>
                    <Span>{item?.tax} Percent</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderTAX