import React from 'react'
import { Span } from '../../../../components/common/Span'
import { currencyCommaFormat } from '../../../../common/utils'
import { useMediaQuery } from '@mui/material';

const Range = ({ data }) => {

    const mobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                <span className={`${mobile ? 'text-xs whitespace-nowrap' : 'text-sm'}`}>{currencyCommaFormat(data?.hdmf_range_from ?? 0, 2, true)}</span>
            </div>
            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                <span className={`${mobile ? 'text-xs whitespace-nowrap' : 'text-sm'}`}>{currencyCommaFormat(data?.hdmf_range_to ?? 0, 2, true)}</span>
            </div>
            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                <span className={`${mobile ? 'text-xs whitespace-nowrap' : 'text-sm'}`}>{data?.employee_share ?? 0}%</span>
            </div>
            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                <span className={`${mobile ? 'text-xs whitespace-nowrap' : 'text-sm'}`}>{data?.employer_share ?? 0}%</span>
            </div>
            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                <span className={`${mobile ? 'text-xs whitespace-nowrap' : 'text-sm'}`}>{Number(data?.employer_share ?? 0) + Number(data?.employee_share ?? 0)}%</span>
            </div>
        </>
    )
}

export default Range