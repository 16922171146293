import { useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { getPermission, onlyNumberKeyDotAndNegative } from '../../../../common/utils';
import { COLUMNS } from './Column';
import { Span } from '../../../../components/common/Span';
import { Button } from '../../../../components/common/Button';
import { BiCloudUpload } from 'react-icons/bi';
import Card from '../../../../components/Card/Card';
import Table from '../../../../components/Table/Table';
import { Input } from 'antd';
import UpdateSSSSettings from '../../../../components/ModuleComponents/UpdateSssSettings';
import { BsPersonFill } from 'react-icons/bs';
import { GrUserManager } from 'react-icons/gr';

const SSS = () => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const permissions = getPermission('benefits', 'canViewSSSSub');

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [open, setOpen] = useState(false);

    const data: any = useSelector((state: RootState) => state.Document.documents.sss_config?.data);

    const table_data = (data?.ranges ?? [])
        ?.filter((range) => {
            const searchVal = parseFloat(search);
            const from = range?.compensation_range_from;
            const to = range?.compensation_range_to;
            const ee = range?.employee_share;
            const er = range?.employer_share;
            const total = range?.total_contribution;
            const isInRanges = searchVal > from && searchVal < to;
            if (isNaN(searchVal)) return true;
            if (isInRanges || [ee, er, total]?.includes(searchVal)) {
                return true;
            }
            return false;
        })

    const columns = useMemo(() => COLUMNS, []);
    const { Search } = Input;

    useEffect(() => {
        dispatch.Document.getFirebaseDoc({ name: 'config', document: 'sss_config', id: 'SSS' })
    }, [])

    return (
        <>
            <div className='px-4'>
                <div className={`flex ${mobile ? 'flex-col items-center justify-center ' : 'justify-between'}`}>
                    <span className={`text-2xl mt-4 font-bold ${mobile ? 'text-center w-full' : 'truncate'}`}>Social Security System Compensation Range</span>
                    <div className={`flex ${mobile ? ' flex-col justify-center items-center w-full mt-4' : 'justify-end px-4 my-4'}`}>
                        <Search
                            className={`${mobile ? 'pb-4' : ''}`}
                            placeholder="Search any..." onChange={(e: any) => setSearch(e.target.value)}
                            style={{ width: mobile ? '100%' : 300 }}
                            onKeyPress={onlyNumberKeyDotAndNegative}
                        />
                        {permissions?.includes('canEdit') && (
                            <Button
                                padding='py-1'
                                border='blue'
                                bg='none'
                                color='blue'
                                margin='mx-2'
                                {...(!mobile && {
                                    width: 'none'
                                })}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                    onClick={() => {
                                        setAdd(true)
                                        history.push(`${pathname}?type=add`)
                                    }}
                                >
                                    <BiCloudUpload className='my-auto mx-2 text-2xl' />
                                    Update Settings
                                </div>
                            </Button>
                        )}
                    </div>
                </div>

                <div className={`flex ${mobile ? 'flex-row items-center justify-between my-2' : 'justify-start items-center'}`}>
                    <div className='flex flex-row justify-start items-center pr-2'>
                        <BsPersonFill />
                        <Span weight='semi' wrap='singleLine'> Employee's Share: {data?.employee_share}%</Span>
                    </div>
                    <div className='flex flex-row justify-start items-center pr-2'>
                        <GrUserManager />
                        <Span weight='semi' wrap='singleLine'>Employer's Share: {data?.employer_share}%</Span>
                    </div>
                </div>
            </div>


            <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                {mobile ? (
                    <Card data={table_data} dataType='sss' sortName='createdAt' />
                ) : (
                    <Table columns={columns} data={table_data} desc={false} dataType='sss' sortName='compensation_range_from' />
                )}
            </div>

            <UpdateSSSSettings setAdd={setAdd} add={add} data={data} />
        </>
    )
}

export default SSS