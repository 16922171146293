import Attachment from '../containers/Authentication/ForgotPassword';

const ForgotPassword = () => {
    return (
        <>
            <Attachment />
        </>
    )
}

export default ForgotPassword
