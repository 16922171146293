import React, { useEffect, useState } from 'react'
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store'
import { Avatar, Badge, DatePicker, Tabs } from 'antd'
import { Span } from '../../components/common/Span'
import { RiGovernmentLine } from 'react-icons/ri'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { BsCardChecklist } from 'react-icons/bs';
import PayrollList from './PayrollList/PayrollList'
import { BiChevronDown, BiCoinStack } from 'react-icons/bi'
import { GiPayMoney } from 'react-icons/gi';
import EarnAndDed from './EarnAndDed/EarnAndDed'
import { useHistory, useLocation } from 'react-router-dom'
import { getPermission, getSalaryCycleRange, useQuery } from '../../common/utils'
import Payslip from './Pasylip/Payslip'
import WithoutClockout from './WithoutClockout/WithoutClockout'
import Contract from './Contract/Contract'
import moment from 'moment';
import { Menu, MenuItem, useMediaQuery } from '@mui/material'
import { Button } from '../../components/common/Button'
import { MoreOutlined } from '@ant-design/icons'
import { FaFileContract } from 'react-icons/fa'
import { AiOutlineInbox } from 'react-icons/ai'
import Reports from './Reports/Reports'

const Payroll = () => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const query = useQuery();
    const mobile = useMediaQuery('(max-width:600px)');
    const permissions = getPermission('payroll');

    const qKey: any = query.get('activeKey');
    const qId = query.get('id');

    const {
        isDefaultSalaryRange,
        payroll_list_count,
        without_clockout_count,
        deductions,
        earnings,
        date,
        salaryRange,
        contract,
        reports
    }: any = useSelector((state: RootState) => state.Payroll);
    const contracts = useSelector((state: RootState) => state.Table.tables.contracts?.data);
    const { isLoading } = useSelector((state: RootState) => state.UI);
    const calcLoading = useSelector((state: RootState) => state.UI.isLoading);

    const { from, to } = salaryRange;
    const { RangePicker } = DatePicker;

    const [loaded, setLoaded] = useState(false);
    const [activeKey, setActiveKey] = useState(qKey ?? '1');
    const [isOpen, setIsOpen] = useState(false);
    const [fromTo, setFromTo] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderExtraAction = () => {
        return (
            <div className={`w-full h-full ${mobile ? ' overflow-auto min-w-[100px]' : 'space-x-2'} flex justify-center items-center`}>
                <RangePicker
                    style={{
                        width: mobile ? '50' : '250px',
                    }}
                    allowClear
                    onChange={handleChange}
                    value={[moment(date[0]), moment(date[1])]}
                    onOpenChange={(v) => setIsOpen(v)}
                    disabled={!contract}
                />

                {mobile ? (
                    <div onClick={handleClick} className='cursor-pointer'>
                        <MoreOutlined className='text-xl' />
                    </div>
                ) : (
                    <Button
                        align='left'
                        width='none'
                        weight='semi'
                        padding='p-2'
                        onClick={handleClick}
                        disabled={!contract}
                        style={{ cursor: !contract ? 'not-allowed' : 'pointer' }}
                    >
                        <div className='flex flex-row justify-center items-center'>
                            <Span margin='mx-2' color='white' weight='semi' transform='capitalize'>{contract?.name ?? 'None'}</Span>
                            <BiChevronDown className='text-lg' />
                        </div>
                    </Button>
                )}
            </div>
        )
    }

    const handleChangetab = (key) => {
        setActiveKey(key);
        history.push(`${pathname}?activeKey=${key}`);
    }

    const handleChange = (date, dateString) => {
        setFromTo(dateString);
    }

    useEffect(() => {
        if (!isOpen && fromTo?.length && (from !== fromTo[0] || to !== fromTo[1])) {
            dispatch.Payroll.updateState({ date: fromTo, salaryRange: { from: fromTo[0], to: fromTo[1] }, deductions: [], earnings: [] })
        }
    }, [isOpen]);

    useEffect(() => {
        if (qId) {
            setActiveKey(qKey);
        }
    }, [qId, qKey]);

    // useEffect(() => {
    //     if (contract?.salary_cycle && isDefaultSalaryRange) {
    //         const salaryRange = getSalaryCycleRange(contract?.salary_cycle);
    //         dispatch.Payroll.updateState({ salaryRange: salaryRange, date: [salaryRange?.from, salaryRange?.to], isDefaultSalaryRange: false });
    //         dispatch.Payroll.calculateCurrentPayroll(null);
    //     }
    // }, [contract]);

    useEffect(() => {
        // if (!calcLoading && contract) dispatch.Payroll.calculateCurrentPayroll(null);
        if (!calcLoading && contract && loaded) {
            const salaryRange = getSalaryCycleRange(contract?.salary_cycle, from);
            dispatch.Payroll.updateState({ salaryRange: salaryRange, date: [salaryRange?.from, salaryRange?.to], isDefaultSalaryRange: false });
            dispatch.Payroll.calculateCurrentPayroll(null);
        }
    }, [date, deductions, earnings, contract]);

    useEffect(() => {
        history.push(`${pathname}?activeKey=${qKey ?? '1'}`);
        dispatch.Table.getFirebaseData({ table: 'organization', name: 'organization' });
        setLoaded(true);
    }, []);

    return (
        <div className='relative'>
            <Loader isLoading={isLoading} />
            <Container border='grey' padding='p-2'>
                <Tabs
                    activeKey={activeKey}
                    onChange={handleChangetab}
                    tabBarExtraContent={{
                        right: renderExtraAction()
                    }}
                >
                    {permissions?.includes('canViewContract') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <FaFileContract className='mr-2' />
                                    <span className='mr-1 font-semibold'>CONTRACT</span>
                                    <Avatar size={15} style={{ backgroundColor: activeKey === '1' ? '#1890FF' : 'gray' }}>{contracts?.length}</Avatar>
                                </div>
                            }
                            key="1"
                        >
                            <Contract activeKey={activeKey} />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewPayrollList') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <BsCardChecklist className='mr-2' />
                                    <span className='mr-1 font-semibold'>
                                        PAYROLL LIST
                                    </span>
                                    <Avatar size={15} style={{ backgroundColor: activeKey === '2' ? '#1890FF' : 'gray' }}>{payroll_list_count}</Avatar>
                                </div>
                            }
                            key="2"
                            disabled={!contract}
                        >
                            <PayrollList />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewE&D') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <BiCoinStack className='mr-2' />
                                    <span className='mr-1 font-semibold'>EARNINGS & DEDUCTIONS</span>
                                    <Avatar size={15} style={{ backgroundColor: activeKey === '3' ? '#1890FF' : 'gray' }}>{deductions?.length + earnings?.length}</Avatar>
                                </div>
                            }
                            key="3"
                            disabled={!contract}
                        >
                            <EarnAndDed activeKey={activeKey} />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewPayslip') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <GiPayMoney className='mr-2' />
                                    <span className='mr-1 font-semibold'>PAYSLIP</span>
                                    <Avatar size={15} style={{ backgroundColor: activeKey === '4' ? '#1890FF' : 'gray' }}>{payroll_list_count}</Avatar>
                                </div>
                            }
                            key="4"
                            disabled={!contract}
                        >
                            <Payslip activeKey={activeKey} />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewLogs') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <RiGovernmentLine className='mr-2' />
                                    <span className='mr-1 font-semibold'>LOGS WITHOUT CLOCKOUT</span>
                                    <Avatar size={15} style={{ backgroundColor: activeKey === '5' ? '#1890FF' : 'gray' }}>{without_clockout_count}</Avatar>
                                </div>
                            }
                            key="5"
                            disabled={!contract}
                        >
                            <WithoutClockout activeKey={activeKey} />
                        </Tabs.TabPane>
                    )}
                    {permissions?.includes('canViewReport') && (
                        <Tabs.TabPane
                            tab={
                                <div className='flex flex-row items-center justify-start'>
                                    <HiOutlineDocumentReport className='mr-2' />
                                    <span className='mr-1 font-semibold'>REPORTS</span>
                                    <Avatar size={15} style={{ backgroundColor: activeKey === '6' ? '#1890FF' : 'gray' }}>{reports?.length}</Avatar>
                                </div>
                            }
                            key="6"
                            disabled={!contract}
                        >
                            <Reports activeKey={activeKey} />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </Container>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {contracts?.length < 2 && (
                    <MenuItem>
                        <AiOutlineInbox className="w-4 h-4 mr-1" />
                        <Span weight='semi' transform='capitalize' size='xs'>No Options</Span>
                    </MenuItem>
                )}

                {contracts?.filter((c) => c?.id !== contract?.id)?.map((c, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleClose();
                            dispatch.Payroll.updateState({ contract: c })
                        }}
                    >
                        <div className="w-8 h-8 mr-2">
                            <Avatar src={c.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{c.name?.charAt(0).toUpperCase()}</Avatar>
                        </div>
                        <Span weight='semi' transform='capitalize'>{c?.name}</Span>
                    </MenuItem>
                ))}
            </Menu>
        </div >
    )
}

export default Payroll