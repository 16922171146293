import { Span } from '../../common/Span';
import { Input } from '../../common/Input';
import { InputContainer } from '../../common/Containers';
import { Select } from '../../common/Select';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    errors: any,
    touched: any,
    errMesssage: string,
}

const HolidayForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldValue }: Props) => {

    return (
        <div className='px-2 text-left'>
            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Name</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.name}
                onChange={handleChange('name')}
                placeholder='Enter name'
                onBlur={handleBlur('name')}
            />
            {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Holiday Type</Span>
                <Span color='red'>*</Span>
            </div>
            <InputContainer border='grey' margin='none'>
                <Select
                    border='none'
                    margin='mr-3'
                    value={values?.holidayType === '' ? null : values?.holidayType}
                    onChange={(e: any) => setFieldValue('holidayType', e?.target?.value)}
                >
                    <option selected disabled>Choose holiday type...</option>
                    <option value='regular_holiday'>Regular Holiday</option>
                    <option value='special_non_working_holiday'>Special Non Working Holiday</option>
                </Select>
            </InputContainer>
            {errors.holidayType && touched.holidayType && <Span color='red' size='xs'>{errors.holidayType}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Date</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                type='date'
                value={values.date}
                onChange={handleChange('date')}
                placeholder='Choose date...'
                onBlur={handleBlur('date')}
            />
            {errors.date && touched.date && <Span color='red' size='xs'>{errors.date}</Span>}

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default HolidayForm