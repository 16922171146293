import { Span } from '../../../common/Span';
import { Button } from '../../../common/Button';
import { PlusOutlined } from '@ant-design/icons';
import Range from './Range';
import { Container, GridContainer, InputContainer } from '../../../common/Containers';
import { AiOutlinePercentage } from 'react-icons/ai';
import { Input } from '../../../common/Input';
import { currencyCommaFormat, onlyNumberKeyDotAndNegative } from '../../../../common/utils';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    setFieldTouched: Function,
    defaultValue: any,
    errors: any,
    touched: any,
    errMesssage: string,
}

const HdmfSettingsForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldValue, setFieldTouched, defaultValue }: Props) => {

    const handleAddRange = () => {
        setFieldValue('ranges', [...values?.ranges, defaultValue]);
    }

    return (
        <div className='px-2 text-left'>
            {values?.ranges?.map((data, index) => (
                <Range
                    key={index}
                    errors={errors?.ranges ? errors?.ranges[index] : {}}
                    touched={touched?.ranges ? touched?.ranges[index] : {}}
                    index={index}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    values={values}
                />
            ))}

            <div className='flex w-full justify-end items-center py-4'>
                <Button
                    padding='px-8'
                    width='none'
                    onClick={handleAddRange}
                >
                    <div className='flex w-full justify-center items-center'>
                        <span className='pr-2 cursor-pointer'>
                            Add Range
                        </span>
                        <PlusOutlined style={{ color: 'white' }} />
                    </div>
                </Button>
            </div>

            <div className='flex justify-center items-start flex-col p-1'>
                <span className='font-bold'>Sealing Result:</span>
                <span className='text-justify'>
                    {`Regardless of employee monthly salary and how much it will increase in the future as long as it’s equal to or above 
                    ${currencyCommaFormat(values?.sealing_amount ?? 0, 2, true)} employee salary deduction for monthly Pag-IBIG contribution will always be 
                    ${currencyCommaFormat(values?.sealing_eeshare_amount ?? 0, 2, true)}. Employer must remit that amount plus the employer counterpart contribution worth 
                    ${currencyCommaFormat(values?.sealing_ershare_amount ?? 0, 2, true)}. Employee total monthly savings as a Pag-IBIG member is 
                    ${currencyCommaFormat(Number(values?.sealing_ershare_amount ?? 0) + Number(values?.sealing_eeshare_amount ?? 0), 2, true)}.`}
                </span>
            </div>

            <GridContainer border='grey' margin='mt-2' grid='none'>
                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center mb-1'>
                        <Span>Sealing Amount</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <Span margin='ml-2' size='base'>₱</Span>
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values?.sealing_amount}
                            onChange={handleChange('sealing_amount')}
                            placeholder='Enter Amount'
                            onBlur={handleBlur('sealing_amount')}
                            onKeyPress={onlyNumberKeyDotAndNegative}
                        />
                    </InputContainer>
                    {errors?.sealing_amount && touched?.sealing_amount && <Span color='red' size='xs'>{errors?.sealing_amount}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center mb-1'>
                        <Span>Employee Share Amount</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <Span margin='ml-2' size='base'>₱</Span>
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values?.sealing_eeshare_amount}
                            onChange={handleChange('sealing_eeshare_amount')}
                            placeholder='Enter Amount'
                            onBlur={handleBlur('sealing_eeshare_amount')}
                            onKeyPress={onlyNumberKeyDotAndNegative}
                        />
                    </InputContainer>
                    {errors?.sealing_eeshare_amount && touched?.sealing_eeshare_amount && <Span color='red' size='xs'>{errors?.sealing_eeshare_amount}</Span>}
                </div>

                <div className='px-2 text-left'>
                    <div className='flex flex-row justify-start items-center mb-1'>
                        <Span>Employer Share Amount</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <InputContainer border='grey' padding='none' flex='row' margin='none'>
                        <Span margin='ml-2' size='base'>₱</Span>
                        <Input
                            border='none'
                            style={{ textTransform: 'capitalize' }}
                            value={values?.sealing_ershare_amount}
                            onChange={handleChange('sealing_ershare_amount')}
                            placeholder='Enter Amount'
                            onBlur={handleBlur('sealing_ershare_amount')}
                            onKeyPress={onlyNumberKeyDotAndNegative}
                        />
                    </InputContainer>
                    {errors?.sealing_ershare_amount && touched?.sealing_ershare_amount && <Span color='red' size='xs'>{errors?.sealing_ershare_amount}</Span>}
                </div>
            </GridContainer>


            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default HdmfSettingsForm