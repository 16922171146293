import React, { useDeferredValue, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { getPermission, useQuery } from '../../../common/utils';
import { Container } from '../../../components/common/Containers';
import { Span } from '../../../components/common/Span';
import { Button } from '../../../components/common/Button';
import { AiOutlinePlus } from 'react-icons/ai';
import Card from '../../../components/Card/Card';
import Table from '../../../components/Table/Table';
import { COLUMNS } from './Column/Column';
import Search from 'antd/lib/input/Search';
import AddED from '../../../components/ModuleComponents/AddED';
import UpdateED from '../../../components/ModuleComponents/UpdateED';

const EarnAndDed = ({ activeKey }) => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');
    const pathname = useLocation().pathname;
    const history = useHistory();
    const permissions = getPermission('payroll', 'canViewE&DSub')

    const [add, setAdd] = useState(false);
    const [select, setSelect] = useState(null) as any;
    const [search, setSearch] = useState('');

    const { deductions, earnings } = useSelector((state: RootState) => state.Payroll);
    const items = [...deductions, ...earnings];

    const columns = useMemo(() => COLUMNS, []);
    const deferredSearch = useDeferredValue(search);

    const data = useMemo(() => {
        const result = items?.filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

        return result;
    }, [items, deferredSearch])

    return (
        <div className='relative'>
            <Container padding='none'>
                <div className={`flex w-full ${mobile ? 'flex-col justify-center items-center px-4 pb-4' : 'justify-between items-center px-4'}`}>
                    <div>
                        <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                            <Span margin='mt-4' weight='bold' size='2xl'>Payroll</Span>
                        </div>
                    </div>

                    <div className={`flex ${mobile ? 'flex-col-reverse justify-center items-center w-full' : 'justify-start items-center'}`}>
                        <Search
                            className={`${mobile ? 'py-4 mt-2' : ''}`}
                            placeholder="Search any..."
                            onChange={(e: any) => setSearch(e.target.value)}
                            style={{ width: mobile ? '100%' : 300 }}
                        />

                        {permissions?.includes('canCreate') && (
                            <>
                                <Button
                                    padding='px-2'
                                    border='red'
                                    bg='none'
                                    color='red'
                                    radius='none'
                                    onClick={() => {
                                        setSelect('add_deductions')
                                        setAdd(true);
                                        history.push(`${pathname}?type=add`)
                                    }}
                                    {...(!mobile ? {
                                        width: 'none',
                                        margin: 'mx-2'
                                    } : {
                                        margin: 'mt-2'
                                    })}
                                >
                                    <div className='flex w-full justify-center items-center'>
                                        <Span padding='pr-2' color='red'>Add Deductions</Span>
                                        <AiOutlinePlus />
                                    </div>
                                </Button>

                                <Button
                                    padding='px-2'
                                    border='blue'
                                    bg='none'
                                    color='blue'
                                    radius='none'
                                    onClick={() => {
                                        setSelect('add_earnings')
                                        setAdd(true);
                                        history.push(`${pathname}?type=add`)
                                    }}
                                    {...(!mobile ? {
                                        width: 'none'
                                    } : {
                                        margin: 'mt-2'
                                    })}
                                >
                                    <div className='flex w-full justify-center items-center'>
                                        <Span padding='pr-2' color='blue'>Add Earnings</Span>
                                        <AiOutlinePlus />
                                    </div>
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                    {mobile ? (
                        <Card data={data} dataType='earnings_deductions' sortName='createdAt' />
                    ) : (
                        <Table columns={columns} data={data} sortName='createdAt' isLastColSticky={true} />
                    )}
                </div>

                {add && <AddED add={add} setAdd={setAdd} select={select} setSelect={setSelect} />}
                {activeKey === '3' && <UpdateED table='earnandded' />}
            </Container >
        </div >
    )
}

export default EarnAndDed