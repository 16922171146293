import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
  exact: boolean;
}

const LoginRoute: React.FC<MyRouteProps> = ({ component: Component, ...rest }) => {

  const { shouldRedirectToLogin, isUserLoggedIn, claims } = useSelector((state: RootState) => state.User);
  const path = claims?.inProgress ? '/setup' : '/home';
  
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          <Redirect to={{ pathname: path, state: { from: props.location }, }} />
        ) : (
          <>
            {shouldRedirectToLogin ? (
              <Component {...props} />
            )
              : (
                <Component {...props} />
              )}
          </>
        )
      }
    />
  );
};

export default LoginRoute;
