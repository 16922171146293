import { Paper } from '@mui/material';
import React, { useState } from 'react'
import { BRAND_IMAGES } from '../../components/constants';
import { Span } from '../../components/common/Span';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { useHistory } from 'react-router-dom';
import { verifyEmail } from '../../common/api/endpoints/client';
import { UserOutlined } from '@ant-design/icons';
import { InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Modal, Spin } from 'antd';
import designbg from '../../assets/images/login_design3.png'
import designbg1 from '../../assets/images/login_design.png'
import BackDrop from '../../components/BackDrop';
import { Button } from '../../components/common/Button';
import { changePassword } from '../../common/api';

const ForgotPassword = () => {
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();

    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const renderSuccess = () => {
        Modal.success({
            title: 'Set Password',
            content: 'Note: We have sent a password instruction to your email'
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            setLoading(true);
            setError(false);
            const res = await changePassword({ email });

            if (res?.isSuccess) {
                renderSuccess();
                setLoading(false);
                history.push('/');
            }
        } catch (err: any) {
            setLoading(false);
            setError(true);
            const errMessage = err?.response?.data?.error ?? 'Unable reset password';
            setErrMsg(errMessage);
        }
    }

    return (
        <div className='min-h-screen overflow-hidden flex items-center justify-center'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

            <Paper
                elevation={3}
                sx={{
                    width: { xs: '90vw', sm: '460px' },
                    minHeight: '500px',
                    padding: { xs: '10px 20px', sm: '30px 50px' },
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: '1'
                }}
            >
                <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

                <div className='flex flex-col justify-start items-start'>
                    <Span letters='wider' size='2xl' weight='bold'>Forgot Password</Span>
                    <span className='mt-1 text-[13px] font-[500]'>Please enter registered email 💁</span>
                </div>

                {error && (
                    <div className='text-center mt-4 w-full p-2 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                        <span className='text-xs text-red-500'>
                            {errMsg}
                        </span>
                    </div>
                )}

                <form onSubmit={handleSubmit} className='w-full'>
                    <InputContainer margin='mt-4' border='grey' flex='row'>
                        <UserOutlined className='ml-2' />
                        <Input
                            disabled={loading}
                            value={email}
                            onChange={(e: any) => {
                                setEmail(e?.target?.value)
                            }}
                            placeholder='sample@gmail.com'
                            border='none'
                            required
                            type='email'
                        />
                    </InputContainer>

                    <Button
                        shadow='grey'
                        padding='p-10'
                        margin='mt-6'
                        width='full'
                        disabled={loading}
                        type='submit'
                    >
                        {loading ? (
                            <Spin className='login_spin' size='small' />
                        ) : 'Submit'}
                    </Button>
                </form>

                <div className='mt-4 w-full flex items-start justify-center flex-row space-x-1'>
                    <span className=' font-[500]'>Already have an account?</span>
                    <span
                        className='font-[500] hover:underline hover:text-blue-3 cursor-pointer'
                        onClick={() => history.push('/')}
                    >
                        Log in
                    </span>
                </div>
            </Paper>
        </div>
    )
}

export default ForgotPassword