import { Avatar, Paper, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { Span } from '../../../components/common/Span'
import { HiUserGroup } from 'react-icons/hi'
import { MdBusinessCenter } from 'react-icons/md';
import { BsCheck2Circle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import moment from 'moment';

const YesterdayCard = () => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')

    const yesterdayTimesheet = useSelector((state: RootState) => state.Table.tables.yesterdayAllTimesheet.data);
    const yesterdayTask = useSelector((state: RootState) => state.Table.tables.yesterdayCompletedTask.data);

    useEffect(() => {
        const filter = {
            date: {
                field: 'timesheetDate',
                operation: '==',
                value: moment().subtract(1, 'day').format('YYYY-MM-DD')
            }
        }
        const start_at = moment().subtract(1, 'day').startOf('day').toDate();
        const end_at = moment().subtract(1, 'day').endOf('day').toDate();

        dispatch.Table.setMultipleFilterValue({ table: 'yesterdayAllTimesheet', filters: filter });

        dispatch.Table.getFirebaseData({ name: 'timesheet', table: 'yesterdayAllTimesheet' });
        dispatch.Table.getFirebaseData({ name: 'tasks', table: 'yesterdayCompletedTask', order_by: 'endTime', start_at, end_at });
    }, []);

    return (
        <Paper
            elevation={2}
            sx={{
                display: 'flex',
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
                width: mobile ? '100%' : '80%',
                minWidth: 300,
                height: 100,
                flexDirection: 'column'
            }}
        >
            <Span weight='semi'>YESTERDAY</Span>

            <div className='flex flex-row justify-between items-center w-full'>
                <div className='flex flex-row items-center justify-center'>
                    <Avatar
                        sx={{
                            bgcolor: '#1890ff80',
                            width: 50,
                            height: 50
                        }}
                    >
                        <MdBusinessCenter className='text-[35px]' />
                    </Avatar>

                    <div className='flex flex-col justify-center items-center ml-2'>
                        <Span weight='semi' size='3xl'>{yesterdayTimesheet?.length}</Span>
                        <Span weight='semi'>ACTIVE</Span>
                    </div>
                </div>

                <div className='flex flex-row items-center justify-center'>
                    <BsCheck2Circle className='text-[50px] text-green-500' />

                    <div className='flex flex-col justify-center items-center ml-2'>
                        <Span weight='semi' size='3xl'>{yesterdayTask?.length}</Span>
                        <Span weight='semi'>TASK</Span>
                    </div>
                </div>
            </div>
        </Paper>
    )
}

export default YesterdayCard