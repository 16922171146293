import React, { useRef, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { Drawer } from 'antd';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { removeSCAndCapitalize } from '../../common/utils';
import { ED_NAME, ED_RESERVE_NAMES } from '../constants';
import EDForm from './Forms/EDForm/EDForm';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    setAdd: Function,
    add: boolean,
    select: string,
    setSelect: Function
}

type State = {
    items: Array<any>;
    employees: Array<any>;
}

const AddED = ({ setAdd, add, select, setSelect }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');

    const formikRef = useRef() as any;

    const data_name = ED_NAME[select];

    const data = useSelector((state: RootState) => state.Payroll[data_name]);

    const defaultValue = {
        name: '',
        amount: '',
    }

    const initialState = {
        items: [defaultValue],
        employees: []
    } as State;

    const validateSchema = yup.object().shape({
        items: yup.array().of(
            yup.object().shape({
                name: yup.string()
                    .required('Name is required field')
                    .test('name', 'Name already exist', (val: any) => {
                        const values = formikRef?.current?.values?.items?.map(i => i?.name?.toLowerCase());
                        const valuesCount = values?.filter(str => str === val?.toLowerCase());
                        const res = ED_RESERVE_NAMES?.includes(val?.toLowerCase()) || valuesCount?.length > 1 ? false : true;
                        return res;
                    }),
                amount: yup.number().required('Amount is required field'),
            })
        ),
        employees: yup.array().min(1, 'Please select atleast 1 employee').required('Employees is required field'),
    })

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleAdd = async (values, actions) => {
        try {
            const timeout = setTimeout(() => {
                const payload = {
                    [data_name]: [
                        ...data,
                        {
                            type: data_name,
                            employeesId: values?.employees?.map(d => d?.id),
                            items: values?.items,
                            employeesData: values?.employees,
                            id: uuidv4()
                        }
                    ]
                }

                dispatch.Payroll.updateState(payload);
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                clearTimeout(timeout);
            }, 1000)
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleAdd(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title={`Add ${removeSCAndCapitalize(data_name, '_')}`}
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            setSelect('null');
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => {
                                    setAdd(false);
                                    setSelect('null');
                                }}
                                setErrorMesage={setErrorMesage}
                                okText={`Add ${removeSCAndCapitalize(data_name, '_')}`}
                            />
                        }
                    >
                        {add && <EDForm {...props} errMesssage={errMesssage} defaultValue={defaultValue} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddED;