import { Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setEmployeeAsAdmin } from '../../../common/api';
import { Dispatch, RootState } from '../../../store';
import { Span } from '../../common/Span';

const EmpLimitExceed = ({ isLimit, setIsLimit, current_org, contactUs, setContactUs }) => {
    const dispatch = useDispatch<Dispatch>()

    const isLoading = useSelector((state: RootState) => state.UI.isLoading);
    const limit = current_org?.plan?.limit;

    return (
        <Modal
            title="Warning: Unable to add employees!"
            cancelButtonProps={{ loading: isLoading }}
            okButtonProps={{ loading: isLoading }}
            onOk={() => {
                setContactUs(true);
                setIsLimit(false);
            }}
            onCancel={() => {
                setIsLimit(false);
            }}
            visible={isLimit}
            okText='Contact Us!'
        >
            <Span>
                You are utilizing the free tier plan and have exceeded the employee limit, please reach out to us for assistance in modifying your current plan.
            </Span>
            <div className='p-2'>
                <Span weight='semi' margin='mt-2'>Current Plan:</Span>
                <div className='p-2 flex flex-col w-full justify-start items-start'>
                    <div className='flex w-full flex-row justify-start items-center'>
                        <span className='w-20 font-semibold'>Plan Type:</span>
                        <Span>Free</Span>
                    </div>
                    <div className='flex w-full flex-row justify-start items-center'>
                        <span className='w-20 font-semibold'>Limit:</span>
                        <Span>{`${limit} Employees`}</Span>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default EmpLimitExceed