import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { addContract, calculatePayroll, getListOfReports, updateContract } from "../common/api";
import { contructContractsForReports, generateDefaultExcelFile, getSalaryCycleRange } from "../common/utils";

interface Props {
    earnings: Array<any>,
    deductions: Array<any>,
    date: Array<any>,
    forCompliance: Array<any>,
    isDefaultSalaryRange: boolean,
    salaryRange: Object,
    data: Array<any>,
    payroll_list_count: any,
    without_clockout_count: any,
    contract: any,
    reports: Array<any>
}

const initialState = {
    earnings: [],
    deductions: [],
    date: [],
    forCompliance: [],
    isDefaultSalaryRange: true,
    salaryRange: {
        from: '2023-01-01',
        to: '2023-01-01'
    },
    data: [],
    payroll_list_count: 0,
    without_clockout_count: 0,
    contract: null,
    reports: []
} as Props;

export const Payroll = createModel<RootModel>()({
    state: initialState,
    reducers: {
        resetState() {
            return {
                ...initialState
            }
        },
        updateState(state, newState) {
            return {
                ...state,
                ...newState
            }
        },
        updateED(state, payload) { //update earnings or deducitons
            const { name, data } = payload;
            return {
                ...state,
                [name]: state[name]?.map((item) => item?.id === data?.id ? data : item)
            }
        },
        deleteED(state, payload) { //delete earnings or deducitons
            const { name, id } = payload;
            return {
                ...state,
                [name]: state[name]?.filter((item) => item?.id !== id)
            }
        },
        updateData(state, payload) {
            const empId = payload?.userInfo?.id;
            return {
                ...state,
                data: state?.data?.map((item: any) => item?.userInfo?.id === empId ? payload : item)
            }
        }
    },
    effects: (dispatch) => ({
        async calculateCurrentPayroll(payload: any, rootState) {
            try {
                dispatch.UI.setIsLoading(true);

                const { date, deductions, earnings, contract }: any = rootState.Payroll;

                const [from, to] = date;

                if (!from || !to) return;

                const body = {
                    deductions,
                    earnings,
                    contract,
                    salaryRange: {
                        from,
                        to
                    }
                }

                const res = await calculatePayroll(body);

                if (res?.isSuccess) {
                    dispatch.UI.setIsLoading(false);
                    dispatch.UI.setAlert({ type: 'Success', message: 'Successfully created' });

                    const payload = {
                        data: res?.message?.items,
                        forCompliance: res?.message?.forCompliance,
                        without_rate_count: res?.message?.forCompliance?.empWithoutRate?.length,
                        without_clockout_count: res?.message?.forCompliance?.logsWithoutClockout?.length,
                    }
                    dispatch.Payroll.updateState(payload);
                    dispatch.Payroll.getListReports(null);
                }

            } catch (err: any) {
                dispatch.UI.setIsLoading(false);
                const errMsg = err?.response?.data?.error ?? 'Unable to calculate payroll';
                dispatch.UI.setAlert({ type: 'Error', message: errMsg })
            }
        },
        async addOrUpdateContract(payload: any, rootState) {
            try {
                const isLoading = rootState.UI.isLoading;
                if (isLoading) return;

                dispatch.UI.setIsLoading(true);
                const { userInfo } = rootState.User;
                const employees = rootState.Table.tables.employees?.data
                    .filter(e => e?.organization?.id === userInfo?.organization?.id);

                const { type, contract, empWithoutContractId } = payload;

                const defaultEmployees = employees?.filter(e => e?.contractId === contract?.id)?.map(e => e?.id);
                const employeesId = [...defaultEmployees, ...empWithoutContractId];
                const employeesData = employees?.filter((e: any) => employeesId?.includes(e?.id));
                const blob = await generateDefaultExcelFile(employeesData);

                const body = {
                    week_from: contract?.week_from ?? 'Monday',
                    week_to: contract?.week_to ?? 'Friday',
                    salary_cycle: contract?.salary_cycle ?? 'semi-monthly',
                    name: contract?.name ?? 'default',
                    file: blob,
                    night_shift: Number(contract?.night_shift ?? 50),
                    overtime: Number(contract?.overtime ?? 50),
                    regular_holiday: Number(contract?.regular_holiday ?? 100),
                    special_holiday: Number(contract?.special_holiday ?? 100),
                    tardiness: Number(contract?.tardiness ?? 50),
                    gracePeriod: Number(contract?.gracePeriod ?? 15),
                    sickLeave: Number(contract?.sickLeave ?? 3),
                    vacationLeave: Number(contract?.vacationLeave ?? 3),
                    isDefault: true
                }

                let res;

                switch (type) {
                    case 'add':
                        res = await addContract(body);
                        break;
                    case 'update':
                        res = await updateContract({ ...body, id: contract?.id });
                        break;
                    default:
                        break;
                }

                if (res.isSuccess) {
                    dispatch.UI.setIsLoading(false);
                    dispatch.Payroll.resetState();
                    dispatch.Table.getFirebaseData({ table: 'contracts', name: 'contracts' });
                    dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' });
                    dispatch.UI.setAlert({ type: 'Success', message: 'Data successfully submitted' });
                }
            } catch (err: any) {
                dispatch.UI.setIsLoading(false);
                const errMsg = err?.response?.data?.error ?? 'Unable to set configuration';
                dispatch.UI.setAlert({ type: 'Error', message: JSON.stringify(errMsg).replace(/[(){}]/g, "").replace(/\[/g, '').replace(/]/g, '') })
            }
        },
        async getListReports(payload: any, rootState) {
            try {
                dispatch.UI.setIsLoading(true);

                const { date, deductions, earnings }: any = rootState.Payroll;
                const contracts = rootState.Table.tables.contracts.data;

                const [from, to] = date;

                const contractsWithSalaryRange = contructContractsForReports(contracts, from, to)

                const body = {
                    selectedRange: {
                        from,
                        to
                    },
                    earnings,
                    deductions,
                    contracts: contractsWithSalaryRange,
                }

                const res = await getListOfReports(body);

                if (res?.isSuccess) {
                    dispatch.UI.setIsLoading(false);
                    dispatch.Payroll.updateState({ reports: res?.message });
                    // dispatch.UI.setAlert({ type: 'Success', message: 'Reports Created!' });
                }
            } catch (err: any) {
                dispatch.UI.setIsLoading(false);
                const errMsg = err?.response?.data?.error ?? 'Unable to calculate payroll';
                dispatch.UI.setAlert({ type: 'Error', message: errMsg })
            }
        }
    })
})