import React, { useDeferredValue, useMemo, useState } from 'react'
import { Container } from '../../../components/common/Containers';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { getPermission, useQuery } from '../../../common/utils';
import Card from '../../../components/Card/Card';
import { Span } from '../../../components/common/Span';
import Search from 'antd/lib/input/Search';
import { COLUMNS } from './Column';
import Table from '../../../components/Table/Table';
import UpdateLog from '../../../components/ModuleComponents/UpdateLog';

const WithoutClockout = ({ activeKey }) => {
    const mobile = useMediaQuery('(max-width:600px)');
    const permissions = getPermission('payroll');

    const [add, setAdd] = useState(false);
    const [search, setSearch] = useState('');

    const deferredSearch = useDeferredValue(search);

    const items: any = useSelector((state: RootState) => state.Payroll.forCompliance);

    const columns = useMemo(() => COLUMNS, []);

    const data = useMemo(() => {
        const result = (items?.logsWithoutClockout || [])?.filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

        return result;
    }, [items, deferredSearch])

    return (
        <div className='relative'>
            <Container padding='none'>
                <div className={`flex w-full ${mobile ? 'flex-col justify-center items-center px-2' : 'justify-between items-center px-4'}`}>
                    <div>
                        <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                            <Span margin='mt-4' weight='bold' size='2xl'>Time Logs</Span>
                        </div>
                    </div>

                    <div className={`flex justify-center items-center ${mobile ? 'w-full' : ''}`}>
                        <Search
                            className={`${mobile ? 'mt-2' : ''}`}
                            placeholder="Search any..."
                            onChange={(e: any) => setSearch(e.target.value)}
                            style={{ width: mobile ? '100%' : 300 }}
                        />
                    </div>
                </div>

                <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                    {mobile ? (
                        <Card data={data} dataType='without_clockout' sortName='createdAt' />
                    ) : (
                        <Table columns={columns} data={data} sortName='createdAt' isLastColSticky={true} />
                    )}
                </div>

                {activeKey === '5' && <UpdateLog table='widthout_clockout' />}
            </Container >
        </div >
    )
}

export default WithoutClockout