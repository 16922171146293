import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { Drawer } from 'antd';
import RoleForm from './Forms/RoleForm/RoleForm';
import { updateRole } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

type Props = {
    table: string,
}

type State = {
    name: string,
    desc: string,
    status: string,
    permissions: object | null
}

const UpdateRole = ({ table }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const role = useSelector((state: RootState) => state.Table.tables[table]?.data)?.find(d => d.id === currentUpdateId);

    const initialState = {
        name: role?.name ?? '',
        desc: role?.desc ?? '',
        status: role?.status ?? '',
        permissions: role?.permissions ?? null
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Name is required field'),
        desc: yup.string(),
        status: yup.string(),
        permissions: yup.object().required('Permissions is required field'),
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleUpdate = async (values, actions) => {
        if (Object?.keys(values?.permissions)?.length < 1) {
            actions.setSubmitting(false);
            actions.setErrors({ permissions: 'Permissions is required field' });
            return;
        }
        try {
            const payload = {
                ...values,
            }
            let res;

            switch (table) {
                case 'role':
                    res = await updateRole(currentUpdateId, payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                dispatch.User.updateUserInfo(null);
                dispatch.UI.resetForm('currentUpdateId');
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update user'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleUpdate(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Role"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            props.resetForm();
                            history.goBack();
                            dispatch.UI.resetForm('currentUpdateId');
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText='Update Role'
                            />
                        }
                    >
                        {currentUpdateId && <RoleForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateRole;