import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Drawer } from 'antd';
import OrgForm from './Forms/OrgForm';
import { addOrganization } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

type Props = {
    table: string,
    setAdd: Function,
    add: boolean
}

type State = {
    name: string,
    address: string,
    phoneNumber: string,
    logo: any,
    website: string,
}

const AddOrganization = ({ table, setAdd, add }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const initialState = {
        name: '',
        address: '',
        phoneNumber: '',
        logo: '',
        website: ''
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Company name is required field'),
        address: yup.string().required('Company address is required field'),
        phoneNumber: yup.number().required('Company number is required field'),
        logo: yup.string().required('Make sure to select company logo'),
        website: yup.string()
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
            }

            let res;

            switch (table) {
                case 'organization':
                    res = await addOrganization(payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully created' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to create user'
            setErrorMesage(errMsg);
        }
    }
    
    return (
        <Formik
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Add Organization"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Add Organization'
                            />
                        }
                    >
                        {add && <OrgForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddOrganization;