import { Box, Checkbox, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import Search from 'antd/lib/input/Search'
import React from 'react'
import ItemList from './ItemList';

const PayslipNav = ({ setSearch, data, users, setUsers, select, setSelect }) => {
    const mobile = useMediaQuery('(max-width:600px)');

    const handleCheckedAll = () => {
        if (users?.length < data?.length) {
            const allUsers = data?.map((d: any) => d?.userInfo?.id);
            setUsers(allUsers);
        } else if (users?.length === data?.length) {
            setUsers([]);
        }
    }

    return (
        <List dense sx={{ width: '100%', padding: '0 8px', maxWidth: mobile ? '100%' : 300, height: '70vh' }} component={Paper} >
            <ListItem
                disablePadding
                key='search'
            >
                <Stack justifyContent='center' alignItems='center' sx={{ width: '100%', my: '10px' }}>
                    <Search
                        className={`${mobile ? 'px-4 pb-4' : ''}`}
                        placeholder="Search any..."
                        onChange={(e: any) => setSearch(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Stack>
            </ListItem>

            <ListItem
                disablePadding
                key='all_item'
                secondaryAction={
                    <Checkbox
                        checked={data?.length === users?.length}
                        indeterminate={data?.length !== users?.length && users?.length > 0}
                        onClick={handleCheckedAll}
                        size='small'
                    />
                }
            >
                <ListItemButton>
                    <ListItemText>
                        <Typography variant='body2' fontWeight='bold' align='center'>Select All</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <Divider variant='fullWidth' component="li" />

            <Box
                sx={{
                    overflow: mobile ? 'auto' : 'hidden',
                    height: '55vh',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                        overflow: 'auto'
                    }
                }}
            >
                {data?.map((item) => (
                    <ItemList data={item} select={select} setSelect={setSelect} users={users} setUsers={setUsers} />
                ))}
            </Box>
        </List>
    )
}

export default PayslipNav