import React, { useEffect, useState } from 'react'
import designbg from '../../assets/images/login_design3.png'
import designbg1 from '../../assets/images/login_design.png'
import { useQuery } from '../../common/utils'
import jwtDecode from 'jwt-decode';
import { verifyLogRequestStatus } from '../../common/api';
import { Span } from '../../components/common/Span';
import { Spin } from 'antd';
import UpdateLogRequest from '../../components/ModuleComponents/Modal/UpdateLogRequest';

const LogRequest = () => {
    const query = useQuery();

    const token: any = query.get('token');
    const type: any = query.get('type');
    const data: any = jwtDecode(token)

    const [success, setSuccess] = useState(false);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(null);
    const [count, setCount] = useState(5);
    const [loading, setLoading] = useState(true);

    const closePage = (errMsg) => {
        setLoading(false);
        setError(errMsg);
    }

    const verifyStatus = async () => {
        try {
            const res = await verifyLogRequestStatus(data?.id)
            if (res?.isSuccess && res?.message === 'pending') {
                setUpdate(true);
                setLoading(false);
                return;
            }

            closePage('Link Expired')

        } catch (err: any) {
            const errMsg = err?.response?.data?.error ?? 'Invalid Credetials!'
            closePage(errMsg);
            console.log(err)
        }
    }

    useEffect(() => {
        verifyStatus();
    }, []);

    useEffect(() => {
        if (error || success) {
            const interval = setInterval(() => {
                setCount(count - 1);
            }, 1000)
            if (count === 0) {
                clearInterval(interval);
                window.close();
            }
        }
    }, [error, count, success]);

    return (
        <div className='w-screen h-screen flex justify-center items-center flex-col relative'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

            {loading && <Spin />}
            {error && <Span color='red' size='xs' margin='mt-2' transform='capitalize'>{error}</Span>}
            {error && <Span color='red' size='2xl' margin='mt-2' transform='capitalize'>Closing in {count}...</Span>}
            {success && <Span color='green-1' size='2xl' margin='mt-2' transform='capitalize'>Status Updated!</Span>}
            {success && <Span color='green-1' size='xs' margin='mt-2' transform='capitalize'>Closing in {count}...</Span>}

            {update && <UpdateLogRequest update={update} setUpdate={setUpdate} data={data} type={type} setSuccess={setSuccess} />}
        </div>
    )
}

export default LogRequest