import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader';

import Table from '../../components/Table/Table';
import { COLUMNS } from './Column'
import { attendancePerEmployeeDataToDownload, attendancePerEmployeeDataToDownloadV2, employeeDataToDownload, getPermission, logsheetDataToDownload, removeSCAndCapitalize } from '../../common/utils';
import { DatePicker, Select } from 'antd';
import { Span } from '../../components/common/Span';
import { Button } from '../../components/common/Button';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { utils, writeFileXLSX } from 'xlsx';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import Card from '../../components/Card/Card';
import OrgFilter from './OrgFilter';

export default () => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')
    const permissions = getPermission('timesheet')

    const data = useSelector((state: RootState) => state.Table.tables.alltimesheet.data);
    const { isLoading } = useSelector((state: RootState) => state.Table);
    const { roleModules, userInfo } = useSelector((state: RootState) => state.User);
    const organizations = useSelector((state: RootState) => state.Table.tables.organization.data);

    const [date, setDate] = useState([moment().startOf('month'), moment()]) as any;
    const [org, setOrg] = useState(userInfo?.organization?.id);

    const columns = useMemo(() => COLUMNS, []);
    const { RangePicker } = DatePicker;
    const options = organizations?.map(o => { return { label: removeSCAndCapitalize(o?.name, ' '), value: o?.id } });

    const handleChange = (date, dateString) => {
        console.log(date, dateString)
        if (!date || !dateString) {
            return setDate([moment().startOf('month'), moment()]);
        }
        setDate(dateString)
    }

    const exportFile = useCallback(() => {
        const Logsheet = utils.json_to_sheet(logsheetDataToDownload(data));
        const Employee = utils.json_to_sheet(employeeDataToDownload(data));
        const PerEmployee = utils.json_to_sheet(attendancePerEmployeeDataToDownload(data));
        const PerEmployeeV2 = utils.json_to_sheet(attendancePerEmployeeDataToDownloadV2(data));
        const wb = utils.book_new();
        utils.book_append_sheet(wb, Logsheet, "Logsheet");
        utils.book_append_sheet(wb, Employee, "Employee");
        utils.book_append_sheet(wb, PerEmployee, "Clock In Per Employee");
        utils.book_append_sheet(wb, PerEmployeeV2, "Clock In Per Employee V2");
        writeFileXLSX(wb, `log_sheet_${moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}.xlsx`);
    }, [data]);

    useEffect(() => {
        const filters = {
            org: {
                field: 'userInfo.organization.id',
                operation: '==',
                value: org
            },
            greater: {
                field: 'timesheetDate',
                operation: '>=',
                value: moment(date[0]).format('YYYY-MM-DD')
            },
            lower: {
                field: 'timesheetDate',
                operation: '<=',
                value: moment(date[1]).format('YYYY-MM-DD')
            }
        }

        dispatch.Table.setMultipleFilterValue({ table: 'alltimesheet', filters: filters });

        dispatch.Table.getFirebaseData({ table: 'alltimesheet', name: 'timesheet' });
        dispatch.Table.getFirebaseData({ table: 'organization', name: 'organization' });
    }, [date, org])

    return (<div className='relative'>
        <Loader isLoading={isLoading} />
        <Container border='grey' padding='none'>
            <div className='p-4'>
                <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
                    <Span margin='mt-4' weight='bold' size='2xl'>All Log Sheet</Span>
                </div>
            </div>

            <div className={`flex w-full ${mobile ? 'flex-col-reverse justify-center items-center px-4 pb-4' : 'justify-between items-center px-4'}`}>
                <div className={`flex w-full justify-start items-center ${mobile ? 'flex-col mt-2 space-y-2' : 'space-x-2 flex-row'}`}>
                    <RangePicker
                        style={{
                            width: mobile ? '100%' : '300px',
                        }}
                        allowClear
                        onChange={handleChange}
                        value={[moment(date[0]), moment(date[1])]}
                    />

                    {roleModules?.includes('organization') && (
                        <Select
                            style={{ width: mobile ? '100%' : '300px' }}
                            showSearch
                            placeholder="Select organization"
                            optionFilterProp="children"
                            value={org}
                            onChange={(value: any) => setOrg(value)}
                            filterOption={(input, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={options}
                        />
                    )}
                </div>

                {permissions.includes('canExport') && (
                    <Button
                        style={{ ...(!data?.length && { cursor: 'not-allowed' }) }}
                        disabled={!data?.length}
                        padding='p-2'
                        onClick={exportFile}
                        {...(!mobile && {
                            width: '36'
                        })}
                    >
                        <div className='flex w-full justify-center items-center'>
                            <Span padding='pr-2' color='white'>Download</Span>
                            <DownloadOutlined style={{ color: 'white' }} />
                        </div>
                    </Button>
                )}
            </div>

            <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                {mobile ? (
                    <Card data={data} dataType='timesheet' sortName='createdAt' />
                ) : (
                    <Table columns={columns} data={data} sortName='createdAt' />
                )}
            </div>
        </Container >
    </div >
    )
}
