import { Avatar, Tag } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";
import cronstrue from 'cronstrue';
import Attachments from "./Attachments";

export const COLUMNS = [
  {
    id: "taskName",
    field: "taskName",
    Header: "Task Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{d.taskName?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d.taskName}</Span>
        </div>
      )
    }
  },
  {
    id: "desc",
    field: "desc",
    Header: "Description",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.remarks ? (
            <span className="capitalize max-w-md block">{d?.remarks}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "schedule",
    field: "schedule",
    Header: "Schedule",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <Span transform='capitalize'>{cronstrue.toString(d?.cron)}</Span>
        </div>
      )
    }
  },
  {
    id: "status",
    field: "status",
    Header: "Status",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.scheduled_status === 'active' ? (
            <Tag className="capitalize" color='success'>{d?.scheduled_status}</Tag>
          ) : (
            <Tag className="capitalize" color='error'>{d?.scheduled_status}</Tag>
          )}
        </>
      )
    }
  },
  {
    id: "attachment",
    field: "attachment",
    Header: "Attachment",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original

      return (
        d?.imagesName?.length ? (
          <Attachments data={d} />
        ) : (
          <div className="emptyContainer flex flex-row justify-start items-center">
            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
            <Span color='lightgrey'>No Data</Span>
          </div>
        )
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'MMM DD YYYY HH:mm')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='workflow' />
      )
    }
  }

];
