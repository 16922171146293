import { Avatar } from '@mui/material'
import { Card } from 'antd'
import moment from 'moment'
import React from 'react'
import { AiOutlineInbox } from 'react-icons/ai'
import { firebaseDateToText } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'

const RenderOrganization = ({ item, dataType }) => {

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.name}</Span>
                        <Span transform='capitalize' color='lightgrey'>{item?.id}</Span>
                    </div>
                </div>
                <Options data={item} table={dataType} />
            </div>

            <div className='py-2'>
                <div className='flex justify-start items-start'>
                    <Span weight='semi' margin='mx-2'>Description: </Span>

                    <>
                        {item?.desc ? (
                            <span className="capitalize max-w-md block text-justify">{item?.desc}</span>
                        ) : (
                            <div className="emptyContainer flex flex-row justify-start items-center">
                                <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                                <Span color='lightgrey'>No Data</Span>
                            </div>
                        )}
                    </>
                </div>

                <div className='flex justify-start items-center'>
                    <Span weight='semi' margin='mx-2'>Created At: </Span>
                    <Span margin='mx-2' transform='capitalize'>{firebaseDateToText(item?.createdAt, 'MMM DD YYYY HH:mm')}</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderOrganization