import { GridContainer, InputContainer } from '../../../common/Containers'
import { Span } from '../../../common/Span'
import { Input, InputMask } from '../../../common/Input'
import { onlyNumberKeyDotAndNegative } from '../../../../common/utils'
import { Badge, Tag } from 'antd'
import { useEffect, useId } from 'react'

const Item = ({ values, errors, touched, index, setFieldTouched, setFieldValue }) => {

    const handleRemove = () => {
        const value = values?.items?.filter((d, i) => i !== index);
        setFieldValue('items', value);
    }

    return (
        <Badge.Ribbon text={index + 1} placement='start'>
            <Badge.Ribbon
                text={<div onClick={handleRemove}>Remove</div>}
                placement='end' color='red'
                style={{ cursor: 'pointer', display: values?.items?.length < 2 ? 'none' : 'flex' }}
            >
                <div className='grid grid-cols-2 gap-2 mt-2 border border-gray-300 p-4'>
                    <div className='px-2 text-left'>
                        <div className='flex flex-row justify-start items-center mb-1'>
                            <Span>Item Name</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer border='grey' padding='none' flex='row' margin='none'>
                            <Input
                                border='none'
                                style={{ textTransform: 'capitalize' }}
                                value={values?.items[index]?.name}
                                onChange={(e: any) => {
                                    setFieldValue(`items.${index}.name`, e.target.value);
                                }}
                                placeholder='Enter Item Name'
                                onBlur={() => setFieldTouched(`items.${index}.name`, true)}
                            />
                        </InputContainer>
                        {errors?.name && touched?.name && <Span color='red' size='xs'>{errors?.name}</Span>}
                    </div>

                    <div className='px-2 text-left'>
                        <div className='flex flex-row justify-start items-center mb-1'>
                            <Span>Amount</Span>
                            <Span color='red'>*</Span>
                        </div>
                        <InputContainer border='grey' padding='none' flex='row' margin='none'>
                            <Span margin='ml-2' size='base'>₱</Span>
                            <InputMask
                                style={{ border: 'none' }}
                                mask='num'
                                blocks={{
                                    num: {
                                        mask: Number,
                                        scale: 2,
                                        thousandsSeparator: ',',
                                        radix: '.'
                                    }
                                }}
                                unmask={true}
                                value={values?.items[index]?.amount}
                                onAccept={(value, mask) => setFieldValue(`items.${index}.amount`, value)}
                                placeholder='Enter Amount'
                                onBlur={() => setFieldTouched(`items.${index}.amount`, true)}
                            />
                        </InputContainer>
                        {errors?.amount && touched?.amount && <Span color='red' size='xs'>{errors?.amount}</Span>}
                    </div>
                </div>
            </Badge.Ribbon>
        </Badge.Ribbon>
    )
}

export default Item