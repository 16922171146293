import Attachment from '../containers/Authentication/Setup/Setup';

const Setup = () => {
    return (
        <>
            <Attachment />
        </>
    )
}

export default Setup
