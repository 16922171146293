import { Span } from '../../common/Span';
import { Input } from '../../common/Input';
import { InputContainer } from '../../common/Containers';
import { AiOutlinePercentage } from 'react-icons/ai';
import { onlyNumberKeyDotAndNegative } from '../../../common/utils';
import { Button } from '../../common/Button';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    errors: any,
    touched: any,
    errMesssage: string,
}

const SssSettingsForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldValue }: Props) => {

    return (
        <div className='px-2 text-left'>
            <div className='px-2 text-left'>
                <div className='flex flex-row justify-start items-center mb-1'>
                    <Span>Employee's Share</Span>
                    <Span color='red'>*</Span>
                </div>
                <InputContainer border='grey' padding='none' flex='row' margin='none'>
                    <AiOutlinePercentage className='ml-2' />
                    <Input
                        border='none'
                        style={{ textTransform: 'capitalize' }}
                        value={values.employee_share}
                        onChange={handleChange('employee_share')}
                        placeholder='Enter Percentage'
                        onBlur={handleBlur('employee_share')}
                        onKeyPress={onlyNumberKeyDotAndNegative}
                    />
                </InputContainer>
                {errors.employee_share && touched.employee_share && <Span color='red' size='xs'>{errors.employee_share}</Span>}
            </div>

            <div className='px-2 text-left mt-4'>
                <div className='flex flex-row justify-start items-center mb-1'>
                    <Span>Employer's Share</Span>
                    <Span color='red'>*</Span>
                </div>
                <InputContainer border='grey' padding='none' flex='row' margin='none'>
                    <AiOutlinePercentage className='ml-2' />
                    <Input
                        border='none'
                        style={{ textTransform: 'capitalize' }}
                        value={values.employer_share}
                        onChange={handleChange('employer_share')}
                        placeholder='Enter Percentage'
                        onBlur={handleBlur('employer_share')}
                        onKeyPress={onlyNumberKeyDotAndNegative}
                    />
                </InputContainer>
                {errors.employer_share && touched.employer_share && <Span color='red' size='xs'>{errors.employer_share}</Span>}
            </div>

            <div className='px-2 text-left mt-4'>
                <div className='flex flex-row justify-start items-center'>
                    <Span>Download Template</Span>
                    <Span color='red'>*</Span>
                </div>
                <Link to='/file/compensation_template.csv' target="_blank" download>
                    <Button>
                        <div className='flex w-full justify-center items-center'>
                            <span className='pr-2 cursor-pointer'>
                                Compensation Range Template
                            </span>
                            <DownloadOutlined style={{ color: 'white' }} />
                        </div>
                    </Button>
                </Link>
            </div>

            <div className='px-2 text-left mt-4'>
                <div className='flex flex-row justify-start items-center'>
                    <Span>Upload Template</Span>
                    <Span color='red'>*</Span>
                </div>
                <input
                    id="file"
                    type="file"
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e: any) => {
                        setFieldValue('file', e.target.files[0]);
                    }}
                />
                <Button
                    padding='px-8'
                >
                    <div className='flex w-full justify-center items-center'>
                        <label htmlFor="file" className='pr-2 cursor-pointer'>
                            Choose file
                        </label>
                        <UploadOutlined style={{ color: 'white' }} />
                    </div>
                </Button>
                <span className='truncate block'><strong>Filename: </strong>{values?.file?.name}</span>
                {errors.file && touched.file && <Span color='red' size='xs'>{errors.file}</Span>}
            </div>

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default SssSettingsForm