import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Steps } from 'antd';
import { Dispatch, RootState } from '../../../store';
import { useMediaQuery } from '@mui/material';
import * as yup from 'yup';
import { addContract, updateContract } from '../../../common/api';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useQuery } from '../../../common/utils';
import { useHistory } from 'react-router-dom';
import ContractForm from '../Forms/ContractForm';

type Props = {
    setOpen: Function,
    open: boolean,
}

type State = {
    week_from: string,
    week_to: string,
    salary_cycle: string | any,
    night_shift: string | any,
    overtime: string | any,
    regular_holiday: string | any,
    special_holiday: string | any,
    tardiness: any,
    gracePeriod: any,
    sickLeave: any,
    vacationLeave: any,
    name: string,
    file: any
}

const AddUpdateContract = ({ open, setOpen }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const type = query.get('type');
    const mobile = useMediaQuery('(max-width:600px)')

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms.currentUpdateId?.id);
    const contracts = useSelector((state: RootState) => state.Table.tables.contracts.data);
    const formikRef: any = useRef();

    const contract = contracts?.find((c: any) => c?.id === currentUpdateId);

    const initialState = {
        week_from: contract?.week_from ?? '',
        week_to: contract?.week_to ?? '',
        salary_cycle: contract?.salary_cycle ?? '',
        night_shift: contract?.night_shift ?? '',
        overtime: contract?.overtime ?? '',
        regular_holiday: contract?.regular_holiday ?? '',
        special_holiday: contract?.special_holiday ?? '',
        tardiness: contract?.tardiness ?? '',
        gracePeriod: contract?.gracePeriod ?? '',
        sickLeave: contract?.sickLeave ?? '',
        vacationLeave: contract?.vacationLeave ?? '',
        name: contract?.name ?? '',
        file: '',
    } as State;

    const validateSchema = yup.object({
        salary_cycle: yup.string().required('Salary cycle is required field'),
        night_shift: yup.number().required('Night shift rate is required field'),
        overtime: yup.number().required('Overtime rate is required field'),
        regular_holiday: yup.number().required('Regular holiday rate is required field'),
        special_holiday: yup.number().required('Special holiday rate is required field'),
        name: yup.string().required('Contract name is required field'),
        gracePeriod: yup.number().required('Grace period is required field'),
        tardiness: yup.number().required('Tardiness rate is required field'),
        sickLeave: yup.number().required('Sick leave is required field'),
        vacationLeave: yup.number().required('Vacation leave rate is required field'),
        file: yup.string()
            .when('type', {
                is: () => type === 'update',
                then: yup.string(),
                otherwise: yup.string().required('Make sure to select a file')
            }),
        week_from: yup.string().required('Week from is required field'),
        week_to: yup.string().required('Week to is requried field')
            .test('week_to', 'Week to must be after week from', (value) => {
                const weekFrom = formikRef?.current?.values?.week_from;

                if (!value || !weekFrom) {
                    return true; // if either week_to or week_from is not provided, let other validators handle the error
                }

                return moment().day(value).isAfter(moment().day(weekFrom), 'day');
            })
    })

    const handleClickSubmit = async (values, actions) => {
        try {

            const payload = {
                ...values,
                night_shift: Number(values?.night_shift),
                overtime: Number(values?.overtime),
                regular_holiday: Number(values?.regular_holiday),
                special_holiday: Number(values?.special_holiday),
                tardiness: Number(values?.tardiness),
                gracePeriod: Number(values?.gracePeriod),
                sickLeave: Number(values?.sickLeave),
                vacationLeave: Number(values?.vacationLeave),
            }

            let res;

            switch (type) {
                case 'add':
                    res = await addContract(payload);
                    break;
                case 'update':
                    res = await updateContract({ ...payload, id: contract?.id });
                    break;
                default:
                    break;
            }

            if (res.isSuccess) {
                dispatch.Payroll.resetState();
                actions.setSubmitting(false)
                setOpen(false);
                actions.resetForm();
                dispatch.UI.resetForm('currentUpdateId')
                dispatch.Table.getFirebaseData({ table: 'contracts', name: 'contracts' });
                dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' });
                dispatch.UI.setAlert({ type: 'Success', message: 'Data successfully submitted' });
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            actions.setFieldValue('file', '');
            const errMsg = err?.response?.data?.error ?? 'Unable to set configuration';
            dispatch.UI.setAlert({ type: 'Error', message: JSON.stringify(errMsg).replace(/[(){}]/g, "").replace(/\[/g, '').replace(/]/g, '') })
        }
    }

    useEffect(() => {
        dispatch.UI.resetForm('currentUpdateId');
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleClickSubmit(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Modal
                        bodyStyle={{ height: '70vh', overflow: 'auto' }}
                        title={type === 'add' ? 'Add Contract' : 'Update Contract'}
                        cancelButtonProps={{ loading: props.isSubmitting }}
                        okButtonProps={{ loading: props.isSubmitting }}
                        onOk={() => props.handleSubmit()}
                        okText={type === 'add' ? 'Add Contract' : 'Update Contract'}
                        cancelText='Cancel'
                        onCancel={() => {
                            props.resetForm();
                            setOpen(false)
                            dispatch.UI.resetForm('currentUpdateId')
                            history?.goBack();
                        }}
                        visible={open || currentUpdateId ? true : false}
                    >
                        <ContractForm {...props} contract={contract} />
                    </Modal>
                )
            }}
        </Formik>
    )
}

export default AddUpdateContract;