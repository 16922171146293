import one from '../../../assets/images/arrow1.png'
import two from '../../../assets/images/arrow2.png'
import step1 from '../../../assets/images/step1_icon.png'
import step2 from '../../../assets/images/step2_icon.png'
import step3 from '../../../assets/images/step3_icon.png'
import step4 from '../../../assets/images/step4_icon.png'

import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { Dispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';

import { Span } from '../../common/Span'
import { Button } from '../../common/Button'
import { addBulkEmployee } from '../../../common/api'
import { CircularProgress, Stack } from '@mui/material'


type Props = {
    setAdd: Function,
}

const ACCEPT = ["text/csv", ".csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]

export default ({ setAdd }: Props) => {

    const dispatch = useDispatch<Dispatch>()
    const history = useHistory();
    const errMesssage = useSelector((state: RootState) => state.UI.alert?.message);

    const { register, resetField } = useForm()
    const [filename, setFilename] = useState('');
    const [file, setFile] = useState(new Blob);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null) as any;

    const isXls = ACCEPT.includes(file.type);

    const handleFile = (e: any) => {
        setFilename(e.target.files[0].name);
        setFile(e.target.files[0]);
    }

    const handleSubmit = async () => {
        if (file?.size === 0) return dispatch.UI.setAlert({ message: 'please select a file!', type: 'error' })
        if (!isXls) return dispatch.UI.setAlert({ message: `The (${file?.type}) couldn't be recognized as a valid Excel document!`, type: 'error' })
        try {
            setIsLoading(true)
            setError(null)
            const res = await addBulkEmployee(file);
            if (res.isSuccess) {
                setIsLoading(false);
                setAdd(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Bulk Account Successfully created' })
                const timeout = setTimeout(() => {
                    dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' })
                    clearTimeout(timeout);
                }, 1500)
            }
        } catch (err: any) {
            setIsLoading(false);
            setFilename('');
            setFile(new Blob);
            resetField('value_name')
            const errMsg = err?.response.data.error ?? 'Unable to upload data';
            dispatch.UI.setAlert({ message: JSON.stringify(errMsg).replace(/[(){}]/g, "").replace(/\[/g, '').replace(/]/g, ''), type: 'error' });
        }
    }

    useEffect(() => {
        if (errMesssage) {
            setError(errMesssage);
        }
    })

    return <>
        <div className="px-4 py-4 flex-auto">
            <Span size='base' weight='semi'>Add Bulk Employee</Span>

            {error && <Alert severity='error' onClose={() => setError(null)}>{error}</Alert>}

            <div className='bulk-content'>
                <div className="bulk flex w-full py-2">
                    <div className="w-2/4">
                        <div className="flex h-3/5 relative bg-palette-blackhover items-center justify-center p-3 border-dashed border-r-4 border-white-1">
                            <div>
                                <img src={step1} className='h-24' />
                            </div>
                            <div className="p-2 break-normal">
                                <Span color='white' size='base'>STEP 1</Span>
                                <p><Span color='white'>Download the bulk employee template.</Span></p>
                            </div>
                            <div className="absolute -right-5 z-40">
                                <img src={one} className='ml-30 h-5' />
                            </div>
                        </div>
                    </div>

                    <div className="w-2/4">
                        <div className="flex h-3/5 relative bg-palette-mainColor items-center justify-center p-3 border-dashed border-r-4 border-white-1">
                            <div> <img src={step2} className='steps' /></div>
                            <div className="p-2 break-normal">
                                <Span color='white' size='base'>STEP 2</Span>
                                <p><Span color='white'>Prepare & encode the bulk employee file</Span></p>
                            </div>
                            <div className="absolute -right-5 z-40">
                                <img src={two} className='ml-30 h-5' />
                            </div>
                        </div>

                        <div className="text-center">
                            <Link to='/file/Employee_template.csv' target="_blank" download>
                                <Button margin='mt-4'>Bulk Employee Template</Button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="bulk flex w-full py-2">
                    <div className="w-2/4">
                        <div className="flex h-3/5 relative  bg-palette-blackhover items-center justify-center p-3 border-dashed border-r-4 border-white-1">
                            <div> <img src={step3} className='steps' /></div>
                            <div className="p-2 break-normal">
                                <Span color='white' size='base'>STEP 3</Span>
                                <p><Span color='white'>Upload the bulk employee file</Span></p>
                            </div>
                            <div className="absolute -right-5 z-40">
                                <img src={one} className='ml-30 h-5' />
                            </div>
                        </div>
                        <div className="text-center mt-6 mx-2">
                            <input
                                {...register('value_name')}
                                id="file"
                                type="file"
                                name="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleFile}
                            />
                            <label htmlFor="file" className=' text-blue-3 underline cursor-pointer'>Choose File</label>
                            <div className="mt-4">
                                <label htmlFor="file">{filename}</label>
                            </div>
                        </div>
                    </div>

                    <div className="w-2/4">
                        <div className="flex h-3/5 relative bg-palette-mainColor items-center justify-center p-3">
                            <div> <img src={step4} className='steps' /></div>
                            <div className="break-normal px-2 py-5">
                                <Span color='white' size='base'>STEP 4</Span>
                                <p><Span color='white' size='base'>Genereate the employees account.</Span></p>
                            </div>
                        </div>
                        <div className="text-center" onClick={handleSubmit}>
                            <Button margin='mt-4' disabled={isLoading}>
                                {isLoading ? (
                                    <div className='relative w-full h-full flex justify-center items-center'>
                                        Uploading...
                                        <CircularProgress color='success' size={18} style={{ position: 'absolute' }} />
                                    </div>
                                ) : 'Upload & Generate'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-4'>
                <Span size='base' weight='semi'>How to use it?</Span>
                <p className='mt-4'><Span>
                    Just download the bulk employee template and follow the instruction provided here. The instruction are
                    comprehensive and provide information on how you can properly use this feature.
                </Span></p>
            </div>

            <div style={{ height: '175px' }}>

            </div>
        </div>
    </>
}