import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import OrgForm from '../../../components/ModuleComponents/Forms/OrgForm';
import { Span } from '../../../components/common/Span';
import { AiOutlineCloseCircle, AiOutlineUpload } from 'react-icons/ai';
import { IMAGE_TYPE } from '../../../components/constants';
import { getBase64, getOrganizationLogo, useQuery } from '../../../common/utils';
import { InputContainer } from '../../../components/common/Containers';
import { Input } from '../../../components/common/Input';
import { Button } from '../../../components/common/Button';
import { BsArrowRightShort } from 'react-icons/bs';
import { Spin } from 'antd';
import { createClientOrganization, updateClientOrganization } from '../../../common/api/endpoints/client';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';
import { useHistory, useLocation } from 'react-router-dom';

type State = {
    name: string,
    address: string,
    phoneNumber: string,
    logo: any,
    website: string,
}

const OrganizationStep = ({ client }) => {
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const type = query.get('type');
    const pathname = useLocation().pathname;
    const history = useHistory();

    const organization = client?.organization;

    const initialState = {
        name: organization?.name ?? '',
        address: organization?.address ?? '',
        phoneNumber: organization?.phoneNumber ?? '',
        logo: organization?.logo ?? '',
        website: organization?.website ?? '',
        deleted_logo: ''
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Company name is required field'),
        address: yup.string().required('Company address is required field'),
        phoneNumber: yup.number().required('Company number is required field'),
        logo: yup.string().when('organization', {
            is: () => {
                if (organization?.logo) return true;
                return false;
            },
            then: yup.string().nullable(),
            otherwise: yup.string().required('Make sure to select company logo').nullable()
        }),
        website: yup.string()
    });

    const [errMesssage, setErrMessage] = useState('');
    const [uri, setUri] = useState(null) as any;
    const [imgFile, setImgFile] = useState(null) as any;

    const formikRef = useRef() as any;

    const source = getOrganizationLogo(organization?.logo);

    const handleImage = async (event, setFieldValue, setFieldTouched, setErrors) => {
        event.preventDefault();
        const file = event?.target?.files[0];

        if (!IMAGE_TYPE?.includes(file?.type)) {
            setFieldTouched('logo', true);
            setErrors({ logo: 'Invalid file type' });
            return;
        }

        const imgUri = await getBase64(file);

        setUri(imgUri);
        setFieldValue('logo', file);
        setImgFile(file);
    }

    const handleRegister = async (values, actions) => {
        try {
            let res;
            const dirty = formikRef.current.dirty;

            if (type === 'update') {
                if (!dirty) {
                    res = { isSuccess: true };
                } else {
                    res = await updateClientOrganization({ ...values, id: organization?.id });
                }
            } else {
                res = await createClientOrganization(values);
            }

            if (res.isSuccess) {
                history.push(`${pathname}?progress=1`);
                dispatch.Document.getFirebaseDoc({ name: 'client', document: 'client', id: client?.id });
                actions.setSubmitting(false)
                actions.resetForm();
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            actions.setFieldValue('logo', imgFile);
            const errMsg = err?.response?.data?.error ?? `Unable to ${type === 'update' ? 'update' : 'create'} organization`
            setErrMessage(errMsg);
        }
    }

    const handleRemoveImage = (setFieldValue) => {
        console.log('delete')
        setUri(null);
        setFieldValue('deleted_logo', organization.logo);
        setFieldValue('logo', '');
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, errors, touched, setFieldTouched, setErrors, setFieldValue, isSubmitting, handleSubmit }: any) => {
                return (
                    <div className='w-[400px] flex flex-col items-center justify-center'>
                        <div className='flex w-full flex-col justify-center items-center mb-4'>
                            <Span letters='wider' size='2xl' weight='bold'>Let's get you started.</Span>
                            <span className='mt-1 text-[13px] font-[500]'>Company Information</span>
                        </div>

                        {(uri ?? values?.logo) ? (
                            <div className='flex w-2/4 rounded-md justify-center items-center relative border border-gray-300 border-solid p-2 h-24'>
                                <AiOutlineCloseCircle
                                    className=' text-sm text-red-400 absolute top-2 right-2 cursor-pointer'
                                    onClick={() => handleRemoveImage(setFieldValue)}
                                />
                                <img src={uri ?? source} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
                            </div>
                        ) : (
                            <div className='flex w-2/4 flex-col rounded-md justify-center items-center border border-gray-300 border-solid p-2 h-20'>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={(event) => handleImage(event, setFieldValue, setFieldTouched, setErrors)}
                                    accept="image/*"
                                />
                                <label htmlFor="file" className="cursor-pointer">
                                    <AiOutlineUpload style={{ fontSize: '40px', color: 'lightgray' }} />
                                </label>
                                <span className='text-xs font-semibold'>Company Logo</span>
                            </div>
                        )}
                        {errors.logo && <Span color='red' size='xs'>{errors.logo}</Span>}

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    disabled={isSubmitting}
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    placeholder='Enter company name*'
                                    onBlur={handleBlur('name')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    disabled={isSubmitting}
                                    value={values.address}
                                    onChange={handleChange('address')}
                                    placeholder='Enter company address*'
                                    onBlur={handleBlur('address')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.address && touched.address && <Span color='red' size='xs'>{errors.address}</Span>}
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    disabled={isSubmitting}
                                    value={values.phoneNumber}
                                    onChange={handleChange('phoneNumber')}
                                    placeholder='Enter company number*'
                                    onBlur={handleBlur('phoneNumber')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.phoneNumber && touched.phoneNumber && <Span color='red' size='xs'>{errors.phoneNumber}</Span>}
                        </div>

                        <div className='flex flex-col w-full justify-center items-start'>
                            <InputContainer margin='mt-4' border='grey' flex='row'>
                                <Input
                                    disabled={isSubmitting}
                                    value={values.website}
                                    onChange={handleChange('website')}
                                    placeholder='Enter company website'
                                    onBlur={handleBlur('website')}
                                    border='none'
                                />
                            </InputContainer>
                            {errors.website && touched.website && <Span color='red' size='xs'>{errors.website}</Span>}
                        </div>

                        {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}

                        <Button
                            shadow='grey'
                            padding='p-10'
                            margin='mt-6'
                            width='full'
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}
                        >
                            <div className='flex flex-row justify-center items-center space-x-2'>
                                {isSubmitting && <Spin className='login_spin' size='small' />}
                                <Span color='white' weight='semi'>Continue</Span>
                                <BsArrowRightShort size={20} />
                            </div>
                        </Button>
                    </div>
                )
            }}
        </Formik >
    )
}

export default OrganizationStep