import { Avatar } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText, removeSCAndCapitalize } from "../../common/utils";
import { Span } from "../../components/common/Span";
import Options from "../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "name",
    field: "name",
    Header: "Organization Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{d.name?.charAt(0).toUpperCase()}</Avatar>
          <Span transform='capitalize'>{d.name}</Span>
        </div>
      )
    }
  },
  {
    id: "holidayType",
    field: "holidayType",
    Header: "Holiday",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {d?.holidayType ? (
            <span className="capitalize max-w-md block">{removeSCAndCapitalize(d?.holidayType, '_')}</span>
          ) : (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          )}
        </>
      )
    }
  },
  {
    id: "date",
    accessor: data => data.date?.seconds,
    field: "date",
    Header: "Date",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d?.date, 'LL')}</Span>
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Created At",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d.createdAt, 'LLLL')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='calendar' />
      )
    }
  }

];
