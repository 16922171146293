import { Paper } from '@mui/material';
import { ConfirmationResult, getAuth } from 'firebase/auth'
import React, { useState } from 'react'
import { BRAND_IMAGES } from '../../../../components/constants';
import { Span } from '../../../../components/common/Span';
import PinInput from 'react-pin-input';
import { Button } from '../../../../components/common/Button';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { signUpWithUserEmailPhone } from '../../../../common/api/endpoints/client';
import { checkOtpCodeForLogin } from '../../../../common/api';

const Phone = ({ phone, type }) => {
    const dispatch = useDispatch<Dispatch>();

    // //@ts-ignore
    // const confirmationResult: ConfirmationResult = window.confirmationResult;
    // //@ts-ignore
    // const widgetId = window.recaptchaWidgetId;
    // console.log(confirmationResult, widgetId, phone)

    const { reference, signup_details } = useSelector((state: RootState) => state.User);

    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [code, setCode] = useState('');
    const [btn, setBtn] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const payload = {
                details: signup_details,
                otp: {
                    code,
                    reference,
                }
            }

            let res;

            switch (type) {
                case 'phoneNotVerified':
                    res = await checkOtpCodeForLogin({ code, reference, phone: `+${phone?.replace(' ', '')}` });
                    break;
                case 'preSignUp':
                    res = await signUpWithUserEmailPhone(payload);
                    break;
                default:
                    break;
            }

            if (res?.isSuccess) {
                dispatch.User.login({ type: 'customToken', token: res?.message?.customToken });
            }
        } catch (err: any) {
            setLoading(false);
            setError(true);
            const errMessage = err?.response?.data?.error ?? 'Incorrect Code';
            setErrMsg(errMessage);
        }
    }

    return (
        <Paper
            elevation={3}
            sx={{
                width: { xs: '90vw', sm: '460px' },
                minHeight: '500px',
                padding: { xs: '10px 20px', sm: '30px 50px' },
                justifyContent: 'center',
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '1'
            }}
        >
            <img src={BRAND_IMAGES['BRAND_LOGO']} alt="" className='login_logo' />

            <div className='flex flex-col justify-start items-start'>
                <Span letters='wider' size='2xl' weight='bold'>One-Time Password</Span>
                <span className='mt-1 text-[13px] font-[500]'>Enter the code sent at +{phone?.replace(' ', '')} 📱</span>
            </div>

            {error && (
                <div className='text-center mt-4 w-full p-2 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                    <span className='text-xs text-red-500'>
                        {errMsg}
                    </span>
                </div>
            )}

            <form onSubmit={handleSubmit} className='w-full'>
                <PinInput
                    disabled={loading}
                    focus={true}
                    length={6}
                    secret={false}
                    style={{ padding: '10px 0' }}
                    inputStyle={{ margin: "5px", border: "none", fontSize: "20px" }}
                    onComplete={(value) => {
                        setCode(value);
                        setBtn(true);
                    }}
                    onChange={() => setBtn(false)}
                />

                <Button
                    shadow='grey'
                    padding='p-10'
                    margin='mt-6'
                    width='full'
                    disabled={!btn || loading}
                    type='submit'
                >
                    {loading ? (
                        <Spin className='login_spin' size='small' />
                    ) : 'VERIFY OTP'}
                </Button>
            </form>
        </Paper>
    )
}

export default Phone