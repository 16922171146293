import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { currencyCommaFormat, getPermission, onlyNumberKeyDotAndNegative } from '../../../../common/utils';
import { Span } from '../../../../components/common/Span';
import { Button } from '../../../../components/common/Button';
import { BiCloudUpload } from 'react-icons/bi';
import { Input } from 'antd';
import UpdateHdmfSettings from '../../../../components/ModuleComponents/UpdateHdmfSettings';
import Range from './Range';
import { FileSearchOutlined } from '@ant-design/icons';

const HDMF = () => {
    const mobile = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const permissions = getPermission('benefits', 'canViewHDMFSub');

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [open, setOpen] = useState(false);

    const data: any = useSelector((state: RootState) => state.Document.documents.hdmf_config?.data);

    const { Search } = Input;

    useEffect(() => {
        dispatch.Document.getFirebaseDoc({ name: 'config', document: 'hdmf_config', id: 'HDMF' })
    }, [])

    return (
        <>
            <div className='px-4'>
                <div className={`flex ${mobile ? 'flex-col items-center justify-center ' : 'justify-between'}`}>
                    <span className={`text-2xl mt-4 font-bold ${mobile ? 'w-full text-center' : 'truncate'}`}>Home Development Mutual Fund (Pag-IBIG) Configuration</span>
                    <div className={`flex ${mobile ? ' flex-col justify-center items-center w-full mt-4' : 'justify-end px-4 my-4'}`}>
                        {permissions?.includes('canEdit') && (
                            <Button
                                padding='py-1'
                                border='blue'
                                bg='none'
                                color='blue'
                                margin='mx-2'
                                {...(!mobile && {
                                    width: 'none'
                                })}
                            >
                                <div
                                    className='flex pr-2 items-center justify-center'
                                    onClick={() => {
                                        setAdd(true)
                                        history.push(`${pathname}?type=add`)
                                    }}
                                >
                                    <BiCloudUpload className='my-auto mx-2 text-2xl' />
                                    Update Settings
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <div className='overflow-auto h-[70vh] flex justify-center items-center flex-col'>
                {!data ? (
                    <div className='absolute left-0 right-0 p-24 flex justify-center flex-col items-center'>
                        <FileSearchOutlined style={{ fontSize: '6rem', color: 'gray' }} />
                        <Span color='lightgrey' size='2xl'>No Data</Span>
                    </div>
                ) : (
                    <>
                        <div className={`flex justify-center items-start flex-col p-4 ${mobile ? 'w-[95%]' : 'w-[60%]'}`}>
                            <span className='font-bold'>Sealing:</span>
                            <span className='text-justify font-semibold'>
                                {`Regardless of employee monthly salary and how much it will increase in the future as long as it’s equal to or above 
                    ${currencyCommaFormat(data?.sealing_amount ?? 0, 2, true)} employee salary deduction for monthly Pag-IBIG contribution will always be 
                    ${currencyCommaFormat(data?.sealing_eeshare_amount ?? 0, 2, true)}. Employer must remit that amount plus the employer counterpart contribution worth 
                    ${currencyCommaFormat(data?.sealing_ershare_amount ?? 0, 2, true)}. Employee total monthly savings as a Pag-IBIG member is 
                    ${currencyCommaFormat(data?.sealing_total_amount ?? 0, 2, true)}.`}
                            </span>
                        </div>
                        <div className={`p-2 ${mobile ? 'w-[95%]' : 'w-[60%]'} grid grid-cols-5 gap-1`}>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center col-span-2'>
                                <Span weight='semi' align='center'>Monthly Salary</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center' wrap={mobile ? 'singleLine' : 'none'}>{mobile ? 'EE Rate' : 'Employee’s Contribution Rate'}</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center' wrap={mobile ? 'singleLine' : 'none'}>{mobile ? 'ER Rate' : 'Employer’s Contribution Rate'}</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center'>Total</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center'>{mobile ? 'From' : 'Range From'}</Span>
                            </div>
                            <div className='p-2 border border-gray-300 border-solid flex items-center justify-center'>
                                <Span weight='semi' align='center'>{mobile ? 'To' : 'Range To'}</Span>
                            </div>
                            <div className='col-span-3'></div>
                            {(data?.ranges ?? [...Array(2)])?.map((range) => <Range data={range} />)}
                        </div>
                    </>
                )}
            </div >

            < UpdateHdmfSettings setAdd={setAdd} add={add} data={data} />
        </>
    )
}

export default HDMF