import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../../store';
import { Span } from '../../../../components/common/Span';
import { Button } from '../../../../components/common/Button';
import { useHistory, useLocation } from 'react-router-dom';
import View from './View';
import { addReport } from '../../../../common/api';

const ReportsView = ({ permissions, select, setSelect, contracts, setContracts }) => {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const dispatch = useDispatch<Dispatch>();

    const { userInfo } = useSelector((state: RootState) => state.User);
    const credentials = useSelector((state: RootState) => state.Table.tables?.credentials?.data);
    const batchContract = useSelector((state: RootState) => state.Table.tables?.contracts?.data)
        ?.find((c) => c?.id === select?.contractId);

    const organization = userInfo?.organization;
    const cred = credentials?.find(c => c?.organization?.id === organization?.id);

    const status = select?.isConfirmed ? 'confirmed' : select?.payslips?.some((res) => !res.isSaved) ? 'incomplete' : 'can confirm';
    const color = status === 'confirmed' ? 'brown' : status === 'can confirm' ? 'blue' : 'red';

    const handleCheckpayslip = () => {
        const payload = {
            contract: batchContract,
            salaryRange: {
                from: select?.reportRange[0],
                to: select?.reportRange[1]
            }
        }

        dispatch.Payroll.updateState(payload);
        history.push(`${pathname}?activeKey=4&id=${select?.id}`);
    }

    const handleConfirm = async () => {
        try {
            dispatch.UI.setIsLoading(true);

            const payload = {
                cred,
                report: select
            }
            console.log(payload);
            const res = await addReport(payload);

            if (res?.isSuccess) {
                dispatch.UI.setIsLoading(false);
                console.log(res?.message);
                dispatch.UI.setAlert({ type: 'Success', message: 'Success' })
                dispatch.Payroll.getListReports(null);
                history.push(`${pathname}?activeKey=6&id=${res?.id}`)
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to confirm';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
    }

    const handleClick = (type) => {
        switch (type) {
            case 'Check Payslip':
                handleCheckpayslip();
                return;
            case 'Confirm':
                handleConfirm();
                return;
            default:
                return;
        }
    }

    return (
        <div className='w-[800px] px-4 py-2 shadow-md'>
            <div className='w-full flex justify-between items-center px-4'>
                <Span letters='wider' align='center' transform='uppercase' size='xl' color={color} weight='bold'>{status}</Span>

                {permissions?.includes('canConfirm') && (
                    status !== 'confirmed' && (
                        <Button
                            padding='px-2'
                            border='blue'
                            bg='none'
                            color='blue'
                            radius='none'
                            width='36'
                            onClick={() => handleClick(status === 'incomplete' ? 'Check Payslip' : status === 'can confirm' ? 'Confirm' : 'Confirmed')}
                        >
                            <div className='flex w-full justify-center items-center'>
                                <Span color='blue' transform='uppercase' letters='wider'>
                                    {status === 'incomplete' ? 'Check Payslip' : status === 'can confirm' ? 'Confirm' : 'Confirmed'}
                                </Span>
                            </div>
                        </Button>
                    )
                )}
            </div>

            <View select={select} status={status} />
        </div>
    )
}

export default ReportsView