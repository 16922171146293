import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat, firebaseDateToText, removeSCAndCapitalize } from '../../../common/utils'
import { Span } from '../../common/Span'

const RenderContract = ({ item, dataType }) => {

    const role = item?.userInfo?.role?.name;

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-start">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize' weight='semi'>{item?.name}</Span>
                        <Span>{item?.empCount} Employees</Span>
                    </div>
                </div>
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Sick Leave: </span>
                    <Span transform='capitalize'>{item?.sickLeave}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Vacation Leave: </span>
                    <Span transform='capitalize'>{item?.vacationLeave}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>From: </span>
                    <span>{item?.week_from}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>To: </span>
                    <span>{item?.week_to}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Salary Cycle: </span>
                    <span>{removeSCAndCapitalize(item?.salary_cycle, '-')}</span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Date: </span>
                    <span>{firebaseDateToText(item?.createdAt, 'LLL')}</span>
                </div>
            </div>
        </Card>
    )
}

export default RenderContract