import React, { useEffect, useState } from 'react'
import Loader from '../../../../components/Loader'
import { useDispatch } from 'react-redux'
import { Dispatch } from '../../../../store'
import { useQuery } from '../../../../common/utils'
import designbg from '../../../../assets/images/login_design3.png'
import designbg1 from '../../../../assets/images/login_design.png'
import BackDrop from '../../../../components/BackDrop'
import Email from './Email'
import Phone from './Phone'

const Station = () => {
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const token = query.get('token');
    const qType = query.get('type');
    const qShow = query.get('show');
    const phone = query.get('phone');

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(qShow === 'true' ? true : false);
    const [type, setType] = useState(qType);

    const handleLogin = async () => {
        const res: any = dispatch.User.login({ token, type: 'customToken' });

        if (res?.notVerified) {
            setLoading(false);
            setType(res?.type);
            setShow(true);
        }
    }

    useEffect(() => {
        if (!qShow && !qType) {
            handleLogin();
        } else {
            setLoading(false);
        }
    }, [])

    return (
        <div className='min-h-screen overflow-hidden flex items-center justify-center'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />
            <Loader isLoading={loading} />
            <BackDrop />

            {show && (
                type === 'emailNotVerified' ? (
                    <Email />
                ) : (
                    <Phone phone={phone} type={qType} />
                )
            )}

        </div>
    )
}

export default Station