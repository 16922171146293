import { Avatar } from "antd";
import moment from "moment";
import { AiOutlineInbox } from "react-icons/ai";
import { currencyCommaFormat, firebaseDateToText } from "../../../common/utils";
import { Span } from "../../../components/common/Span";
import Options from "../../../components/ModuleComponents/Popover/Options";
import { Checkbox } from "@mui/material";

export const COLUMNS = [
  {
    id: "employee",
    field: "employee",
    Header: "Employee Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const employee = d?.userInfo;
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={employee?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{employee?.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{employee?.name}</Span>
        </div>
      )
    }
  },
  {
    id: "leave",
    accessor: 'leave',
    field: "leave",
    Header: "Unused Leave",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      const user = d?.userInfo;
      return (
        <div className=" flex justify-start items-start flex-col">
          <div className=" flex justify-start items-center flex-row w-full">
            <strong className="w-24 whitespace-nowrap mr-2">Sick Leave: </strong>
            <span className="capitalize truncate w-full"> {user?.sickLeave}</span>
          </div>

          <div className=" flex justify-start items-center flex-row w-full">
            <strong className="w-24 whitespace-nowrap mr-3">Vacation Leave:</strong>
            <span className="capitalize truncate w-full"> {user?.vacationLeave}</span>
          </div>
        </div>
      )
    }
  },
  {
    id: "position",
    accessor: 'position',
    field: "position",
    Header: "Position",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      const role = d?.userInfo?.role?.name;
      return (
        <>
          <Span transform='capitalize'>{d?.userInfo?.position} - {d?.userInfo?.organization.name}</Span>
          {role && (
            <Span transform='capitalize' color='lightgrey'>({role})</Span>
          )}
        </>
      )
    }
  },
  {
    id: "ratePerDay",
    field: "ratePerDay",
    Header: "Rate Per Day",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const rate = d?.userInfo?.rateAmount * 8;
      console.log(rate);
      return <span>{currencyCommaFormat(rate, 2, true)}</span>
    }
  },
  {
    id: "grossSalary",
    field: "grossSalary",
    Header: "Gross Salary",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <span>{currencyCommaFormat(d?.grossSalary, 2, true)}</span>
    }
  },
  {
    id: "deductions",
    field: "deductions",
    Header: "Deductions",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <span>{currencyCommaFormat(d?.totalDeductions, 2, true)}</span>
    }
  },
  {
    id: "netSalary",
    field: "netSalary",
    Header: "Net Salary",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <span>{currencyCommaFormat(d?.netSalary, 2, true)}</span>
    }
  }
];
