import { Avatar } from "antd";
import moment from "moment";
import { AiOutlineInbox } from "react-icons/ai";
import { currencyCommaFormat, firebaseDateToText, removeSCAndCapitalize } from "../../../common/utils";
import { Span } from "../../../components/common/Span";
import Options from "../../../components/ModuleComponents/Popover/Options";
import { Checkbox } from "@mui/material";

export const COLUMNS = [
  {
    id: "name",
    field: "name",
    Header: "Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{d.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d.name}</Span>
        </div>
      )
    }
  },
  {
    id: "employees",
    field: "employees",
    Header: "Employees",
    accessor: 'employees',
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original

      return <Span>{d?.empCount} Employees</Span>
    }
  },
  {
    id: "leave",
    accessor: 'leave',
    field: "leave",
    Header: "Leave",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return (
        <div className=" flex justify-start items-start flex-col">
          <div className=" flex justify-start items-center flex-row w-full">
            <strong className="w-32 whitespace-nowrap mr-2">Sick Leave: </strong>
            <span className="capitalize truncate w-full"> {d?.sickLeave}</span>
          </div>

          <div className=" flex justify-start items-center flex-row w-full">
            <strong className="w-32 whitespace-nowrap mr-3">Vacation Leave:</strong>
            <span className="capitalize truncate w-full"> {d?.vacationLeave}</span>
          </div>
        </div>
      )
    }
  },
  {
    id: "workDays",
    accessor: 'workDays',
    field: "workDays",
    Header: "Work Days",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return (
        <div className=" flex justify-start items-start flex-col">
          <div className=" flex justify-start items-center flex-row w-full">
            <strong className="w-24 whitespace-nowrap mr-2">From: </strong>
            <span className="capitalize truncate w-full"> {d?.week_from}</span>
          </div>

          <div className=" flex justify-start items-center flex-row w-full">
            <strong className="w-24 whitespace-nowrap mr-3">To:</strong>
            <span className="capitalize truncate w-full"> {d?.week_to}</span>
          </div>
        </div>
      )
    }
  },
  {
    id: "salary_cycle",
    accessor: 'salary_cycle',
    field: "salary_cycle",
    Header: "Salary Cycle",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return (
        <>
          <Span transform='capitalize'>{removeSCAndCapitalize(d?.salary_cycle, '-')}</Span>
        </>
      )
    }
  },
  {
    id: "createdAt",
    accessor: data => data.createdAt?.seconds,
    field: "createdAt",
    Header: "Date",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return <Span transform='capitalize'>{firebaseDateToText(d?.createdAt, 'LLL')}</Span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='contracts' subPermission='canViewContractSub' />
      )
    }
  }
];
