import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { currencyCommaFormat } from '../../../common/utils'
import { Span } from '../../common/Span'
import Options from '../../ModuleComponents/Popover/Options'
import { AiOutlineInbox } from 'react-icons/ai'

const RenderWithoutRate = ({ item, dataType }) => {

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.name}</Span>
                        <Span transform='capitalize'>{item?.position} - {item?.organization.name}</Span>
                        {item?.role && (
                            <Span transform='capitalize' color='lightgrey'>({item?.role?.name})</Span>
                        )}
                    </div>
                </div>

                <Options data={item} table={dataType} />
            </div>

            <div className='py-2 border-b border-solid border-gray-300'>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Sick Leave: </span>
                    <Span transform='capitalize'>Unused: {item?.sickLeave}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Vacation Leave: </span>
                    <Span transform='capitalize'>Unused: {item?.vacationLeave}</Span>
                </div>

                <div className='flex justify-start items-center'>
                    <span className=' font-semibold mr-1 w-28'>Hourly Rate: </span>
                    {item?.ratePerHour ? (
                        <span>{currencyCommaFormat(item?.ratePerHour, 2, true)}</span>
                    ) : (
                        <div className="emptyContainer flex flex-row justify-start items-center">
                            <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                            <Span color='lightgrey'>No Data</Span>
                        </div>
                    )}
                </div>

            </div>
        </Card>
    )
}

export default RenderWithoutRate