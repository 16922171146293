import { Checkbox } from 'antd'
import React, { forwardRef, useEffect, useRef, useState } from 'react'

const IndeterminateCheckbox = forwardRef((props: any, ref: any) => {
    const { indeterminate, ...rest } = props;

    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        if (resolvedRef?.current) {
            resolvedRef.current.indeterminate = indeterminate;
        }
    }, [resolvedRef, indeterminate]);

    return (
        <Checkbox
            {...rest}
            ref={resolvedRef}
            indeterminate={indeterminate}
        />
    )
})

export default IndeterminateCheckbox