import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { addLog } from '../../common/api';
import { Drawer } from 'antd';
import LogForm from './Forms/LogForm';
import { useHistory, useParams } from 'react-router-dom';
import DrawerFooter from './Footer/DrawerFooter';
import { useMediaQuery } from '@mui/material';

type Props = {
    table: string,
    setAdd: Function,
    add: boolean
}

type State = {
    date: string,
    timeIn: string,
    timeOut: string,
    userId: string,
    workBreakTotal: string,
}


const AddLog = ({ table, setAdd, add }: Props) => {
    const mobile = useMediaQuery('(max-width:600px)')
    const history = useHistory();
    const params: any = useParams();

    const initialState = {
        date: '',
        timeIn: '',
        timeOut: '',
        workBreakTotal: '',
    } as State;

    const validateSchema = yup.object({
        date: yup.string().required('Date is required field'),
        timeIn: yup.string().required('Clock in is required field'),
        timeOut: yup.string().required('Clock in is required field'),
        workBreakTotal: yup.string(),
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleRegister = async (values, actions) => {
        try {
            const payload = {
                ...values,
                timeIn: `${values?.date} ${values?.timeIn}`,
                timeOut: `${values?.date} ${values?.timeOut}`,
                userId: params.id
            }

            let res;

            switch (table) {
                case 'timesheet':
                    res = await addLog(payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                setAdd(false);
                history.goBack();
                actions.resetForm();
                dispatch.UI.setAlert({ type: 'Success', message: 'Log Successfully created' })
                dispatch.Table.getFirebaseData({ table, name: 'timesheet' })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to create Log'
            setErrorMesage(errMsg);
        }
    }

    return (
        <Formik
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleRegister(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Add Log"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            setAdd(false);
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={add}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => setAdd(false)}
                                setErrorMesage={setErrorMesage}
                                okText='Add Log'
                            />
                        }
                    >
                        {add && <LogForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default AddLog;