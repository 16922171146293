import React, { useState } from 'react'
import { currencyCommaFormat } from '../../../../common/utils';

const RenderItems = ({ items }) => {

    const [show, setShow] = useState(false);

    return (
        <div className="flex flex-col justify-start items-start w-52">
            {items?.filter((d, i) => show ? i < items?.length : i < 3)?.map((item: any, index: any) => (
                <span
                    key={index}
                    className='w-full capitalize truncate border-b border-gray-300 border-solid text-center'
                >
                    {item?.name}: {currencyCommaFormat(item?.amount, 2, true)}
                </span>
            ))}

            {items?.length > 3 && (
                <div className='pt-2 flex items-center justify-center w-full'>
                    <div
                        className='px-1 rounded-md border border-gray-300 border-solid cursor-pointer'
                        onClick={() => setShow(!show)}
                    >
                        <span className='text-[10px] font-bold tracking-wide'>{show ? 'HIDE' : 'SHOW MORE'}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RenderItems