import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { Container } from '../../components/common/Containers'
import Loader from '../../components/Loader';

import Table from '../../components/Table/Table';
import { COLUMNS } from './Column'
import { attendancePerEmployeeDataToDownloadV2, getPermission, useQuery } from '../../common/utils';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DatePicker, Input } from 'antd';
import { Span } from '../../components/common/Span';
import { Button } from '../../components/common/Button';
import { Button as AntBtn } from 'antd';
import { BiPlusCircle } from 'react-icons/bi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, DownloadOutlined, LeftOutlined } from '@ant-design/icons';
import AddLog from '../../components/ModuleComponents/AddLog';
import Card from '../../components/Card/Card';
import moment from 'moment';
import { utils, writeFileXLSX } from 'xlsx';

export default () => {
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const params: any = useParams();
    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname + location.search;
    const permissions = getPermission('timesheet');
    const mobile = useMediaQuery('(max-width:600px)')

    const [search, setSearch] = useState('');
    const [add, setAdd] = useState(false);
    const [date, setDate] = useState([moment().startOf('month'), moment()]) as any;

    const data: any = useSelector((state: RootState) => state.Table.tables.timesheet.data)
        .filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

    const { isLoading } = useSelector((state: RootState) => state.Table);

    const columns = useMemo(() => COLUMNS, []);
    const { RangePicker } = DatePicker;

    const { Search } = Input

    const handleChange = (date, dateString) => {
        console.log(date, dateString)
        if (!date || !dateString) {
            return setDate([moment().startOf('month'), moment()]);
        }
        setDate(dateString)
    }

    const exportFile = useCallback(() => {
        const PerEmployeeV2 = utils.json_to_sheet(attendancePerEmployeeDataToDownloadV2(data));
        const wb = utils.book_new();
        utils.book_append_sheet(wb, PerEmployeeV2, "Clock In Per Employee V2");
        writeFileXLSX(wb, `log_sheet_${moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}.xlsx`);
    }, [data]);

    useEffect(() => {

        const filters = {
            greater: {
                field: 'timesheetDate',
                operation: '>=',
                value: moment(date[0]).format('YYYY-MM-DD')
            },
            lower: {
                field: 'timesheetDate',
                operation: '<=',
                value: moment(date[1]).format('YYYY-MM-DD')
            },
            user: {
                field: 'userId',
                operation: '==',
                value: params.id
            }
        }

        dispatch.Table.setMultipleFilterValue({ table: 'timesheet', filters: filters })

        dispatch.Table.getFirebaseData({ table: 'timesheet', name: 'timesheet' });

    }, [date])

    return (<div className='relative'>
        <Loader isLoading={isLoading} />
        <Container border='grey' padding='none'>
            <div className='p-4'>
                <div className={`flex ${mobile ? 'flex-col items-center justify-center' : 'justify-between'}`}>
                    <div className='flex justify-start items-center'>
                        <AntBtn className=' cursor-pointer mr-4' type="primary" shape="circle" icon={<ArrowLeftOutlined />} size='small' onClick={() => history.goBack()} />
                        <Span weight='bold' size='2xl' transform='capitalize' align='center'>{query.get('name')} Log Sheet</Span>
                    </div>
                </div>
            </div>

            <div className={`flex w-full ${mobile ? 'flex-col-reverse justify-center items-center px-4 pb-4' : 'justify-start items-center p-4'}`}>
                <div className={`flex justify-start items-center w-full ${mobile ? 'flex-col-reverse' : 'flex-row'}`}>
                    <Search className={`${mobile ? 'w-full' : 'p-4'}`} placeholder="Search any..." onChange={(e: any) => setSearch(e.target.value)} style={{ width: mobile ? '100%' : 300 }} />
                    <RangePicker
                        style={{
                            width: mobile ? '100%' : '300px',
                            margin: '16px 0'
                        }}
                        allowClear
                        onChange={handleChange}
                        value={[moment(date[0]), moment(date[1])]}
                    />
                </div>

                <div className={`flex ${mobile ? 'flex-col space-y-2 w-full' : 'flex-row space-x-2'}`}>
                    {permissions.includes('canExport') && (
                        <Button
                            style={{ ...(!data?.length && { cursor: 'not-allowed' }) }}
                            disabled={!data?.length}
                            padding='p-2'
                            onClick={exportFile}
                            {...(!mobile && {
                                width: '36'
                            })}
                        >
                            <div className='flex w-full justify-center items-center'>
                                <Span padding='pr-2' color='white'>Download</Span>
                                <DownloadOutlined style={{ color: 'white' }} />
                            </div>
                        </Button>
                    )}

                    {permissions.includes('canAddLogs') && (
                        <Button
                            padding='p-2'
                            {...(!mobile && {
                                width: '36'
                            })}
                            onClick={() => {
                                setAdd(true)
                                history.push(`${pathname}&type=add`)
                            }}
                        >
                            <div className='flex w-full justify-center items-center'>
                                <Span padding='pr-2' color='white'>Add Log</Span>
                                <BiPlusCircle style={{ color: 'white' }} />
                            </div>
                        </Button>
                    )}

                </div>
            </div>

            <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                {mobile ? (
                    <Card data={data} dataType='timesheet' sortName='createdAt' />
                ) : (
                    <Table columns={columns} data={data} sortName='createdAt' />
                )}
            </div>

            <AddLog add={add} setAdd={setAdd} table='timesheet' />
        </Container>
    </div>
    )
}
