import { Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdminAccount, deleteCalendarEvent, deleteContract, deleteCredential, deleteEmployeeAccount, deleteOrganization, deleteRole, deleteWorkflow } from '../../../common/api';
import { Dispatch, RootState } from '../../../store';
import { Span } from '../../common/Span';

const DeleteModal = ({ deleteModal, setDeleteModal, table, id }) => {
    const dispatch = useDispatch<Dispatch>()

    const isLoading = useSelector((state: RootState) => state.UI.isLoading);

    const handleDelete = async () => {
        try {
            dispatch.UI.setIsLoading(true);

            let res;
            console.log(table, id);
            switch (table) {
                case 'employees':
                    res = await deleteEmployeeAccount(id);
                    break;
                case 'users':
                    res = await deleteAdminAccount(id);
                    break
                case 'organization':
                    res = await deleteOrganization(id);
                    break
                case 'role':
                    res = await deleteRole(id);
                    break
                case 'workflow':
                    res = await deleteWorkflow(id);
                    break
                case 'calendar':
                    res = await deleteCalendarEvent(id);
                    break
                case 'deductions':
                    dispatch.Payroll.deleteED({ name: 'deductions', id });
                    res = { isSuccess: true }
                    break
                case 'earnings':
                    dispatch.Payroll.deleteED({ name: 'earnings', id });
                    res = { isSuccess: true }
                    break
                case 'contracts':
                    res = await deleteContract(id);
                    break
                case 'credentials':
                    res = await deleteCredential(id);
                    break
                default:
                    dispatch.UI.setIsLoading(false);
                    dispatch.UI.setAlert({ type: 'Error', message: 'Invalid Table' })
                    return;
            }

            if (res?.isSuccess) {
                setDeleteModal(false);
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully deleted' })

                switch (table) {
                    case 'contracts':
                        dispatch.Table.getFirebaseData({ table, name: table });
                        dispatch.Payroll.updateState({ contract: null });
                        dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' });
                        break;
                    default:
                        dispatch.Table.getFirebaseData({ table, name: table });
                        return;
                }
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to delete';
            dispatch.UI.setAlert({ type: 'Error', message: errMsg })
        }
    }

    return (
        <Modal
            title="Delete!"
            cancelButtonProps={{ loading: isLoading }}
            okButtonProps={{ loading: isLoading }}
            onOk={() => handleDelete()}
            onCancel={() => {
                setDeleteModal(false)
            }}
            visible={deleteModal}
        >
            <Span>Are you sure, you want delete this data?</Span>
        </Modal>
    )
}

export default DeleteModal