import { currencyCommaFormat, firebaseDateToText } from "../../../../common/utils";
import { Span } from "../../../../components/common/Span";

export const COLUMNS = [
  {
    id: "compensation_range_from",
    field: "compensation_range_from",
    accessor: (data) => currencyCommaFormat(data?.compensation_range_from, 2, true),
    Header: "Range From",
    isVisible: true,
  },
  {
    id: "compensation_range_to",
    field: "compensation_range_to",
    Header: "Range To",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const showVal = d?.compensation_range_to >= 1000000 ? 'Over' : currencyCommaFormat(d?.compensation_range_to, 2, true);
      return <Span>{showVal}</Span>;
    }
  },
  {
    id: "employer_share",
    field: "employer_share",
    accessor: (data) => currencyCommaFormat(data?.employer_share, 2, true),
    Header: "Employer Share",
    isVisible: true,
  },
  {
    id: "employee_share",
    field: "employee_share",
    accessor: (data) => currencyCommaFormat(data?.employee_share, 2, true),
    Header: "Employee Share",
    isVisible: true,
  },
  {
    id: "total_contribution",
    field: "total_contribution",
    accessor: (data) => currencyCommaFormat(data?.total_contribution, 2, true),
    Header: "Total Contribution",
    isVisible: true,
  }
];
