import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button } from '../../common/Button';

interface Props {
    isSubmitting: boolean, 
    handleSubmit: Function, 
    resetForm: Function, 
    funcToRun: Function, 
    setErrorMesage: Function,
    okText: string
}

const DrawerFooter = ({ isSubmitting, handleSubmit, resetForm, funcToRun, setErrorMesage, okText } : Props) => {
    const history = useHistory();

    return (
        <div className='w-full p-4 flex flex-row justify-end items-center'>
            <Button
                disabled={isSubmitting}
                margin='mx-4'
                bg='none'
                color='black'
                padding='px-4'
                onClick={() => {
                    funcToRun();
                    history.goBack();
                    setErrorMesage(null);
                    resetForm();
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={() => {
                    handleSubmit();
                }}
                disabled={isSubmitting}
                padding='px-4'
            >
                {isSubmitting ? <CircularProgress color='success' size={18} /> : okText}
            </Button>
        </div>
    )
}

export default DrawerFooter