import { createElement, useEffect } from 'react';
import { UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined, CalendarOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { BRAND_IMAGES } from '../constants';
import { AntDesignOutlined, UserSwitchOutlined, ShoppingOutlined } from '@ant-design/icons';
import { NavLink, useLocation, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { ImClock } from 'react-icons/im';
import { VscOrganization } from 'react-icons/vsc';
import { MdOutlineManageAccounts, MdManageAccounts } from 'react-icons/md';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsCalendarCheck } from 'react-icons/bs';
import { FcWorkflow } from 'react-icons/fc'
import { GiTakeMyMoney } from 'react-icons/gi'
import { FaWpforms } from 'react-icons/fa';
import './Sidebar.css'
import { useMediaQuery } from '@mui/material';
import MobileDrawer from './MobileDrawer';
import { RiGovernmentLine } from 'react-icons/ri';
import { HiOutlineUsers } from 'react-icons/hi';
import { IoIosPeople } from 'react-icons/io';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;
interface Props {
  collapsed: boolean;
  onToggle?: () => void;
}

export default function SideBar(children) {
  const dispatch = useDispatch<Dispatch>();
  const mobile = useMediaQuery('(max-width:600px)')

  const collapsed = useSelector((state: RootState) => state.UI.sideBarCollapse);
  const { openKeys } = useSelector((state: RootState) => state.UI);
  const roleModules = useSelector((state: RootState) => state.User.roleModules);

  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/home" || location.pathname === ""
      ? "/home"
      : location.pathname,
  );

  const onToggle = () => {
    dispatch.UI.updateState({ sideBarCollapse: !collapsed })
  }

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);


  function handleClick(e: any) {
    setCurrent(e.key);
  }

  return (
    <>
      {mobile ? (
        <MobileDrawer />
      ) : (
        <Sider
          collapsible
          width={210}
          collapsedWidth={60}
          breakpoint="lg"
          collapsed={collapsed}
          onCollapse={value => dispatch.UI.updateState({ sideBarCollapse: value })}
          trigger={
            <div
              className={classNames(
                'sider-bar__trigger',
                collapsed && 'sider-bar__trigger--collapsed',
              )}
            >
              {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                onClick: onToggle,
              })}
            </div>
          }>
          {!collapsed && (
            <div className='p-5'>
              <img src={BRAND_IMAGES['BRAND_LOGO_WHITE']} alt='logo' className='w-full' />
            </div>
          )}

          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={[current?.split('/')[1]]}
            onClick={handleClick}
            openKeys={[openKeys]}
            style={{
              marginTop: !collapsed ? 0 : '58px'
            }}
          >
            <Menu.Item
              key="home"
              icon={<AntDesignOutlined />}
              onClick={() => {
                dispatch.UI.updateState({ openKeys: '' })
              }}
            >
              <NavLink to="/home">Home</NavLink>
            </Menu.Item>

            {/* <Menu.Item
          key="admin"
          icon={<RiAdminFill />}
          onClick={() => {
            dispatch.UI.updateState({ openKeys: '' })
          }}
        >
          <NavLink to="/admin">Admin</NavLink>
        </Menu.Item> */}

            {roleModules.includes('employees') && (
              <Menu.Item
                key="employees"
                icon={<UserOutlined />}
                onClick={() => {
                  dispatch.UI.updateState({ openKeys: '' })
                }}
              >
                <NavLink to="/employees">Employees</NavLink>
              </Menu.Item>
            )}

            {roleModules.includes('timesheet') && (
              <Menu.Item
                key="timesheet"
                icon={<ImClock />}
                onClick={() => {
                  dispatch.UI.updateState({ openKeys: '' })
                }}
              >
                <NavLink to="/timesheet">Timesheet</NavLink>
              </Menu.Item>
            )}

            {roleModules.includes('organization') && (
              <Menu.Item
                key="organization"
                icon={<VscOrganization size={16} />}
                onClick={() => {
                  dispatch.UI.updateState({ openKeys: '' })
                }}
              >
                <NavLink to="/organization">Organization</NavLink>
              </Menu.Item>
            )}

            {roleModules.includes('role') && (
              <Menu.Item
                key="role"
                icon={<MdOutlineManageAccounts size={16} />}
                onClick={() => {
                  dispatch.UI.updateState({ openKeys: '' })
                }}
              >
                <NavLink to="/role">Role</NavLink>
              </Menu.Item>
            )}

            {/* {roleModules.includes('workflow') && (
              <Menu.Item
                key="workflow"
                icon={<FcWorkflow />}
                onClick={() => {
                  dispatch.UI.updateState({ openKeys: '' })
                }}
              >
                <NavLink to="/workflow">Workflow</NavLink>
              </Menu.Item>
            )} */}

            <SubMenu
              key='requests'
              title='Requests'
              icon={<FaWpforms />}
              onTitleClick={() => {
                if (openKeys !== 'requests') {
                  dispatch.UI.updateState({ openKeys: 'requests' })
                } else {
                  dispatch.UI.updateState({ openKeys: '' })
                }
              }}
            >
              {roleModules.includes('log') && (
                <Menu.Item
                  key="log"
                  icon={<BsCalendarCheck size={13} />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/log">Manual Log</NavLink>
                </Menu.Item>
              )}

              {roleModules.includes('overtime') && (
                <Menu.Item
                  key="overtime"
                  icon={<ImClock />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/overtime">Overtime</NavLink>
                </Menu.Item>
              )}

              {roleModules.includes('leave') && (
                <Menu.Item
                  key="leave"
                  icon={<AiTwotoneCalendar size={16} />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/leave">Leave</NavLink>
                </Menu.Item>
              )}
            </SubMenu>

            <SubMenu
              key='payroll_management'
              title='Payroll Mgmt.'
              icon={<MdManageAccounts />}
              onTitleClick={() => {
                if (openKeys !== 'payroll_management') {
                  dispatch.UI.updateState({ openKeys: 'payroll_management' })
                } else {
                  dispatch.UI.updateState({ openKeys: '' })
                }
              }}
            >
              {roleModules.includes('payroll') && (
                <Menu.Item
                  key="payroll"
                  icon={<GiTakeMyMoney />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/payroll">Payroll</NavLink>
                </Menu.Item>
              )}

              {roleModules.includes('benefits') && (
                <Menu.Item
                  key="benefits"
                  icon={<RiGovernmentLine />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/benefits">Benefits</NavLink>
                </Menu.Item>
              )}

              {roleModules.includes('calendar') && (
                <Menu.Item
                  key="calendar"
                  icon={<CalendarOutlined />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/calendar">Calendar</NavLink>
                </Menu.Item>
              )}

              {roleModules.includes('credentials') && (
                <Menu.Item
                  key="credentials"
                  icon={<HiOutlineUsers />}
                  onClick={() => {
                    if (collapsed) {
                      dispatch.UI.updateState({ openKeys: '' })
                    }
                  }}
                >
                  <NavLink to="/credentials">Credentials</NavLink>
                </Menu.Item>
              )}
            </SubMenu>

            <Menu.Item
              key="referral"
              icon={<IoIosPeople size={16} />}
              onClick={() => {
                dispatch.UI.updateState({ openKeys: '' })
              }}
            >
              <NavLink to="/referral">Referral</NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
      )}

    </>
  );
}; 