import Attachment from '../containers/Authentication/OtpEmail';

const OtpEmail = () => {
    return (
        <>
            <Attachment />
        </>
    )
}

export default OtpEmail
