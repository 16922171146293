import React from "react";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
  exact: boolean;
}

const AuthRoute: React.FC<MyRouteProps> = ({ component: Component, ...rest }) => {

  const location = useLocation();
  const pathname = location.pathname;
  const currentModule = pathname?.split('/')[1];

  const { isUserLoggedIn, roleModules, claims } = useSelector((state: RootState) => state.User);

  const isEmailVerified = pathname === '/otp-email' && (claims.email_verified || claims.emailVerified) ? true : false; 
  const isAuthorized = ['setup', 'home', 'referral', 'otp-email', ...roleModules].includes(currentModule) && !isEmailVerified ? true : false;

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          isAuthorized ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
