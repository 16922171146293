import { AntDesignOutlined, CalendarOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from '@mui/material';
import { Drawer, Menu } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlineLogout, AiTwotoneCalendar } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { ImClock } from 'react-icons/im';
import { MdManageAccounts, MdOutlineManageAccounts } from 'react-icons/md';
import { VscOrganization } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Dispatch, RootState } from '../../store';
import { BRAND_IMAGES } from '../constants';
import { FcWorkflow } from 'react-icons/fc';
import { BsCalendarCheck } from 'react-icons/bs';
import { GiTakeMyMoney } from 'react-icons/gi';
import { RiGovernmentLine } from 'react-icons/ri';
import { FaWpforms } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';

const MobileDrawer = () => {
    const history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch<Dispatch>();

    const [current, setCurrent] = useState(
        location.pathname === "/home" || location.pathname === ""
            ? "/home"
            : location.pathname,
    );

    const { openKeys, openMobileDrawer } = useSelector((state: RootState) => state.UI);
    const roleModules = useSelector((state: RootState) => state.User.roleModules);
    const user: any = useSelector((state: RootState) => state.User?.userInfo) as any;

    const SubMenu = Menu.SubMenu;

    function handleClick(e: any) {
        setCurrent(e.key);
        dispatch.UI.updateState({ openMobileDrawer: false });
    }

    const handleClickMyAccount = () => {
        history.push('/employees?type=update')
        dispatch.UI.updateFormId({ target: 'currentUpdateId', value: user?.id });
    }

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    return (
        <Drawer
            placement='left'
            onClose={() => dispatch.UI.updateState({ openMobileDrawer: false })}
            closable={false}
            visible={openMobileDrawer}
            width='60%'
            bodyStyle={{
                padding: '0px',
                backgroundColor: '#001529'
            }}
        >
            <div className='p-5'>
                <img src={BRAND_IMAGES['BRAND_LOGO_WHITE']} alt='logo' className='w-full' />
            </div>

            <div className='w-full border-t border-solid border-gray-500 my-4' />

            <div className='flex flex-col items-center justify-center' >
                <Avatar sx={{ backgroundColor: '#1890ff', width: 40, height: 40 }} >
                    <span className='text-base capitalize'>{(user?.name).substring(0, 1)}</span>
                </Avatar>

                <span className='mx-3 my-3 text-white capitalize'>{user?.name ?? ''}</span>
            </div>

            <Menu
                theme='dark'
                mode='inline'
                selectedKeys={[current?.split('/')[1]]}
                onClick={handleClick}
                openKeys={[openKeys]}
            >
                <Menu.Item
                    key="home"
                    icon={<AntDesignOutlined />}
                    onClick={() => {
                        dispatch.UI.updateState({ openKeys: '' })
                    }}
                >
                    <NavLink to="/home">Home</NavLink>
                </Menu.Item>

                {roleModules.includes('employees') && (
                    <Menu.Item
                        key="employees"
                        icon={<UserOutlined />}
                        onClick={() => {
                            dispatch.UI.updateState({ openKeys: '' })
                        }}
                    >
                        <NavLink to="/employees">Employees</NavLink>
                    </Menu.Item>
                )}

                {roleModules.includes('timesheet') && (
                    <Menu.Item
                        key="timesheet"
                        icon={<ImClock />}
                        onClick={() => {
                            dispatch.UI.updateState({ openKeys: '' })
                        }}
                    >
                        <NavLink to="/timesheet">Timesheet</NavLink>
                    </Menu.Item>
                )}

                {roleModules.includes('organization') && (
                    <Menu.Item
                        key="organization"
                        icon={<VscOrganization size={16} />}
                        onClick={() => {
                            dispatch.UI.updateState({ openKeys: '' })
                        }}
                    >
                        <NavLink to="/organization">Organization</NavLink>
                    </Menu.Item>
                )}

                {roleModules.includes('role') && (
                    <Menu.Item
                        key="role"
                        icon={<MdOutlineManageAccounts size={16} />}
                        onClick={() => {
                            dispatch.UI.updateState({ openKeys: '' })
                        }}
                    >
                        <NavLink to="/role">Role</NavLink>
                    </Menu.Item>
                )}

                {/* {roleModules.includes('workflow') && (
                    <Menu.Item
                        key="workflow"
                        icon={<FcWorkflow />}
                        onClick={() => {
                            dispatch.UI.updateState({ openKeys: '' })
                        }}
                    >
                        <NavLink to="/workflow">Workflow</NavLink>
                    </Menu.Item>
                )} */}

                <SubMenu
                    key='requests'
                    title='Requests'
                    icon={<FaWpforms />}
                    onTitleClick={() => {
                        if (openKeys !== 'requests') {
                            dispatch.UI.updateState({ openKeys: 'requests' })
                        } else {
                            dispatch.UI.updateState({ openKeys: '' })
                        }
                    }}
                >
                    {roleModules.includes('leave') && (
                        <Menu.Item
                            key="leave"
                            icon={<AiTwotoneCalendar size={16} />}
                            onClick={() => {
                                dispatch.UI.updateState({ openKeys: '' })
                            }}
                        >
                            <NavLink to="/leave">Leave</NavLink>
                        </Menu.Item>
                    )}

                    {roleModules.includes('log') && (
                        <Menu.Item
                            key="log"
                            icon={<BsCalendarCheck size={13} />}
                            onClick={() => {
                                dispatch.UI.updateState({ openKeys: '' })
                            }}
                        >
                            <NavLink to="/log">Manual Log</NavLink>
                        </Menu.Item>
                    )}

                    {roleModules.includes('overtime') && (
                        <Menu.Item
                            key="overtime"
                            icon={<ImClock />}
                            onClick={() => {
                                dispatch.UI.updateState({ openKeys: '' })
                            }}
                        >
                            <NavLink to="/overtime">Overtime</NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>

                <SubMenu
                    key='payroll_management'
                    title='Payroll Mgmt.'
                    icon={<MdManageAccounts />}
                    onTitleClick={() => {
                        if (openKeys !== 'payroll_management') {
                            dispatch.UI.updateState({ openKeys: 'payroll_management' })
                        } else {
                            dispatch.UI.updateState({ openKeys: '' })
                        }
                    }}
                >
                    {roleModules.includes('payroll') && (
                        <Menu.Item
                            key="payroll"
                            icon={<GiTakeMyMoney />}
                            onClick={() => {
                                dispatch.UI.updateState({ openKeys: '' })
                            }}
                        >
                            <NavLink to="/payroll">Payroll</NavLink>
                        </Menu.Item>
                    )}

                    {roleModules.includes('benefits') && (
                        <Menu.Item
                            key="benefits"
                            icon={<RiGovernmentLine />}
                            onClick={() => {
                                dispatch.UI.updateState({ openKeys: '' })
                            }}
                        >
                            <NavLink to="/benefits">Benefits</NavLink>
                        </Menu.Item>
                    )}

                    {roleModules.includes('calendar') && (
                        <Menu.Item
                            key="calendar"
                            icon={<CalendarOutlined />}
                            onClick={() => {
                                dispatch.UI.updateState({ openKeys: '' })
                            }}
                        >
                            <NavLink to="/calendar">Calendar</NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>

                <div className='w-full border-t border-solid border-gray-500 my-4' />

                <Menu.Item
                    key="my_account"
                    icon={<CgProfile size={16} />}
                    onClick={() => {
                        dispatch.UI.updateState({ openMobileDrawer: false });
                        handleClickMyAccount();
                    }}
                >
                    <NavLink to="">My account</NavLink>
                </Menu.Item>

                <Menu.Item
                    key="my_account"
                    icon={<AiOutlineLogout size={16} />}
                    onClick={() => {
                        dispatch.User.logout(null);
                    }}
                >
                    <NavLink to="">Logout</NavLink>
                </Menu.Item>

                <Menu.Item
                    key="referral"
                    icon={<IoIosPeople size={16} />}
                    onClick={() => {
                        dispatch.UI.updateState({ openKeys: '' })
                    }}
                >
                    <NavLink to="/referral">Referral</NavLink>
                </Menu.Item>
            </Menu>

        </Drawer >
    )
}

export default MobileDrawer