import React, { useEffect, useState } from 'react'
import { Span } from '../../common/Span';
import { Input, InputMask } from '../../common/Input';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { onlyNumberKey, useQuery } from '../../../common/utils';
import { Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    isSubmitting: boolean,
    errMesssage: string,
    setFieldValue: Function,
    user?: any
}


const UserForm = ({ user, setFieldValue, values, handleChange, handleBlur, errors, touched, errMesssage }: Props) => {
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const type: any = query.get('type');

    const [allDataLoaded, setAllDataLoaded] = useState(false);


    const role = useSelector((state: RootState) => state.Table.tables.role?.data)
        .filter(r => r?.id !== values?.role?.id)
        .filter(r => r.status === 'active');
    const roleModules = useSelector((state: RootState) => state.User.roleModules);
    const organizations = useSelector((state: RootState) => state.Table.tables.organization.data)
        .filter(o => values?.organization?.id !== o?.id);

    const isAuthorizedToUpdateRole = roleModules.includes('role') && user?.admin;
    const isAuthorizedToUpdateOrg =  roleModules.includes('organization') ? true : false;

    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        setVisible(!visible);
    }

    useEffect(() => {
        setAllDataLoaded(true);
        dispatch.Table.getFirebaseData({ table: 'role', name: 'role' })
    }, [])

    return (
        <div className='px-2 text-left'>
            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Name</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.name}
                onChange={handleChange('name')}
                placeholder='Enter name'
                onBlur={handleBlur('name')}
            />
            {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Phone number</Span>
                <Span color='red'>*</Span>
            </div>
            <div className='border border-grey-1 flex flex-row pr-4 items-center'>
                <Span padding='pl-2'>+63</Span>
                <Input
                    border='none'
                    value={values.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    placeholder='Enter phone number'
                    onBlur={handleBlur('phoneNumber')}
                    onKeyPress={onlyNumberKey}
                    maxLength={10}
                />
            </div>
            {errors.phoneNumber && touched.phoneNumber && <Span color='red' size='xs'>{errors.phoneNumber}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Email</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.email}
                onChange={handleChange('email')}
                placeholder='Enter email ex:(your_email@gmail.com)'
                onBlur={handleBlur('email')}
            />
            {errors.email && touched.email && <Span color='red' size='xs'>{errors.email}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Date of birth</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.bday}
                onChange={handleChange('bday')}
                placeholder='Enter birth day'
                onBlur={handleBlur('bday')}
                type='date'
                max="9999-12-31"
                min="0000-01-01"
            />
            {errors.bday && touched.bday && <Span color='red' size='xs'>{errors.bday}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Password</Span>
                <Span margin='mt-4' color={type === 'add' ? 'red' : 'lightgrey'}>{type === 'add' ? '*' : "(Optional)"}</Span>
            </div>
            <div className='border border-grey-1 flex flex-row pr-4 items-center'>
                <Input
                    border='none'
                    value={values.password}
                    onChange={handleChange('password')}
                    placeholder='Enter password'
                    onBlur={handleBlur('password')}
                    type={visible ? 'text' : 'password'}
                />
                <div className='cursor-pointer' onClick={toggleVisibility}>
                    {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </div>
            </div>
            {errors.password && touched.password && <Span color='red' size='xs'>{errors.password}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Employee No.</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                value={values.employeeNumber}
                onChange={handleChange('employeeNumber')}
                placeholder='Enter employee number'
                onBlur={handleBlur('employeeNumber')}
            />
            {errors.employeeNumber && touched.employeeNumber && <Span color='red' size='xs'>{errors.employeeNumber}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Sick Leave Entitlement</Span>
                <Span color='red'>*</Span>
            </div>
            <InputMask
                mask='00'
                unmask={true}
                value={values?.sickLeave}
                onAccept={(value, mask) => setFieldValue('sickLeave', value)}
                placeholder='Enter number of days'
                onBlur={handleBlur('sickLeave')}
            />
            {errors.sickLeave && touched.sickLeave && <Span color='red' size='xs'>{errors.sickLeave}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Vacation Leave Entitlement</Span>
                <Span color='red'>*</Span>
            </div>
            <InputMask
                mask='00'
                unmask={true}
                value={values?.vacationLeave}
                onAccept={(value, mask) => setFieldValue('vacationLeave', value)}
                placeholder='Enter number of days'
                onBlur={handleBlur('vacationLeave')}
            />
            {errors.vacationLeave && touched.vacationLeave && <Span color='red' size='xs'>{errors.vacationLeave}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Position</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.position}
                onChange={handleChange('position')}
                placeholder='Enter position'
                onBlur={handleBlur('position')}
            />
            {errors.position && touched.position && <Span color='red' size='xs'>{errors.position}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Department</Span>
                <Span color='red'>*</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.department}
                onChange={handleChange('department')}
                placeholder='Enter department'
                onBlur={handleBlur('department')}
            />
            {errors.department && touched.department && <Span color='red' size='xs'>{errors.department}</Span>}

            <div className='flex flex-row justify-start items-center'>
                <Span margin='mt-4'>Group</Span>
            </div>
            <Input
                style={{ textTransform: 'capitalize' }}
                value={values.group}
                onChange={handleChange('group')}
                placeholder='Enter group'
                onBlur={handleBlur('group')}
            />
            {errors.group && touched.group && <Span color='red' size='xs'>{errors.group}</Span>}

            {allDataLoaded && (
                <>
                    <div className='flex flex-row justify-start items-center'>
                        <Span margin='mt-4'>Organization</Span>
                        <Span color='red'>*</Span>
                    </div>
                    <Autocomplete
                        disabled={!isAuthorizedToUpdateOrg}
                        disablePortal
                        disableClearable
                        filterSelectedOptions
                        size="small"
                        id="combo-box-demo"
                        options={organizations}
                        value={values?.organization}
                        onChange={(e, newValue) => {
                            setFieldValue('organization', newValue);
                        }}
                        onBlur={handleBlur('organization')}
                        getOptionLabel={(option: any) => option.name}
                        ListboxProps={{
                            style: {
                                maxHeight: '220px',
                            }
                        }}
                        renderOption={(props, option: any) => {
                            return (<>
                                <li {...props}>
                                    <div className="flex flex-row items-center justify-center">
                                        {/* <img src={location_icon} alt="" className="w-6 h-6 object-contain" /> */}
                                        <Span transform='capitalize'>{option?.name}</Span>
                                    </div>
                                </li>
                            </>)
                        }}
                        renderInput={(params) => (
                            <TextField
                                placeholder='Select Organization...'
                                sx={{
                                    '& legend': {
                                        display: 'none'
                                    },
                                    '& fieldset': {
                                        top: 0
                                    },
                                }}
                                {...params}
                            />
                        )}
                    />
                    {errors.organization && touched.organization && <Span color='red' size='xs'>{errors.organization}</Span>}
                </>
            )}

            {allDataLoaded && isAuthorizedToUpdateRole && (
                <>
                    <div className='flex flex-row justify-start items-center'>
                        <Span margin='mt-4'>Role</Span>
                        <Span color='red'>*</Span>
                    </div>

                    <Autocomplete
                        disablePortal
                        disableClearable
                        filterSelectedOptions
                        size="small"
                        id="combo-box-demo"
                        options={role}
                        value={values?.role}
                        onChange={(e, newValue) => {
                            setFieldValue('role', newValue);
                        }}
                        onBlur={handleBlur('role')}
                        getOptionLabel={(option: any) => option.name}
                        ListboxProps={{
                            style: {
                                maxHeight: '220px',
                            }
                        }}
                        renderOption={(props, option: any) => {
                            return (<>
                                <li {...props}>
                                    <div className="flex flex-row items-center justify-center">
                                        {/* <img src={location_icon} alt="" className="w-6 h-6 object-contain" /> */}
                                        <Span transform='capitalize'>{option?.name}</Span>
                                    </div>
                                </li>
                            </>)
                        }}
                        renderInput={(params) => (
                            <TextField
                                placeholder='Select Role...'
                                sx={{
                                    '& legend': {
                                        display: 'none'
                                    },
                                    '& fieldset': {
                                        top: 0
                                    },
                                }}
                                {...params}
                            />
                        )}
                    />

                    {errors.role && touched.role && <Span color='red' size='xs'>{errors.role}</Span>}
                </>
            )}

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
            {/* 
            <div className='p-4 flex flex-row justify-end items-center'>
                <Button
                    disabled={isSubmitting}
                    margin='mx-4'
                    bg='none'
                    color='black'
                    onClick={() => {
                        if (type === 'add') {
                            if (setAdd) setAdd(false)
                        } else {
                            dispatch.UI.resetForm('currentUpdateId')
                        }
                        setErrorMesage(null);
                        resetForm();
                    }}
                >
                    Cancel
                </Button>
                <Button onClick={() => handleSubmit()} disabled={isSubmitting} >
                    {isSubmitting ? <CircularProgress color='success' size={18} /> : 'Submit'}
                </Button>
            </div> */}

        </div>
    )
}

export default UserForm