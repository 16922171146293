import { Avatar } from '@mui/material'
import { Card } from 'antd'
import { AiOutlineInbox } from 'react-icons/ai'
import { Span } from '../../common/Span'

const RenderDashboard = ({ item, dataType }) => {

    return (
        <Card>
            <div className='flex justify-between items-center pb-2 border-b border-solid border-gray-300'>
                <div className="flex flex-row justify-start items-center">
                    <Avatar src={item?.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{item?.name?.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Span transform='capitalize'>{item?.name}</Span>
                        <Span transform='capitalize' color='lightgrey'>{item?.status}</Span>
                    </div>
                </div>
            </div>

            <div className='py-2'>
                <div className='flex justify-start items-start'>
                    <Span weight='semi' margin='mx-2'>Time in: </Span>

                    <>
                        {item?.time ? (
                            <span className="capitalize">{item?.time}</span>
                        ) : (
                            <div className="emptyContainer flex flex-row justify-start items-center">
                                <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
                                <Span color='lightgrey'>No Data</Span>
                            </div>
                        )}
                    </>
                </div>

                <div className='flex justify-start items-start'>
                    <Span weight='semi' margin='mx-2'>Task: </Span>
                    <Span margin='mx-2' transform='capitalize'>{item?.task}</Span>
                </div>
            </div>
        </Card>
    )
}

export default RenderDashboard