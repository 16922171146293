import { Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setEmployeeAsAdmin } from '../../../common/api';
import { Dispatch, RootState } from '../../../store';
import { Span } from '../../common/Span';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../common/Button';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const EventOption = ({ open, setOpen, setDeleteModal, id, permissions }) => {
    const dispatch = useDispatch<Dispatch>()
    const pathname = useLocation().pathname;
    const history = useHistory();

    const showUpdate = () => {
        setOpen(false);
        history.push(`${pathname}?type=update`)
        dispatch.UI.updateFormId({ target: 'currentUpdateId', value: id });
    }

    return (
        <Modal
            title="Options"
            onCancel={() => setOpen(false)}
            visible={open}
            footer={null}
        >
            <div className='grid grid-cols-2 gap-5'>
                <Button
                    disabled={!permissions?.includes('canDelete')}
                    padding='px-8'
                    onClick={() => {
                        setOpen(false);
                        setDeleteModal(true);
                    }}
                    bg='red'
                >
                    <div className='flex w-full justify-center items-center'>
                        <Span padding='pr-2' color='white'>Delete</Span>
                        <DeleteOutlined style={{ color: 'white' }} />
                    </div>
                </Button>

                <Button
                    padding='px-8'
                    onClick={showUpdate}
                    disabled={!permissions?.includes('canEdit')}
                >
                    <div className='flex w-full justify-center items-center'>
                        <Span padding='pr-2' color='white'>Update</Span>
                        <EditOutlined style={{ color: 'white' }} />
                    </div>
                </Button>
            </div>
        </Modal>
    )
}

export default EventOption