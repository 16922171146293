import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { Container } from '../../../components/common/Containers'
import Table from '../../../components/Table/Table';
import { COLUMNS } from './Column'
import { useMediaQuery } from '@mui/material';
import Card from '../../../components/Card/Card';
import TitleAndButtons from './TitleAndButtons';
import { useHistory, useLocation } from 'react-router-dom';

export default () => {
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)');
    const pathname = useLocation().pathname;
    const history = useHistory();

    const [search, setSearch] = useState('');

    const deferredSearch = useDeferredValue(search);

    const { data: items, contract }: any = useSelector((state: RootState) => state.Payroll);
    const { userInfo } = useSelector((state: RootState) => state.User);

    const data = useMemo(() => {
        const result = items?.filter(d => {
            if (JSON.stringify(d).search(new RegExp(search, 'i')) !== -1) {
                return true;
            }
            return false
        });

        return result;
    }, [items, deferredSearch])

    const columns = useMemo(() => COLUMNS, []);

    const handleRowClick = (row) => {
        history.push(`${pathname}?activeKey=4&id=${row?.original?.userInfo?.id}`)
    }

    useEffect(() => {
        dispatch.Table.getFirebaseData({ table: 'employees', name: 'employees' })
        dispatch.Document.getFirebaseDoc({ name: 'payroll_config', document: 'payroll_config', id: userInfo?.organization?.id });
    }, []);

    useEffect(() => {
        dispatch.Payroll.updateState({ payroll_list_count: data?.length })
    }, [data]);

    return (
        <div className='relative'>
            <Container padding='none'>
                {contract && <TitleAndButtons data={data} search={search} setSearch={setSearch} />}

                <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
                    {mobile ? (
                        <Card data={data} dataType='payroll_list' sortName='createdAt' />
                    ) : (
                        <Table columns={columns} data={data} sortName='createdAt' isRowClick={true} handleRowClick={handleRowClick} />
                    )}
                </div>
            </Container >
        </div >
    )
}
