import { Checkbox } from '@mui/material';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Span } from '../../../common/Span';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RenderOption = ({ employees, option, props, values, selected, setFieldValue }) => {

    const isSelected = employees?.length === values?.employees?.length ? true : false;
    const isSelectAll = option?.name === 'Select all';

    const handleClickSelectAll = () => {
        if (isSelected) {
            setFieldValue('employees', []);
        }
    }

    return (
        <li {...props} {...(isSelectAll && isSelected && { onClick: handleClickSelectAll })}>
            <div className=' flex flex-row justify-start items-center w-full'>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={isSelectAll ? isSelected : selected}
                />
                <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{option?.name?.charAt(0).toUpperCase()}</Avatar>
                <div className='flex flex-col justify-start items-start'>
                    <Span transform='capitalize'>{option?.name}</Span>
                    {option?.organization && (
                        <Span transform='capitalize' color='grey'>{option?.role?.name ?? option?.position} - ({option?.organization?.name})</Span>
                    )}
                </div>
            </div>
        </li>
    )
}

export default RenderOption