import { Modal } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setEmployeeAsAdmin } from '../../../common/api';
import { Dispatch, RootState } from '../../../store';
import { Span } from '../../common/Span';
import { Formik } from 'formik';
import * as yup from 'yup';
import { InputContainer } from '../../common/Containers';
import { Input } from '../../common/Input';
import { Textarea } from '../../common/Textarea';
import { sendChangePlanEmail } from '../../../common/api/endpoints/client';

type State = {
    name: string,
    email: string,
    message: string,
}

const ContactUs = ({ contactUs, setContactUs }) => {
    const dispatch = useDispatch<Dispatch>()

    const initialState = {
        name: '',
        email: '',
        message: '',
    } as State;

    const validateSchema = yup.object({
        name: yup.string().required('Name is required field'),
        email: yup.string().email('Should be valid email example(youremail@domain.com)').required('Email is required field'),
        message: yup.string().required('Message is required field')
    })

    const [errMesssage, setErrMessage] = useState('');

    const handleSend = async (values, actions) => {
        try {

            const res = await sendChangePlanEmail(values);

            if (res.isSuccess) {
                dispatch.UI.setAlert({ type: 'Success', message: res?.message });
                actions.setSubmitting(false)
                actions.resetForm();
                setContactUs(false);
            }

        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to send email'
            setErrMessage(errMsg);
        }
    }

    return (
        <Formik
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleSend(values, actions);
            }}
        >
            {({ values, handleChange, handleBlur, errors, touched, isSubmitting, handleSubmit }: any) => {
                return (
                    <Modal
                        title="Contact Us!"
                        cancelButtonProps={{ loading: isSubmitting }}
                        okButtonProps={{ loading: isSubmitting }}
                        onOk={() => handleSubmit()}
                        onCancel={() => {
                            setContactUs(false)
                        }}
                        visible={contactUs}
                        okText='Send'
                    >
                        <div className='px-4'>
                            <div className='flex flex-col w-full justify-center items-start'>
                                <InputContainer margin='none' border='grey' flex='row'>
                                    <Input
                                        style={{ textTransform: 'capitalize' }}
                                        disabled={isSubmitting}
                                        value={values.name}
                                        onChange={handleChange('name')}
                                        placeholder='Enter Name*'
                                        onBlur={handleBlur('name')}
                                        border='none'
                                    />
                                </InputContainer>
                                {errors.name && touched.name && <Span color='red' size='xs'>{errors.name}</Span>}
                            </div>

                            <div className='flex flex-col w-full justify-center items-start'>
                                <InputContainer margin='mt-4' border='grey' flex='row'>
                                    <Input
                                        disabled={isSubmitting}
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        placeholder='Enter email ex:(your_email@gmail.com)*'
                                        onBlur={handleBlur('email')}
                                        border='none'
                                    />
                                </InputContainer>
                                {errors.email && touched.email && <Span color='red' size='xs'>{errors.email}</Span>}
                            </div>

                            <div className='flex flex-col w-full justify-center items-start'>
                                <Textarea
                                    disabled={isSubmitting}
                                    value={values.message}
                                    onChange={handleChange('message')}
                                    placeholder='Message'
                                    onBlur={handleBlur('message')}
                                    rows={5}
                                    style={{
                                        resize: 'none',
                                        columnCount: 5,
                                        padding: '.5rem',
                                        marginTop: '1rem',
                                        textTransform: 'capitalize'
                                    }}
                                />
                                {errors.message && touched.message && <Span color='red' size='xs'>{errors.message}</Span>}
                            </div>

                            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
                        </div>
                    </Modal >
                )
            }}
        </Formik >
    )
}

export default ContactUs