import { Avatar } from 'antd';
import React, { useState } from 'react'
import ImageViewer from '../../components/ModuleComponents/Modal/ImageViewer';

const Attachments = ({ data }) => {

    const [open, setOpen] = useState(false);

    const handleClickImage = () => {
        setOpen(!open);
    }

    return (
        <>
            <div onClick={handleClickImage} className=" cursor-pointer hover:scale-105 transition-all ease-in-out">
                <Avatar.Group
                    maxCount={2}
                    maxPopoverTrigger={undefined}
                >
                    {data?.imagesName?.map((image: any, index: any) => {
                        const source = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/task%2F${image}?alt=media&token=77af9bf2-8b1b-4f80-910f-c0b19ad26d3a`;

                        return (
                            <Avatar key={index} src={source} />
                        )
                    })}
                </Avatar.Group>
            </div>
            {open && <ImageViewer open={open} setOpen={setOpen} data={data} />}
        </>
    )
}

export default Attachments