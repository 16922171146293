import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { collection, getDocs, getFirestore, onSnapshot, doc as Doc, query, where, startAt, orderBy, endAt } from "firebase/firestore";
import { FILTER_ORGANIZATION_1, FILTER_ORGANIZATION_2 } from "../components/constants";

var TABLE_UNSUBSCRIBE: any;

interface IFirebaseTablePayload {
  table: string;
  name: string;
  order_by?: string;
  start_at?: any;
  end_at?: any;
  tab?: any;
  type?: any;
}

interface ITableUnsubscribePayload {
  table: string;
  data: any;
}


interface ITableDataPayload {
  table: string;
  data: any;
  next?: any;
  previous?: any;
  totalRecords?: any;
  value?: number;
}

interface IFilterMultiplePayload {
  table: string,
  filters: any
}


export interface IFilterValue {
  field: string;
  operation: string;
  value: any;
  type: string;
}



interface IFilterPayload {
  table: string;
  key: string;
  filter: any;
}

let defaultTableValue = {
  filters: {},
  limit: 10,
  page: 1,
  sort: '',
  unsubscribe: null,
  data: [],
  totalRecords: null,
  keyword: '',
  original_data: null
}


const initialState = {
  tables: {
    timesheet: defaultTableValue,
    alltimesheet: defaultTableValue,
    employees: defaultTableValue,
    users: defaultTableValue,
    organization: defaultTableValue,
    role: defaultTableValue,
    leave: defaultTableValue,
    log: defaultTableValue,
    overtime: defaultTableValue,
    workflow: defaultTableValue,
    workflow_employee: defaultTableValue,
    calendar: defaultTableValue,
    contracts: defaultTableValue,
    credentials: defaultTableValue,
    todayAllTimesheet: defaultTableValue,
    todayCompletedTask: defaultTableValue,
    yesterdayAllTimesheet: defaultTableValue,
    yesterdayCompletedTask: defaultTableValue,
    todayActiveEmployees: defaultTableValue,
    todayActiveEmployeesWithTasks: defaultTableValue,
    referrals: defaultTableValue,
  },
  isLoading: true,
  currentRoute: null,
  customerInfo: {},
  driverInfo: [],
  driverDetails: []
} as any;

export const Table = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */
    resetTable(state, table: string) {
      return {
        ...state,
        tables: { ...state.tables, [table]: { ...initialState.tables[table] } },
      };
    },
    setFilterValue(state, payload: IFilterPayload) {
      const { table, key, filter } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            filters: { [key]: filter },
          },
        },
      };
    },
    setMultipleFilterValue(state, payload: IFilterMultiplePayload) {
      const { table, filters } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            filters: filters
          }
        }
      }
    },
    setTableData(state, payload: ITableDataPayload) {
      const { table, data, totalRecords = null } = payload;

      return {
        ...state,
        isLoading: false,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            data,
            totalRecords
          },
        },
      };
    },
    setFirebaseData(state, payload: ITableDataPayload) {
      const { table, data } = payload;

      return {
        ...state,
        isLoading: false,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            original_data: data,
            data,
          },
        },
      };
    },
    updatePageIndex(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            page: value,
          },
        },
      };
    },
    updatePageLimit(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            limit: value,
          },
        },
      };
    },
    updateKeyword(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            keyword: value,
          },
        },
      };
    },
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState
      };
    },
    setTableUnsubscribe(state, payload: ITableUnsubscribePayload) {
      const { table, data } = payload;
      console.log("here", data)

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            unsubscribe: data
          },
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getFirebaseData(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();
      const organization = rootState.User?.userInfo.organization;
      const roleModules = rootState.User?.roleModules;

      let { name, table, order_by, start_at, end_at } = payload
      let { filters } = rootState.Table.tables[table]
      let q = query(collection(db, name));

      if (filters) {
        for (const key in filters) {
          let { field, operation, value } = filters[key]

          if (value) {
            q = query(q, where(field, operation, value))
          }
        }
      }

      //organization filter
      if (FILTER_ORGANIZATION_1.includes(table)) {
        switch (name) {
          case 'tasks':
            q = query(q, where('userInfo.organization.id', '==', organization?.id));
            break;
          case 'timesheet':
            q = query(q, where('userInfo.organization.id', '==', organization?.id));
            break;
          default:
            q = query(q, where('organization.id', '==', organization?.id));
            break;
        }
      }

      if (FILTER_ORGANIZATION_2?.includes(table) && !roleModules.includes('organization')) {
        switch (table) {
          case 'employees':
            q = query(q, where('organization.id', '==', organization?.id));
            break;
          case 'workflow':
            q = query(q, where('createdBy.organization.id', '==', organization?.id));
            break;
          default:
            q = query(q, where('userInfo.organization.id', '==', organization.id))
            break;
        }
      }

      //apply orderBy and startAt
      if (order_by && start_at && !end_at) {
        q = query(q, orderBy(order_by), startAt(start_at));
      }

      if (order_by && start_at && end_at) {
        q = query(q, orderBy(order_by), startAt(start_at), endAt(end_at))
      }

      let querySnapshot = await getDocs(q);

      const listData = (querySnapshot?.docs || []).map((ref) => {
        const data = ref.data() as any;
        return data
      }) as any[];

      dispatch.Table.setTableData({
        table: table,
        data: listData
      })

    },

    async subscribeToFirebase(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();
      const organization = rootState.User?.userInfo.organization;
      const roleModules = rootState.User?.roleModules;

      let { name, table, order_by, start_at, end_at } = payload
      const { data } = rootState.Table.tables[table];

      if (data?.length > 0) dispatch.Table.updateState({ isLoading: false });
      if (TABLE_UNSUBSCRIBE) TABLE_UNSUBSCRIBE();

      let q = query(collection(db, name));

      //organization filter
      if (FILTER_ORGANIZATION_1.includes(table)) {
        switch (name) {
          case 'tasks':
            q = query(q, where('userInfo.organization.id', '==', organization?.id));
            break;
          case 'timesheet':
            q = query(q, where('userInfo.organization.id', '==', organization?.id));
            break;
          default:
            q = query(q, where('organization.id', '==', organization?.id));
            break;
        }
      }

      if (FILTER_ORGANIZATION_2?.includes(table) && !roleModules.includes('organization')) {
        switch (table) {
          case 'employees':
            q = query(q, where('organization.id', '==', organization?.id));
            break;
          case 'workflow':
            q = query(q, where('createdBy.organization.id', '==', organization?.id));
            break;
          default:
            q = query(q, where('userInfo.organization.id', '==', organization.id));
            break;
        }
      }

      //apply orderBy and startAt
      if (order_by && start_at) {
        q = query(q, orderBy(order_by), startAt(start_at));
      }

      if (order_by && start_at && end_at) {
        q = query(q, orderBy(order_by), startAt(start_at), endAt(end_at))
      }

      TABLE_UNSUBSCRIBE = onSnapshot(q, (doc) => {
        const listData = (doc?.docs || []).map((ref) => {
          const data = ref.data();
          return data
        }) as any[];

        dispatch.Table.setFirebaseData({
          table: table,
          data: listData,
        })

      })

    },
    async unsubscribed() {
      TABLE_UNSUBSCRIBE();
    },

  }),
});
