import Attachment from '../containers/Authentication/Redirect/SESuccess';

const SESuccess = () => {
    return (
        <>
            <Attachment />
        </>
    )
}

export default SESuccess
