import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { Drawer } from 'antd';
import { updateScheduledTask } from '../../common/api';
import DrawerFooter from './Footer/DrawerFooter';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import ScheduledTaskForm from './Forms/ScheduleTaskForm/ScheduledTaskForm';
import moment from 'moment';
import { firebaseDateToText } from '../../common/utils';

type Props = {
    table: string
}

type State = {
    cron: string,
    remarks: string,
    images: Array<any>,
    dueDate: any,
    employees: Array<any>,
    taskName: string,
    startTime: any,
    endTime: any,
    status: string,
}

const UpdateScheduledTask = ({ table }: Props) => {
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)')

    const currentUpdateId = useSelector((state: RootState) => state.UI.forms?.currentUpdateId.id);
    const task = useSelector((state: RootState) => state.Table.tables[table]?.data)?.find(d => d.id === currentUpdateId);
    const workflowEmployees = useSelector((state: RootState) => state.Table.tables.workflow_employee.data);

    const initialState = {
        cron: task?.cron ?? '0 0 * * *',
        remarks: task?.remarks ?? '',
        oldImages: task?.imagesName ?? [],
        deletedImages: [],
        images: [],
        dueDate: firebaseDateToText(task?.dueDate) ?? null,
        employees: workflowEmployees ?? [],
        taskName: task?.taskName ?? '',
        startTime: null,
        endTime: null,
        status: 'pending',
        scheduled_status: task?.scheduled_status,
    } as State;

    const validateSchema = yup.object({
        cron: yup.string().required('Cron is required field'),
        taskName: yup.string().required('Name is required field'),
        remarks: yup.string(),
        employees: yup.array().required('Employees is required field').min(1, 'Employees field must be atleast 1 employee'),
        dueDate: yup.string().test('dueDate', 'Due Date should be after creation date', (val) => {
            const dateNow = moment();
            const selectedDate = moment(val);
            if (!val) return true;
            if (dateNow.isBefore(selectedDate)) {
                return true;
            }
            return false
        }).nullable()
    })

    const dispatch = useDispatch<Dispatch>();

    const [errMesssage, setErrorMesage] = useState(null) as any;

    const handleUpdateScheduledtask = async (values, actions) => {
        try {
            const payload = {
                ...values,
                employees: values?.employees?.map(e => e?.id),
                id: task?.id,
                scheduleId: task?.scheduleId
            }
            let res;

            switch (table) {
                case 'workflow':
                    res = await updateScheduledTask(payload);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                actions.setSubmitting(false)
                dispatch.UI.resetForm('currentUpdateId')
                history.goBack();
                actions.resetForm();
                setErrorMesage(null);
                dispatch.UI.setAlert({ type: 'Success', message: 'Successfully updated' })
                dispatch.Table.getFirebaseData({ table, name: table })
            }
        } catch (err: any) {
            actions.setSubmitting(false)
            const errMsg = err?.response?.data?.error ?? 'Unable to update user'
            setErrorMesage(errMsg);
        }
    }

    useEffect(() => {
        if (task?.employees?.length) {
            const filters = {
                emp: {
                    field: 'id',
                    operation: 'in',
                    value: task?.employees
                }
            };

            dispatch.Table.setMultipleFilterValue({ table: 'workflow_employee', filters: filters })

            dispatch.Table.getFirebaseData({ table: 'workflow_employee', name: 'employees' });
        }
    }, [task])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialState}
            validationSchema={validateSchema}
            onSubmit={(values, actions) => {
                handleUpdateScheduledtask(values, actions);
            }}
        >
            {(props) => {
                return (
                    <Drawer
                        title="Update Scheduled Task"
                        placement="right"
                        width={mobile ? '100%' : 500}
                        onClose={() => {
                            dispatch.UI.resetForm('currentUpdateId')
                            history.goBack();
                            props.resetForm();
                        }}
                        visible={currentUpdateId ? true : false}
                        footer={
                            <DrawerFooter
                                {...props}
                                funcToRun={() => dispatch.UI.resetForm('currentUpdateId')}
                                setErrorMesage={setErrorMesage}
                                okText='Submit'
                            />
                        }
                    >
                        {currentUpdateId && <ScheduledTaskForm {...props} errMesssage={errMesssage} />}
                    </Drawer>
                )
            }}
        </Formik>
    )
}

export default UpdateScheduledTask;