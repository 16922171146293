import { Avatar } from "antd";
import moment from "moment";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText } from "../../../common/utils";
import { Span } from "../../../components/common/Span";
import Options from "../../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "employee",
    field: "employee",
    Header: "Employee Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={d.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} >{d.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{d.name}</Span>
        </div>
      )
    }
  },
  {
    id: "timein",
    field: "timein",
    Header: "Time in",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <span>{d?.time}</span>
    }
  },
  {
    id: "task",
    accessor: 'task',
    field: "task",
    Header: "Task",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      return (
        <Span transform='capitalize' color='lightgrey'>{d.task}</Span>
      )
    }
  }
];
