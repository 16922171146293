import { get, post, postMultipart, put, remove } from "../core";

export const createUser = async (body: any) => {
    try {
        const res = await post('/admin/create/employee-account', body);
        console.log(res.data);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addBulkEmployee = async (file: any) => {
    try {
        const body: any = new FormData();
        body.append('file', file);
        body.append('name', 'Bulk Employee');

        const res = await postMultipart(`/admin/create/bulk/employee-account`, body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateEmployeeAccount = async (id: string, payload: any) => {
    try {
        const res = await put(`/admin/update/employee-account/${id}`, payload)
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const deleteEmployeeAccount = async (id: string) => {
    try {
        const res = await remove(`/admin/delete/employee-account/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const setEmployeeSchedule = async (body: Object) => {
    try {
        const res = await put(`/admin/set/employee-schedule`, body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const verifyLeaveRequestStatus = async (id: string) => {
    try {
        const res = await get(`/employee/request-status/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateLeaveRequestStatus = async (body: any) => {
    try {
        const res = await put('/admin/update/leave-request', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const verifyLogRequestStatus = async (id: string) => {
    try {
        const res = await get(`/employee/log/request-status/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateLogRequestStatus = async (body: any) => {
    try {
        const res = await put('/admin/update/log-request', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const verifyOTRequestStatus = async (id: string) => {
    try {
        const res = await get(`/employee/over-time/request-status/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const updateOTRequestStatus = async (body: any) => {
    try {
        const res = await put('/admin/update/over-time-request', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const requestLeave = async (body: any) => {
    try {
        const res = await post('/employee/request-leave', body);
        return res.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const changePassword = async (body: any) => {
    try {
        const res = await post('/admin/reset/password', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const loginUsingPhoneNumber = async (body: any) => {
    try {
        const res = await post('/employee/login-using/phone-number', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const checkOtpCodeForLogin = async (body: any) => {
    try {
        const res = await post('/employee/check-otp/for-login', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const resendVerificationEmail = async (body) => {
    try {
        const res = await post('/employee/resend-email/verification', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const sendOtpEmailVerification = async (body) => {
    try {
        const res = await post('/employee/send-otp-email/verification', body);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const generateReferralCode = async () => {
    try {
        const res = await get('/admin/generate/referral-code');
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const verifyReferralCode = async (code) => {
    try {
        const res = await get(`/admin/verify/referral-code/${code}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}