import { Span } from '../../../common/Span';
import { Input } from '../../../common/Input';
import Cron from 'react-js-cron';
import cronstrue from 'cronstrue';
import '../Forms.css'
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useCallback, useEffect, useState } from 'react';
import { IMAGE_TYPE } from '../../../constants';
import { getBase64, useQuery } from '../../../../common/utils';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Textarea } from '../../../common/Textarea';
import { Switch } from 'antd';
import RenderOption from './RenderOption';
import OldImage from './OldImage';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    values: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    errMesssage: string,
    setFieldValue: Function,
    setFieldTouched: Function
    setErrors: Function
}

const ScheduledTaskForm = ({ values, handleChange, handleBlur, errors, touched, errMesssage, setFieldValue, setFieldTouched, setErrors }: Props) => {
    const query = useQuery();
    const dispatch = useDispatch<Dispatch>();
    const mobile = useMediaQuery('(max-width:600px)')
    const type = query.get('type');

    const employees = useSelector((state: RootState) => state.Table.tables.employees.data);

    const [selectedIndex, setSelectedIndex] = useState([]) as any;
    const [images, setImages] = useState([]) as any;
    const [search, setSearch] = useState('');
    const [fileError, setFileError] = useState(null) as any;

    const cronResult = cronstrue.toString(values.cron)
    const roleArray = employees?.map(emp => emp?.role?.name ?? 'employee');
    const role = [...new Set(roleArray)];
    const listOfEmployees: any = role?.map((r: any, index: any) => {
        let result;

        if (r === 'employee') {
            result = employees?.filter(e => !e?.role?.name);
        } else {
            result = employees?.filter(e => e?.role?.name === r)
        }

        const res = {
            title: `All ${r?.split(' ').map(str => str[0]?.toUpperCase() + str.substring(1))?.join(' ')}`,
            value: result
        }

        return res;
    })

    const validateFileType = (files) => {
        const fileTypes = Object.values(files)?.map((f: any) => f?.type);
        const testResult = fileTypes?.map(type => IMAGE_TYPE?.includes(type) ? 'valid' : 'invalid');

        const result = testResult?.includes('invalid') ? 'invalid' : 'valid';

        return result;
    }

    const handleImage = async (e) => {
        e.preventDefault();
        const files = e?.target?.files;

        if (validateFileType(files) === 'invalid') {
            console.log('alert')
            setFileError('Invalid file type, please check the selected file');
            return;
        }

        const filesUri: any = Object?.values(files)?.map(async (f: any) => {
            const res = {
                uri: await getBase64(f),
                name: f?.name
            }
            return res;
        });

        const promiseResult = await Promise.allSettled(filesUri);
        const uris: any = promiseResult?.map((r: any) => r?.value);
        setFieldValue('images', [...values?.images, ...Object?.values(files)?.map(f => f)])
        setImages([...images, ...uris]);
    }

    const handleRemoveImage = (data) => {
        const value = values?.images?.filter(i => i?.name !== data?.name);
        setFieldValue('images', value);
        setImages(images?.filter(i => i?.name !== data?.name))
    }

    return (
        <div className='text-left'>
            <div className='flex flex-col justify-start items-start'>
                <div className='flex flex-row justify-start items-center'>
                    <Span margin='mt-4'>Task</Span>
                    <Span color='red'>*</Span>
                    {/* <Span margin='mt-4' color='lightgrey'>(Optional)</Span> */}
                </div>
                <Input
                    style={{ textTransform: 'capitalize' }}
                    value={values.taskName}
                    onChange={handleChange('taskName')}
                    placeholder='Name your task...'
                    onBlur={handleBlur('taskName')}
                />
                {errors.taskName && touched.taskName && <Span color='red' size='xs'>{errors.taskName}</Span>}
            </div>

            <div className='flex flex-col justify-start items-start'>
                <div className='flex flex-row justify-start items-center'>
                    <Span margin='mt-4'>Assign To</Span>
                    <Span color='red'>*</Span>
                </div>

                {/* <div className={`grid ${mobile ? 'grid-cols-1' : 'grid-cols-3'} w-full pl-4`}>
                    {listOfEmployees?.map((data: any) => (
                        <div className='flex flex-row justify-start items-center'>
                            <FormControlLabel
                                control={
                                    <Checkbox sx={{ padding: 0 }} size='small' onChange={(value) => console.log(value)} />
                                }
                                label={<Span size='xs'>{data?.title}</Span>}
                            />
                        </div>
                    ))}
                </div> */}

                <Autocomplete
                    style={{ width: '100%' }}
                    disablePortal
                    disableClearable
                    disableCloseOnSelect
                    multiple
                    onFocus={() => setFieldTouched('employees')}
                    size="small"
                    id="combo-box-demo"
                    options={[{ name: 'Select all' }, ...employees]}
                    value={values?.employees}
                    onChange={(event, newValue: any) => {
                        if (newValue.some((option: any) => option?.name === 'Select all')) {
                            setFieldValue('employees', employees);
                        } else {
                            setFieldValue('employees', newValue)
                        }
                    }}
                    onBlur={handleBlur('role')}
                    getOptionLabel={(option: any) => option.name}
                    ListboxProps={{
                        style: {
                            maxHeight: '220px',
                        }
                    }}
                    renderOption={(props, option, { selected }) =>
                        <RenderOption
                            props={props}
                            option={option}
                            values={values}
                            selected={selected}
                            setFieldValue={setFieldValue}
                            employees={employees}
                        />
                    }
                    renderInput={(params) => (
                        <TextField
                            placeholder='Select Role...'
                            sx={{
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                },
                            }}
                            {...params}
                        />
                    )}
                />

                {errors.employees && touched.employees && <Span color='red' size='xs'>{errors.employees}</Span>}
            </div>

            <div className='flex flex-row justify-between items-center mt-4 mb-2'>
                <Span>Status</Span>
                <Switch
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    checked={values?.scheduled_status === 'active' ? true : false}
                    onChange={(value: any) => setFieldValue('scheduled_status', value ? 'active' : 'inactive')}
                />
            </div>

            <div className='flex flex-col justify-start items-start'>
                <Span >Select Recurring</Span>
                <div className='flex p-4 border border-gray-300 border-solid flex-col w-full rounded-sm'>
                    <div className='flex flex-row justify-start items-start'>
                        <Span weight='semi'>Result: </Span>
                        <Span color='brown' margin='ml-2'> {cronResult}</Span>
                    </div>
                    <Cron
                        humanizeLabels={true}
                        className='ant-cron'
                        value={values.cron}
                        setValue={(newValue: string) => {
                            console.log(newValue);
                            setFieldValue('cron', newValue)
                        }}
                        onError={(err) => setErrors({ cron: err })}
                        clearButton={false}
                    />
                </div>
                {errors.cron && touched.cron && <Span color='red' size='xs'>{errors.cron}</Span>}
            </div>

            <div className='flex flex-col justify-start items-start'>
                <div className='flex flex-row justify-start items-center'>
                    <Span margin='mt-4'>Image</Span>
                    <Span margin='mt-4' color='lightgrey'>(Optional)</Span>
                </div>
                <div className=' flex border border-gray-300 border-solid rounded-sm w-full flex-row justify-start items-center flex-wrap p-2'>
                    {type === 'update' && values?.oldImages?.map((old_image: any) => <OldImage data={old_image} setFieldValue={setFieldValue} values={values} />)}
                    {images?.map(image => (
                        <div key={image?.name} className='w-14 h-14 p-1 border border-gray-300 border-solid relative mr-2 rounded-sm'>
                            <AiOutlineCloseCircle className=' text-sm text-red-400 absolute top-[-5px] right-[-5px] cursor-pointer' onClick={() => handleRemoveImage(image)} />
                            <img src={image?.uri} alt={image?.name} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
                        </div>
                    ))}
                    <input type="file" name="file" id="file" onChange={handleImage} accept="image/*" multiple />
                    <label htmlFor="file" className="cursor-pointer">
                        <BsFileEarmarkPlus style={{ fontSize: '40px', color: 'lightgray' }} />
                    </label>
                </div>

                {fileError && <Span color='red' size='xs'>{fileError}</Span>}
            </div>

            <div className='flex flex-col justify-start items-start'>
                <div className='flex flex-row justify-start items-center'>
                    <Span margin='mt-4'>Remarks</Span>
                    <Span margin='mt-4' color='lightgrey'>(Optional)</Span>
                </div>
                <Textarea
                    style={{ textTransform: 'capitalize', resize: 'none' }}
                    value={values.remarks}
                    onChange={handleChange('remarks')}
                    placeholder='Task Description...'
                    onBlur={handleBlur('remarks')}
                    rows={3}
                />
                {errors.remarks && touched.remarks && <Span color='red' size='xs'>{errors.remarks}</Span>}
            </div>

            {errMesssage && <Span color='required' size='xs' margin='mt-2' align='center'>{errMesssage}</Span>}
        </div>
    )
}

export default ScheduledTaskForm