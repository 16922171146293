import { Avatar } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { firebaseDateToText } from "../../../common/utils";
import { Span } from "../../../components/common/Span";
import moment from "moment";
import Options from "../../../components/ModuleComponents/Popover/Options";

export const COLUMNS = [
  {
    id: "employee",
    field: "employee",
    Header: "Employee Name",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      const user = d?.userInfo;
      return (
        <div className="flex flex-row justify-start items-center">
          <div className="w-8 h-8 mr-2">
            <Avatar src={user.image} style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 10 }} >{user.name?.charAt(0).toUpperCase()}</Avatar>
          </div>
          <Span transform='capitalize'>{user.name}</Span>
        </div>
      )
    }
  },
  {
    id: "position",
    accessor: 'position',
    field: "position",
    Header: "Position",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original;
      const role = d?.userInfo?.role?.name;
      return (
        <>
          <Span transform='capitalize'>{d?.userInfo?.position}</Span>
          {role && (
            <Span transform='capitalize' color='lightgrey'>({role})</Span>
          )}
        </>
      )
    }
  },
  {
    id: "clockIn",
    field: "clockIn",
    Header: "Clock In",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {!d?.clockIn ? (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          ) : (
            <span>{firebaseDateToText(d?.clockIn?.time, 'LLL')}</span>
          )}
        </>
      )
    }
  },
  {
    id: "clockOut",
    field: "clockOut",
    Header: "Clock Out",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return (
        <>
          {!d?.clockOut ? (
            <div className="emptyContainer flex flex-row justify-start items-center">
              <AiOutlineInbox className=" text-base text-palette-lightgrey mr-2" />
              <Span color='lightgrey'>No Data</Span>
            </div>
          ) : (
            <span>{firebaseDateToText(d?.clockOut?.time, 'LLLL')}</span>
          )}
        </>
      )
    }
  },
  {
    id: "timesheetDate",
    field: "timesheetDate",
    Header: "Timesheet Date",
    isVisible: true,
    Cell: data => {
      const d = data?.row?.original
      return <span>{moment(d?.timesheetDate).format('LL')}</span>
    }
  },
  {
    accessor: ' ',
    Header: ' ',
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: data => {
      const d = data.row.original;
      return (
        <Options data={d} table='without_clockout' subPermission='canViewLogsSub' />
      )
    }
  }
];
